import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography, } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { useRecoilState } from "recoil";
import { globalSpinner } from "../../recoil/profile-atom";
import { KSArrowLeftIcon } from "../../icons/custome-icon";


export const DialogCommonHooks = (props) => {
  let [spinner, setSpinner] = useRecoilState(globalSpinner);
  return (
    <Dialog
      fullWidth
      maxWidth={props.size}
      open={props.show}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle
        sx={{ fontWeight: "bold", display: props?.title ? "" : "none" }}
      >
        {props?.title}

        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>


      <IconButton
        aria-label="close"
        onClick={props.handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "black",
        }}
      >
        <CloseIcon sx={{ display: props?.onlyCloseIcon ? null : "none" }} />
      </IconButton>


      <DialogContent>
        <LoadingOverlay
          active={spinner}
          spinner={true}
          text="Please wait a moment"
        >{props.body}
        </LoadingOverlay>
      </DialogContent>
      {props.footer ? (
        <DialogActions sx={{ py: 3 }}>{props.footer}</DialogActions>
      ) : null}
    </Dialog>
  );
};


// export const DocumentDialogCommonHooks = (props) => {
//   const [spinner, setSpinner] = useRecoilState(globalSpinner);

//   return (
//     <Dialog
//       fullWidth
//       maxWidth={props.size}
//       open={props.show}
//       aria-labelledby="customized-dialog-title"
//     >
//       <div style={{ position: 'relative' }}>
//         {/* Blue strip at the top */}
//         <div style={{ height: '10px', backgroundColor: 'blue' }}></div>

//         {/* Title and close button */}
//         <Box
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-between',
//             padding: '16px',
//             borderBottom: '1px solid #ccc'
//           }}
//         >
//           <Typography variant="h6">{props.title}</Typography>
//           <IconButton
//             aria-label="close"
//             onClick={props.handleClose}
//             sx={{
//               color: 'black'
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </Box>

//         <DialogContent>
//           <LoadingOverlay
//             active={spinner}
//             spinner={true}
//             text="Please wait a moment"
//           >
//             {props.body}
//           </LoadingOverlay>
//         </DialogContent>

//         {props.footer && (
//           <DialogActions sx={{ py: 3 }}>{props.footer}</DialogActions>
//         )}
//       </div>
//     </Dialog>
//   );
// };
export const DocumentDialogCommonHooks = (props) => {
  const [spinner, setSpinner] = useRecoilState(globalSpinner);

  return (
    <Dialog
      fullWidth
      maxWidth={props.size}
      open={props.show}
      aria-labelledby="customized-dialog-title"
    >
      <div style={{ position: 'relative' }}>
        {/* Blue strip at the top */}
        <div style={{ height: '10px', backgroundColor: '#2F80ED' }}></div>

        <Grid container justifyContent="center" alignItems="center">
          <Grid item md={10}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',

                paddingX: 5,
                paddingTop: 5,
                //  border: '1px solid red',
                color: 'black',
                boxSizing: 'border-box' // Ensure padding doesn't affect width
              }}
            >
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                {props.title}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={props.handleClose}
                sx={{
                  color: 'white'
                }}
              >
                <CloseIcon sx={{ color: 'black' }} />
              </IconButton>
            </Box>


            <Box
              sx={{
                paddingX: 2,
                // border: '1px solid red',
              }}
            >
              <DialogContent>
                <LoadingOverlay
                  active={spinner}
                  spinner={true}
                  text="Please wait a moment"
                >
                  {props.body}
                </LoadingOverlay>
              </DialogContent>

              {props.footer && (
                <DialogActions sx={{ py: 2 }}>{props.footer}</DialogActions>
              )}
            </Box>
          </Grid>
        </Grid>


      </div>
    </Dialog>
  );
};


export const PreviewPDFModal = (props) => {
  return (
    <Dialog
      fullWidth
      maxWidth={props.size}
      open={props.show}
      aria-labelledby="File-Preview"
      PaperComponent={Box}
    >
      <DialogContent sx={{ p: 0 }}>
        <Box pb={5} position={'relative'}>
          <Tooltip title={'Close'}>
            <IconButton
              aria-label="close"
              sx={{
                position: "absolute",
                left: 16,
                top: 0,
                p: 0.5,
                color: "white"
              }}
            >
              <KSArrowLeftIcon onClick={props.handleClose} />
              <Typography pl={1}>
                {"Generated Quote"}
              </Typography>
            </IconButton>
          </Tooltip>
        </Box>
        {props.body}
      </DialogContent>
      {props.footer ? (
        <DialogActions sx={{ py: 3 }}>{props.footer}</DialogActions>
      ) : null}
    </Dialog>
  );
};


