import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { TimeStampToMonthDateYear } from '../../../../../helpers/time-formater'

export default function LoanCreditReportStatus({ creditData, handleCreditSubmit, handlePrevious }) {

    let generateText;


    if (creditData?.isPulled) {
        if (!creditData?.creditReportStatus) {
            generateText = `Credit report pulled on ${TimeStampToMonthDateYear(creditData?.pullDate)} with qualifying credit score ${creditData?.creditScore}.`
        } else {
            generateText = `Credit consent was given on ${TimeStampToMonthDateYear(creditData?.consentDate)} Credit pull is failed please report to your loan officer. 
                            You still can continue the application and submit`
        }
    } else {
        generateText = `Broker can not gave the permission to pull the credit report.`
    }


    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box>
                    <Typography variant='h3' mb={2}>{`Credit Report`}</Typography>
                    <Typography variant='body1' mb={2}>
                        {generateText}
                    </Typography>
                </Box>

                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Button onClick={handlePrevious} startIcon={<ArrowBackIos />}>
                            Previous
                        </Button>
                        <Button onClick={handleCreditSubmit} endIcon={<ArrowForwardIos />} >
                            Next
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </>
    )
}
