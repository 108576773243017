import { Box, Button } from "@mui/material";
import React from "react";
import CustomInput from "../../../../helpers/custom-input";
import { CREATE, DESIGNATOR_LIST, MODAL_ACTION } from "../../../../constants/constatnt-variable";
import { Controller, useForm } from "react-hook-form";
import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";
import { AdditionalLoanContactValidation } from "../../../../constants/validation-rules";

export default function AdditionalLoanContactForm({
  data,
  action = CREATE,
  onSubmit
}) {



  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      name: data?.name,
      phone: data?.phone,
      email: data?.email,
      companyName: data?.companyName,
      designator: data?.designator,
      id: data?.id
    }
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name='name'
        control={control}
        rules={AdditionalLoanContactValidation.name}
        render={({ field }) => (
          <CustomInput
            {...field}
            type={'text'}
            label={'Name'}
            required
            placeholder={'Jhonson'}
            error={errors?.name?.message}
          />

        )}
      />
      <Controller
        name='phone'
        control={control}
        rules={AdditionalLoanContactValidation.phone}
        render={({ field }) => (
          <CustomInput
            {...field}
            type={'number'}
            label={'Mobile number'}
            required
            placeholder={'(956)252-5262'}
            error={errors?.phone?.message}
          />

        )}
      />
      <Controller
        name='email'
        control={control}
        rules={AdditionalLoanContactValidation.email}
        render={({ field }) => (
          <CustomInput
            {...field}
            type={'text'}
            label={'Email ID'}
            required
            placeholder={'Johnson@wiscosin.com'}
            error={errors?.email?.message}
          />

        )}
      />
      <Controller
        name='companyName'
        control={control}
        rules={AdditionalLoanContactValidation.companyName}
        render={({ field }) => (
          <CustomInput
            {...field}
            type={'text'}
            label={'Company Name'}
            required
            error={errors?.companyName?.message}
          />

        )}
      />

      <Controller
        name='designator'
        control={control}
        rules={AdditionalLoanContactValidation.designator}
        render={({ field }) => (
          <>
            <CustomDropDown
              {...field}
              field={field}
              label={'Designator'}
              required
              option={DESIGNATOR_LIST}
              error={errors?.designator?.message}
            />
          </>
        )}
      />
      <Box display="flex" justifyContent="flex-end" m={1}>
        <Button type="submit" id={action} variant="contained">
          {MODAL_ACTION[action]}
        </Button>
      </Box>
    </form>
  );
}
