import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";

export default function CustomRadioChip({
  data,
  state,
  label,
  field,
  required = false,
  error = null,
  labelComponent,
}) {
  const handleClick = (e) => {
    state.saveValue(e.target.id);
  };
  return (
    <Box my={2}>
      <FormControl>
        {labelComponent ? (
          labelComponent
        ) : (
          <FormLabel>
            {label}
            {required ? (
              <Typography component={"span"} color={"error.main"}>
                *
              </Typography>
            ) : null}
          </FormLabel>
        )}

        <RadioGroup row>
          {data.map((data) => (
            <>
              <FormControlLabel
                key={data.value}
                sx={{ margin: 0 }}
                control={
                  <Radio
                    id={data.value}
                    value={data.value}
                    sx={{ display: "none" }}
                  />
                }
                label={
                  <Chip
                    label={data.label}
                    color={
                      field?.value == data?.value || state?.value == data?.value
                        ? "primary"
                        : "default"
                    }
                    clickable={true}
                    variant="outlined"
                    sx={{ marginRight: 1.5, marginY: 1.5 }}
                  />
                }
                // onClick={handleClick}
                {...(field ? field : { onClick: handleClick })}
              />
            </>
          ))}
        </RadioGroup>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
