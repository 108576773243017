import axios from "axios";
import { APIConfig } from "../auth/config";
import { AlertError } from "./alert-msg";
import { API } from "../services/api-config";
import FilePreview from "../pages/broker/leads/file-preview";


export const downloadReportFromServer = (url, data) => {
    let params = {};
    if (data) {
        params = data;
    }

    console.log('download document called' + url + data)
    return axios
        .get(
            url,
            {
                params: params,
                responseType: "blob",
            }

            // important
        )
        .then((response) => {
            let filename = "";
            let blob = new Blob([response.data]),
                downloadUrl = window.URL.createObjectURL(blob),
                disposition = response.headers["content-disposition"];
            console.log("disposition", disposition);

            if (disposition && disposition.indexOf("attachment") !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                    matches = filenameRegex.exec(disposition);

                if (matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            let a = document.createElement("a");
            if (typeof a.download === "undefined") {
                window.location.href = downloadUrl;
            } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            }
        })
        .catch((error) => AlertError(error));
};

export const getFileUrl = (id, type) => {
    return process.env.REACT_APP_BASE_URL + API.leadDownload + `?id=${id}&&serviceType=${type}&&shouldPreview=true`
}

export const getImageUrl = (id, fileName, type) => {
    if (id && fileName) {
        return process.env.REACT_APP_BASE_URL + API.proxyUserDownload + `?id=${id}&serviceType=${type}&fileName=${fileName}`
    }
}


export const documentPreview = (url, data) => {
    let params = {};
    if (data) {
        params = data;
    }

    console.log('download document called' + url + data)
    return axios
        .get(
            url,
            {
                params: params,
                responseType: "blob",
            }

            // important
        )
        .then((response) => {
            let filename = "";
            let blob = new Blob([response.data], { type: 'application/pdf' });
            // downloadUrl = window.URL.createObjectURL(blob);
            //    disposition = response.headers["content-disposition"];

            const url = URL.createObjectURL(blob);
            console.log(url);


            const windowName = 'popupWindow';
            const windowFeatures = 'width=600,height=800';

            // Open the popup window
            const popupWindow = window.open(url, windowName, windowFeatures);

            // Check if the popup window was blocked by the browser
            if (popupWindow === null || typeof popupWindow === 'undefined') {
                alert('Popup window was blocked by the browser. Please allow popups and try again.');
            } else {
                // Popup window was successfully opened
                // You can manipulate the popup window here if needed
                // For example, you can close it after a certain time with popupWindow.close()
            }
            //  window.open(url, '_blank');
            return url;
            //   console.log("disposition", disposition);






        })
        .catch((error) => AlertError(error));
};



export const docPreview = (url, data) => {
    let params = {};
    if (data) {
        params = data;
    }

    console.log('download document called: ', url, data);

    return axios
        .get(url, {
            params: params,
            responseType: "blob", // Expecting binary data (Blob)
        })
        .then((response) => {
            // Check if the response contains a content type or default to application/octet-stream
            let contentType = response.headers['content-type'] || 'application/octet-stream';
            let blob;

            // Force the content type based on file extension if it's not correct
            if (contentType === 'application/octet-stream' && data.fileName) {
                const extension = data.fileName.split('.').pop().toLowerCase();

                if (extension === 'pdf') {
                    contentType = 'application/pdf';
                } else if (extension === 'jpg' || extension === 'jpeg') {
                    contentType = 'image/jpeg';
                } else if (extension === 'png') {
                    contentType = 'image/png';
                } else {
                    console.error('Unsupported file extension:', extension);
                    throw new Error('Unsupported file type');
                }
            }

            // Create Blob with the corrected MIME type
            blob = new Blob([response.data], { type: contentType });

            // Create URL from Blob
            const fileUrl = URL.createObjectURL(blob);
            console.log('Preview URL:', fileUrl);

            // Load the file into the iframe or open a popup
            const iframe = document.getElementById('previewIframe');
            if (iframe) {
                // If there's an iframe, set the source
                iframe.src = fileUrl;
            } else {
                // If no iframe, open in a popup window
                const windowName = 'popupWindow';
                const windowFeatures = 'width=600,height=800';
                const popupWindow = window.open(fileUrl, windowName, windowFeatures);

                // Handle blocked popups
                if (popupWindow === null || typeof popupWindow === 'undefined') {
                    alert('Popup window was blocked by the browser. Please allow popups and try again.');
                }
            }

            return fileUrl;
        })
        .catch((error) => {
            console.error('Error loading document:', error);
            AlertError(error);
        });
};

