import { Box, Button, DialogActions, Grid, Typography } from "@mui/material";
import React from "react";

export default function DialogSuccesMsg(props) {
  return (
    <>
      <Grid item md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
        <Box display={'flex'} overflow={'hidden'} justifyContent={'center'}>
          <img src="/images/sucess.png" width="368" height="246" alt={"Sucess"} />
        </Box>
        <Typography variant="h5">{props.description}</Typography>
      </Grid>
      <DialogActions sx={{ pt: 3, pb: 3 }}>
        <Grid container item md={12} sx={{ justifyContent: "center" }}>
          <Button onClick={props.handleClose} variant={"contained"}>
            Done
          </Button>
        </Grid>
      </DialogActions>
    </>
  );
}
