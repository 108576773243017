import {
  Avatar,
  Box,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInput from "../../helpers/custom-input";
import { EditOutlined, FileUploadOutlined } from "@mui/icons-material";
import useInput from "../../helpers/use-input";
import {
  getApiCallDynamic,
  postApiCallDynamic,
  postApiCallDynamicWithOutReturn,
  uploadFile,
} from "../../services/api-service";
import { API } from "../../services/api-config";
import { MAXLength } from "../../constants/validation";
import { useRecoilState } from "recoil";
import { globalSpinner, profileState } from "../../recoil/profile-atom";
import { useFormik } from "formik";
import {
  DEMO_IMAGE,
  ROLE_LIST,
  roleOwner,
  UPDATE,
} from "../../constants/constatnt-variable";
import IdentityChange from "../../components/setting/identity-change";
import {
  AccessableRoleForAvatarAndSignature,
  AccessableRoleForProfile,
} from "../../constants/access-control";
import { KSPhoneIcon } from "../../icons/custome-icon";
import { ServerSuccessMsg } from "../../helpers/alert-msg";
import { getImageUrl } from "../../helpers/file-upload-download";

export default function Profile(props) {
  let [profileData, setProfileData] = useRecoilState(profileState);
  let [spinner, setSpinner] = useRecoilState(globalSpinner);
  const profileCall = () => {
    setCustomError('')
    let param = { userCode: props.data.userCode };
    getApiCallDynamic({ param: param, path: API.ownInfo })
      .then((res) => {
        if (res?.status === 200) {
          setProfileData(res.data);
          setSpinner(false);
        }
        setBasicInfo((prev) => !prev);
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
      });
  };
  const [altPhoneChange, setaltPhoneChange] = useState(true);

  let [customError, setCustomError] = useState({})

  const handleUpload = (event, id, type) => {
    let file = event.target.files[0]

    if (event.target.files) {
      if (event.target.files[0].size < MAXLength.AvatarMAXLength) {
        if (type == "avatar") {
          setCustomError({
            avatarSizeError: false
          })
        } else {
          setCustomError({
            eSignSizeError: false
          })
        }
        uploadFile({ docId: id, file: file, path: API.proxyUserUpload }).then((res) => {
          if (res.status === 201) {
            ServerSuccessMsg(res);
            if (type == "avatar") {
              avatarDocId.saveValue(res.data.docId);
              avatarDocFileName.saveValue(res.data.fileName);
              // console.log("salil", res.data, avatarDocId.value, avatarDocFileName.value);
            } else {
              esignDocId.saveValue(res.data.docId)
              esignDocFileName.saveValue(res.data.fileName)
            }
          }
        })
      } else {
        if (type == "avatar") {
          setCustomError({
            avatarSizeError: true
          })
        } else {
          setCustomError({
            eSignSizeError: true
          })
        }
      }

    } else {
      console.log("not found");
    }
  }

  const handleRemove = (type) => {
    let apiCallData
    if (type == 'avatar') {
      apiCallData = {
        docId: profileData.avatarDocId
      }
    } else {
      apiCallData = {
        docId: profileData.esignDocId
      }
    }
    postApiCallDynamic({
      data: apiCallData,
      refresh: (res) => {
        ServerSuccessMsg(res)
        if (type == 'avatar') {
          avatarDocId.saveValue("");
          avatarDocFileName.saveValue("")
        } else {
          esignDocId.saveValue("");
          esignDocFileName.saveValue("")
        }
      },
      path: API.proxyUserRemove,
      header: "update",
    })
  }


  const [basicInfo, setBasicInfo] = useState(false);
  let [email] = useInput({
    data: props?.data?.email,
    status: true,
    initial: "",
  });

  let [firstName] = useInput({
    data: props?.data?.firstName,
    status: true,
    initial: "",
  });

  let [lastName] = useInput({
    data: props?.data?.lastName,
    status: true,
    initial: "",
  });

  let [entityName] = useInput({
    data: props?.data?.entityName,
    status: true,
    initial: "",
  });

  let [accountNo] = useInput({
    data: props?.data?.accountNo,
    status: true,
    initial: "",
  });

  let [nmlsId] = useInput({
    data: props?.data?.nmlsId,
    status: true,
    initial: "",
  });

  let [avatarDocId] = useInput({
    data: props?.data?.avatarDocId,
    status: true,
    initial: "",
  });

  let [avatarDocFileName] = useInput({
    data: props?.data?.avatarDocFileName,
    status: true,
    initial: "",
  });

  let [esignDocId] = useInput({
    data: props?.data?.esignDocId,
    status: true,
    initial: "",
  });

  let [esignDocFileName] = useInput({
    data: props?.data?.esignDocFileName,
    status: true,
    initial: "",
  });

  let [compNmlsId] = useInput({
    data: props?.data?.companyNmlsId,
    status: true,
    initial: "",
  });

  let [firstAddress] = useInput({
    data: props?.data?.firstAddress,
    status: true,
    initial: "",
  });

  let [secondAddress] = useInput({
    data: props?.data?.secondAddress,
    status: true,
    initial: "",
  });

  let [city] = useInput({
    data: props?.data?.city,
    status: true,
    initial: "",
  });

  let [state] = useInput({
    data: props?.data?.state,
    status: true,
    initial: "",
  });

  let [zip] = useInput({
    data: props?.data?.zip,
    status: true,
    initial: "",
  });
  let [phone] = useInput({
    data: props?.data?.phone,
    status: true,
    initial: "",
  });

  let [altPhone] = useInput({
    data: props?.data?.altPhone,
    status: true,
    initial: "",
  });

  useEffect(() => {
    firstName.saveValue(props?.data?.firstName);
    lastName.saveValue(props?.data?.lastName);
    email.saveValue(props?.data?.email);
    accountNo.saveValue(props?.data?.accountNo);
    avatarDocId.saveValue(props?.data?.avatarDocId);
    esignDocId.saveValue(props?.data?.esignDocId);
    avatarDocFileName.saveValue(props?.data?.avatarDocFileName)
    esignDocFileName.saveValue(props?.data?.esignDocFileName)
    // avatar.saveValue(DEMO_IMAGE);
    // esignature.saveValue(DEMO_IMAGE);

    firstAddress.saveValue(props?.data?.firstAddress);
    secondAddress.saveValue(props?.data?.secondAddress);
    city.saveValue(props?.data?.city);
    state.saveValue(props?.data?.state);
    zip.saveValue(props?.data?.zip);
    phone.saveValue(props?.data?.phone);
    altPhone.saveValue(props?.data?.altPhone);
    entityName.saveValue(props?.data?.entityName);
    nmlsId.saveValue(props?.data?.nmlsId);
    compNmlsId.saveValue(props?.data?.companyNmlsId);
  }, [props.data, basicInfo]);

  const handleValidation = (values) => {
    let errors = {};
    if (firstName.value === "" || firstName.value === null) {
      errors.firstName = "Please enter First Name";
    } else if (firstName.value.toString().length < MAXLength.minFirstName) {
      errors.firstName = "Minimum 3 alphabet";
    } else if (firstName.value.toString().length > MAXLength.maxName) {
      errors.firstName = "Maximum 10 alphabet";
    }

    if (lastName.value === "" || lastName.value === null) {
      errors.lastName = "Please enter Last Name";
    } else if (lastName.value.toString().length > MAXLength.maxName) {
      errors.lastName = "Maximum 10 alphabet";
    }
    if (roleOwner(props?.data?.role) || props?.data?.role === ROLE_LIST.mlp || props?.data?.role === ROLE_LIST.mlo) {
      if (entityName.value === "" || entityName.value === null) {
        errors.entityName = "Please enter Entity Name";
      }
    }
    if (roleOwner(props?.data?.role)) {
      if (accountNo.value === "" || accountNo.value === null) {
        errors.accountNo = "Please enter Account No";
      }
    }
    if (roleOwner(props?.data?.role) || props?.data?.role === ROLE_LIST.mlp || props?.data?.role === ROLE_LIST.mlo) {
      if (firstAddress.value === "" || firstAddress.value === null) {
        errors.firstAddress = "Please enter First Address";
      }
      if (secondAddress.value === "" || secondAddress.value === null) {
        errors.secondAddress = "Please enter Second Address";
      }
      if (city.value === "" || city.value === null) {
        errors.city = "Please enter your city";
      }
      if (state.value === "" || state.value === null) {
        errors.state = "Please enter your state";
      }

      if (zip.value === "" || zip.value === null) {
        errors.zip = "Please enter your zip";
      } else if (zip.value.toString().length != MAXLength.zip) {
        errors.zip = "Please enter valid zip";
      }
    }
    return errors;
  };

  let [resultValidation, setResultValidation] = useState({});
  const handleUpdateBasicInfo = async () => {
    setSpinner(true);

    if (basicInfo) {
      let result = handleValidation();
      if (!Object.values(result).every((x) => x == "")) {
        setResultValidation(result);
        setSpinner(false);
        return;
      }
      setResultValidation({});
      let data = {
        userCode: props.data.userCode,
        firstName: firstName.value,
        lastName: lastName.value,
        avatarDocId: avatarDocId.value,
        avatarDocFileName: avatarDocFileName.value,
        entityName: entityName.value,
        esignDocId: esignDocId.value,
        esignDocFileName: esignDocFileName.value,
        accountNo: accountNo.value,
        firstAddress: firstAddress.value,
        secondAddress: secondAddress.value,
        city: city.value,
        state: state.value,
        zip: zip.value,
      };

      await postApiCallDynamicWithOutReturn({
        data: data,
        refresh: profileCall,
        path: API.ownInfo,
        header: "update",
      });
      // Api call
    } else {
      setSpinner(false);
      setBasicInfo((prev) => !prev);
    }
  };
  const altPhoneValidation = (values) => {
    let errors = {};
    if (altPhone.value === "" || altPhone.value.length !== 10) {
      errors.altPhone = "Please enter valid Alternate Phone No";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      altPhone: altPhone.value,
    },
    validate: (values) => altPhoneValidation(values),

    onSubmit: async (values) => {
      let data = {
        altPhone: altPhone.value,
        userCode: profileData.userCode,
      };
      await postApiCallDynamicWithOutReturn({
        data: data,
        refresh: profileCall,
        path: API.ownInfo,
        header: UPDATE,
      });
      setaltPhoneChange(true);
    },
  });

  const resetAltPhoneForm = () => {
    altPhone.saveValue(profileData.altPhone);
  };

  let profileInfo = [
    <>
      <CustomInput
        required
        disabled={!basicInfo}
        size="small"
        fullWidth
        label={"First Name"}
        change={firstName}
        error={resultValidation?.firstName}
        maxLength={MAXLength.name}
      />

      <CustomInput
        required
        disabled={!basicInfo}
        size="small"
        fullWidth
        label={"Last Name"}
        change={lastName}
        error={resultValidation?.lastName}
        maxLength={MAXLength.name}
      />
    </>,
  ];

  if (AccessableRoleForProfile.includes(props?.data?.role)) {
    profileInfo.push(
      <CustomInput
        disabled={!basicInfo}
        size="small"
        fullWidth
        label={"Entity Name"}
        change={entityName}
        error={resultValidation?.entityName}
        maxLength={MAXLength.entityName}
      />
    );
  }

  if (roleOwner(props?.data?.role)) {
    profileInfo.push(
      <CustomInput
        required
        disabled={true}
        size="small"
        change={accountNo}
        fullWidth
        label={"Account Number"}
        error={resultValidation?.accountNo}
        placeholder="12345"
      />
    );
  }

  if (AccessableRoleForAvatarAndSignature.includes(props?.data?.role)) {
    profileInfo.push(
      <>
        <InputLabel sx={{ pb: "5px", fontSize: "15px" }}>
          Upload Avatar*
        </InputLabel>

        {/* Used for  making inline*/}
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <input id="customUpload" type="file" onChange={event => handleUpload(event, avatarDocId.value, "avatar")} hidden disabled={!basicInfo} />
            <label htmlFor={"customUpload"}>
              <Box
                sx={{ background: "#F3F8FF" }}
                border="2px dashed #999999"
                borderRadius={3}
              >
                <Grid container pt={1} pl={2}>
                  <Grid item sx={{ verticalAlign: "center" }}>
                    <FileUploadOutlined></FileUploadOutlined>
                  </Grid>
                  <Grid item px={1} sx={{ verticalAlign: "center" }}>
                    <Typography
                      variant="body"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {avatarDocFileName.value ? avatarDocFileName.value : `Drop your Avatar`}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </label>
            <FormHelperText error={customError.avatarSizeError ? true : false}>
              The maximum file size allowed is 500KB.
            </FormHelperText>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ pt: "unset !important" }}>
            <Avatar src={getImageUrl(avatarDocId.value, avatarDocFileName.value, 'avatar')} sx={{ width: "70px", height: "70px" }}>VA</Avatar>
          </Grid>
        </Grid>

        {/* For removing the Avatar */}
        <Button
          variant="text"
          color="error"
          sx={{ textTransform: "capitalize" }}
          disabled={!basicInfo}
          onClick={() => { handleRemove("avatar") }}
        >
          Remove Avatar
        </Button>
      </>
    );
  }

  if (AccessableRoleForProfile.includes(props?.data?.role)) {
    profileInfo.push(
      <>
        <Box>
          <Typography variant="p">Address</Typography>
          <CustomInput
            disabled={!basicInfo}
            size="small"
            fullWidth
            label={"Address Line 1"}
            change={firstAddress}
            maxLength={MAXLength.address}
            error={resultValidation?.firstAddress}
            required
          />
        </Box>
        <CustomInput
          disabled={!basicInfo}
          size="small"
          fullWidth
          label={"Address Line 2"}
          change={secondAddress}
          maxLength={MAXLength.address}
          error={resultValidation?.secondAddress}
          required
        />
        <CustomInput
          disabled={!basicInfo}
          size="small"
          fullWidth
          label={"City"}
          maxLength={MAXLength.city}
          error={resultValidation?.city}
          change={city}
          required
        />
        <Grid container spacing={{ xs: 0, md: 2 }}>
          <Grid item md={6} xs={12}>
            <CustomInput
              disabled={!basicInfo}
              size="small"
              fullWidth
              label={"State"}
              change={state}
              maxLength={MAXLength.state}
              error={resultValidation?.state}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomInput
              disabled={!basicInfo}
              size="small"
              fullWidth
              label={"ZIP"}
              change={zip}
              type="number"
              required
              error={resultValidation?.zip}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  if (AccessableRoleForAvatarAndSignature.includes(props?.data?.role)) {
    profileInfo.push(
      <>
        <InputLabel sx={{ pb: "5px", fontSize: "15px" }}>
          Upload E-Signature
        </InputLabel>

        {/* Used for  making inline*/}
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <input id="esign" type="file" onChange={event => handleUpload(event, esignDocId.value, "esign")} hidden disabled={!basicInfo} />
            <label htmlFor={"esign"}>
              <Box
                sx={{ background: "#F3F8FF" }}
                border="2px dashed #999999"
                borderRadius={3}
              >
                <Grid container pt={1} pl={2}>
                  <Grid item sx={{ verticalAlign: "center" }}>
                    <FileUploadOutlined></FileUploadOutlined>
                  </Grid>
                  <Grid item px={1} sx={{ verticalAlign: "center" }}>
                    <Typography
                      id="avatar"
                      variant="body"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {esignDocFileName.value ? esignDocFileName.value : `Drop your E-Signature`}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </label>
            <FormHelperText error={customError.eSignSizeError ? true : false}>
              The maximum file size allowed is 500KB.
            </FormHelperText>
          </Grid>
        </Grid>

        {/* For removing the E-Signature */}
        <Button
          variant="text"
          color="error"
          sx={{ textTransform: "capitalize" }}
          disabled={!basicInfo}
          onClick={() => { handleRemove("esign") }}
        >
          Remove E-Signature
        </Button>
      </>
    );
  }

  return (
    <>
      <Grid container>
        {/* Left Column in md */}
        <Grid item xs={12} md={6} my={1}>
          <Typography variant="h3">Personal Info</Typography>
          <Typography my={1} variant="body1" color="text.secondary">
            Update your Name, Avatar
          </Typography>
          <Box display={basicInfo ? "none" : ""}>
            <Button
              startIcon={<EditOutlined />}
              variant="outlined"
              onClick={() => {
                setBasicInfo((prev) => !prev);
              }}
            >
              Edit
            </Button>
          </Box>
        </Grid>
        {/* Right Column in md */}
        <Grid item xs={12} md={4} my={1}>
          {profileInfo}

          <Box
            my={2}
            display={basicInfo ? "flex" : "none"}
            justifyContent={"space-between"}
          >
            {/* Used for  making inline*/}

            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                setBasicInfo((prev) => !prev);
                setResultValidation({});
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              size="large"
              onClick={handleUpdateBasicInfo}
            >
              Update
            </Button>
          </Box>
        </Grid>
      </Grid>

      <hr style={{ borderColor: "#0000001f" }} />

      {/* For Email */}
      <IdentityChange identityValue={email} variant={"email"} />
      <hr style={{ borderColor: "#0000001f" }} />

      {/* For Phone */}
      <Grid container>
        <Grid item xs={12} md={6} my={1}>
          <Typography variant="h3">Phone Number</Typography>
          <Typography my={1} variant="body1" color="text.secondary">
            {"Contact Customer service to update"}
            <br /> {"your phone number"}
          </Typography>
          <Box
            display={props?.data?.role === ROLE_LIST.borrower ? null : "none"}
          >
            <Box display={altPhoneChange ? "" : "none"}>
              <Button
                startIcon={<EditOutlined />}
                variant="outlined"
                onClick={() => {
                  setaltPhoneChange(false);
                }}
              >
                Edit
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} my={1}>
          <CustomInput
            change={{ value: phone.value }}
            sicon={<KSPhoneIcon />}
            disabled={true}
            label={"Phone Number"}
          />
          {/* <CustomInput
            change={{ value: contactNumber.value }}
            sicon={<LocalPhoneOutlined />}
            disabled={true}
            label={"Phone Number"}
          /> */}

          <Box
            my={2}
            display={props?.data?.role === ROLE_LIST.borrower ? null : "none"}
          >
            <form onSubmit={formik.handleSubmit}>
              <CustomInput
                size="small"
                label="Alternate Phone No."
                change={altPhone}
                type="number"
                sicon={<KSPhoneIcon />}
                disabled={altPhoneChange}
                error={formik.errors.altPhone}
              />
              {/* This Eleement for field alert */}
              {/* <FormHelperText sx={{ color: 'red' }}>Kindly Give your First Name</FormHelperText> */}

              <Box
                my={2}
                display={altPhoneChange ? "none" : "flex"}
                justifyContent={"space-between"}
              >
                <Button
                  size="large"
                  color="primary"
                  onClick={() => {
                    setaltPhoneChange(true);
                    resetAltPhoneForm();
                  }}
                  type="button"
                  variant="outlined"
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                >
                  Update
                </Button>
              </Box>
            </form>
          </Box>

          {/* Use for OTP */}
        </Grid>
      </Grid>

      {/* For Company NMLS ID */}
      {roleOwner(props?.data?.role) || props?.data?.role === ROLE_LIST.mlo ? (
        <>
          <hr style={{ borderColor: "#0000001f" }} />
          <IdentityChange identityValue={compNmlsId} variant={"companyNmls"} />
          <hr style={{ borderColor: "#0000001f" }} />
          <IdentityChange identityValue={nmlsId} variant={"nmls"} />
        </>
      ) : null}
    </>
  );
}
