import {
  Button,
  Divider,
  Grid,
  InputLabel,
  Link,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MainLoyOut from "../../../../components/main-layout";
import CustomInput from "../../../../helpers/custom-input";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { PURPOSE_LIST, LOAN_TYPE_LIST, UPDATE } from "../../../../constants/constatnt-variable";
import RadioButtonGroup from "../../../../helpers/radio-button-group";
import { DateToTimestapmFormater, TimeStampToMonthDateYearForInput } from "../../../../helpers/time-formater";
import { postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import { downloadReportFromServer, getFileUrl } from "../../../../helpers/file-upload-download";
import { PreApprovalFormValidation } from "../../../../constants/validation-rules";
import FilePreview from "../file-preview";
import { DialogCommonHooks, PreviewPDFModal } from "../../../../helpers/modal/modal";
import { useRecoilState } from "recoil";
import { globalSpinner } from "../../../../recoil/profile-atom";

export default function PreapprovalForm({ leadData }) {
  let pull = true;


  const router = useNavigate();
  const location = useLocation();
  const leadId = location.pathname.split("/")[location.pathname.split("/").length - 1];

  const [modalOpen, setModalOpen] = useState(false);
  let [, setSpinner] = useRecoilState(globalSpinner);

  const handleClose = () => {
    router("/broker/leads")
  }


  // pre approval form submit logic here
  const onSubmit = async data => {
    setSpinner(true)
    console.log("data", data)
    let apiCallData = {
      id: leadId,
      leadInfo: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        dateOfBirth: DateToTimestapmFormater(data?.dateOfBirth),
        phone: data?.phone,
        ssn: data?.ssn,
        monthlyIncome: data?.monthlyIncome,
        totalLiabilities: data?.totalLiabilities,
        averageScore: data?.averageScore,
        loanType: data?.loanType,
        loanPurpose: data?.loanPurpose,
        propertyZip: data?.propertyZip,
        propertyAddress: data?.propertyAddress,
        salePrice: data?.salePrice,
        issueDate: DateToTimestapmFormater(data?.issueDate),
        loanTerm: data?.loanTerm,
      },
      leadForm: {
        maxPurchasePriceEligibility: data?.maxPurchasePriceEligibility,
        loanToValue: data?.loanToValue,
        loanAmount: data?.loanAmount,
      }
    }
    await postApiCallDynamicWithOutReturn({
      data: apiCallData, refresh: () => { setSpinner(false); setModalOpen(true); }, path: API.lead, header: UPDATE
    })

  }

  const downloadPdf = () => {
    downloadReportFromServer(API.leadDownload, { id: leadId, serviceType: "preapproval" })

  }
  const handlePdfClose = () => {
    setModalOpen(false)
  }


  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      firstName: leadData?.leadInfo?.firstName,
      lastName: leadData?.leadInfo?.lastName,
      email: leadData?.leadInfo?.email,
      dateOfBirth: TimeStampToMonthDateYearForInput(leadData?.leadInfo?.dateOfBirth),
      phone: leadData?.leadInfo?.phone,
      ssn: leadData?.leadInfo?.ssn,
      monthlyIncome: leadData?.leadInfo?.monthlyIncome,
      totalLiabilities: leadData?.leadInfo?.totalLiabilities,
      averageScore: leadData?.leadInfo?.averageScore,
      loanType: leadData?.leadInfo?.loanType,
      loanPurpose: leadData?.leadInfo?.loanPurpose,
      propertyZip: leadData?.leadInfo?.propertyZip,
      propertyAddress: leadData?.leadInfo?.propertyAddress,
      salePrice: leadData?.leadInfo?.salePrice,
      issueDate: TimeStampToMonthDateYearForInput(leadData?.leadInfo?.issueDate),
      loanTerm: leadData?.leadInfo?.loanTerm,

      maxPurchasePriceEligibility: leadData?.leadForm?.maxPurchasePriceEligibility,
      loanAmount: leadData?.leadForm?.loanAmount,
      loanToValue: leadData?.leadForm?.loanToValue,
    }
  })


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container p={5} direction={"column"} spacing={5}>
          <Grid item container direction={"column"}>
            <Grid item>
              <Typography variant="h3">Lead info</Typography>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>

                <Controller
                  name="firstName"
                  control={control}
                  rules={PreApprovalFormValidation.firstName}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'text'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"First Name"}
                      placeholder={"Enter your first name"}
                      error={errors?.firstName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>

                <Controller
                  name="lastName"
                  control={control}
                  rules={PreApprovalFormValidation.lastName}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'text'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Last Name"}
                      placeholder={"Enter your last name"}
                      error={errors?.lastName?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="email"
                  control={control}
                  rules={PreApprovalFormValidation.email}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'email'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Email Id"}
                      placeholder={"Enter your email id"}
                      error={errors?.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="dateOfBirth"
                  control={control}
                  rules={PreApprovalFormValidation.dateOfBirth}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'date'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Date of Birth"}
                      placeholder={"Mar-15-1994"}
                      error={errors?.dateOfBirth?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="phone"
                  control={control}
                  rules={PreApprovalFormValidation.phone}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      sicon={<CallOutlinedIcon />}
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Mobile Number"}
                      placeholder={"(887) 509 - 5904"}
                      error={errors?.phone?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="ssn"
                  control={control}
                  rules={PreApprovalFormValidation.ssn}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Enter your SSN"}
                      placeholder={"455-45-4578"}
                      error={errors?.ssn?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="monthlyIncome"
                  control={control}
                  rules={PreApprovalFormValidation.monthlyIncome}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      sicon={<CallOutlinedIcon />}
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Total Monthly Income"}
                      placeholder={"$10000"}
                      error={errors?.monthlyIncome?.message}
                    />
                  )}
                />
                <Controller
                  name="averageScore"
                  control={control}
                  rules={PreApprovalFormValidation.averageScore}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Your Average Credit Score"}
                      placeholder={"$698"}
                      error={errors?.averageScore?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="totalLiabilities"
                  control={control}
                  rules={PreApprovalFormValidation.totalLiabilities}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Total Liabilities"}
                      placeholder={"$5000"}
                      error={errors?.totalLiabilities?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          {!pull ? (
            <Grid item container direction={"column"}>
              <Grid item my={5}>
                <Typography variant="h3">Credit Score</Typography>
                <Typography variant="body2">
                  Press the button to see your Credit Score
                </Typography>
              </Grid>
              <Grid item sm={12} md={4}>
                <Button size="small" variant="contained">
                  Pull Credit Report
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item container direction={"column"}>
              <Grid item my={5}>
                <Typography variant="h3">Credit Score</Typography>
                <Typography variant="body2">
                  Pulled on mm/dd/yyy with qualifying credit score 755
                </Typography>
                <Link variant="body2">Review Credit Report</Link>
              </Grid>
              <Grid item sm={12} md={4}>
                <Button size="small" variant="contained">
                  Repull
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Divider />
          </Grid>

          <Grid item container direction={"column"}>
            <Grid item my={5}>
              <Typography variant="h3">Loan details</Typography>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item container md={5} direction={"column"}>
                <Grid item>
                  <InputLabel>
                    Loan Type
                    <Typography variant="small" color="error">
                      *
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Controller
                    name='loanType'
                    control={control}
                    rules={PreApprovalFormValidation.loanType}
                    render={({ field }) => (
                      <RadioButtonGroup
                        field={field}
                        data={LOAN_TYPE_LIST}
                        direction="row"
                        labelColor={true}
                        error={errors?.loanType?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item container md={5} direction={"column"}>
                <Grid item>
                  <InputLabel>
                    Loan Purpose
                    <Typography variant="small" color="error">
                      *
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Controller
                    name='loanPurpose'
                    control={control}
                    rules={PreApprovalFormValidation.loanPurpose}
                    render={({ field }) => (
                      <RadioButtonGroup
                        field={field}
                        data={PURPOSE_LIST}
                        direction="row"
                        labelColor={true}
                        error={errors?.loanPurpose?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>

                <Controller
                  name='propertyZip'
                  control={control}
                  rules={PreApprovalFormValidation.propertyZip}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      required
                      type="number"
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Property ZIP Code"}
                      placeholder={"Enter your property ZIP code"}
                      error={errors?.propertyZip?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name='propertyAddress'
                  control={control}
                  rules={PreApprovalFormValidation.propertyAddress}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      required
                      type="text"
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Property Address"}
                      placeholder={"To be determined"}
                      error={errors?.propertyAddress?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name='salePrice'
                  control={control}
                  rules={PreApprovalFormValidation.salePrice}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      required
                      type="number"
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Sale Price"}
                      placeholder={"Enter the sale price"}
                      error={errors?.salePrice?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="issueDate"
                  control={control}
                  rules={PreApprovalFormValidation.issueDate}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type="date"
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Date Issued"}
                      placeholder={"25/56/2023"}
                      error={errors?.issueDate?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="loanTerm"
                  control={control}
                  rules={PreApprovalFormValidation.loanTerm}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'text'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Loan Term"}
                      placeholder={"Years"}
                      error={errors?.loanTerm?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>

          <Grid item container direction={"column"}>
            <Grid item>
              <Typography variant="h3">Fill by MLO/Broker/MLP</Typography>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="maxPurchasePriceEligibility"
                  control={control}
                  rules={PreApprovalFormValidation.maxPurchasePriceEligibility}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Max Purchase Price Eligibility"}
                      placeholder={"$ 350,000"}
                      error={errors?.maxPurchasePriceEligibility?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="loanToValue"
                  control={control}
                  rules={PreApprovalFormValidation.loanToValue}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"LTV (%)"}
                      placeholder={"80%"}
                      error={errors?.loanToValue?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="loanAmount"
                  control={control}
                  rules={PreApprovalFormValidation.loanAmount}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Loan Amount"}
                      placeholder={"$ 280,000"}
                      error={errors?.loanAmount?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container md={12} justifyContent={"end"} spacing={2}>
            <Grid item>
              <Button onClick={handleClose} variant="outlined"> Cancel</Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained"> Preview & Save</Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <PreviewPDFModal
        size={'lg'}
        show={modalOpen}
        handleClose={handlePdfClose}
        title={`Generate Quote`}
        body={
          <FilePreview
            fileUrl={getFileUrl(leadId, "preapproval")}
            downloadPdf={downloadPdf}
          />
        }
      />
    </>
  );
}
