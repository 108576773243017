import { Box, Button, IconButton } from "@mui/material";
import React, { useMemo, useState } from "react";
import AdditionalLoanContactForm from "./additional-loan-contact-form";
import CustomReactMaterialTable from "../../../../helpers/table/react-material-table";
import { DialogCommonHooks } from "../../../../helpers/modal/modal";
import ConfirmationModel from "../../../../helpers/modal/confirm-modal";
import { CREATE, UPDATE } from "../../../../constants/constatnt-variable";
import MainLoyOut from "../../../../components/main-layout";
import { useLocation } from "react-router-dom";
import { deleteApiCallDynamic, postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import { KSMailIcon, KSPencilAltIcon, KSTrashIcon } from "../../../../icons/custome-icon";
import { openMail } from "../../../../helpers/common_fuctions/open-mail";

export default function AdditionalLoanContact() {

  const location = useLocation();
  const formId = location.pathname.split("/")[location.pathname.split("/").length - 2];

  const [action, setAction] = useState(CREATE);

  const [tableData, setTableData] = useState("");
  const [tableReload, setTableReload] = useState(() => false);



  const handleEdit = (cell, row) => {
    handleOpen();
    console.log("setTableData", row?.original);
    setTableData(row?.original);
    setAction(UPDATE);
  };

  const handleDelete = (row) => {
    let apiCallData = {
      id: row.original.id,
      formId: formId,
    };

    deleteApiCallDynamic(
      {
        refresh: () => {
          setTableReload((prev) => !prev);
        },
        path: API.additionalContact,
        data: apiCallData
      }
    ).then(r => {

    })
  };

  const [openModal, setOpenModal] = useState();

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(false);
    setAction(CREATE);
    setTableData("");
  };

  const handleSubmit = async data => {
    let apiCallData = Object.assign(data, {
      formId: formId,
    });
    await postApiCallDynamicWithOutReturn({
      data: apiCallData,
      path: API.additionalContact,
      refresh: handleClose,
      header: action == UPDATE ? UPDATE : null
    })

  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "NAME",
        enableColumnActions: false,
        enableSorting: false,
      },
      //column definitions...
      {
        accessorKey: "phone",
        header: "MOBILE NUMBER",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "email",
        header: "EMAIL ID",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "designator",
        header: "DESIGNATOR",
        enableColumnActions: false,
        enableSorting: false,
      },

      {
        accessorKey: "id",
        header: "ACTION",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell, column, row, table }) => {
          return (
            <Box display={"flex"}>
              <IconButton onClick={(e) => {
                handleEdit(cell, row);
              }}>
                <KSPencilAltIcon />
              </IconButton>
              <IconButton onClick={(e) => {
                openMail(row.original.email);
              }}>
                <KSMailIcon />
              </IconButton>

              <ConfirmationModel
                body={<IconButton><KSTrashIcon /></IconButton>}
                handleSubmit={(e) => {
                  handleDelete(row);
                }}
                message={
                  `Are you sure, You want to delete ${row?.original?.name} (Contact) details?`
                }
                title={"Confirmation"}
              />

            </Box>
          );
        },
      },
      //end
    ],
    []
  );

  let tableDataDemo = [
    {
      id: 1,
      name: "Williams",
      mobile: "(956)584-8475",
      email: "williams@cache.com",
      designator: "Realtor Seller",
    },
    {
      id: 2,
      name: "Chandler Bing",
      mobileNumber: "(859)652-1475",
      emailId: "bing@cache.com",
      designator: "Lender Officer",
    },
  ];

  return (
    <MainLoyOut>
      <CustomReactMaterialTable
        enableToolbarInternalActions={false}
        // staticLoad={true}
        // staticData={tableDataDemo}
        endPoint={API.additionalContact}
        params={{ "formId": formId }}
        refetch={tableReload}
        columns={columns}
        enablePagination={false}
        showGlobalFilter={true}
        muiSearchTextFieldProps={{
          placeholder: "Search for Loan Contacts",
          sx: { minWidth: "18rem" },
          variant: "outlined",
        }}
        renderTopToolbarCustomActions={(table) => (
          <Box display="flex" justifyContent="flex-end">
            <Button variant="outlined" onClick={handleOpen}>
              Add
            </Button>
          </Box>
        )}
      />
      <DialogCommonHooks
        title="Add New Additional Loan Contacts"
        show={openModal}
        handleClose={handleClose}
        body={
          <AdditionalLoanContactForm
            data={tableData}
            action={action}
            handleClose={handleClose}
            onSubmit={handleSubmit}
          />
        }
      />
    </MainLoyOut>
  );
}
