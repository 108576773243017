import React from "react";
import InvitationEmptyIcon from "../../assets/no_Invitation.png";
import {Box, Grid, Typography} from "@mui/material";

function InvitationEmptyPage() {
  return (
    <Grid container my={3}>
      <Grid item xs={12} sm={12} md={4} margin={"auto"}>
        <Box
          textAlign={"center"}
          my={5}
          width={{ xs: "100%", sm: "60%", md: "60%" }}
          mx={"auto"}
        >
          <img src={InvitationEmptyIcon} width={"100%"} />
        </Box>
        <Box textAlign={"center"} my={2}>
          <Typography variant="h2">No Invitations Yet!</Typography>
          <Typography variant="body">
            {`Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s`}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default InvitationEmptyPage;
