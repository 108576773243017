import { Delete, Edit, MailOutline, } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import ConfirmationModel from "../../../helpers/modal/confirm-modal";
import { DialogCommonHooks } from "../../../helpers/modal/modal";
import CustomReactMaterialTable from "../../../helpers/table/react-material-table";
import UserStatus from "../../../helpers/user-status";
import TeamInvitationForm from "./team-invitation-form";
import { KSMailIcon, KSPencilAltIcon, KSTrashIcon } from "../../../icons/custome-icon";
import { deleteApiCallDynamic, getApiCallDynamic, postApiCallDynamic } from "../../../services/api-service";
import { API } from "../../../services/api-config";
import { NMLS_STATUS_VALID, SERVICE_TYPE, UPDATE, roleOwner } from "../../../constants/constatnt-variable";
import { useRecoilState, useRecoilValue } from "recoil";
import { GlobalAlertSeverity, globalSpinner, profileState } from "../../../recoil/profile-atom";
import { ClientSideMsg } from "../../../helpers/alert-msg";
import { setRecoil } from "recoil-nexus";

export default function Manage() {
  const [openModal, setOpenModal] = useState();
  let [spinner, setSpinner] = useRecoilState(globalSpinner);
  const [reload, setreload] = useState(false)
  let profileInfo = useRecoilValue(profileState);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({});

  const handleOpen = () => {

    if (profileInfo.companyNmls.status == NMLS_STATUS_VALID && profileInfo.nmls.status == NMLS_STATUS_VALID) {
      setOpenModal(true)
    } else {
      setRecoil(GlobalAlertSeverity, {
        state: true,
        data: {
          severity: "error",
          msg: "Nmls id is not validated"
        }
      })
    }
  }
  const handleClose = () => {
    setOpenModal(false)
    setEdit(false)
  };



  const handleDelete = (id) => {
    deleteApiCallDynamic({ path: API.teams, data: { id: id, service: SERVICE_TYPE.teamInvitation } });
    setreload(prev => !prev)
  };

  const tableColumnManage = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Team members",
        enableColumnActions: false,
        Cell: ({ cell, column, row, table }) => {
          return (
            <Typography>
              {`${row?.original?.firstName} ${row?.original?.lastName}`}
            </Typography>
          );
        },
      },
      //column definitions...
      {
        accessorKey: "role",
        header: "Assigned Role",
        enableColumnActions: false,
        Cell: ({ cell, column, row, table }) => {
          return (
            <Typography>
              {`${roleOwner(row?.original?.role) ? "Broker, MLO, MLP" : row?.original?.role}`}
            </Typography>
          );
        },
      },
      {
        accessorKey: "id",
        header: "Action",
        enableColumnActions: false,
        enableSorting: false,

        Cell: ({ cell, column, row, table }) => {
          return (
            <Stack direction={"row"} display={roleOwner(row?.original?.role) ? "none" : null}>

              {row.original.status == "Accepted" ? (
                <ConfirmationModel
                  body={
                    <IconButton>
                      <KSMailIcon />
                    </IconButton>
                  }
                  handleSubmit={() => {
                    handleEmail(row?.original?.id);
                  }}
                  message={
                    `Are you sure you want to send the invitation link ${row?.original?.firstName} ${row?.original?.lastName} for ${row?.original?.role} role?`
                  }
                  title={"Confirmation"}
                />
              ) : (
                <IconButton onClick={() => {
                  handleOpen();
                  setData(row?.original);
                  setEdit(true);
                }}>
                  <KSPencilAltIcon />
                </IconButton>
              )}

              <ConfirmationModel
                body={<IconButton disabled={row?.original?.isDeleted}><KSTrashIcon /> </IconButton>}
                disabled={row?.original?.isDeleted}
                handleSubmit={() => {
                  handleDelete(row?.original?.id);
                }}
                message={
                  `Are you sure you want revoke (${row?.original?.role}) ${row?.original?.firstName} ${row?.original?.lastName}?`
                }
                title={"Confirmation"}
              />

            </Stack>
          );
        },
      },

      {
        accessorKey: "status",
        header: "",
        enableColumnActions: false,
        Cell: ({ cell, column, row, table }) => {
          return <UserStatus status={row.original.status} />;
        },
      },

      //end
    ],
    []
  );

  const manageData = [
    {
      id: 1,
      firstName: "Thomas",
      lastName: "Jone (You)",
      role: "Broker, MLO, MLP",
      phone: "2342342344",
      email: "sal@gmail.com",
      invitationMessage: "good one",
      status: "Pending Invitation",
      isDeleted: true,
    },
    {
      id: 2,
      firstName: "Kim",
      lastName: "David",
      role: "MLO",
      phone: "5854949503",
      email: "sam@gmail.com",
      invitationMessage: "join our team",
      status: "Approved",
      isDeleted: false,
    },
    {
      id: 3,
      firstName: "Mary",
      lastName: "Allen",
      role: "MLP",
      phone: "4444444444",
      email: "sumit@gmail.com",
      invitationMessage: "tata bye bye",
      status: "Declined",
      isDeleted: true,
    },
  ];

  const onSubmit = async (data) => {

    setSpinner(true);
    let apiData = { ...data, message: data.invitationMessage, service: SERVICE_TYPE.teamInvitation }
    postApiCallDynamic(
      {
        data: apiData,
        refresh: (res) => {
          return res;
        },

        path: edit ? API.teams : API.teamInvitaion,
        header: edit && UPDATE
      }
    ).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setreload(prev => !prev)
      }
      setSpinner(false);
      handleClose()
    });
  }

  const handleEmail = (id) => {
    getApiCallDynamic({ param: { id: id, service: SERVICE_TYPE.teamInvitation }, path: API.loanInvitaionEmail }).then(
      res => {
        if (res?.status) {
          ClientSideMsg(res.data.message)
          setreload(prev => !prev)
        }
      }
    )
  }

  return (
    <Grid container>
      {/* Left Column in md */}
      <Grid item container justifyContent={"space-between"} xs={12}>
        <Grid item sm={12} md={8} mb={3}>
          <Typography variant="h3">Manage Team</Typography>
          <Typography variant="body1" color="text.secondary">
            Re-assign roles, add or remove members in a team.
          </Typography>
        </Grid>
        <Grid item sm={12} md={4} my={1} textAlign={{ xs: 'left', md: 'right' }}>
          <Box mb={{ xs: 2, md: 0 }}>
            <Button variant="outlined" onClick={() => { setData(null); handleOpen() }}>
              Invite New Member
            </Button>
          </Box>
        </Grid>
      </Grid>
      <DialogCommonHooks
        title={"Team Member Invitation"}
        show={openModal}
        handleClose={handleClose}
        body={
          <TeamInvitationForm
            data={data}
            onSubmit={onSubmit}
            handleClose={handleClose}
          />
        }
      />

      <Grid item xs={12}>
        <CustomReactMaterialTable
          // staticLoad={true}
          // staticData={manageData}
          endPoint={API.teams}
          refetch={reload}
          columns={tableColumnManage}
          enablePagination={false}
          enableBottomToolbar={false}
          enableSorting={false}
          enableToolbarInternalActions={false}
          enableTopToolbar={false}
        />
      </Grid>
    </Grid>
  );
}
