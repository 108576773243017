import { Box, Stack } from '@mui/material'
import React from 'react'
import { CONTACT_STATE, CONTACT_COUNTRY } from '../../constants/constatnt-variable';
import CustomInput from '../../helpers/custom-input'
import CustomDropDown from '../../helpers/dropdown/custom-dropdown'
import { PropertyAddressValidation } from '../../constants/validation-rules';

export function PropertyAddress({ Controller, control, stateOfCountry, country, errors }) {



    return (
        <>
            <Controller
                name='addressLine1'
                control={control}
                rules={PropertyAddressValidation.addressline1}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'Address Line 1'} required error={errors.addressLine1?.message} />
                )}
            />
            <Controller
                name='addressLine2'
                control={control}
                rules={PropertyAddressValidation.addressline2}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'Address Line 2'} error={errors?.addressLine2?.message} />
                )}
            />

            <Controller
                name='unit'
                control={control}
                rules={PropertyAddressValidation.unit}
                render={({ field }) => (
                    <CustomInput {...field} type={'Number'} label={'Unit'} error={errors?.unit?.message} />
                )}
            />

            <Controller
                name='city'
                control={control}
                rules={PropertyAddressValidation.city}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={'City'} required error={errors?.city?.message} />
                )}
            />



            <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 0, md: 2 }}>
                <Box width={'100%'}>
                    <Controller
                        name='stateOfCountry'
                        control={control}
                        rules={PropertyAddressValidation.stateofCountry}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'State'}
                                required
                                state={stateOfCountry}
                                option={CONTACT_STATE}
                                error={errors?.stateOfCountry?.message}
                            />
                        )}
                    />
                </Box>
                <Box width={'100%'}>
                    <Controller
                        name='zip'
                        control={control}
                        rules={PropertyAddressValidation.zip}
                        render={({ field }) => (
                            <CustomInput {...field} type={'text'} label={'ZIP'} required error={errors?.zip?.message} />
                        )}
                    />
                </Box>
            </Stack>

            <Controller
                name="country"
                control={control}
                rules={PropertyAddressValidation.country}
                render={({ field }) => (
                    <CustomDropDown
                        {...field}
                        field={field}
                        label={'Country'}
                        required
                        // state={mailingCountry}
                        option={CONTACT_COUNTRY}
                        error={errors?.country?.message}
                    />
                )}
            />
        </>
    )

}

