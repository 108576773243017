import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const customStyle = makeStyles({

  ContentHeader: {
    fontFamily: "Inter-Medium !important",
    fontSize: "24px !important",
    color: "#221F1F",
  },
  ContentBody: {
    fontFamily: "DMSans-Regular !important",
    fontSize: "24px !important",
    color: "#22222290",
    textAlign: "justify",
  },

});

function CaliforniaPolicy() {
  const classes = customStyle();
  return (
    <>
      <Box pt={4} id={"californiaPolicy"}>
        <Container maxWidth="lg">
          <Grid container minHeight="50vh">
            <Grid item xs={12} md={12} px={{ xs: 2 }} mb={6}>
              <Typography variant="h1" color={"primary.main"} my={5}>
                {`California Policy`}
              </Typography>
              <Box>
                <Typography mb={2} className={classes.ContentHeader}>
                  {`Link to purple heading in Privacy Policy`}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default CaliforniaPolicy;
