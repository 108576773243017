import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";

import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";


import { Link, useLocation, useNavigate } from "react-router-dom";
import { Path } from "../../routes/route-config";
import CustomInput from "../../helpers/custom-input";
import useInput from "../../helpers/use-input";
import { postApiCallDynamic } from "../../services/api-service";
import { API } from "../../services/api-config";
import { useFormik } from "formik";
import { MAXLength, ValidationConstant } from "../../constants/validation";
import TermCondition from "../../components/term-condition";
import { ROLE_LIST, SERVICE_TYPE } from "../../constants/constatnt-variable";
import AuthSideUi from "../../components/auth-side-ui";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBack";
import { AlertSeverity } from "../../helpers/alert-msg";

export default function Signup() {

  const location = useLocation()
  const website = location?.state?.website

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const router = useNavigate();

  let [email] = useInput({
    data: "",
    status: false,
    initial: "",
  });
  let [phone] = useInput({
    data: "",
    status: false,
    initial: "",
  });
  let [entityName] = useInput({
    data: "",
    status: false,
    initial: "",
  });
  let [firstName] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [lastName] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [companyNmlsId] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [nmlsId] = useInput({
    data: "",
    status: false,
    initial: "",
  });
  let [termConditon, setTermCondtion] = useState(false);

  const [role, setRole] = useState(ROLE_LIST.broker);

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleSubmit = () => {
    let data = {
      firstName: firstName.value,
      lastName: lastName.value,
      companyName: entityName.value,
      email: email.value,
      phone: phone.value,
      companyNmlsId: companyNmlsId.value,
      nmlsId: nmlsId.value,
      NMLSDocument: "",
      termConditon: termConditon,
      role: role,

    };

    postApiCallDynamic(
      {
        data: data,
        refresh: (res) => {
          return res;
        },
        path: API.signup
      }
    ).then((res) => {
      if (
        res &&
        (res.status === 200 || res.status === 201 || res.status === 202)
      ) {

        router(Path.otp, {
          state: { ...location.state, ...res.data, ...res.requestData, from: SERVICE_TYPE.signup },
        });
      }
    });
  };

  const handleValidation = (values) => {
    let errors = {};

    if (firstName.value.trim() == "") {
      errors.firstName = "Please enter your First Name";
    }
    if (lastName.value.trim() == "") {
      errors.lastName = "Please enter your Last Name";
    }

    if (entityName.value.trim() == "") {
      errors.entityName = "Please enter your Entity Name";
    }

    const re = ValidationConstant.emailRegx;
    const phoneRegx = ValidationConstant.usPhoneNumber;

    if (re.test(String(email.value.trim()).toLowerCase()) === false) {
      errors.email = "Please enter your valid Email ID";
    }


    if (phoneRegx.test(String(phone.value)) === false) {
      errors.phone = "Please enter valid Contact Number";
    }

    if (
      companyNmlsId.value.length < MAXLength.nmlsIdMin ||
      companyNmlsId.value.length > MAXLength.nmlsIdMax
    ) {
      errors.companyNmlsId = "Please enter valid Company NMLS ID";
    }
    if (
      nmlsId.value.length < MAXLength.nmlsIdMin ||
      nmlsId.value.length > MAXLength.nmlsIdMax
    ) {
      errors.nmlsId = "Please enter valid  NMLS ID";
    }
    if (termConditon == false) {
      errors.termConditon = "Please accept Terms and Conditions";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: firstName.value,
      lastName: lastName.value,
      entityName: entityName.value,
      email: email.value,
      phone: phone.value,
      companyNmlsId: companyNmlsId.value,
      nmlsId: nmlsId.value,
      termConditon: "",
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  return (
    <Grid container>
      {/* side background */}
      <AuthSideUi />
      {/* singup form */}
      <Grid item sm={6} md={6} sx={{ height: "100vh", overflow: "auto" }}>
        {/* Showing the alert notification on top of main layout */}
        <AlertSeverity />
        <Box p={2}>
          <Grid container md={12} justifyContent="space-between">
            <Grid item>
              <Stack direction={"row"} spacing={2}>
                <Button
                  onClick={() => {
                    router(-1);
                  }}
                >
                  <ArrowBackOutlinedIcon />
                </Button>
              </Stack>
            </Grid>
            <Grid item sx={{ textAlign: "right" }}>
              <Typography>
                Already a member? &nbsp;

                <Link to={Path.signin} state={website && location.state} style={{ color: "#2f80ed" }}>
                  Sign In
                </Link>

              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box px={{ xs: 2, md: 20 }} pb={{ xs: 2, md: 3 }}>
          <Typography variant="h4" fontWeight="bold" mb={3}>
            Sign Up
          </Typography>

          {/* Form for Registration */}
          <form onSubmit={formik.handleSubmit}>
            {/* <Box mb={4} sx={{ fontFamily: "DMSans-Medium", color: "#222222" }}>
              <CustomeFileUpload />
              <label>Supporting formats: PDF, PNG, JPEG, DOC</label>
            </Box> */}

            {/* For Every Input Field */}
            {/* Kindly follow the 1st component for all options */}
            <Box my={2}>
              <CustomInput
                required
                placeholder={"Thomas"}
                label={"First Name"}
                maxLength={MAXLength.name}
                change={firstName}
                error={formik.errors.firstName}
              />
            </Box>

            <Box my={2}>
              <CustomInput
                required
                placeholder={"Jone"}
                maxLength={MAXLength.name}
                label={"Last Name"}
                change={lastName}
                error={formik.errors.lastName}
              />
            </Box>

            <Box my={2}>
              <CustomInput
                required
                label={"Company Name"}
                placeholder={"CACHE Mortgage"}
                maxLength={MAXLength.entityName}
                change={entityName}
                error={formik.errors.entityName}
              />
            </Box>

            <Box my={2}>
              <CustomInput
                required
                label={"Email"}
                change={email}
                maxLength={MAXLength.email}
                placeholder={"thomas@cache.com"}
                error={formik.errors.email}
              />
            </Box>

            <Box my={2}>
              <CustomInput
                required
                type="number"
                label={"Phone number"}
                change={phone}
                placeholder={"(799)234-4567"}
                sicon={<LocalPhoneOutlinedIcon />}
                error={formik.errors.phone}
              />
            </Box>

            <Box my={2}>
              <CustomInput
                required
                label={"Company NMLS ID"}
                change={companyNmlsId}
                type={"number"}
                placeholder={"1254639"}
                error={formik.errors.companyNmlsId}
              />
            </Box>

            <Box my={2}>
              <CustomInput
                required
                label={"Your NMLS ID"}
                change={nmlsId}
                type={"number"}
                placeholder={"1419057"}
                error={formik.errors.nmlsId}
              />
            </Box>

            <Box>
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  required={true}

                >
                  Are you a Broker?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={role}
                  onChange={handleRoleChange}
                >
                  <FormControlLabel
                    value={ROLE_LIST.broker}
                    control={<Radio />}
                    label="yes"
                  />
                  <FormControlLabel
                    value={ROLE_LIST.mloOwner}
                    control={<Radio />}
                    label="no"
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <TermCondition
              formik={formik}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              open={open}
              setTermCondtion={setTermCondtion}
              termConditon={termConditon}
            />
            <Box my={2}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                sx={{ borderRadius: "24px", fontWeight: "bold" }}
                type="submit"
              >
                Sign Up
              </Button>
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
}
