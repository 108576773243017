import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { SERVICE_TYPE } from "../../constants/constatnt-variable";
import { ValidationConstant } from "../../constants/validation";
import { toastNotify } from "../../helpers/alert-msg";
import CustomInput from "../../helpers/custom-input";
import { DialogCommonHooks } from "../../helpers/modal/modal";
import { CountDown } from "../../helpers/time-counter";
import useInput from "../../helpers/use-input";
import { API } from "../../services/api-config";
import {
  getApiCallDynamic,
  postApiCallDynamic,
} from "../../services/api-service";
import OTPValidation from "../../helpers/common_fuctions/otp-validation";

//  Update user email, phone
export default function UpdateUserInfo({
  updatedValue,
  userCode,
  userEmail,
  adminEmail,
  variant,
}) {
  let [show, setShow] = useState("updateContact");

  const [openModal, setOpenModal] = useState(false);

  let [emailOtpCount] = CountDown({ sec: 0 });
  let [phoneOtpCount] = CountDown({ sec: 0 });

  const [newPhone] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let componentData = {
    label: "",
    placeholder: "",
    title: "",
    button: "",
  };

  switch (variant) {
    case "phone":
      componentData.label = "Enter new mobile number";
      componentData.placeholder = "(000)-000 0000";
      componentData.title = "Enter new mobile number";
      componentData.button = "UPDATE CONTACT NO";
      componentData.buttonStyle = "outlined";
      break;
    case "email":
      componentData.label = "Enter new Email Id";
      componentData.placeholder = "example@gmail.com";
      componentData.title = "Enter new email id";
      componentData.button = "UPDATE EMAIL";
      componentData.buttonStyle = "contained";

      break;
    default:
    // code block
  }

  const handleValidation = (values) => {
    let errors = {};

    if (variant == "phone") {
      if (
        ValidationConstant.usPhoneNumber.test(String(newPhone.value)) === false
      ) {
        errors.newPhone = "Please enter a valid Phone no";
      }
    }
    if (variant == "email") {
      if (ValidationConstant.emailRegx.test(String(newPhone.value)) === false) {
        errors.newPhone = "Please enter a valid Email";
      }
    }
    return errors;
  };

  const handleClose = () => {
    newPhone.saveValue("");
    setShow("updateContact");
  };

  const phoneNoChange = (email, phone) => {
    let data = {};
    switch (variant) {
      case "phone":
        data = {
          service: SERVICE_TYPE.adminChangePhone,
        };
        break;
      case "email":
        data = {
          service: SERVICE_TYPE.adminChangeEmail,
        };
        break;
      default:
      // code block
    }

    if (show == "newPhone") {
      data = {
        ...data,
        changedValue: newPhone.value,
        userCode: userCode,
      };

      // setShow("verifyUser")

      postApiCallDynamic({
        data: data,
        refresh: (res) => {
          return res;
        },
        path: API.admin,
      }).then((res) => {
        if (res?.status == 200) {
          setShow("verifyUser");
          emailOtpCount.setCounter(res.data.emailOtpCount);
          phoneOtpCount.setCounter(res.data.phoneOtpCount);
        } else {
          setShow("newPhone");
          handleClose();
        }
      });
    }

    if (show === "verifyUser") {
      setOpenModal(true);

      data = {
        ...data,
        emailOTP: email.value,
        phoneOTP: phone.value,
        type: "user",
      };
      postApiCallDynamic({
        data: data,
        refresh: (res) => {
          return res;
        },
        path: API.securedOTP,
      }).then((res) => {
        if (res?.status == 200) {
          setShow("verifyAdmin");
          email.saveValue("");
          phone.saveValue("");
          emailOtpCount.setCounter(res.data.emailOtpCount);
          phoneOtpCount.setCounter(res.data.phoneOtpCount);
        }
      });
    }

    if (show === "verifyAdmin") {
      data = {
        ...data,
        emailOTP: email.value,
        phoneOTP: phone.value,
        type: "admin",
      };
      postApiCallDynamic({
        data: data,
        refresh: (res) => {
          return res;
        },
        path: API.securedOTP,
      }).then((res) => {
        if (res?.status === 200) {
          setShow("updateContact");
          email.saveValue("");
          phone.saveValue("");
          setOpenModal(false);
          toastNotify("success", res.data.message);
          updatedValue.saveValue(newPhone.value);
          handleClose();
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      newPhone: newPhone.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      phoneNoChange();
    },
  });

  const resendOtp = (event) => {
    event.preventDefault();

    switch (show) {
      case 'verifyUser': {
        let param = {
          email: userEmail,
          type: event.target.value,
          service: SERVICE_TYPE.adminChangePhone,
        };

        getApiCallDynamic({ param: param, path: API.verifyOTP }).then((res) => {
          if (res?.status === 200) {
            switch (event.target.value) {
              case 'email':
                emailOtpCount.setCounter(res.data.emailOtpCount);
                break;

              case 'phone':
                phoneOtpCount.setCounter(res.data.phoneOtpCount);
                break;
              default:
                break;
            }
          }
        });
      }
        break;

      case 'verifyAdmin': {
        let param = {
          email: adminEmail,
          type: event.target.value,
          service: SERVICE_TYPE.adminChangePhone,
        };

        getApiCallDynamic({ param: param, path: API.verifyOTP }).then((res) => {
          if (res?.status === 200) {
            switch (event.target.value) {
              case 'email':
                emailOtpCount.setCounter(res.data.emailOtpCount);
                break;

              case 'phone':
                phoneOtpCount.setCounter(res.data.phoneOtpCount);
                break;

              default:
                break;
            }
          }
        });
      }
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Box width={"100%"}>
        <Box
          display={show === "updateContact" ? "block" : "none"}
          width={{ xs: "100%", md: "70%" }}
          mx={"auto"}
          mb={{ xs: 2, md: 0 }}
        >
          <Button
            onClick={() => {
              setShow("newPhone");
            }}
            fullWidth
            variant={componentData.buttonStyle}
          >
            {componentData.button}
          </Button>
        </Box>
        <Box display={show === "newPhone" ? "block" : "none"}>
          <form id="newPhone" onSubmit={formik.handleSubmit}>
            <Typography variant="h3">{componentData.title}</Typography>
            <CustomInput
              size="small"
              required
              change={newPhone}
              placeholder={componentData.placeholder}
              fullWidth
              label={componentData.label}
              error={formik.errors.newPhone}
            />

            <Box my={2} display={"flex"} justifyContent={"space-between"}>
              <Button
                size="large"
                color="primary"
                onClick={() => {
                  handleClose();
                }}
                type="button"
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                size="large"
                color="primary"
                sx={{ borderRadius: "24px", fontWeight: "bold" }}
                id="newPhone"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
        {/* for user Otp */}
        <Box display={show === "verifyUser" ? "" : "none"}>
          <OTPValidation
            variant={variant}
            emailOtpCount={emailOtpCount}
            phoneOtpCount={phoneOtpCount}
            setShow={setShow}
            resendOtp={resendOtp}
            phoneNoChange={phoneNoChange}
            setOpenModal={setOpenModal}
            title={"Verify OTP from User"}
            emailOtpLabel={"Enter OTP sent on user's email"}
            phoneOtpLabel={"Enter OTP sent on user’s new mobile no."}
            description={
              "We have sent OTP to user’s registered new mobile number and user’s email"
            }
          />
        </Box>
      </Box>

      {/* for admin otp */}

      <DialogCommonHooks
        onlyCloseIcon={true}
        show={openModal}
        handleClose={() => {
          setShow("updateContact");
          setOpenModal(false);
        }}
        body={
          <Box p={3}>
            <OTPValidation
              emailOtpCount={emailOtpCount}
              phoneOtpCount={phoneOtpCount}
              onlyVerifyBtn={true}
              setShow={setShow}
              resendOtp={resendOtp}
              phoneNoChange={phoneNoChange}
              setOpenModal={setOpenModal}
              title={"Verify your identity"}
              emailOtpLabel={"Enter OTP sent on your email"}
              phoneOtpLabel={"Enter OTP sent on your phone"}
              description={
                "Hey! Kramasoft admin please enter your OTP information to save the changes done by MLO."
              }
            />
          </Box>
        }
      />
    </>
  );
}
