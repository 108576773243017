import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import CustomReactMaterialTable from "../../helpers/table/react-material-table";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import ProfileImg from "../../assets/Avatar-mlo.png";
import { Link, useNavigate } from "react-router-dom";
import { Path } from "../../routes/route-config";
import { API } from "../../services/api-config";
import { useRecoilValue } from "recoil";
import { profileState } from "../../recoil/profile-atom";
import { KSChatSmIcon, KSPencilAltIcon } from "../../icons/custome-icon";
import ChatApp from "../../pages/chat/chat-app";

function LoanData() {
  let profileInfo = useRecoilValue(profileState);
  const [chatData, setChatData] = useState(
    {
      firstScreen: false,
      externalChat: {},
      internalChat: {},
      appNo: ""
    }
  );

  const [value, setValue] = useState("all");
  let route = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  // Chaning form chip color
  let appStatusColor, appStatusBackgroundColor

  const statusColor = (color) => {
    switch (color) {
      case 'In Progress': appStatusColor = "#FFB210"; appStatusBackgroundColor = '#FFB21026'
        break;

      case 'rejected': appStatusColor = "#FFB210"; appStatusBackgroundColor = '#FFB21026'
        break;

      default: appStatusColor = null; appStatusBackgroundColor = null;
        break;
    }
  }

  // Table's cell value configuration start

  const appNo = (cell) => {
    return (
      <Link
        key={cell.getValue()}
        to={Path.loanSummary + "/" + cell.getValue()}
      >
        {cell.getValue()}
      </Link>
    );
  }

  const borrowerName = (row) => {
    return (
      <Stack direction={'column'}>
        <Typography>{row.borrowerName}</Typography>
        {row.otherBorrowers.map((item, index) => (
          <Typography key={item.name} my={1} sx={{ minWidth: 'max-content' }}>
            {item.name} {item.type ? ` (${item.type})` : null}
          </Typography>
        ))}
      </Stack>
    );
  }

  const appStatus = (row) => {
    statusColor(row.formStatus)
    return (
      <Stack direction={'column'} gap={1}>
        <Typography
          variant="body2"
          sx={{
            backgroundColor: appStatusBackgroundColor,
            color: appStatusColor,
            borderRadius: "44px",
            maxWidth: "12ch",
            p: "0.25rem",
            textAlign: "center",
          }}
        >
          {row.formStatus}
        </Typography>

        {row?.otherBorrowers?.map((item) => (
          <Typography
            key={item?.formStatus}
            variant="body2"
            sx={{
              backgroundColor: appStatusBackgroundColor,
              color: appStatusColor,
              borderRadius: "44px",
              maxWidth: "12ch",
              p: "0.25rem",
              textAlign: "center",
            }}
          >
            {item?.formStatus}
          </Typography>
        ))}
      </Stack>
    );
  }

  const mloMlpName = (cell) => {
    return (
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        {cell.getValue() === null ? (
          "Not assigned"
        ) : (
          <img
            alt="Profile"
            height={30}
            src={ProfileImg}
            loading="lazy"
            style={{ borderRadius: "50%", visibility: cell.getValue() ? '' : 'hidden' }}
          />
        )}
        <Typography>{cell.getValue()}</Typography>
      </Stack>
    );
  }

  const formActions = (row) => {
    return (
      <Stack direction={"row"}>
        <Tooltip arrow placement="left" title="Edit">
          <IconButton>
            <KSPencilAltIcon />
          </IconButton>
        </Tooltip>

        <Tooltip arrow placement="right" title="Chat">
          <IconButton
            onClick={() => {
              setChatData({
                firstScreen: true,
                externalChat: row?.original?.externalChat,
                internalChat: row?.original?.internalChat,
                appNo: row?.original?.appNo
              })
            }}
          >
            <KSChatSmIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  }

  const formLoanLink = (row) => {
    return (
      <Box justifyContent={"center"}>
        <Button variant="contained"
          onClick={() => { route(window.open(Path.loanApplication + `/${row.original.formId}`, "_blank")) }}>
          {row.original.isLocked ? `View application` : `Continue your application`}
        </Button>
      </Box>
    );
  }


  // Table's cell value configuration end
  const columns = useMemo(
    () => [
      {
        accessorKey: "appNo",
        header: "App No.",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => appNo(cell)
      },

      //column definitions...
      {
        accessorFn: (row) => borrowerName(row),
        accessorKey: "borrowerName",
        header: "Borrower Name",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorFn: (row) => appStatus(row),
        id: "formStatus",
        header: "Review Status",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "mloName",
        header: "MLO",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => mloMlpName(cell),
      },
      {
        accessorKey: "mlpName",
        header: "MLP",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => mloMlpName(cell),
      },
      {
        accessorKey: "action",
        header: "Action",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => formActions(row),
      },
      {
        accessorKey: "id",
        header: "",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => formLoanLink(row),
      },
      //end
    ],
    []
  );



  return (
    <>
      <ChatApp setChatData={setChatData} chatData={chatData} />
      <Grid container>
        <Grid item md={7} xs={12}>
          <Typography variant="h2">
            Welcome back, {profileInfo.firstName}
          </Typography>
          <Typography variant="body1" my={2}>
            {`Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text ever
            since the 1500s`}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <TabContext value={value}>
            <Paper>
              <Box>
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  allowScrollButtonsMobile
                >
                  <Tab label="All" value="all" />
                  <Tab label="Active" value="active" />
                  <Tab label="In Progress" value="in Progress" />
                  <Tab label="Closed" value="closed" />
                  <Tab label="Rejected" value="rejected" />
                  <Tab label="Locked" value="locked" />
                  <Tab label="Cancelled" value="archived" />
                </TabList>
              </Box>
            </Paper>
          </TabContext>
          <CustomReactMaterialTable
            enableToolbarInternalActions={false}
            enablePagination={false}
            params={{ status: value }}
            endPoint={API.loanApplications}
            columns={columns}
            enableTopToolbar={false}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default LoanData;
