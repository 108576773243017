import {Box, Button, Stack, Typography} from '@mui/material'
import React from 'react'


export function DemographicNeedInfo({ handleClose }) {
    return (
        <Box width={'100%'} my={2} >
            <Typography mb={2} variant={'inherit'} textAlign={'justify'}>
                {
                    `The purpose of collecting this information is to help ensure that 
            all applicants are treated fairly and that the housing needs of communities and 
            neighborhoods are being fulfilled. For residential mortgage lending, Federal 
            law requires that {{Broker company name}} ask applicants for their demographic 
            information (ethnicity, sex, and race) in order to monitor our compliance with 
            equal credit opportunity, fair housing, and home mortgage disclosure laws. You 
            are not required to provide this information, but are encouraged to do so. You 
            may select one or more designations for "Ethnicity" and one or more designations 
            for "Race." The law provides that Quick Loans may not discriminate on the basis of 
            this information, or on whether you choose to provide it. However, if you choose not 
            to provide the information and you have made this application in person, Federal 
            regulations require us to note your ethnicity, sex, and race on the basis of visual 
            observation or surname. The law also provides that Quick Loans may not discriminate 
            on the basis of age or marital status information you provide in this application. `
                }
            </Typography>
            <Stack direction={'row-reverse'} gap={2} >
                <Button variant='contained' onClick={handleClose}>Continue</Button>
            </Stack>
        </Box>
    )
}