import { CheckCircleRounded, Lock } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  KSChatSmIcon,
  KSMailIcon,
  KSPhoneIcon,
  KSUserGroupIcon,
} from "../../../../icons/custome-icon";
import AvaterMlo from "../../../../assets/Avatar-mlo.png";
import {
  TimeStampToMonthDateYear,
  TimeStampToMonthDateYearTime,
} from "../../../../helpers/time-formater";
import { LoanSummaryParentData } from "../loan-summary-visiblity";
import { Path } from "../../../../routes/route-config";
import { useRecoilValue } from "recoil";
import { profileState } from "../../../../recoil/profile-atom";
import { ROLE_LIST } from "../../../../constants/constatnt-variable";
import { getImageUrl } from "../../../../helpers/file-upload-download";

export default function LoanSummaryBorrowerInformation({
  loanState = "",
  loanStateDate = null,
  lockStatus = null,
  lockExpirationDate = null,
  borrowerData,
}) {
  const { visiblity } = useContext(LoanSummaryParentData);
  let profileInfo = useRecoilValue(profileState);

  const location = useLocation();
  const route = useNavigate();

  const goToAdditionalLoanContact = () => {
    route(location.pathname.replace(/\/[^/]+\/[^/]+$/, `/${borrowerData?.formId}/additional-loan-contact`))
  }

  return (
    <>
      <Grid container>
        <Grid item p={1} xs={12} md={"auto"}>
          <Avatar
            variant="rounded"
            src={getImageUrl(borrowerData?.avatarDocId, borrowerData?.avatarDocFileName, 'avatar')}
            sx={{
              width: { md: 100, sm: 80, xs: 70 },
              height: { md: 100, sm: 80, xs: 70 },
              margin: { xs: "auto" },
            }}
          />
        </Grid>
        <Grid
          container
          item
          p={1}
          xs={12}
          md={7}
          alignItems={"center"}
          flexGrow={1}
          spacing={1}
          justifyContent={"flex-start"}
        >
          <Grid item xs={12}>
            {/* Borrower Name and Form Link Start */}
            <Stack
              direction={"row"}
              gap={1}
              alignItems={"center"}
              justifyContent={{ xs: "center", md: "initial" }}
            >
              <Typography variant="h2">{`${borrowerData?.firstName} ${borrowerData?.lastName}`}</Typography>
              {visiblity ? (
                <Link
                  target="_blank"
                  to={
                    `../../` + Path.loanApplication + `/${borrowerData.formId}`
                  }
                  color="primary"
                >{`Edit Loan`}</Link>
              ) : null}
            </Stack>
            {/* Borrower Name and Form Link End */}
          </Grid>
          {loanState || loanStateDate ? (
            <Grid
              item
              xs={6}
              borderRight={2}
              pr={{ xs: 1, md: 0 }}
              borderColor={"#C4C4C420"}
            >
              {/* Borrower Info Start */}

              {loanState ? (
                <Stack
                  color={"success.main"}
                  direction={"row"}
                  gap={1}
                  alignItems={{ xs: "initial", md: "center" }}
                  my={1}
                >
                  <Typography variant="body2">{`${loanState}`}</Typography>
                  <CheckCircleRounded sx={{ fontSize: "14px" }} />
                </Stack>
              ) : null}

              {loanStateDate ? (
                <Typography
                  my={1}
                  variant="overline"
                  fontStyle={"italic"}
                  sx={{ textTransform: "initial", lineHeight: 1 }}
                >
                  {`(${TimeStampToMonthDateYearTime(loanStateDate)})`}
                </Typography>
              ) : null}

              {/* Borrower Info End */}
            </Grid>
          ) : null}
          <Grid item xs={6}>
            {/* Lock Status Start */}
            <Stack
              direction={"column"}
              gap={1}
              my={{ xs: 2, md: 0 }}
              alignItems={"start"}
              pl={{ xs: 0, md: 3 }}
            >
              {lockStatus == "Locked" ? (
                <Chip
                  icon={<Lock />}
                  label="LOCKED"
                  variant="outlined"
                  color="warning"
                  sx={{
                    backgroundColor: "warninglight.main",
                    border: "none",
                    fontWeight: "bold",
                    letterSpacing: 1.5,
                  }}
                />
              ) : null}
              {lockExpirationDate ? (
                <Box>
                  <Typography
                    variant="overline"
                    fontStyle={"italic"}
                    sx={{ textTransform: "initial", lineHeight: 1 }}
                  >
                    {`(Expires on ${TimeStampToMonthDateYear(
                      lockExpirationDate
                    )})`}
                  </Typography>
                </Box>
              ) : null}
            </Stack>
            {/* Lock Status End */}
          </Grid>
          <Grid item xs={12}>
            {/* Communication Icons Start */}
            <Stack
              direction={"row"}
              justifyContent={{ xs: "center", md: "start" }}
            >
              {borrowerData?.phone ? (
                <IconButton href={`tel:${borrowerData?.phone}`}>
                  <KSPhoneIcon />
                </IconButton>
              ) : null}

              {borrowerData?.chat ? (
                <IconButton>
                  <KSChatSmIcon />
                </IconButton>
              ) : null}

              {borrowerData?.email ? (
                <IconButton href={`mailto:${borrowerData?.email}`}>
                  <KSMailIcon />
                </IconButton>
              ) : null}

              {profileInfo.role == ROLE_LIST.borrower ? null : (
                <IconButton onClick={goToAdditionalLoanContact}>
                  <KSUserGroupIcon />
                </IconButton>
              )}
            </Stack>
            {/* Communication Icons End */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
