import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { KSCloudUploadIcon, KSDocumentSearchIcon } from '../../../../../icons/custome-icon'
import React, { useState } from 'react'
import DocumentStatus from './document-status'
import { downloadReportFromServer, documentPreview } from '../../../../../helpers/file-upload-download'
import { API } from '../../../../../services/api-config'
import { useRecoilValue } from 'recoil'
import { profileState } from '../../../../../recoil/profile-atom'
import { ROLE_LIST, UPDATE } from '../../../../../constants/constatnt-variable'
import OverriddeReasonForm from './override-document/Overridde-reason-form'
import { DialogCommonHooks, PreviewPDFModal } from '../../../../../helpers/modal/modal'
import { postApiCallDynamicWithOutReturn } from '../../../../../services/api-service'
import FilePreview from '../../../../broker/leads/file-preview'
export default function DocumentStatusCard(
    { control,
        status,
        title = null,
        fileName,
        info,
        docIc,
        uploadTime,
        fileUpload,
        isReview,
        isOverridden,
        validStatus,
        overrideReason,
        setUpdateState,
        ...props }
) {
    const downloadDocument = () => {
        downloadReportFromServer(API.proxyDocumentDownload, {
            id: docIc,
            serviceType: 'guided'
        })
    }

    const previewDocument = () => {


        console.log('in preview');
        const fileUrl = documentPreview(API.proxyDocumentDownload, {
            id: docIc,
            serviceType: 'guided'
        }).then((response) => {

            console.log('return url' + response);
            < PreviewPDFModal
                size={'lg'}
                show={modalOpen}
                handleClose={handleClose}
                title={`Document`
                }
                body={
                    < FilePreview
                        fileUrl={response}
                        downloadPdf={downloadDocument}
                    />
                }
            />
        })

    }
    let profileInfo = useRecoilValue(profileState);

    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => {
        setModalOpen(true);
    };
    const handleClose = () => {
        setModalOpen(false);
    };

    const overrideReasonOnSubmit = async (data) => {
        let apiCallData = {
            id: docIc,
            overrideReason: data.overrideReason
        }

        await postApiCallDynamicWithOutReturn({
            data: apiCallData,
            path: API.guidedDocumentOverride,
            // header: statisticsData ? UPDATE : "",
        });
        setUpdateState((prev) => !prev)
        handleClose();

    };

    const overrideValidInvalidStatus = async (status) => {
        let apiCallData = {
            id: docIc,
            validStatus: status
        }

        await postApiCallDynamicWithOutReturn({
            data: apiCallData,
            path: API.guidedDocumentOverride,
            header: UPDATE,
        });
        setUpdateState((prev) => !prev)
        handleClose();
    }

    return (
        <>
            <Box my={2} p={{ xs: 1, md: 2, lg: 3 }} border={1} borderColor={'#11111115'} borderRadius={1}>
                <Grid container justifyContent={'space-between'} spacing={3}>

                    {/* Documents title */}
                    <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
                        <Typography my={.5} variant='h6'>{title}</Typography>
                        {info && info.map((item) =>
                            <Typography color={"#222222BF"} key={item.label} variant='body1'>{item.label}: {item.value}</Typography>
                        )}
                    </Grid>

                    {/* Documents review status */}
                    <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
                        <DocumentStatus matchStatus={status} />
                        {
                            isOverridden ?
                                <Stack direction={'row'} gap={1} alignSelf={'center'}>
                                    <KSDocumentSearchIcon />
                                    <Typography color={'error.main'}>{`Overridden`}</Typography>
                                </Stack>
                                :
                                <Stack direction={'row'} gap={1} alignSelf={'center'}>
                                    <KSDocumentSearchIcon />
                                    <Typography
                                        sx={{ cursor: 'pointer' }}
                                        onClick={isReview ? "" : handleModalOpen}
                                        color={'error.main'}
                                    >
                                        {`Overridde`}
                                    </Typography>
                                </Stack>
                        }
                        <DocumentStatus matchStatus={validStatus} />
                        <Typography mt={3}
                            display={(status == "Matched" && validStatus == "Valid") ? "none" : ""}
                            sx={{ cursor: profileInfo.role == ROLE_LIST.borrower ? `` : `pointer` }}
                            color={'A5.main'}
                            onClick={isReview ? null : profileInfo.role !== ROLE_LIST.borrower ? handleModalOpen : null}
                        >
                            {profileInfo.role == ROLE_LIST.borrower ? `Under Manual Review` : `Manual Review`}
                        </Typography>
                    </Grid>

                    {/* Documents file status */}
                    <Grid container direction={'column'} justifyContent={'space-between'} item xs={12} md={4} textAlign={{ xs: 'center', md: 'right' }}>
                        <Grid item>
                            <Stack direction={'column'} gap={1}>
                                <Typography sx={{ cursor: 'pointer' }} onClick={downloadDocument} variant='body1' color={"A5.main"}>{fileName}</Typography>
                                <Stack display={isReview ? 'none' : null} direction={'row-reverse'} gap={1} alignSelf={{ xs: 'center', md: 'auto' }}>
                                    <IconButton color='primary' component="label">
                                        <input id={docIc} onChange={fileUpload} hidden accept="image/*" type="file" />
                                        <KSCloudUploadIcon />
                                    </IconButton>
                                    <IconButton color='primary' onClick={previewDocument}>
                                        <KSDocumentSearchIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Typography color={'A5.main'}>{`Uploaded on: ${uploadTime}`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Override`}
                body={
                    <OverriddeReasonForm
                        overrideReasonOnSubmit={overrideReasonOnSubmit}
                        overrideValidInvalidStatus={overrideValidInvalidStatus}
                        overrideReason={overrideReason}
                        handleClose={handleClose}
                        role={profileInfo.role}
                        fileName={fileName}
                    />
                }
            />
        </>


    )
}
