import React, { useState } from "react";
import CustomRadioChip from "../../../../helpers/radio-chip";
import {
    Button,
    FormHelperText,
    FormLabel,
    InputLabel,
    Stack,
    Typography,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
    TASK_LENDERS_CONDITION,
    TASK_PRIORITY,
    TASK_TYPE_CHOICE,
    UPDATE,
} from "../../../../constants/constatnt-variable";
import CustomInput from "../../../../helpers/custom-input";
import RadioButtonGroup from "../../../../helpers/radio-button-group";
import {
    postApiCallDynamicWithOutReturn,
    uploadFile,
} from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import {
    DateToTimestapmFormater,
    TimeStampToMonthDateYearForInput,
} from "../../../../helpers/time-formater";
import { currentDate } from "../../../../helpers/common_fuctions/current-date";
import { KSCloudUploadIcon } from "../../../../icons/custome-icon";
import { toastNotify } from "../../../../helpers/alert-msg";
import { TaskValidation } from "../../../../constants/validation-rules";

export default function BorrowerTaskForm({
    formId,
    edit = false,
    taskFormData,
    handleClose,
    taskReload,
}) {

    const [brokerFileID, setBrokerFileID] = useState(null);
    const [disableTaskCreate, setDisableTaskCreate] = useState(false)

    const fileUpload = (event) => {
        setDisableTaskCreate(true)
        let file = event.target.files[0];
        if (event.target.files) {
            uploadFile({
                formId: formId,
                docId: taskFormData?.brokerDocId ? taskFormData?.brokerDocId : null,
                file: file,
                serviceType: "task",
                path: API.proxyDocumentOthersUpload,
            }).then((res) => {
                if (res.status === 201) {
                    toastNotify("success", res.data.message);
                    setBrokerFileID(res?.data?.docId);
                    setDisableTaskCreate(false)
                }
            });
        } else {
            console.log("not found");
        }
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            formId: formId,
            taskType: taskFormData?.taskType,
            title: taskFormData?.title,
            description: taskFormData?.description,
            isLender: taskFormData?.isLender ? "Yes" : "No",
            dueDate: TimeStampToMonthDateYearForInput(taskFormData?.dueDate),
            priority: taskFormData?.priority,
            brokerDocId: taskFormData?.brokerDocId,
            borrowerDocId: taskFormData?.borrowerDocId,
        },
    });

    const formOnSubmit = async (data) => {
        data = Object.assign(data, {
            formId: formId,
            dueDate: DateToTimestapmFormater(data.dueDate),
            isLender: data?.isLender == "Yes" ? true : false,
            id: taskFormData?.id ? taskFormData.id : null,
            brokerDocId: taskFormData?.brokerDocId
                ? taskFormData?.brokerDocId
                : brokerFileID,
        });

        await postApiCallDynamicWithOutReturn({
            data: data,
            path: API.task,
            header: taskFormData ? UPDATE : "",
            refresh: taskReload,
        });

        handleClose();
    };

    const { taskType } = useWatch({ control })

    return (
        <>
            <form onSubmit={handleSubmit(formOnSubmit)}>
                Borrower Task Form
                <Controller
                    name="taskType"
                    control={control}
                    rules={TaskValidation.taskType}
                    render={({ field }) => (
                        <CustomRadioChip
                            {...field}
                            field={field}
                            required
                            label={"Task Type"}
                            data={TASK_TYPE_CHOICE}
                            error={errors?.taskType?.message}
                            state={taskType}
                        />
                    )}
                />

                <Controller
                    name="title"
                    control={control}
                    rules={TaskValidation.title}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            field={field}
                            required
                            type="text"
                            label={"Task Title"}
                            error={errors?.title?.message}
                        />
                    )}
                />

                <Controller
                    name="description"
                    control={control}
                    rules={TaskValidation.description}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            field={field}
                            required
                            type="text"
                            label={"Task Description"}
                            error={errors?.description?.message}
                        />
                    )}
                />

                <Controller
                    name="isLender"
                    control={control}
                    rules={TaskValidation.isLender}
                    render={({ field }) => (
                        <RadioButtonGroup
                            {...field}
                            field={field}
                            required
                            direction="row"
                            labelComponent={<FormLabel>{`Is Lender's Condition?`}</FormLabel>}
                            data={TASK_LENDERS_CONDITION}
                            error={errors?.isLender?.message}
                        />
                    )}
                />

                <Controller
                    name="dueDate"
                    control={control}
                    rules={TaskValidation.dueDate}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            field={field}
                            minDate={currentDate()}
                            required
                            type="date"
                            label={"Due Date"}
                            error={errors?.dueDate?.message}
                        />
                    )}
                />

                {
                    taskType == 'Download & upload' ?
                        <Controller
                            name="brokerDocId"
                            control={control}
                            rules={TaskValidation.brokerDocId}
                            render={({ field }) => (
                                <>
                                    <Stack
                                        direction={"row"}
                                        gap={1}
                                        alignItems={"center"}
                                        my={2}
                                        justifyContent={"space-between"}
                                    >
                                        <InputLabel>{`Document to Sign`}
                                            <Typography component={'span'} color={'error'}>{`*`}</Typography>
                                        </InputLabel>


                                        <Button
                                            component="label"
                                            size="small"
                                            {...field}
                                            value={brokerFileID}
                                            startIcon={<KSCloudUploadIcon color={"primary"} />}
                                        >
                                            <input
                                                onChange={fileUpload}
                                                hidden
                                                accept="image/*"
                                                type="file"
                                            />
                                            {`Upload`}
                                        </Button>
                                    </Stack>
                                    <FormHelperText error>
                                        {errors?.brokerDocId?.message}
                                    </FormHelperText>
                                </>
                            )}
                        /> : null
                }



                <Controller
                    name="priority"
                    control={control}
                    rules={TaskValidation.priority}
                    render={({ field }) => (
                        <CustomRadioChip
                            {...field}
                            field={field}
                            required
                            label={"Task Type"}
                            data={TASK_PRIORITY}
                            error={errors?.priority?.message}
                        />
                    )}
                />

                <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2}>
                    <Button type="submit" variant="contained" disabled={disableTaskCreate}>
                        {edit ? "Update" : "Create"}
                    </Button>
                    <Button variant="text" onClick={handleClose}>
                        Cancel
                    </Button>
                </Stack>
            </form>
        </>
    );
}
