import { TabContext, TabList } from "@mui/lab";
import { Box, Button, Chip, Tab } from "@mui/material";
import React, { useContext, useState } from "react";
import LoanSummaryTaskCard from "./loan-summary-task-card";
import { DialogCommonHooks } from "../../../../helpers/modal/modal";
import LoanSummaryTaskForm from "../loan-summary-forms/loan-summary-task-from";
import { LoanSummaryParentData } from "../loan-summary-visiblity";
import BorrowerTaskForm from "../loan-summary-forms/borrower-task-form";
import BorrowerTaskCard from "./third-party-task-card";

export default function BorrowerTaskBody({
    formId,
    borrowerName,
    taskTabCount,
    tasksLists,
    taskReload,
    taskTabValue,
    taskTabHandleChange,
}) {
    const { visiblity } = useContext(LoanSummaryParentData);
    // New Task Modal
    const [taksModal, setTaksModal] = useState(false);

    const handleTaskModalOpen = () => {
        setTaksModal(true);
    };

    const handleTaskModalClose = () => {
        setTaksModal(false);
    };

    // TODO Task filter
    // const [anchorEl, setAnchorEl] = useState(null);
    // const filterOpen = Boolean(anchorEl);
    // const filterHandleClick = (event) => {
    //   setAnchorEl(event.currentTarget);
    // };
    // const filterHandleClose = () => {
    //   setAnchorEl(null);
    // };
    // const [filterCreated, setFilterCreated] = useState(false);
    // const createdFilterHandleChange = (event) => {
    //   setFilterCreated(event.target.checked);
    // };
    // const [filterDueDate, setFilterDueDate] = useState(false);
    // const dueDateFilterHandleChange = (event) => {
    //   setFilterDueDate(event.target.checked);
    // };

    return (
        <>
            <TabContext value={taskTabValue}>
                <Box bgcolor={"white"} sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                        onChange={taskTabHandleChange}
                        variant="scrollable"
                        allowScrollButtonsMobile
                        aria-label="Loan Summary Task Tab"
                    >
                        {taskTabCount.map((tabStatus) => (
                            <Tab
                                key={tabStatus.name}
                                label={tabStatus.name}
                                value={tabStatus.name}
                                iconPosition="end"
                                icon={
                                    // Use for tab counting
                                    <Chip
                                        label={tabStatus.count}
                                        sx={{
                                            color: "black",
                                            backgroundColor:
                                                taskTabValue == tabStatus.name
                                                    ? "primarylight.main"
                                                    : "#F2F2F2",
                                            fontWeight: "bold",
                                            borderRadius: "5px",
                                        }}
                                    />
                                }
                            />
                        ))}

                        {/* TODO Task Filter Menu */}
                        {/* <Box ml={"auto"} my={"auto"} pr={2}>
              <IconButton
                id="basic-button"
                aria-controls={filterOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={filterOpen ? "true" : undefined}
                onClick={filterHandleClick}
                sx={{ borderRadius: "5px" }}
              >
                <KSFilterIcon />
              </IconButton>
              <Menu
                id="task-filter"
                anchorEl={anchorEl}
                open={filterOpen}
                onClose={filterHandleClose}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
              >
                <MenuItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterCreated}
                        onChange={createdFilterHandleChange}
                      />
                    }
                    label={`Created on`}
                  />
                </MenuItem>
                <MenuItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterDueDate}
                        onChange={dueDateFilterHandleChange}
                      />
                    }
                    label={`Due date`}
                  />
                </MenuItem>
              </Menu>
            </Box> */}
                    </TabList>
                </Box>

                {visiblity ? (
                    <Box
                        my={2}
                        display={"flex"}
                        justifyContent={{ xs: "center", md: "end" }}
                    >
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={handleTaskModalOpen}
                        >{`New Task`}</Button>
                    </Box>
                ) : null}
            </TabContext>

            {tasksLists?.data?.map((taskCardData) =>
                taskCardData ? (
                    <BorrowerTaskCard
                        formId={formId}
                        borrowerName={borrowerName}
                        key={taskCardData.formId}
                        taskCardData={taskCardData}
                        taskReload={taskReload}
                    />
                ) : null
            )}

            <DialogCommonHooks
                title={`Create New Task for ${borrowerName}`}
                show={taksModal}
                handleClose={handleTaskModalClose}
                body={
                    <BorrowerTaskForm
                        formId={formId}
                        taskReload={taskReload}
                        handleClose={handleTaskModalClose}
                    />
                }
            />
        </>
    );
}
