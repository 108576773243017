// // import { Box, Button } from "@mui/material";
// // import React from "react";
// // import { CREATE, ERROR_MESSAGE, MODAL_ACTION } from "../../../constants/constatnt-variable";
// // import { Controller, useForm } from "react-hook-form";
// // import CustomInput from "../../../helpers/custom-input";

// // export default function DocumentLibraryForm({
// //     data,
// //     action = CREATE,
// //     onSubmit,
// // }) {
// //     const DemoRe1uestNoteRules = {
// //         note: {
// //             required: { value: true, message: ERROR_MESSAGE.required },
// //         },

// //     }
// //     const { control, handleSubmit, formState: { errors } } = useForm({
// //         defaultValues: {
// //             note: data?.note,
// //             id: data?.id
// //         }
// //     })

// //     return (
// //         <form onSubmit={handleSubmit(onSubmit)}>
// //             <Controller
// //                 name='note'
// //                 control={control}
// //                 rules={DemoRe1uestNoteRules.note}
// //                 render={({ field }) => (
// //                     <CustomInput
// //                         {...field}
// //                         label={'Notes'}
// //                         required
// //                         placeholder={'This loan is in the process'}
// //                         size="large"
// //                         multiline
// //                         rows={10}
// //                         error={errors?.note?.message}
// //                     />
// //                 )}
// //             />

// //             <Box display="flex" justifyContent="flex-end" m={1}>
// //                 <Button type='submit' id={action} variant="contained" onClick={handleSubmit}>
// //                     {/* {MODAL_ACTION[action]} */} Save
// //                 </Button>
// //             </Box>
// //         </form>
// //     );
// // }


// import { AttachMoney, } from '@mui/icons-material'
// import { Box, Button, FormLabel, Stack } from '@mui/material'
// import React from 'react'
// import {
//     ACCOUNT_TYPE,
//     ASSET_OWNED_BY,
//     ASSET_OWNED_BY_WITHOUT_PARTNER,
//     BLANK_DOCUMENT_STATUS,
//     STATUS_OF_REALTOR,
//     TASK_VENDOR
// } from '../../../constants/constatnt-variable'
// import CustomInput from '../../../helpers/custom-input'
// import CustomInputSideCheckbox from '../../../helpers/custom-input-side-checkbox'
// import CustomDropDown from '../../../helpers/dropdown/custom-dropdown'
// import { Controller, useForm } from 'react-hook-form'
// import CustomRadioChip from '../../../helpers/radio-chip'
// import RadioButtonGroupDoc from '../../../helpers/radio-button-group-doc'
// import { KSCloudUploadIcon } from '../../../icons/custome-icon'


// export default function SupportingDocumentForm({ handleClose, onSubmit, editItem, hasPartner }) {



//     const { control, handleSubmit, formState: { errors } } = useForm({
//         defaultValues: {
//             // accountType: editItem?.accountType,
//             // institutionName: editItem?.institutionName,
//             // accountNo: editItem?.accountNo,
//             // marketValue: editItem?.marketValue,
//             // ownedBy: editItem?.ownedBy,
//             // isDownPayment: editItem?.isDownPayment
//             note: editItem?.note,
//             documentType: editItem?.documentType,
//             taskType: editItem?.taskType
//         }
//     })

//     const [brokerFileID, setBrokerFileID] = useState(null);
//     const [disableTaskCreate, setDisableTaskCreate] = useState(false)

//     const fileUpload = (event) => {
//         setDisableTaskCreate(true)
//         let file = event.target.files[0];
//         if (event.target.files) {
//             uploadFile({
//                 formId: formId,
//                 docId: taskFormData?.brokerDocId ? taskFormData?.brokerDocId : null,
//                 file: file,
//                 serviceType: "task",
//                 path: API.proxyDocumentOthersUpload,
//             }).then((res) => {
//                 if (res.status === 201) {
//                     toastNotify("success", res.data.message);
//                     setBrokerFileID(res?.data?.docId);
//                     setDisableTaskCreate(false)
//                 }
//             });
//         } else {
//             console.log("not found");
//         }
//     };

//     return (
//         <form onSubmit={handleSubmit(onSubmit)}>
//             <Box width={'100%'} my={6} >
//                 <Box>
//                     <Stack spacing={6}> {/* Adjust spacing value as needed */}
//                         {/* <Button variant='contained'>Upload from Local</Button>
//                         <Button variant='contained'>Upload from CliQloan Library</Button>
//                         <Button variant='contained'>Upload from Loan Documents</Button> */}


//                         <Button
//                             component="label"
//                             size="small"
//                             {...field}
//                             value={brokerFileID}
//                             startIcon={<KSCloudUploadIcon color={"primary"} />}
//                         >
//                             <input
//                                 onChange={fileUpload}
//                                 hidden
//                                 accept="image/*"
//                                 type="file"
//                             />
//                             {`Upload`}
//                         </Button>

//                         {/* <Button
//                             type="button"
//                             onChange={fileUpload}
//                             variant="contained"

//                         >
//                             Upload from Local
//                         </Button> */}

//                         <Button
//                             type="button"
//                             onClick={handleClose}
//                             variant="contained"

//                         >
//                             Upload from CliQloan Library
//                         </Button>

//                         <Button
//                             type="button"
//                             onClick={handleClose}
//                             variant="contained"

//                         >
//                             Upload from Loan Documents
//                         </Button>
//                     </Stack>
//                 </Box>



//                 {/* <Controller
//                         name="taskType"
//                         control={control}
//                         //rules={AssetsCreditValidation.institutionName}
//                         render={({ field }) => (
//                             <CustomInput {...field}
//                                 placeholder={'Task Type'}
//                                 type={'text'} label={'Select the Task Type *'} required error={errors?.institutionName?.message} />
//                         )}
//                     />
//                     <Controller
//                         name="documentType"
//                         control={control}
//                         //rules={AssetsCreditValidation.institutionName}
//                         render={({ field }) => (
//                             <CustomInput {...field}
//                                 placeholder={'Enter document type type'}
//                                 type={'text'} label={'Enter Document Type *'} required error={errors?.institutionName?.message} />
//                         )}
//                     />

//                     <Controller
//                         name="documentDecriptor"
//                         control={control}
//                         //rules={AssetsCreditValidation.institutionName}
//                         render={({ field }) => (
//                             <CustomInput {...field}
//                                 placeholder={'Enter the instructions for filling the document'}
//                                 type={'text'} label={'Note for Filling the Document *'} required error={errors?.institutionName?.message} />
//                         )}
//                     />

//                     <Controller
//                         name="note"
//                         control={control}
//                         //rules={AssetsCreditValidation.institutionName}
//                         render={({ field }) => (
//                             <CustomInput {...field}
//                                 placeholder={'None'}
//                                 type={'text'} label={'Enter Document Descriptor *'} required error={errors?.institutionName?.message} />
//                         )}
//                     />

//                     <Controller
//                         name="isBlankDoc"
//                         control={control}
//                         //  rules={TaskValidation.isLender}
//                         render={({ field }) => (
//                             <RadioButtonGroupDoc
//                                 {...field}
//                                 field={field}
//                                 required
//                                 direction="row"
//                                 labelComponent={<FormLabel>{`Is Blank document available?`}</FormLabel>}
//                                 data={BLANK_DOCUMENT_STATUS}
//                                 error={errors?.isLender?.message}
//                             />
//                         )}
//                     /> */}







//                 <Box marginY={4} textAlign={'right'}>
//                     {/* <Button type='submit' variant='contained' >
//                         {editItem.length !== 0 ? 'Update' : 'Add'}
//                     </Button> */}

//                     {/* <Button
//                         type="button"
//                         onClick={handleClose}
//                         //variant="contained"
//                         sx={{
//                             //  width: '200px', // Set a fixed width for the button
//                             width: '50%', // Alternatively, set a percentage width
//                         }}
//                     >
//                         Cancel
//                     </Button> */}

//                     {/* <Button
//                         type="submit"
//                         variant="contained"
//                         sx={{
//                             //  width: '200px', // Set a fixed width for the button
//                             width: '50%', // Alternatively, set a percentage width
//                         }}
//                     >
//                         {editItem.length !== 0 ? 'Update' : 'Add'}
//                     </Button> */}
//                 </Box>
//             </Box>
//         </form>

//     )
// }

import React, { useState } from "react";
import CustomRadioChip from "../../../../helpers/radio-chip";
import {
    Box,
    Button,
    FormHelperText,
    FormLabel,
    InputLabel,
    Stack,
    Typography,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
    CONTACT_STATE,
    TASK_LENDERS_CONDITION,
    TASK_PRIORITY,
    TASK_TYPE_CHOICE,
    UPDATE,
} from "../../../../constants/constatnt-variable";
import CustomInput from "../../../../helpers/custom-input";
import RadioButtonGroup from "../../../../helpers/radio-button-group";
import {
    postApiCallDynamicWithOutReturn,
    uploadFile,
} from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import {
    DateToTimestapmFormater,
    TimeStampToMonthDateYearForInput,
} from "../../../../helpers/time-formater";
import { currentDate } from "../../../../helpers/common_fuctions/current-date";
import { KSCloudUploadIcon } from "../../../../icons/custome-icon";
import { toastNotify } from "../../../../helpers/alert-msg";
import { TaskValidation } from "../../../../constants/validation-rules";
import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";
import MultiSelectDropDown from "../../../../helpers/dropdown/multi-select-dropdown";
import SupportingDocument from "./supporting-doc";



export default function SupportingDocumentForm({
    formId,
    edit = false,
    taskFormData,
    handleClose,
    taskReload,
}) {

    const [brokerFileID, setBrokerFileID] = useState(null);
    const [disableTaskCreate, setDisableTaskCreate] = useState(false)

    const fileUpload = (event) => {
        setDisableTaskCreate(true)
        let file = event.target.files[0];
        if (event.target.files) {
            uploadFile({
                formId: formId,
                docId: taskFormData?.brokerDocId ? taskFormData?.brokerDocId : null,
                file: file,
                serviceType: "task",
                path: API.proxyDocumentOthersUpload,
            }).then((res) => {
                if (res.status === 201) {
                    toastNotify("success", res.data.message);
                    setBrokerFileID(res?.data?.docId);
                    setDisableTaskCreate(false)
                }
            });
        } else {
            console.log("not found");
        }
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            formId: formId,
            taskType: taskFormData?.taskType,
            title: taskFormData?.title,
            description: taskFormData?.description,
            isLender: taskFormData?.isLender ? "Yes" : "No",
            dueDate: TimeStampToMonthDateYearForInput(taskFormData?.dueDate),
            priority: taskFormData?.priority,
            brokerDocId: taskFormData?.brokerDocId,
            borrowerDocId: taskFormData?.borrowerDocId,
        },
    });

    const formOnSubmit = async (data) => {
        data = Object.assign(data, {
            formId: formId,
            dueDate: DateToTimestapmFormater(data.dueDate),
            isLender: data?.isLender == "Yes" ? true : false,
            id: taskFormData?.id ? taskFormData.id : null,
            brokerDocId: taskFormData?.brokerDocId
                ? taskFormData?.brokerDocId
                : brokerFileID,
        });

        await postApiCallDynamicWithOutReturn({
            data: data,
            path: API.task,
            header: taskFormData ? UPDATE : "",
            refresh: taskReload,
        });

        handleClose();
    };

    const { taskType } = useWatch({ control })

    return (
        <>
            <Box>
                <Stack spacing={2}> {/* Adjust spacing value as needed */}
                    <Button
                        component="label"
                        size="small"
                        // {...field}
                        variant="contained"
                        value={brokerFileID}
                    // startIcon={<KSCloudUploadIcon  color={"primary"}  />}
                    >
                        <input
                            onChange={fileUpload}
                            hidden
                            accept="image/*"
                            type="file"
                        />
                        {`Upload from Local`}
                    </Button>

                    {/* <Button
                                type="button"
                                onChange={fileUpload}
                                variant="contained"

                            >
                                Upload from Local
                            </Button> */}

                    <Button
                        type="button"
                        onClick={handleClose}
                        variant="contained"

                    >
                        Upload from CliQloan Library
                    </Button>

                    <Button
                        type="button"
                        onClick={handleClose}
                        variant="contained"

                    >
                        Upload from Loan Documents
                    </Button>
                </Stack>
            </Box >


        </>
    );
}
