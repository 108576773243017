import { Box, IconButton, Typography, useMediaQuery, } from "@mui/material";
import React, { useMemo, useState } from "react";
import MainLoyOut from "../../../components/main-layout";
import { useLocation, useNavigate } from "react-router-dom";
import CustomReactMaterialTable from "../../../helpers/table/react-material-table";
import { API } from "../../../services/api-config";
import { DEMO_STATUS, UPDATE } from "../../../constants/constatnt-variable";
import CustomDropDown from "../../../helpers/dropdown/custom-dropdown";
import { postApiCallDynamicWithOutReturn, } from "../../../services/api-service";
import { TimeStampToMonthDateYear } from "../../../helpers/time-formater";
import { KSClipBoardListIcon, KSMailIcon } from "../../../icons/custome-icon";
import { kstheme } from "../../../theme/theme";
import { openMail } from "../../../helpers/common_fuctions/open-mail";

export default function DemoRequest() {
  const [tableReload, setTableReload] = useState(() => false);
  const router = useNavigate();

  const isTab = useMediaQuery(kstheme.breakpoints.down("md"));

  const demoStatusChange = async ({ id, value }) => {
    let data = { id: id, status: value };
    await postApiCallDynamicWithOutReturn({
      data: data, refresh: () => {
        setTableReload((prev) => !prev);
      }, path: API.demoStatusChange, header: UPDATE
    });
  };

  const demoStatus = (cell, row) => {
    return (
      <Box sx={{ minWidth: 120 }}>
        <CustomDropDown
          option={DEMO_STATUS}
          defaultValue={cell.getValue()}
          changeCallBack={demoStatusChange}
          id={row.original.id}
        />
      </Box>
    );
  }

  const demoActions = (cell, row) => {
    return (
      <Box justifyContent={"center"}>
        <IconButton
          onClick={(event) => {
            goToNote(cell, row);
          }}
        >
          <KSClipBoardListIcon />
        </IconButton>
        <IconButton onClick={() => { openMail(row.original.email) }}>
          <KSMailIcon />
        </IconButton>

      </Box>
    );
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Serial no.",
        enableColumnActions: false,
        size: 0
      },

      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: "Name",
        enableColumnActions: false,
        size: 0
      },
      {
        accessorKey: "email",
        header: "Email",
        enableColumnActions: false,
        size: 0
      },
      {
        accessorKey: "phone",
        header: "Contact no.",
        enableColumnActions: false,
        size: 0
      },
      {
        accessorKey: "date",
        header: "Requested Date",
        enableColumnActions: false,
        size: 0,
        Cell: ({ cell }) => {
          return (
            TimeStampToMonthDateYear(cell.getValue())
          );
        },
      },
      {
        accessorKey: "status",
        header: "Demo Status",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell, row }) => demoStatus(cell, row),
      },

      {
        accessorKey: "id",
        header: "Action",
        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell, row }) => demoActions(cell, row),
      },
    ],
    []
  );



  const location = useLocation();

  const goToNote = (cell, row) => {
    router(`${cell.getValue()}/notes`, {
      state: { ...location.state },
    });
  };

  return (
    <MainLoyOut>

      <CustomReactMaterialTable
        enableToolbarInternalActions={false}
        showGlobalFilter={true}
        endPoint={API.demoRequest}
        refetch={tableReload}
        columns={columns}
        muiSearchTextFieldProps={{
          placeholder: isTab ? 'Search' :
            "Search for people with their name / registered number / registered email",
          sx: { minWidth: { xs: 'auto', md: "42rem" } },
          variant: "outlined",
        }}
      />

    </MainLoyOut>
  );
}
