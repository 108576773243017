import React, { useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import { Chat, Channel, Thread, Window } from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";
import { getApiCallDynamic } from "../../services/api-service";
import { API } from "../../services/api-config";
import ChatHeader from "./chat-header";
import { CustomMessageInput } from "./custom-message-input";
import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Tab,
} from "@mui/material";
import ChatBody from "./chat-body";
import ChatInitial from "../../assets/chat_initial.png";
import { Close } from "@mui/icons-material";
import { useRecoilValue } from "recoil";
import { profileState } from "../../recoil/profile-atom";
import { CHAT_TABS, ROLE_LIST } from "../../constants/constatnt-variable";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { KSChatImage } from "../../icons/custome-icon";

const chatClient = new StreamChat(process.env.REACT_APP_GETSTREAM_SECRECT_KEY);

export default function ChatApp({ chatData, setChatData }) {
  let profileInfo = useRecoilValue(profileState);

  const [chatScreen, setChatScreen] = useState(false);
  const [chatTabValue, setchatTabValue] = useState(null);
  const [userToken, setUserToken] = useState("");

  let externalButton;
  if (profileInfo.role === ROLE_LIST.borrower) {
    externalButton = `CHAT WITH BROKER/MLO/MLP`;
  } else {
    externalButton = `CHAT WITH BORROWER`;
  }

  const chatTabHandleChange = (event, newValue) => {
    if (newValue == 1) {
      setchatTabValue(1);
      fetchChannel(chatData.internalChat.groupId);
    }
    if (newValue == 2) {
      setchatTabValue(2);
      fetchChannel(chatData.externalChat.groupId);
    }
  };

  const user = {
    id: profileInfo?.userCode,
    name: profileInfo?.firstName,
  };

  useEffect(() => {
    getApiCallDynamic({
      path: API.chatUserToken,
    }).then((res) => {
      setUserToken(res.data.accessToken);
    });
  }, []);

  const [channel, setChannel] = useState(null);

  const fetchChannel = async (channelId) => {
    try {
      // Connect the user
      await chatClient.connectUser(user, userToken);

      // Create channel
      const newChannel = chatClient.channel("team", channelId);
      // Watch the channel and wait for it to resolve
      await newChannel.watch();

      // Update the channel state
      setChannel(newChannel);
    } catch (error) {
      console.error("Channel creation error:", error);
    }
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        open={chatData?.firstScreen}
        sx={{ zIndex: 999999 }}
      >
        <Stack
          direction={"column"}
          justifyContent={"center"}
          height={"100%"}
          gap={3}
          p={3}
          position={"relative"}
          width={{ xs: "100vw", md: "480px" }}
          overflow={"hidden"}
        >
          <Box position={"absolute"} right={2} top={2}>
            <IconButton
              onClick={() => {
                setChatData({ ...chatData, firstScreen: false });
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <KSChatImage sx={{ width: '100%', height: 'auto' }} />
          {profileInfo.role !== ROLE_LIST.borrower ? (
            <Button
              variant="outlined"
              sx={{
                minWidth: "initial",
              }}
              onClick={() => {
                setChatData({ ...chatData, firstScreen: false });
                setchatTabValue(1);
                setChatScreen(true);
                fetchChannel(chatData.internalChat.groupId);
              }}
            >{`CHAT WITH MLP/MLO`}</Button>
          ) : null}
          <Button
            variant="contained"
            onClick={() => {
              setChatData({ ...chatData, firstScreen: false });
              setchatTabValue(2);
              setChatScreen(true);
              fetchChannel(chatData.externalChat.groupId);
            }}
          >
            {externalButton}
          </Button>
        </Stack>
      </Drawer>

      <Drawer anchor={"right"} open={chatScreen} sx={{ zIndex: 999999 }}>
        <Chat client={chatClient} theme="str-chat__theme-light">
          <Channel channel={channel}>
            <Box width={{ xs: "100vw", md: "480px" }}>
              <Window>
                <Grid
                  container
                  direction={"column"}
                  flexWrap={"nowrap"}
                  height={"100%"}
                  overflow={"hidden"}
                  justifyContent={"space-between"}
                >
                  {/* component call for chat header  */}
                  <Grid item flexGrow={0}>
                    <ChatHeader
                      setChatScreen={setChatScreen}
                      setChatData={setChatData}
                      chatData={chatData}
                      channel={channel}
                    />
                  </Grid>

                  {/* component call for chat body */}
                  <Grid item flexGrow={1} overflow={"hidden"}>
                    {profileInfo?.role !== ROLE_LIST.borrower ? (
                      <TabContext value={chatTabValue}>
                        <Grid
                          container
                          direction={"column"}
                          flexWrap={"nowrap"}
                          height={"100%"}
                          overflow={"hidden"}
                          justifyContent={"space-between"}
                        >
                          <Grid item flexGrow={0}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <TabList
                                onChange={chatTabHandleChange}
                                aria-label="Tab Lists"
                                variant="fullWidth"
                              >
                                {CHAT_TABS.map((chat) => (
                                  <Tab
                                    key={chat.id}
                                    label={chat.title}
                                    value={chat.id}
                                  />
                                ))}
                              </TabList>
                            </Box>
                          </Grid>
                          <Grid item overflow={"hidden"} flexGrow={1}>
                            {CHAT_TABS.map((chat) => (
                              <TabPanel
                                key={chat.id}
                                value={chat.id}
                                sx={{ p: 0, height: "100%" }}
                              >
                                <ChatBody userId={user.id} />
                              </TabPanel>
                            ))}
                          </Grid>
                        </Grid>
                      </TabContext>
                    ) : (
                      <ChatBody userId={user.id} />
                    )}
                  </Grid>

                  {/* component call for chat input box  */}
                  <Grid item flexGrow={0}>
                    <CustomMessageInput chatTabValue={chatTabValue} channel={channel} formId={chatData.formId} />
                  </Grid>
                </Grid>
              </Window>
            </Box>
            <Thread />
          </Channel>
        </Chat>
      </Drawer>
    </>
  );
}
