import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, FormHelperText, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks } from '../../../../../helpers/modal/modal'
import CustomReactMaterialTable from '../../../../../helpers/table/react-material-table'
import { postApiCallDynamicWithOutReturn } from '../../../../../services/api-service'
import { formState } from '../../../../../recoil/profile-atom'
import { useRecoilState } from 'recoil'
import { UPDATE } from '../../../../../constants/constatnt-variable'
import { API } from '../../../../../services/api-config'
import ConfirmationModel from '../../../../../helpers/modal/confirm-modal'
import { KSPencilAltIcon, KSTrashIcon } from '../../../../../icons/custome-icon'
import { AssetCreditForm } from './assets-credit-form'

export default function LoanAssetsCredits() {

    const route = useNavigate()
    const location = useLocation();

    let [formValue, setFormValue] = useRecoilState(formState)

    let [assestCreditList, setAssestCreditList] = useState(() =>
        formValue?.assetsCredits?.page == null ? [] : _.cloneDeep(formValue?.assetsCredits?.page)
    )



    let [customError, setCustomerror] = useState("")

    const [editState, setEditState] = useState(() => {
        return null
    })


    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(assestCreditList)
            let editItem = list.find((item, i) => i == editState)
            setAssestCreditEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])


    const [deleteState, setDeleteState] = useState(() => {
        return null
    })


    // delete table
    useEffect(() => {
        if (deleteState != null) {
            assestCreditList.splice(deleteState, 1)
            setAssestCreditList([...assestCreditList])
            setDeleteState(null)
        }

    }, [deleteState])



    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "accountType",
                header: "Account type",
                enableColumnActions: false,
            },
            {
                accessorKey: "accountNo",
                header: "Account number",
                enableColumnActions: false,
            },
            {
                accessorKey: "ownedBy",
                header: "Asset owned by",
                enableColumnActions: false,
            },
        ],
        []
    );

    const [modalOpen, setModalOpen] = useState(false);
    const [assestCreditEdit, setAssestCreditEdit] = useState();
    const handleClickOpen = () => {

        setAssestCreditEdit([])
        setModalOpen(true);
    };



    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)

    };



    const handleCloseDelete = (event, row) => {

        if (event.target.value == 'yes') {
            setDeleteState(row.index)

        }
    }

    const assetsSubmit = (data) => {
        setCustomerror("")
        if (editState != null) {
            assestCreditList[editState] = {
                ...assestCreditList[editState],
                ...data
            }

            setEditState(null)
        } else {
            assestCreditList.push(data);
        }

        setAssestCreditList([...assestCreditList]);
        handleClose()
    }
    useEffect(() => {
        setAssestCreditList([...assestCreditList]);
    }, [assestCreditList.length])


    const handleAssestCreditFormSubmit = async () => {
        console.log("assestCreditList", assestCreditList);
        if (assestCreditList.length == 0) {
            setCustomerror("Please enter atleast one assets")
        } else {
            setCustomerror("")
            const apiCallData = {
                page: assestCreditList,
                formId: formValue.id,
                isComplete: true
            }
            const updateState = () => {
                setFormValue({
                    ...formValue,
                    assetsCredits: {
                        ...formValue.assetsCredits,
                        page: assestCreditList,
                        isComplete: true
                    }
                })
                route(location.pathname.replace(/[^/]*$/, 'real-estate'));
            }

            await postApiCallDynamicWithOutReturn(
                {
                    data: apiCallData,
                    path: API.assetCredit,
                    header: UPDATE,
                    refresh: updateState
                }
            )
        }
    }






    return (
        <>

            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box >
                    <Typography variant='h3' mb={2} >{`Mention all the financial asssts you hold`}</Typography>
                    <Typography variant='h4' mb={2} >{`Bank Accounts, Retirement, and Other Accounts`}</Typography>

                    <CustomReactMaterialTable
                        staticLoad={true}
                        staticData={assestCreditList}
                        columns={tableColumn}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableSorting={false}
                        enableToolbarInternalActions={false}
                        renderTopToolbarCustomActions={
                            () => (
                                <Button variant='outlined' onClick={handleClickOpen}>Add</Button>
                            )
                        }
                        enableRowActions={true}
                        renderRowActions={
                            ({ row }) => {

                                return (
                                    <Stack direction={'row'}>
                                        <IconButton onClick={() => { setEditState(row.index) }}>
                                            <KSPencilAltIcon />
                                        </IconButton>


                                        <ConfirmationModel
                                            body={<IconButton
                                                id={row.index} onClick={() => {
                                                    // setDeleteState(row.index)
                                                }}>
                                                <KSTrashIcon />
                                            </IconButton>}
                                            message={`Are you sure want remove  ${row?.original?.institutionName} - Savings?`}
                                            title={'Delete Additional Address'}
                                            handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                        />

                                    </Stack>
                                )
                            }
                        }
                    />
                    {customError && <FormHelperText error>{customError}</FormHelperText>}
                </Box>



                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Link to={'../borrower-info/personal-information'}>
                            <Button startIcon={<ArrowBackIos />}>
                                Previous
                            </Button>
                        </Link>
                        <Button onClick={handleAssestCreditFormSubmit} endIcon={<ArrowForwardIos />} >
                            Next
                        </Button>
                    </Stack>
                </Box>
            </Stack>

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Financial assets`}
                body={
                    <AssetCreditForm
                        onSubmit={assetsSubmit}
                        editItem={assestCreditEdit}
                        hasPartner={formValue?.hasPartner}
                    />
                }
            />


        </>
    )
}
