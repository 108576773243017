
// import {
//     FormControl,
//     InputLabel,
//     Select,
//     MenuItem,
//     Checkbox,
//     ListItemText,
//     OutlinedInput,
// } from '@mui/material';
// import React from 'react';

// const MultiSelectDropDown = ({ field, options, errors, label }) => {
//     return (
//         <FormControl sx={{ m: 1, width: 300 }} error={!!errors}>
//             <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
//             <Select
//                 {...field}
//                 multiple
//                 input={<OutlinedInput label={label} />}
//                 renderValue={(selected) => selected.join(', ')}
//                 MenuProps={{
//                     PaperProps: {
//                         style: {
//                             maxHeight: 224,
//                             width: 250,
//                         },
//                     },
//                 }}
//                 onChange={(event) => {
//                     // Make sure to handle `event.target.value` properly
//                     const value = event.target.value;
//                     field.onChange(value);
//                     //console.log(value);
//                 }}
//                 value={field.value || []} // Ensure default value is an empty array
//             >
//                 {options.map((option) => (
//                     <MenuItem key={option} value={option}>
//                         <Checkbox checked={field.value ? field.value.indexOf(option) > -1 : false} />
//                         <ListItemText primary={option} />
//                     </MenuItem>
//                 ))}
//             </Select>
//         </FormControl>
//     );
// };

// export default MultiSelectDropDown;
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
} from '@mui/material';
import React from 'react';

const MultiSelectDropDown = ({ field, options, errors, label }) => {
    return (
        <FormControl sx={{ m: 1, width: 300 }} error={!!errors}>
            <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
            <Select
                {...field}
                multiple
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 224,
                            width: 250,
                        },
                    },
                }}
                onChange={(event) => {
                    const value = event.target.value;
                    field.onChange(value);
                    // Log the selected values
                    console.log("Selected values:", value);
                }}
                value={field.value || []} // Ensure default value is an empty array
            >
                {options.map((option) => (
                    <MenuItem key={option} value={option}>
                        <Checkbox checked={field.value ? field.value.indexOf(option) > -1 : false} />
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultiSelectDropDown;
