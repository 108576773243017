import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getApiCallDynamic, postApiCallDynamicWithOutReturn } from "../../../../../services/api-service";
import { API } from "../../../../../services/api-config";
import CoBorrowerStatus from "./co-borrower";
import PartnerCoBorrowerSet from "./partner-co-borrower-set";
import { useRecoilState } from "recoil";
import { formState, profileState } from "../../../../../recoil/profile-atom";
import Partner from "./partner";
import { UPDATE } from "../../../../../constants/constatnt-variable";
import { DialogCommonHooks } from "../../../../../helpers/modal/modal";
import { ThatsGreat } from "./thats-great";

export default function GettingStarted() {
  let [formValue, setFormValue] = useRecoilState(formState);
  let primaryBorrower = {
    firstName: formValue?.getStarted?.page?.primaryBorrower?.firstName,
    lastName: `${formValue?.getStarted?.page?.primaryBorrower?.lastName} (Primary Borrower)`,
    phone: formValue?.getStarted?.page?.primaryBorrower?.phone,
    email: formValue?.getStarted?.page?.primaryBorrower?.email,
  };

  const { getStarted, id } = formValue;
  const [partner, setPartner] = useState(formValue?.hasPartner);

  const route = useNavigate();
  const location = useLocation();

  const parnerOnSubmit = async (data) => {
    setFormValue({
      ...formValue,
      getStarted: {
        ...formValue.getStarted,
        page: {
          ...formValue.getStarted.page,
          partner: data,
          // primaryBorrower: primaryBorrower,
          hasPartner: data?.loanPartnerStatus,
        },
      },
      // hasPartner: data?.loanPartnerStatus == "Yes" ? true : false
    });
    setPage("co-borrower");
    setPartner(data?.loanPartnerStatus == "Yes");
  };

  const coborrowerOnSubmit = (data) => {
    if (data.length != 0) {
      setFormValue({
        ...formValue,
        getStarted: {
          ...formValue.getStarted,
          page: {
            ...formValue.getStarted.page,
            // primaryBorrower: primaryBorrower,
            coBorrower: data,
          },
        },
      });
      //
      setPage("merge");
    } else {
      handleSubmit({ coBorr: data });
    }
  };

  const handleSubmit = ({ coBorr, coBorrInv, coBorrAdd }) => {
    if (coBorrAdd == "no") {
      coBorr = []
    }
    const apiCallData = {
      page: {
        partner:
          getStarted?.page?.partner?.email == undefined
            ? null
            : getStarted?.page?.partner,
        coBorrower: coBorr?.length == 0 ? [] : getStarted?.page?.coBorrower,
        primaryBorrower: primaryBorrower,
        hasPartner: getStarted?.page?.hasPartner,
        // co borrower invite
        shouldMail: coBorrInv == "co-bor" ? true : false
      },
      formId: id,
      isComplete: true,
    };

    const updateState = () => {
      setFormValue({
        ...formValue,
        getStarted: {
          ...formValue.getStarted,
          page: apiCallData.page,
          // {
          //     ...formValue.getStarted.page,
          //     coBorrower: getStarted?.page?.coBorrower,
          //     partner: getStarted?.page?.partner,
          //     hasPartner: getStarted?.page?.hasPartner,
          // },
          isComplete: true,
        },
        hasPartner: partner,
      });
      route(location.pathname.replace(/[^/]*$/, "loan-details"));
    };

    postApiCallDynamicWithOutReturn({
      data: apiCallData,
      path: API.getStarted,
      header: UPDATE,
      refresh: updateState,
    });
    // route(location.pathname.replace(/[^/]*$/, 'loan-details'));
  };



  const [page, setPage] = useState("partner");

  // partner, coborwo, merge
  const GenerateHTML = () => {
    if (page === "partner") {
      return (
        <Partner
          setPage={setPage}
          data={getStarted?.page}
          onSubmit={parnerOnSubmit}
        />
      );
    } else if (page === "co-borrower") {
      return (
        <CoBorrowerStatus
          setPage={setPage}
          onSubmit={coborrowerOnSubmit}
          data={getStarted?.page?.coBorrower}
        />
      );
    } else if (page === "merge") {
      return (
        <PartnerCoBorrowerSet
          setPage={setPage}
          handleSubmit={handleSubmit}
          data={getStarted?.page?.coBorrower}
          partnerData={getStarted?.page?.partner}
          hasPartner={getStarted?.page?.hasPartner}
          primaryBorrower={primaryBorrower}
        />
      );
    }
  };

  const [modalOpen, setModalOpen] = useState(!getStarted?.isComplete);
  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <GenerateHTML />
      <DialogCommonHooks
        show={modalOpen}
        handleClose={handleClose}
        body={<ThatsGreat handleClose={handleClose} />}
      />
    </>
  );
}
