import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Grid,
  Stack,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LoanSummaryDetailsLayout from "./loan-summary-details/loan-summary-details-layout";
import LoanSummaryLoanStatistics from "./loan-summary-statistices/loan-summary-loan-statistics";
import LoanSummaryTaskBody from "./loan-summary-tasks/loan-summary-task-body";
import { getApiCallDynamic } from "../../../services/api-service";
import { API } from "../../../services/api-config";
import { useParams } from "react-router-dom";
import MainLoyOut from "../../main-layout";
import LoanSummaryMiles from "./loan-summary-miles/loan-summary-miles";
import { InfoOutlined } from "@mui/icons-material";
import { LoanSummaryParentData } from "./loan-summary-visiblity";

import ThirdPartyTaskBody from "./loan-summary-tasks/third-part-task-body";
import BorrowerTaskBody from "./loan-summary-tasks/borrower-task-body";

export default function LoanSummaryLayout() {
  // Variables and functions
  const { id } = useParams();
  const { visiblity } = useContext(LoanSummaryParentData);
  // Use State Start
  // Data Set Start
  const [mainAPIData, setMainAPIData] = useState({});
  const [borrowerListsData, setBorrowerListsData] = useState([]);
  const [changeMloMlpData, setChangeMloMlpData] = useState([]);
  // Data Set End

  const [borrowerFormID, setborrowerFormID] = useState(null);
  const [borrowerTabValue, setBorrowerTabValue] = useState(null);

  const [typeMloMlp, setTypeMloMlp] = useState(null);

  const [taskTabCount, setTaskTabCount] = useState([]);
  const [taskTabValue, setTaskTabValue] = useState(null);
  const [tasksLists, setTasksLists] = useState({});
  // Use State End

  // Main API Start
  // API
  const mainAPICall = (status) => {
    getApiCallDynamic({
      path: API.loanSummaryMainAPI,
      param: { loanCode: id },
    }).then((res) => {
      if (res?.status === 200) {
        setMainAPIData(res?.data);
        setBorrowerListsData(res?.data?.formInfos);
        setborrowerFormID(res?.data?.formInfos[0].formId);
        setBorrowerTabValue(res?.data?.formInfos[0].formId);
      }
    });
  };

  // Reloader
  const mainAPIReloader = () => {
    mainAPICall();
  };
  // Main API End

  // Borrower tab function Start
  const borrowerTabHandleChange = (event, newValue) => {
    setBorrowerTabValue(newValue);
    setborrowerFormID(newValue);
  };

  // Conditions Start
  let borrowerTypeShort = (e) => {
    switch (e) {
      case "Primary":
        return "(PB)";

      case "Co-borrower":
        return "(CB)";

      default:
        return "";
    }
  };
  // Conditions End
  // Borrower tab function End

  // Task Related Code Start
  // Tab value
  const taskTabHandleChange = (event, newValue) => {
    setTaskTabValue(newValue);
  };

  // API Start
  // Task Start
  const taskTabCounter = (formId) => {
    getApiCallDynamic({
      path: API.taskTabCount,
      param: { formId: formId },
    }).then((res) => {
      if (res?.status === 200) {
        setTaskTabCount(res?.data);
        setTaskTabValue(res?.data[0]?.name);
      }
    });
  };

  // Tasks Lists Body
  const taskListsData = (formId, status) => {
    getApiCallDynamic({
      path: API.taskLists,
      param: { formId: formId, status: status },
    }).then((res) => {
      if (res?.status === 200) {
        setTasksLists(res?.data);
      }
    });
  };
  // Task End

  const taskReload = () => {
    taskTabCounter(borrowerFormID);
    taskListsData(borrowerFormID, taskTabValue);
  };
  // Task Related Code End

  // MLO MLP API
  const changeMloMlpAPI = (userType) => {
    getApiCallDynamic({
      path: API.loanChangeMloMlp,
      param: { loanCode: id, type: userType },
    }).then((res) => {
      if (res?.status === 200) {
        setChangeMloMlpData(res?.data);
      }
    });
  };

  const changeUserType = (value) => {
    setTypeMloMlp(value);
  };

  // API End

  // Use Effect Start
  // 1st load
  useEffect(() => {
    mainAPICall();
  }, []);

  // Borrower Tab
  useEffect(() => {
    if (borrowerTabValue !== null) {
      taskListsData(borrowerFormID, taskTabValue);
      taskTabCounter(borrowerTabValue);
    }
  }, [borrowerTabValue]);

  // Task
  useEffect(() => {
    if (taskTabValue !== null) {
      taskListsData(borrowerFormID, taskTabValue);
    }
  }, [taskTabValue]);

  // Change MLO MLP
  useEffect(() => {
    if (typeMloMlp !== null) {
      changeMloMlpAPI(typeMloMlp);
    }
  }, [typeMloMlp]);
  // Use Effect End

  // State to manage the currently selected tab
  const [selectedTab, setSelectedTab] = useState(0);

  // Function to handle tab changes
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  //const [selectedTab, setSelectedTab] = React.useState('1');

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };
  return (
    <MainLoyOut>
      {/* Main container and box for padding on this page */}
      {/* Tab of loan users start */}
      <TabContext value={borrowerTabValue}>
        {/* Tab navigation */}
        <Box bgcolor={"white"} sx={{ borderBottom: 1, borderColor: "divider", display: visiblity ? 'block' : 'none' }}>
          <TabList
            onChange={borrowerTabHandleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            aria-label="Loan Summary Task Tab"
          >
            {borrowerListsData
              // Checking Borrower Type
              .sort((a) => (a.borrowerType == "Primary" ? -1 : 1))
              .map((borrowerData) => (
                <Tab
                  key={borrowerData.formId}
                  label={
                    borrowerData.firstName +
                    " " +
                    borrowerData.lastName +
                    " " +
                    borrowerTypeShort(borrowerData.borrowerType)
                  }
                  value={borrowerData.formId}
                />
              ))}

            <Box ml={"auto"} my={"auto"} px={2}>
              <Tooltip
                arrow
                placement="left"
                color="A8"
                title={
                  <>
                    <Typography
                      component={"span"}
                      variant="body2"
                      color={"primary.main"}
                    >
                      {`Note : `}
                    </Typography>
                    <Typography component={"span"} variant="body2">
                      {`Here we have use few abbreviation`}
                    </Typography>
                    <Typography component={"span"} variant="body2">
                      {`ie. PB (Primary Borrower) | CB (Co-Borrower here)`}
                    </Typography>
                  </>
                }
              >
                <InfoOutlined />
              </Tooltip>
            </Box>
          </TabList>
        </Box>
        {/* Tab Content */}
        {borrowerListsData.map((borrowerData) => (
          <TabPanel
            key={borrowerData.formId}
            value={borrowerData.formId}
            sx={{ marginTop: 3, padding: 0 }}
          >
            <LoanSummaryDetailsLayout
              loanState={mainAPIData.loanState}
              loanStateDate={mainAPIData.loanStateDate}
              lockStatus={mainAPIData.loanSummary.lockStatus}
              lockExpirationDate={mainAPIData.loanSummary.lockExpirationDate}
              borrowerData={borrowerData}
              mloData={mainAPIData.mloInfo}
              mlpData={mainAPIData.mlpInfo}
              userType={changeUserType}
              mloMlpList={changeMloMlpData}
              loanCode={id}
              reload={mainAPIReloader}
            />

            <Box>
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12} md={5}>
                  <Stack direction={"column"} gap={2}>
                    <LoanSummaryLoanStatistics
                      loanSummaryLoanStatisticsData={mainAPIData.loanSummary}
                      statisticsReloader={mainAPIReloader}
                    />
                    <LoanSummaryMiles
                      loanCode={id}
                      loanState={mainAPIData.loanState}
                      milesData={mainAPIData.miles}
                      milesReloader={mainAPIReloader}
                    />
                  </Stack>
                </Grid>
                {/* <Grid item xs={12} md={7}>
                  <LoanSummaryTaskBody
                    formId={borrowerFormID}
                    borrowerName={
                      borrowerData.firstName + " " + borrowerData.lastName
                    }
                    taskTabCount={taskTabCount}
                    tasksLists={tasksLists}
                    taskReload={taskReload}
                    taskTabValue={taskTabValue}
                    taskTabHandleChange={taskTabHandleChange}
                  />
                </Grid> */}
                <Grid item xs={12} md={7}>
                  <TabContext value={selectedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleTabChange} aria-label="custom tabs example">
                        <Tab label="Borrower Tasks" value="1" />
                        <Tab label="Third Party Tasks" value="2" />

                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <BorrowerTaskBody
                        formId={borrowerFormID}
                        borrowerName={
                          borrowerData.firstName + " " + borrowerData.lastName
                        }
                        taskTabCount={taskTabCount}
                        tasksLists={tasksLists}
                        taskReload={taskReload}
                        taskTabValue={taskTabValue}
                        taskTabHandleChange={taskTabHandleChange}
                      /></TabPanel>
                    <TabPanel value="2">
                      <ThirdPartyTaskBody
                        formId={borrowerFormID}
                        borrowerName={
                          borrowerData.firstName + " " + borrowerData.lastName
                        }
                        taskTabCount={taskTabCount}
                        tasksLists={tasksLists}
                        taskReload={taskReload}
                        taskTabValue={taskTabValue}
                        taskTabHandleChange={taskTabHandleChange}
                      /></TabPanel>

                  </TabContext>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        ))}
      </TabContext>

      {/* Tab of loan users end */}
    </MainLoyOut>
  );
}
