import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomRadioChip from '../../../../../helpers/radio-chip'
import CustomInput from '../../../../../helpers/custom-input'
import CustomDropDown from '../../../../../helpers/dropdown/custom-dropdown'
import { AttachMoney } from '@mui/icons-material'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { LOAN_CREDIT_TYPE, LOAN_CREDIT_TYPE_MORTGAGE, LOAN_CREDIT_TYPE_MORTGAGE_OPTION, LOAN_CREDIT_TYPE_NON_MORTGAGE, LOAN_CREDIT_TYPE_NON_MORTGAGE_OPTION, LOAN_CREDIT_TYPE_EXPENSE, LOAN_CREDIT_TYPE_EXPENSE_OPTION, LOAN_OWNED_BY } from '../../../../../constants/constatnt-variable'
import SingleSelectDynamicValueDropdown from '../../../../../helpers/dropdown/single-select-dropdown'
import { KSPlusCircleIcon } from '../../../../../icons/custome-icon'
import { useLocation, useNavigate } from 'react-router-dom'
import { LiabilitiesValidation } from '../../../../../constants/validation-rules'

export default function LiabilitiesForm({ realEstate, onSubmit, editItem, handleClose, hasPartner, value }) {

    let propertyAddressOptionList = [];
    realEstate.map((item) => { propertyAddressOptionList.push({ "label": item?.addressLine1, "value": item?.addressLine1 }) })


    const location = useLocation();
    const route = useNavigate();



    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            creditType: editItem?.creditType ? editItem?.creditType : value,
            companyName: editItem?.companyName,
            propertyAddress: editItem?.propertyAddress,
            accountNo: editItem?.accountNo,
            unpaidBalance: editItem?.unpaidBalance,
            monthlyPay: editItem?.monthlyPay,
            monthsLeft: editItem?.monthsLeft,
            liabilityType: editItem?.liabilityType,
            ownedBy: editItem?.ownedBy,

        }
    })


    const { creditType } = useWatch({ control })

    const [loanTerm, setLoanTerm] = useState([]);

    const [liabilityType, setLiabilityType] = useState([]);

    useEffect(() => {
        if (creditType == LOAN_CREDIT_TYPE_MORTGAGE) {
            setLiabilityType(LOAN_CREDIT_TYPE_MORTGAGE_OPTION)
        }
        if (creditType == LOAN_CREDIT_TYPE_NON_MORTGAGE) {
            setLiabilityType(LOAN_CREDIT_TYPE_NON_MORTGAGE_OPTION)
        }
        if (creditType == LOAN_CREDIT_TYPE_EXPENSE) {
            setLiabilityType(LOAN_CREDIT_TYPE_EXPENSE_OPTION)
        }
    }, [creditType])



    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box width={'100%'} my={2} >
                <Box>
                    <Controller
                        name='creditType'
                        control={control}
                        rules={LiabilitiesValidation.creditType}
                        render={({ field }) => (
                            <CustomRadioChip
                                {...field}
                                field={field}
                                required
                                label={'Credit Type'}
                                data={LOAN_CREDIT_TYPE}
                                error={errors?.creditType?.message}
                            />
                        )}
                    />
                    {creditType !== LOAN_CREDIT_TYPE_EXPENSE ?
                        <Controller
                            name='companyName'
                            control={control}
                            rules={LiabilitiesValidation.companyName}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    type={'text'}
                                    label={'Company Name'}
                                    required
                                    error={errors?.companyName?.message}
                                />

                            )}
                        /> : null}
                    {creditType == LOAN_CREDIT_TYPE_MORTGAGE ?
                        <Controller
                            name='propertyAddress'
                            control={control}
                            rules={LiabilitiesValidation.propertyAddress}
                            render={({ field }) => (
                                <>

                                    <CustomDropDown
                                        {...field}
                                        field={field}
                                        label={'Property'}
                                        labelRightComponent={
                                            <Stack onClick={() => route(location.pathname.replace(/[^/]*$/, 'real-estate'))} direction={'row'} gap={1} color={'primary.main'}>
                                                <KSPlusCircleIcon />
                                                <Typography>{`Add New Property`}</Typography>
                                            </Stack>
                                        }
                                        required
                                        option={propertyAddressOptionList}
                                        error={errors?.propertyAddress?.message}
                                    />
                                </>
                            )}
                        /> : null}
                    {creditType !== LOAN_CREDIT_TYPE_EXPENSE ?
                        <>
                            <Controller
                                name='accountNo'
                                control={control}
                                rules={LiabilitiesValidation.accountNo}
                                render={({ field }) => (
                                    <CustomInput
                                        {...field}
                                        type={'number'}
                                        label={'Account Number'}
                                        required
                                        error={errors?.accountNo?.message}
                                    />

                                )}
                            />
                            <Controller
                                name='unpaidBalance'
                                control={control}
                                rules={LiabilitiesValidation.unpaidBalance}
                                render={({ field }) => (
                                    <CustomInput
                                        {...field}
                                        type={'number'}
                                        label={'Unpaid Balance'}
                                        required
                                        sicon={<AttachMoney />}
                                        error={errors?.unpaidBalance?.message}
                                    />

                                )}
                            />
                        </> : null}
                    <Controller
                        name='monthlyPay'
                        control={control}
                        rules={LiabilitiesValidation.monthlyPay}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                type={'number'}
                                label={'Monthly Payment'}
                                required
                                sicon={<AttachMoney />}
                                error={errors?.monthlyPay?.message}
                            />

                        )}
                    />
                    <Controller
                        name='monthsLeft'
                        control={control}
                        rules={LiabilitiesValidation.monthsLeft}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                type={'number'}
                                label={'Months Left to pay'}
                                required
                                error={errors?.monthsLeft?.message}
                            />

                        )}
                    />
                    <Controller
                        name='liabilityType'
                        control={control}
                        render={({ field }) => (
                            <SingleSelectDynamicValueDropdown
                                label={"Liability Type"}
                                {...field}
                                field={field}
                                options={liabilityType}
                                value={loanTerm}
                                setValue={setLoanTerm}
                            />
                        )}
                    />
                    {hasPartner ?
                        <Controller
                            name='ownedBy'
                            control={control}
                            render={({ field }) => (
                                <>
                                    <CustomDropDown
                                        {...field}
                                        field={field}
                                        label={'Liability Owned By'}
                                        option={LOAN_OWNED_BY}
                                    />
                                </>
                            )}
                        /> : null}
                </Box>

                <Stack direction={'row-reverse'} gap={2} >
                    <Button type='submit' variant='contained' >{editItem.length == 0 ? 'Add liability' : 'Update'} </Button>
                    <Button variant='text' onClick={handleClose}>{`Cancel`}</Button>
                </Stack>
            </Box>
        </form>
    )
}
