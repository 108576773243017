import React, { useEffect, useState } from "react";

import { API } from "../../../../services/api-config";
import { getApiCallDynamic } from "../../../../services/api-service";
import { useLocation, useNavigate } from "react-router-dom";
import QuoteForm from "./quote-form";
import { useRecoilState } from "recoil";
import { globalSpinner } from "../../../../recoil/profile-atom";


export default function Quote() {

    const route = useNavigate();
    const location = useLocation();
    const leadId = location.pathname.split("/")[location.pathname.split("/").length - 1];


    let [leadData, setleadData] = useState({});
    let [, setSpinner] = useRecoilState(globalSpinner);

    const getLeadData = () => {
        setSpinner(true)
        getApiCallDynamic({ path: API.lead, param: { leadId: leadId } }).then((res) => {
            setSpinner(false)
            if (res?.status === 200) {
                setleadData(res.data)
            } else {
                route("/broker/leads")
            }
        })

    }

    useEffect(() => {
        if (leadId) {
            getLeadData()
        }

    }, [leadId])

    if (_.isEmpty(leadData)) {
        return <></>
    }


    return (
        <QuoteForm leadData={leadData} />
    );
}
