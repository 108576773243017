import { Box, Typography } from '@mui/material'
import React from 'react'
import CustomCheckboxFileUpload from '../../../../../helpers/custom-checkbox-file-upload'
import DocumentStatusCard from './document-status-card'
import { TimeStampToMonthDateYear } from '../../../../../helpers/time-formater';

export default function UploadDocumentCard({ data, control, fileUpload, isReview = false }) {

    return (
        <>

            {/* Document Upload Start */}
            <Box my={3}>

                {/* Before Upload Cards  Start*/}
                <>
                    {data.uploadDate ?
                        <DocumentStatusCard
                            key={data.documentName}
                            control={control}
                            title={data.documentName}
                            fileName={data.fileName}
                            info={data.info}
                            docIc={data.id}
                            uploadTime={TimeStampToMonthDateYear(data.uploadDate)}
                            status={data.matchStatus}
                            fileUpload={fileUpload}
                            isReview={isReview}
                        />
                        :

                        <CustomCheckboxFileUpload
                            id={data.id}
                            key={data.documentName}
                            control={control}
                            title={data.documentName}
                            docIc={data.id}
                            checkData={data.isSkipped}
                            fileUpload={fileUpload}
                            isReview={isReview}
                            isSkiped={true}
                        />

                    }
                </>

                {/* Before Upload Cards End*/}
            </Box>
        </>
    )
}
