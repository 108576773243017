import React, { useState } from "react";
import useInput from "../../../helpers/use-input"
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, IconButton, } from "@mui/material";
import CustomInput from "../../../helpers/custom-input";
import { useFormik } from "formik";
import { useRecoilState, useRecoilValue } from "recoil";
import { globalSpinner, profileState } from "../../../recoil/profile-atom";
import { CREDCO_INFO, ROLE_LIST, UPDATE } from "../../../constants/constatnt-variable";
import { postApiCallDynamic } from "../../../services/api-service";
import { API } from "../../../services/api-config";
import RadioButtonGroup from "../../../helpers/radio-button-group";

export default function CredcoCredentialsForm({ data, setCredcoEditSection, setData }) {

    const [showPassword, setShowPassword] = useState(false);
    let profileInfo = useRecoilValue(profileState);
    let [spinner, setSpinner] = useRecoilState(globalSpinner);

    const [credcoInfo] = useInput({
        data: null,
        status: false,
        initial: null

    })

    const [internalAccountIdentifier] = useInput({
        data: data?.internalAccountIdentifier,
        status: true,
        initial: ""

    })
    const [requestedByName] = useInput({
        data: data?.requestedByName,
        status: true,
        initial: ""

    })
    const [requestingPartyBranchIdentifier] = useInput({
        data: data?.requestingPartyBranchIdentifier,
        status: true,
        initial: ""

    })
    const [loginAccountIdentifier] = useInput({
        data: data?.loginAccountIdentifier,
        status: true,
        initial: ""

    })
    const [loginAccountPassword] = useInput({
        data: data?.loginAccountPassword,
        status: true,
        initial: ""

    })


    const handleValidation = (values) => {
        let errors = {};
        if (internalAccountIdentifier.value === "" || internalAccountIdentifier.value === null) {
            errors.internalAccountIdentifier = "Enter Internal Account Identifier to proceed";
        }
        if (requestedByName.value === "" || requestedByName.value === null) {
            errors.requestedByName = "Enter Requested By Name to proceed";
        }

        if (requestingPartyBranchIdentifier.value === "" || requestingPartyBranchIdentifier.value === null) {
            errors.requestingPartyBranchIdentifier = "Enter Requesting Party Branch Identifier to proceed";
        }
        if (loginAccountIdentifier.value === "" || loginAccountIdentifier.value === null) {
            errors.loginAccountIdentifier = "Enter Login Account Identifier to proceed";
        }

        if (loginAccountPassword.value === "" || loginAccountPassword.value === null) {
            errors.loginAccountPassword = "Enter Login Account Password to proceed";
        }

        return errors;
    };


    const formik = useFormik({
        initialValues: {
            internalAccountIdentifier: internalAccountIdentifier.value,
            requestedByName: requestedByName.value,
            requestingPartyBranchIdentifier: requestingPartyBranchIdentifier.value,
            loginAccountIdentifier: loginAccountIdentifier.value,
            loginAccountPassword: loginAccountPassword.value,
        },
        validate: (values) => handleValidation(values),
        onSubmit: (values) => {
            handleSubmit();
        },
    });


    const updateUi = (res) => {
        if (res.status == 200 || res.status == 201) {
            setCredcoEditSection(false)
            setData(res.data)
            setSpinner(false)
        }

    }
    const handleSubmit = () => {
        setSpinner(true)

        let apiCallData = {
            internalAccountIdentifier: internalAccountIdentifier.value,
            requestedByName: requestedByName.value,
            requestingPartyBranchIdentifier: requestingPartyBranchIdentifier.value,
            loginAccountIdentifier: loginAccountIdentifier.value,
            loginAccountPassword: loginAccountPassword.value,
        }

        if (data?.internalAccountIdentifier) {
            postApiCallDynamic({
                refresh: (res) => {
                    return res;
                }, data: apiCallData, path: API.credcoCredentials, header: UPDATE
            }).then((res) => updateUi(res))
        } else {
            postApiCallDynamic({
                refresh: (res) => {
                    return res;
                }, data: apiCallData, path: API.credcoCredentials
            }).then((res) => updateUi(res))

        }



    }
    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                {profileInfo?.role == ROLE_LIST.mlo || profileInfo?.role == ROLE_LIST.mloOwner ?
                    <RadioButtonGroup data={CREDCO_INFO} state={credcoInfo} defaultValue={"use broker info"} direction={"row"} />
                    : null}
                <CustomInput
                    required
                    boxProps={{ my: 2 }}
                    size="small"
                    fullWidth
                    label={"Internal Account Identifier"}

                    placeholder={"122"}
                    change={internalAccountIdentifier}
                    error={formik.errors.internalAccountIdentifier}
                />
                <CustomInput
                    required
                    boxProps={{ my: 2 }}
                    size="small"
                    fullWidth
                    label={"Requested By Name"}
                    placeholder={"Thomos Jone"}
                    change={requestedByName}
                    error={formik.errors.requestedByName}
                />
                <CustomInput
                    required
                    boxProps={{ my: 2 }}
                    size="small"
                    fullWidth
                    label={"Requesting Party Branch Identifier"}

                    placeholder={"34343"}
                    change={requestingPartyBranchIdentifier}
                    error={formik.errors.requestingPartyBranchIdentifier}
                />
                <CustomInput
                    required
                    boxProps={{ my: 2 }}
                    size="small"
                    fullWidth
                    label={"Login Account Identifier"}
                    placeholder={"4004107"}
                    change={loginAccountIdentifier}
                    error={formik.errors.loginAccountIdentifier}
                />
                <CustomInput
                    required
                    boxProps={{ my: 2 }}
                    size="small"
                    fullWidth
                    label={"Login Account Password"}

                    placeholder={"*******"}
                    type={showPassword ? "text" : "password"}
                    eicon={
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}

                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    }
                    change={loginAccountPassword}
                    error={formik.errors.loginAccountPassword}
                />
            </Box>
            <Box>
                <Button type="submit" variant="contained" size="large" fullWidth>
                    Submit
                </Button>
            </Box>
        </form>
    );
}
