import { Button, Grid, Stack } from "@mui/material";
import React, { useContext } from "react";
import LoanSummaryBorrowerInformation from "./loan-summary-borrower-profile";
import LoanSummaryMloMlpProfile from "./loan-summary-mlo-mlp-profile";
import { API } from "../../../../services/api-config";
import { downloadReportFromServer, getImageUrl } from "../../../../helpers/file-upload-download";
import { LoanSummaryParentData } from "../loan-summary-visiblity";

export default function LoanSummaryDetailsLayout({
  loanState,
  loanStateDate,
  lockStatus,
  lockExpirationDate,
  borrowerData,
  mloData,
  mlpData,
  userType,
  mloMlpList,
  loanCode,
  reload,
}) {
  const { visiblity } = useContext(LoanSummaryParentData);

  // MISMO File download API
  const handleMismoExport = () => {
    downloadReportFromServer(API.mismoExport, {
      loanCode: loanCode,
    });
    // window.open(process.env.REACT_APP_BASE_URL + API.mismoExport + "?loanCode=" + loanCode, "_blank")
  };

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        bgcolor={"white"}
        mb={3}
        p={2}
        borderRadius={2}
      >
        {/* Borrower information */}
        <Grid item xs={12} md={8}>
          <LoanSummaryBorrowerInformation
            loanState={loanState}
            loanStateDate={loanStateDate}
            lockStatus={lockStatus}
            lockExpirationDate={lockExpirationDate}
            borrowerData={borrowerData}
          />
        </Grid>

        {/* MLO/MLP information */}
        {visiblity ? (
          <Grid item xs={12} md={4}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              gap={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <LoanSummaryMloMlpProfile
                type="MLO"
                data={mloData}
                avatar={getImageUrl(mloData?.avatarDocId, mloData?.avatarDocFileName, "avatar")}
                userType={userType}
                mloMlpList={mloMlpList}
                loanCode={loanCode}
                reload={reload}
              />
              <LoanSummaryMloMlpProfile
                type="MLP"
                data={mlpData}
                avatar={getImageUrl(mlpData?.avatarDocId, mlpData?.avatarDocFileName, "avatar")}
                userType={userType}
                mloMlpList={mloMlpList}
                loanCode={loanCode}
                reload={reload}
              />
            </Stack>
          </Grid>
        ) : null}

        {/* Loan files and document infromation */}
        {visiblity ? (
          <Grid item xs={12}>
            <Stack
              my={2}
              gap={2}
              overflow={"hidden"}
              justifyContent={"center"}
              alignItems={"center"}
              direction={{ xs: "column", md: "row" }}
            >
              <Button
                variant="outlined"
                sx={{ minWidth: "auto" }}
                onClick={handleMismoExport}
              >{`Export MISMO File`}</Button>
              <Button
                variant="outlined"
                sx={{ minWidth: "auto" }}
              >{`Export Full List Documents`}</Button>
              <Button
                variant="outlined"
                sx={{ minWidth: "auto" }}
              >{`Export Underwriting Documents`}</Button>
            </Stack>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
