import {
  Box,
  Checkbox,
  Fab,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Send } from "@mui/icons-material";
import React, { useState } from "react";
import { postApiCallDynamic, postApiCallDynamicWithOutReturn } from "../../services/api-service";
import { API } from "../../services/api-config";
import { profileState } from "../../recoil/profile-atom";
import { useRecoilValue } from "recoil";
import { ROLE_LIST } from "../../constants/constatnt-variable";

export function CustomMessageInput({ channel, formId, chatTabValue }) {

  let profileInfo = useRecoilValue(profileState);


  const { control, handleSubmit } = useForm({
    defaultValues: {},
  });

  const [message, setMessage] = useState("");

  const handleChange = async (e) => {
    setMessage(e.target.value);
  };

  const getNotifierType = (data) => {
    let type
    if (data.sms && data.email) {
      type = "all"
    } else if (data.sms) {
      type = "sms"
    } else if (data.email) {
      type = "email"
    }
    return type
  }

  const onSubmit = async (data) => {
    let notifierType = getNotifierType(data)

    if (message) {
      await channel.sendMessage({ text: message });
      if (notifierType != undefined) {
        let apiCallData = {
          notifierType: notifierType,
          message: message,
          formId: formId
        }
        postApiCallDynamic({ data: apiCallData, path: API.msgNotifier })
      }
      setMessage("");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <TextField
            size="small"
            fullWidth
            type={"text"}
            placeholder={"Type your message here..."}
            value={message}
            multiline
            minRows={3}
            maxRows={5}
            onChange={handleChange}
          />

          <Stack direction={"row"} gap={1} justifyContent={"flex-end"} p={2}>
            {profileInfo.role == ROLE_LIST.borrower ? null :
              chatTabValue == 1 ? null :
                <>
                  <FormControlLabel
                    control={
                      <Controller
                        name="sms"
                        control={control}
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    label="SMS"
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    label="Mail"
                  />
                </>
            }
            <Fab color="primary" size="small" type="submit">
              <Send />
            </Fab>
          </Stack>
        </Box>
      </form>
    </>
  );
}
