// import { Box, Button, Grid, IconButton, Typography, } from "@mui/material";
// import React, { useMemo, useState } from "react";
// import { useLocation } from "react-router-dom";
// import MainLoyOut from "../../../components/main-layout";
// import CustomReactMaterialTable from "../../../helpers/table/react-material-table";
// import { DialogCommonHooks } from "../../../helpers/modal/modal";
// import NoteForm from "../note/note-form";



// const DocumentLibrary = () => {
//     return (
//         <MainLoyOut>
//             <Box mx={{ md: 15 }}>
//                 <Grid container>
//                     <Grid item xs={12} sm={12} md={12}>
//                         <CustomReactMaterialTable
//                             endPoint={API.note}
//                             params={{ demoRequestId: demoRequestId }}
//                             refetch={tableReload}
//                             columns={columns}
//                             enablePagination={false}
//                             enableToolbarInternalActions={false}
//                             renderTopToolbarCustomActions={(table) => (
//                                 <Box display="flex" justifyContent="flex-end">
//                                     <Button
//                                         variant="outlined"
//                                         onClick={handleOpen}
//                                     >
//                                         Add More
//                                     </Button>
//                                 </Box>
//                             )}
//                         />
//                     </Grid>
//                     HIIIII
//                     <DialogCommonHooks
//                         title="Add New Note"
//                         show={openModal}
//                         handleClose={handleClose}
//                         body={
//                             <NoteForm
//                                 data={data}
//                                 action={action}
//                                 onSubmit={handleSubmit}
//                             />
//                         }
//                     />
//                 </Grid>
//             </Box>
//         </MainLoyOut>
//     )
// }

// export default DocumentLibrary

import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, FormHelperText, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks, DocumentDialogCommonHooks, PreviewPDFModal } from '../../../helpers/modal/modal'
import CustomReactMaterialTable from '../../../helpers/table/react-material-table'
import { API } from '../../../services/api-config'
import ConfirmationModel from '../../../helpers/modal/confirm-modal'
import { KSDocumentSearchIcon, KSPencilAltIcon, KSTrashIcon } from '../../../icons/custome-icon'
import DocumentLibraryForm from './DocumentLibraryForm'
import { getApiCallDynamic, postApiCallDynamicWithOutReturn } from '../../../services/api-service'
import { CREATE, PUT, UPDATE } from '../../../constants/constatnt-variable'
import { kstheme } from '../../../theme/theme'
import FilePreview from '../../broker/leads/file-preview'
import { docPreview, documentPreview } from '../../../helpers/file-upload-download'


export default function DocumenLibrary() {


    const [taskList, setTaskList] = useState([]);
    const [docEdit, setDocEdit] = useState();


    useEffect(() => {
        const taskListsData = () => {
            getApiCallDynamic({
                path: API.getMasterTask,
                // param: { formId: formId, status: status }, 
            }).then((res) => {
                if (res?.status === 200) {
                    setTaskList(res?.data);
                }
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
        };

        taskListsData();
    }, []);
    const [refreshStatus, setRefreshStatus] = useState(false)

    const [docList, setDocList] = useState([]);
    const [docListShow, setDocListShow] = useState([]);
    useEffect(() => {
        const taskListsData = () => {
            getApiCallDynamic({
                path: API.getMasterTaskDoc,
                // param: { formId: formId, status: status }, 
            }).then((res) => {
                if (res?.status === 200) {
                    setDocList(res?.data);
                }
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
        };

        taskListsData();
    }, [refreshStatus]);


    // useEffect(() => {
    //     const updatedDocListShow = docList.map(doc => ({
    //         id: doc.id,
    //         documentType: doc.documentType,
    //         documentDescriptor: doc.documentDescriptor,
    //         storageKey: doc.storageKey,
    //         documentNote: doc.documentNote,
    //         fileName: doc.fileName,
    //         blankDocument: doc.blankDocument,
    //         taskTypeName: doc.taskMaster?.taskTypeName // Add taskTypeName from taskMaster
    //     }));
    //     setDocListShow(updatedDocListShow);
    // }, [docList]);

    useEffect(() => {
        const updatedDocListShow = docList
            .map(doc => ({
                id: doc.id,
                documentType: doc.documentType,
                documentDescriptor: doc.documentDescriptor,
                storageKey: doc.storageKey,
                documentNote: doc.documentNote,
                fileName: doc.fileName,
                blankDocument: doc.blankDocument,
                taskTypeName: doc.taskMaster?.taskTypeName // Add taskTypeName from taskMaster
            }))
        // .sort((a, b) => a.documentType.localeCompare(b.documentType)); // Sorting based on documentType

        setDocListShow(updatedDocListShow);
    }, [docList]);



    const taskTypeOptions = taskList.map((task) => ({
        label: task.taskTypeName, // Use taskType as the label
        value: task.id, // Use taskType as the value
    }));

    console.log(" THIS NEEED TO ANALYSE " + JSON.stringify(docList, null, 2))


    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

    const route = useNavigate()
    const location = useLocation();

    let [formValue, setFormValue] = useState([])



    let [customError, setCustomerror] = useState("")

    const [editState, setEditState] = useState(() => {
        return null
    })


    const [uploadData, setUploadData] = useState(null);

    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(docList)
            let editItem = list.find((item, i) => i == editState)
            setDocEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])


    const [deleteState, setDeleteState] = useState(() => {
        return null
    })


    // delete table
    useEffect(() => {
        if (deleteState != null) {
            docList.splice(deleteState, 1)
            setDocList([...docList])
            setDeleteState(null)
        }

    }, [deleteState])

    const updateState = () => {

        setRefreshStatus(true)

    }

    const tableColumn = useMemo(
        () => [
            // {
            //     accessorKey: "id",
            //     header: "Task Id",
            //     enableColumnActions: false,

            // },
            {
                accessorKey: "taskTypeName",
                header: "Task Type",
                enableColumnActions: false,

            },
            // {
            //     accessorKey: "documentType",
            //     header: "Document Type",
            //     enableColumnActions: false,
            //     Cell: ({ row }) => (
            //         <>
            //             <Typography>
            //                 {row.original['documentType']}
            //             </Typography>
            //             {row.original['blankDocument'] && (
            //                 <Typography variant="body2" sx={{ color: '#D94166' }}>
            //                     Blank form
            //                 </Typography>
            //             )}
            //             <Typography variant="body2" sx={{ color: 'grey' }}>
            //                 {row.original['fileName']}
            //             </Typography>
            //         </>


            //     ),
            // },
            // {
            //     accessorKey: "documentDescriptor",
            //     header: "Document Descriptor",
            //     enableColumnActions: false,
            // },
            {
                accessorKey: 'documentType',
                header: 'Document Type',
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            whiteSpace: 'normal', // Allows text to wrap
                            wordBreak: 'break-word', // Break long words
                            maxWidth: '300px', // Set your desired max width
                        }}
                    >
                        <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                            {row.original['documentType']}
                        </Typography>
                        {row.original['blankDocument'] && (
                            <Typography variant="body2" sx={{ color: '#D94166' }}>
                                Blank form
                            </Typography>
                        )}
                        <Typography variant="body2" sx={{ color: 'grey' }}>
                            {row.original['fileName']}
                        </Typography>
                    </Box>
                ),
            },
            {
                accessorKey: 'documentDescriptor',
                header: 'Document Descriptor',
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            whiteSpace: 'normal', // Allows text to wrap
                            wordBreak: 'break-word', // Break long words
                            maxWidth: '300px', // Set your desired max width
                        }}
                    >
                        <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                            {row.original['documentDescriptor']}
                        </Typography>
                    </Box>
                ),
            },

            {
                accessorKey: 'documentNote',
                header: 'Note',
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            whiteSpace: 'normal', // Allows text to wrap
                            wordBreak: 'break-word', // Break long words
                            maxWidth: '300px', // Set your desired max width
                        }}
                    >
                        <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                            {row.original['documentNote']}
                        </Typography>
                    </Box>
                ),
            },

        ],
        []
    );

    const [modalOpen, setModalOpen] = useState(false);
    const handleClickOpen = () => {

        setDocEdit([])
        setModalOpen(true);
    };



    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)

    };



    const handleCloseDelete = (event, row) => {

        if (event.target.value == 'yes') {
            setDeleteState(row.index)

        }
    }

    console.log('File uploaded successfully:', JSON.stringify(uploadData, null, 2));

    useEffect(() => {
        setDocList([...docList]);
    }, [docList.length])

    const docsSubmit = async (data) => {
        console.log(" updated clicked")

        setCustomerror("")
        if (editState != null) {
            docList[editState] = {
                ...docList[editState],
                ...data
            }

            setEditState(null)
        } else {
            docList.push(data);
        }

        setDocList([...docList]);


        const apiCallData = {

            "id": data?.id,
            "taskMaster":
            {
                "id": data?.taskType,
                // "taskTypeName": "UploadSignature1",
                // "taskTypeDescription": "Hello",
                // "taskTypeFor": "borrower"
            },
            "documentType": data?.documentType,
            "documentDescriptor": data?.documentDescriptor,
            "storageKey": uploadData?.storageKey,
            "documentNote": data?.documentNote,
            "blankDocument": data?.blankDocument === 'Yes' ? true : false,
            "fileName": uploadData?.fileName
        };

        console.log(" apiCallData " + JSON.stringify(apiCallData, null, 2))

        try {
            await postApiCallDynamicWithOutReturn(
                {
                    data: apiCallData,
                    path: API.createMasterTaskDoc,
                    //header: PUT,
                    header: docEdit?.length !== 0 ? UPDATE : CREATE,
                    refresh: updateState
                }
            )
        } catch (error) {
            console.error("API call failed", error);
            // setCustomError("An error occurred while submitting the form.");
        }
        handleClose()
    }



    const previewDocument = (row) => {
        const storageKey = row?.original?.storageKey;
        const fileName = row?.original?.fileName;
        const apiUrl = API.downloadDoc

        if (storageKey && fileName) {
            // Call docPreview to download or preview the document
            docPreview(apiUrl, { storageKey, fileName })
                .then((url) => {
                    console.log('Preview URL:', url);
                })
                .catch((error) => {
                    console.error('Error previewing the file:', error);
                });
        } else {
            alert('Missing storage key or file name!');
        }
    };




    return (
        <>

            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box >
                    {/* <Typography variant='h3' mb={2} >{`Mention all the financial asssts you hold`}</Typography>
                    <Typography variant='h4' mb={2} >{`Bank Accounts, Retirement, and Other Accounts`}</Typography> */}

                    <CustomReactMaterialTable
                        staticLoad={true}
                        staticData={docListShow}
                        columns={tableColumn}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableSorting={false}
                        enableToolbarInternalActions={false}
                        renderTopToolbarCustomActions={() => (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mr: isMobile ? '0rem' : '6rem', mt: isMobile ? '0.5rem' : '2rem' }}>
                                <Button variant="outlined" onClick={handleClickOpen}>
                                    Add More
                                </Button>
                            </Box>
                        )}
                        enableRowActions={true}
                        renderRowActions={
                            ({ row }) => {

                                return (
                                    <Stack direction={'row'}>


                                        {/* <IconButton color='primary' onClick={previewDocument}>
                                            <KSDocumentSearchIcon />
                                        </IconButton> */}
                                        <IconButton onClick={() => { setEditState(row.index) }}>
                                            <KSPencilAltIcon />
                                        </IconButton>

                                        <IconButton
                                            color="primary"
                                            onClick={() => previewDocument(row)}  // Pass the row data when clicked
                                            disabled={!row?.original?.fileName}
                                        >
                                            <KSDocumentSearchIcon />
                                        </IconButton>

                                        {/* <ConfirmationModel
                                            body={<IconButton
                                                id={row.index} onClick={() => {
                                                    // setDeleteState(row.index)
                                                }}>
                                                <KSTrashIcon />
                                            </IconButton>}
                                            message={`Are you sure want remove  ${row?.original?.documentType} - Savings?`}
                                            title={'Delete Document'}
                                            handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                        /> */}

                                    </Stack>
                                )
                            }
                        }
                    />
                    {customError && <FormHelperText error>{customError}</FormHelperText>}
                </Box>



                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    {/* <Stack direction={'row'} justifyContent={'space-between'}>
                        <Link to={'../borrower-info/personal-information'}>
                            <Button startIcon={<ArrowBackIos />}>
                                Previous
                            </Button>
                        </Link>
                        <Button onClick={handleAssestCreditFormSubmit} endIcon={<ArrowForwardIos />} >
                            Next
                        </Button>
                    </Stack> */}
                </Box>
            </Stack>

            <DocumentDialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={docEdit?.length !== 0 ? `Update Task Documents` : `Add Task Documents`}
                body={
                    <DocumentLibraryForm
                        onSubmit={docsSubmit}
                        editItem={docEdit}
                        setUploadData={setUploadData}
                        hasPartner={formValue?.hasPartner}
                        handleClose={handleClose}
                        docList={taskTypeOptions}
                    />
                }
            />


        </>
    )
}
