import { ArrowBackIos, ArrowForwardIos, NavigateNext } from '@mui/icons-material'
import { Box, Breadcrumbs, Button, FormHelperText, Link as MLink, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks } from '../../../../../../helpers/modal/modal'
import useInput from '../../../../../../helpers/use-input'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../../../recoil/profile-atom'
import { useForm } from 'react-hook-form'
import SelfEmployed from './self-employed/self-employed'
import Employed from './epmloyed/epmloyed'
import Retired from './retired/retired'
import SecondaryEmployment from './secondary-employment/secondary-employment'
import LoanExtraIncome from './extra-income/extra-income'
import { API } from '../../../../../../services/api-config'
import { postApiCallDynamicWithOutReturn } from '../../../../../../services/api-service'
import { UPDATE } from '../../../../../../constants/constatnt-variable'
import { getMonthsBetweenDates } from '../../../../../../helpers/time-formater'

export default function LoanEmployment() {

    const route = useNavigate()
    const location = useLocation();
    const partnerInfoPath = location.pathname.split("/")[location.pathname.split("/").length - 2]


    let [formValue, setFormValue] = useRecoilState(formState)

    const handleClose = () => {
        setModalOpen(false);
    };

    const [employmentStatus] = useInput({
        data: null,
        status: false,
        initial: null
    });


    const [modalOpen, setModalOpen] = useState(false);


    let [employed, setEmployed] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue?.partnerInfo?.page?.employment?.subPage?.employed)
            :
            _.cloneDeep(formValue?.borrowerInfo?.page?.employment?.subPage?.employed)
    )

    let [secondary, setSecondary] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue?.partnerInfo?.page?.employment?.subPage?.secondary)
            :
            _.cloneDeep(formValue?.borrowerInfo?.page?.employment?.subPage?.secondary)
    )

    let [retired, setRetired] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue?.partnerInfo?.page?.employment?.subPage?.retired)
            :
            _.cloneDeep(formValue?.borrowerInfo?.page?.employment?.subPage?.retired)
    )

    let [selfEmployed, setSelfEmployed] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue?.partnerInfo?.page?.employment?.subPage?.selfEmployed)
            :
            _.cloneDeep(formValue?.borrowerInfo?.page?.employment?.subPage?.selfEmployed)
    )

    let [isSideIncomeAvailable, setIsSideIncomeAvailable] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue?.partnerInfo?.page?.employment?.subPage?.isSideIncomeAvailable)
            :
            _.cloneDeep(formValue?.borrowerInfo?.page?.employment?.subPage?.isSideIncomeAvailable)
    )

    let [sideIncome, setSideIncome] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue?.partnerInfo?.page?.employment?.subPage?.sideIncome)
            :
            _.cloneDeep(formValue?.borrowerInfo?.page?.employment?.subPage?.sideIncome)
    )


    const empty = (data) => {
        return data.length == 0;
    }
    let [customError, setCustomerror] = useState({})

    const validation = (data) => {
        if (empty(data.retired)) {
            if (empty(data.employed) && empty(data.selfEmployed)) {
                setCustomerror({
                    ...customError,
                    employed: {
                        'message': "Employed details need to be added"
                    },
                    selfEmployed: {
                        'message': "Self Employed details need to be added"
                    },
                })
                return false
            } else {
                // for employed months count
                let monthsCountEmployed = data?.employed.map((item) => {
                    return getMonthsBetweenDates(item.startDate, item.endDate, item.isStillWorking)
                })
                monthsCountEmployed = monthsCountEmployed.reduce((acc, curr) => acc + curr, 0)

                // for self employed months count
                let monthsCountSelf = 0;
                if (!empty(data.selfEmployed)) {
                    monthsCountSelf = data?.selfEmployed.map((item) => {
                        return getMonthsBetweenDates(item.startDate, item.endDate, item.isStillWorking)
                    })
                    monthsCountSelf = monthsCountSelf.reduce((acc, curr) => acc + curr, 0)
                }

                let totalMonth = (monthsCountEmployed + monthsCountSelf)


                if (totalMonth < 24) {
                    if (data?.employed?.length != 0) {
                        setCustomerror({
                            ...customError,
                            employed: {
                                'message': "Your profession is less than 2 year, Please add more"
                            },
                            selfEmployed: null
                        })
                        return false
                    }
                    if (data?.selfEmployed?.length != 0) {
                        setCustomerror({
                            ...customError,
                            employed: null,
                            selfEmployed: {
                                'message': "Your profession is less than 2 year, Please add more"
                            },
                        })
                        return false
                    }
                    return false
                } else {
                    return true
                }

            }
        } else {
            return true
        }

    }

    const onSubmit = async (data, event) => {

        if (event.target.id == "main") {
            if (!validation(data)) {
                return
            }
            const apiCallData = {
                page: {
                    subPage: {
                        employed: data.employed,
                        secondary: data.secondary,
                        retired: data.retired,
                        isSideIncomeAvailable: data.isSideIncomeAvailable,
                        selfEmployed: data.selfEmployed,

                        sideIncome: {
                            avgMonthlyComission: data.avgMonthlyComission,
                            avgMonthlyIncome: data.avgMonthlyIncome,
                            avgMonthlyOvertime: data.avgMonthlyOvertime,
                            militaryEntitlement: data.militaryEntitlement

                        }

                    },
                    isComplete: true
                },
                formId: formValue.id,
                isComplete: true
            }

            let updateState, path

            if (partnerInfoPath == "partner-info") {
                path = API.partnerInfoEmployment
                updateState = {
                    ...formValue,
                    partnerInfo: {
                        ...formValue.partnerInfo,
                        page: {
                            ...formValue.partnerInfo.page,
                            employment: apiCallData.page
                        }
                    }

                }

            } else {
                path = API.borrowerInfoEmployment
                updateState = {
                    ...formValue,
                    borrowerInfo: {
                        ...formValue.borrowerInfo,
                        page: {
                            ...formValue.borrowerInfo.page,
                            employment: apiCallData.page
                        }
                    }

                }

            }



            const employmentInfoUpdateInRecoil = () => {
                setFormValue(updateState)
                route(location.pathname.replace(/[^/]*$/, 'military-service'));
            }

            await postApiCallDynamicWithOutReturn(
                {
                    data: apiCallData,
                    path: path,
                    header: UPDATE,
                    refresh: employmentInfoUpdateInRecoil
                }

            )
            console.log("apiCallData:", JSON.stringify(apiCallData, null, 2));
        }


    }



    const { setValue, control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            employed: employed,
            secondary: secondary,
            selfEmployed: selfEmployed,
            retired: retired,
            isSideIncomeAvailable: isSideIncomeAvailable,
            avgMonthlyIncome: sideIncome?.avgMonthlyIncome,
            avgMonthlyOvertime: sideIncome?.avgMonthlyOvertime,
            militaryEntitlement: sideIncome?.militaryEntitlement,
            avgMonthlyComission: sideIncome?.avgMonthlyComission,
        }

    })

    return (
        <>
            <form key={'main'} id='main' onSubmit={handleSubmit(onSubmit)} >
                <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                    <Box>

                        <Breadcrumbs aria-label="breadcrumb"
                            separator={<NavigateNext fontSize="small" />}
                        >
                            <MLink underline="hover" color="inherit" href="#">
                                {`${partnerInfoPath == "partner-info" ? "Partner" : "Borrower"} Profile`}
                            </MLink>
                            <Typography color="primary.main">Employment</Typography>
                        </Breadcrumbs>
                        <Box maxWidth={{ xs: '100%', md: '100%' }} mt={2}>

                            <Employed setValue={setValue} employedList={employed} setEmployedList={setEmployed} />
                            {customError?.employed && <FormHelperText error>{customError.employed.message}</FormHelperText>}
                            <SelfEmployed setValue={setValue} employedList={selfEmployed} setEmployedList={setSelfEmployed} />
                            {customError?.selfEmployed && <FormHelperText error>{customError.selfEmployed.message}</FormHelperText>}
                            <SecondaryEmployment setValue={setValue} employedList={secondary} setEmployedList={setSecondary} />
                            <Retired setValue={setValue} retiredList={retired} setRetiredList={setRetired} />
                            {/* <LoanExtraIncome
                                control={control}
                                errors={errors}
                                sideIncome={sideIncome}
                                setSideIncome={setSideIncome}
                                defaultIsSideIncomeAvailable={isSideIncomeAvailable}
                                setDefaultIsSideIncomeAvailable={setIsSideIncomeAvailable}
    />*/}

                        </Box>
                    </Box>
                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Link to={'../dependent'}>
                                <Button startIcon={<ArrowBackIos />}>
                                    Previous
                                </Button>
                            </Link>
                            <Button form='main' type='submit' endIcon={<ArrowForwardIos />} >
                                Next
                            </Button>
                        </Stack>
                    </Box>

                </Stack>
            </form>

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Previous Employment`}
                body={
                    <>
                        <Box width={'100%'} my={2} >
                            <Typography>
                                {`We need minimum 2 years of employment history, Please enter your previous employment.`}
                            </Typography>
                            <Stack direction={'row-reverse'} gap={2}>
                                <Button type='button' onClick={() => { employmentStatus.saveValue("previous-employments") }} variant='contained'>Okay</Button>
                                <Button variant='text' onClick={handleClose}>Cancel</Button>
                            </Stack>
                        </Box>
                    </>
                }
            />
        </>
    )
}
