import { Box, InputLabel, TextField, Typography } from '@mui/material';
import React from 'react'
import { usePlacesWidget } from 'react-google-autocomplete';
import ErrorIcon from "@mui/icons-material/Error";
import { GOOGLE_MAP_API } from '../constants/constatnt-variable';

export default function GoogleAutocomplete({
    error = null,
    label,
    required,
    setValue,
    name,
    marginBottom = 3,
    marginTop = 1,
    ...props
}) {

    const { ref } = usePlacesWidget({
        apiKey: GOOGLE_MAP_API,
        onPlaceSelected: (place) => {
            setValue(name, place.formatted_address)
        },
        options: {
            // This option configure to only show USA location
            types: ["(regions)"],
            componentRestrictions: { country: "us" },
        }

    });

    return (
        <Box mb={marginBottom} mt={marginTop}>
            <InputLabel>
                {label}
                {required ? (
                    <Typography variant="small" color="error">
                        *
                    </Typography>
                ) : null}
            </InputLabel>
            <TextField
                fullWidth
                inputRef={ref}
                InputProps={{
                    endAdornment:
                        error && props?.id !== "password" ? (
                            <ErrorIcon color="error" />
                        ) : null,
                }}
                {...props}
                {...(error && { error: true, helperText: error })}
            />
        </Box>
    )
}

