import { Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { Path } from "../../routes/route-config";
import useInput from "../../helpers/use-input";
import { Auth } from "../../auth/auth";
import CustomInput from "../../helpers/custom-input";
import { toastNotify } from "../../helpers/alert-msg";
import { ArrowBackOutlined, Visibility, VisibilityOff, } from "@mui/icons-material";
import { MAXLength, ValidationConstant } from "../../constants/validation";
import { useFormik } from "formik";
import { MSG, SERVICE_TYPE } from "../../constants/constatnt-variable";
import AuthSideUi from "../../components/auth-side-ui";

export default function SetPassword(props) {
  const { state, ...location } = useLocation();

  const router = useNavigate();
  useEffect(() => {
    if (state == null) {
      router(Path.landing);
    }
  }, []);

  let [confirmPassword] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  let [password] = useInput({
    data: "",
    status: false,
    initial: "",
  });

  const signIn = (data, from) => {
    Auth.changePassword(data, from).then((response) => {
      props.userHasAuthenticated(response[0], response[1]);
      // setSpinner(false);

      if (response[0]) {
        toastNotify(
          "success",
          state.from == SERVICE_TYPE.signup ||
            state.from == SERVICE_TYPE.invitation ||
            state.from == SERVICE_TYPE.teamInvitation ||
            state.from == SERVICE_TYPE.coBorrowerInvitation
            ? MSG.userCreated
            : MSG.changePassword
        );
        if (state.website || state.from == SERVICE_TYPE.coBorrowerInvitation) {
          console.log("ss", response);
          router(`${Path.loanFormApply}${response[1]?.data?.formId}`);

        } else {
          router(Path.index);

        }

      } else {
        if (response[1] && response[1].error && response[1].error.errorCode) {
          if (response[1].error.errorCode === 2005) {
            alert(response[1].error.errorMessage);
          } else {
            toastNotify("error", response[1].error.errorMessage);
          }
        } else {
          toastNotify("error", "API Server Unreachable");
        }
      }
    });
  };
  const handleSubmit = () => {
    let data;

    if (state?.from === SERVICE_TYPE.invitation || state?.from === SERVICE_TYPE.teamInvitation || state?.from === SERVICE_TYPE.coBorrowerInvitation) {
      data = {
        ...state,
        password: password.value,
        // other value
      };
    } else if (state?.from === SERVICE_TYPE.forget) {
      data = {
        tempToken: state.tempToken,
        password: password.value,
        service: SERVICE_TYPE.forget,
      };
    } else {
      data = {
        tempToken: state.tempToken,
        password: password.value,
        service: SERVICE_TYPE.signup,
      };
    }
    signIn(data, state.from);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleValidation = (values) => {
    let errors = {};
    if (!ValidationConstant.regNumber.test(password.value)) {
      errors.password = "At least one number required";
    }

    if (!ValidationConstant.regCapitalAlpha.test(password.value)) {
      errors.password = "At least one uppercase letter";
    }
    if (!ValidationConstant.regSmallAlpha.test(password.value)) {
      errors.password = "At least one lowercase letter";
    }

    if (!ValidationConstant.regSpecialChar.test(password.value)) {
      errors.password = "Special character required";
    }
    if (!(password.value.length >= ValidationConstant.passwordLength)) {
      errors.password = "Password is short";
    }

    if (password.value !== confirmPassword.value) {
      errors.confirmPassword = "Password didn't match";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: password.value,
      confirmPassword: confirmPassword.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  return (
    <>
      <Grid container alignItems="center">
        {/* side background */}
        <AuthSideUi />
        {/* set password form */}
        <Grid item xs={12} sm={6} sx={{ height: "100vh", overflow: "auto" }}>
          <Box p={2}>
            <Grid container md={12} justifyContent="space-between">
              <Grid item>
                <Stack direction={"row"} spacing={2}>
                  <Button
                    onClick={() => {
                      router(-1);
                    }}
                  >
                    <ArrowBackOutlined />
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box
              px={{ xs: 2, md: 20 }}
              pb={{ xs: 2, md: 3 }}
              minHeight="85vh"
              justifyContent="center"
              flexDirection="column"
              display="flex"
            >
              <Typography variant="h4" fontWeight="bold" mb={2}>
                Set your Password
              </Typography>

              {/* For Every Input Field */}
              {/* Kindly follow the 1st component for all options */}
              <Box my={2}>
                <CustomInput
                  type={showPassword ? "text" : "password"}
                  eicon={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  }
                  label={"New Password"}
                  helperText={"Invalid Password"}
                  id="password"
                  placeholder={"Enter your new passowrd"}
                  change={password}
                  error={formik.errors.password}
                  maxLength={MAXLength.password}
                />
              </Box>

              <Box my={2}>
                <CustomInput
                  type={showConfirmPassword ? "text" : "password"}
                  eicon={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  }
                  label={"Re-enter New Password"}
                  placeholder={"Enter your new passowrd"}
                  change={confirmPassword}
                  id="password"
                  error={formik.errors.confirmPassword}
                  maxLength={MAXLength.password}
                />
                {/* Add this sx to make it inline */}
              </Box>

              <Box
                my={2}
                p={2}
                sx={{ background: "#2F80ED20", borderRadius: "8px" }}
              >
                <Box display="flex" my={1}>
                  {password.value.length >=
                    ValidationConstant.passwordLength ? (
                    <CheckCircleIcon color="primary" />
                  ) : (
                    <CircleOutlinedIcon color="primary" />
                  )}
                  <Typography ml={1}>At least 8 characters</Typography>
                </Box>
                <Box display="flex" my={1}>
                  {ValidationConstant.regSpecialChar.test(password.value) ? (
                    <CheckCircleIcon color="primary" />
                  ) : (
                    <CircleOutlinedIcon color="primary" />
                  )}
                  <Typography ml={1}>At least one special character</Typography>
                </Box>
                <Box display="flex" my={1}>
                  {ValidationConstant.regNumber.test(password.value) ? (
                    <CheckCircleIcon color="primary" />
                  ) : (
                    <CircleOutlinedIcon color="primary" />
                  )}
                  <Typography ml={1}>At least one number</Typography>
                </Box>

                <Box display="flex" my={1}>
                  {ValidationConstant.regCapitalAlpha.test(password.value) &&
                    ValidationConstant.regSmallAlpha.test(password.value) ? (
                    <CheckCircleIcon color="primary" />
                  ) : (
                    <CircleOutlinedIcon color="primary" />
                  )}
                  <Typography ml={1}>
                    At least one uppercase and lowercase letter
                  </Typography>
                </Box>
              </Box>

              <Box my={2}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{ borderRadius: "24px", fontWeight: "bold" }}
                  type="submit"
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </>
  );
}
