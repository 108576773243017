import { AttachMoney } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import {
    HOME_HOA_MANAGEMENT,
    INTENDED_OCCUPANCY,
    MORTAGE_NEW_LENDER,
    MORTAGE_PROPERTY,
    PROPERTY_STATUS,
    PROPERTY_TYPE,
    REAL_STATE_OWNED_BY,
    REAL_STATE_OWNED_BY_WITHOUT_PARTNER,
    REAL_STATE_STATUS,
    SOLAR_STATUS,
    STATUS_OF_NEW_LENDER
} from '../../../../../constants/constatnt-variable'
import CustomInput from '../../../../../helpers/custom-input'
import CustomDropDown from '../../../../../helpers/dropdown/custom-dropdown'
import CustomLargeLabel from '../../../../../helpers/large-label'
import CustomRadioChip from '../../../../../helpers/radio-chip'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { RealEstateValidation } from '../../../../../constants/validation-rules'
import { PropertyAddress } from '../../../../../components/loan-application/property-address'

import {
    TYPES_OF_LOAN,
} from "../../../../../constants/constatnt-variable";
import { LoanDetailsValidation } from "../../../../../constants/validation-rules";


export function RealStateForm({ onSubmit, editItem, hasPartner }) {

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            // realestateStatus: editItem?.realestateStatus,
            solarStatus: editItem?.solarStatus,
            isHoaManaged: editItem?.isHoaManaged,
            newLeaderStatus: editItem?.newLeaderStatus,
            newLeaderStatement: editItem?.newLeaderStatement,
            addressLine1: editItem?.addressLine1,
            addressLine2: editItem?.addressLine2,
            unit: editItem?.unit,
            city: editItem?.city,
            stateOfCountry: editItem?.stateOfCountry,
            zip: editItem?.zip,
            propertyType: editItem?.propertyType,
            marketValue: editItem?.marketValue,
            realEstateOwnedBy: editItem?.realEstateOwnedBy,
            propertyStatus: editItem?.propertyStatus,
            propertyMortgage: editItem?.propertyMortgage,
            intendedOccupancy: editItem?.intendedOccupancy,
            propertyInsurance: editItem?.propertyInsurance,
            propertyTaxes: editItem?.propertyTaxes,
            solarLeasePayment: editItem?.solarLeasePayment,
            hoaPayment: editItem?.hoaPayment,
            monthlyIncome: editItem?.monthlyIncome,
            mortgageInsurance: editItem?.mortgageInsurance,
            // isMortgageStatementAvailable: '',
            creditLimit: editItem?.creditLimit,
            realEstateLoanType: editItem?.realEstateLoanType,
        }
    })

    const { isHoaManaged, solarStatus, newLeaderStatus, newLeaderStatement, stateOfCountry,
        propertyType, realEstateOwnedBy, propertyStatus, propertyMortgage, intendedOccupancy, creditLimit, realEstateLoanType
    } = useWatch({ control })





    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Box width={'100%'} my={2} >
                <Box>

                    {/* <Controller
                        name='realestateStatus'
                        control={control}
                        rules={RealEstateValidation.realestateStatus}
                        render={({ field }) => (
                            <CustomRadioChip
                                labelComponent={<CustomLargeLabel required value={'Do you own any real estate?'} />}
                                {...field}
                                field={field}
                                state={realestateStatus}
                                data={REAL_STATE_STATUS}
                                error={errors?.realestateStatus?.message}
                            />
                        )}
                    /> */}

                    <Controller
                        name='solarStatus'
                        control={control}
                        rules={RealEstateValidation.solarStatus}
                        render={({ field }) => (
                            <CustomRadioChip
                                labelComponent={<CustomLargeLabel required value={'Is solar purchased?'} />}
                                {...field}
                                field={field}
                                state={solarStatus}
                                data={SOLAR_STATUS}
                                error={errors?.solarStatus?.message}
                            />
                        )}
                    />

                    <Controller
                        name='isHoaManaged'
                        control={control}
                        rules={RealEstateValidation.isHoaManaged}
                        render={({ field }) => (
                            <CustomRadioChip
                                labelComponent={<CustomLargeLabel required value={'Do the home under HOA management?'} />}
                                {...field}
                                field={field}
                                state={isHoaManaged}
                                data={HOME_HOA_MANAGEMENT}
                                error={errors?.isHoaManaged?.message}
                            />
                        )}
                    />
                    {isHoaManaged == 'No' ?
                        <>
                            <Typography variant='body' color={'success.main'} >
                                {`
                            You will be asked to provide a transfer letter and the mortgage 
                            statement with the previous lender in the documents section!
                        `}
                            </Typography>
                        </>
                        : null
                    }

                    {/* {isHoaManaged == 'Yes' ? */}
                    <>


                        <Controller
                            name='newLeaderStatus'
                            control={control}
                            rules={RealEstateValidation.newLeaderStatus}
                            render={({ field }) => (
                                <CustomRadioChip
                                    labelComponent={<CustomLargeLabel required value={'Did the mortgage transfer to a new lender recently?'} />}
                                    {...field}
                                    field={field}
                                    state={newLeaderStatus}
                                    data={STATUS_OF_NEW_LENDER}
                                    error={errors?.newLeaderStatus?.message}
                                />
                            )}
                        />


                        <Controller
                            name='newLeaderStatement'
                            control={control}
                            rules={RealEstateValidation.newLeaderStatement}
                            render={({ field }) => (
                                <CustomRadioChip
                                    labelComponent={<CustomLargeLabel value={'Do you have a mortgage statement with the new lender?'} />}
                                    {...field}
                                    field={field}
                                    state={newLeaderStatement}
                                    data={MORTAGE_NEW_LENDER}
                                    error={errors?.newLeaderStatement?.message}
                                />
                            )}
                        />

                        <Typography variant='inherit' mt={2}>Property Address</Typography>


                        <PropertyAddress control={control} Controller={Controller} stateOfCountry={stateOfCountry} errors={errors} />

                        <Controller
                            name='propertyType'
                            control={control}
                            rules={RealEstateValidation.propertyType}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    label={'Property Type'}
                                    required
                                    state={propertyType}
                                    option={PROPERTY_TYPE}
                                    error={errors?.propertyType?.message}
                                />
                            )}
                        />

                        <Controller
                            name='marketValue'
                            control={control}
                            rules={RealEstateValidation.marketValue}
                            render={({ field }) => (
                                <CustomInput {...field} type={'number'} label={'Market Value'} required sicon={<AttachMoney />} error={errors?.marketValue?.message} />

                            )}
                        />

                        <Controller
                            name='realEstateOwnedBy'
                            control={control}
                            rules={RealEstateValidation.realEstateOwnedBy}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    label={'Real estate Owned by'}
                                    required
                                    state={realEstateOwnedBy}
                                    option={hasPartner ? REAL_STATE_OWNED_BY : REAL_STATE_OWNED_BY_WITHOUT_PARTNER}
                                    error={errors?.realEstateOwnedBy?.message}
                                />

                            )}
                        />

                        <Controller
                            name='propertyStatus'
                            control={control}
                            rules={RealEstateValidation.propertyStatus}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    required
                                    label={'Property Status'}
                                    state={propertyStatus}
                                    data={PROPERTY_STATUS}
                                    error={errors?.propertyStatus?.message}

                                />
                            )}
                        />

                        <Controller
                            name='propertyMortgage'
                            control={control}
                            rules={RealEstateValidation.propertyMortgage}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    required
                                    label={'Do you have mortgage on this property?'}
                                    state={propertyMortgage}
                                    data={MORTAGE_PROPERTY}
                                    error={errors?.propertyMortgage?.message}
                                />
                            )}
                        />

                        <Controller
                            name='intendedOccupancy'
                            control={control}
                            rules={RealEstateValidation.intendedOccupancy}
                            render={({ field }) => (
                                <CustomRadioChip
                                    {...field}
                                    field={field}
                                    required
                                    label={'Intended Occupancy'}
                                    state={intendedOccupancy}
                                    data={INTENDED_OCCUPANCY}
                                    error={errors?.intendedOccupancy?.message}
                                />
                            )}
                        />

                        <Controller
                            name='propertyInsurance'
                            control={control}
                            rules={RealEstateValidation.propertyInsurance}
                            render={({ field }) => (
                                <CustomInput {...field} type={'number'} label={'Property Insurance'} required sicon={<AttachMoney />} error={errors?.propertyInsurance?.message} />
                            )}
                        />

                        <Controller
                            name='propertyTaxes'
                            control={control}
                            rules={RealEstateValidation.propertyTaxes}
                            render={({ field }) => (
                                <CustomInput {...field} type={'number'} label={'Property Taxes'} required sicon={<AttachMoney />} error={errors?.propertyTaxes?.message} />
                            )}
                        />

                        <Controller
                            name='solarLeasePayment'
                            control={control}
                            rules={RealEstateValidation.solarLeasePayment}
                            render={({ field }) => (
                                <CustomInput {...field} type={'number'} label={'Solar Lease Payment'} required sicon={<AttachMoney />} error={errors?.solarLeasePayment?.message} />
                            )}
                        />
                        {isHoaManaged === 'Yes' ? (
                            <Controller
                                name='hoaPayment'
                                control={control}
                                rules={RealEstateValidation.hoaPayment}
                                render={({ field }) => (
                                    <CustomInput {...field} type={'number'} label={'HOA payment'} required sicon={<AttachMoney />} error={errors?.hoaPayment?.message} />
                                )}
                            />) : null}

                        <Controller
                            name='monthlyIncome'
                            control={control}
                            rules={RealEstateValidation.monthlyIncome}
                            render={({ field }) => (
                                <CustomInput {...field} type={'number'} label={'Monthly Income'} required sicon={<AttachMoney />} error={errors?.monthlyIncome?.message} />
                            )}
                        />

                        <Controller
                            name='mortgageInsurance'
                            control={control}
                            rules={RealEstateValidation.mortgageInsurance}
                            render={({ field }) => (
                                <CustomInput {...field} type={'number'} label={'Mortgage Insurance'} required sicon={<AttachMoney />} error={errors?.mortgageInsurance?.message} />
                            )}
                        />

                        <Controller
                            name="realEstateLoanType"
                            control={control}
                            rules={LoanDetailsValidation.loanTypes}
                            render={({ field }) => (
                                <CustomDropDown

                                    {...field}
                                    field={field}
                                    label={'Loan Type'}
                                    state={realEstateLoanType}
                                    option={TYPES_OF_LOAN}
                                    error={errors?.loanTypes?.message}
                                />
                            )}
                        />




                        <Controller
                            name='creditLimit'
                            control={control}
                            // rules={RealEstateValidation.mortgageInsurance}
                            render={({ field }) => (
                                <CustomInput {...field} type={'number'} label={'Credit Limit'} sicon={<AttachMoney />}
                                // error={errors?.mortgageInsurance?.message} 
                                />
                            )}
                        />

                    </>
                    {/* : null}*/}
                </Box>
                <Box textAlign={'right'}>
                    <Button type='submit' variant='contained' >
                        {editItem.length != 0 ? 'Update' : 'Add'}
                    </Button>
                </Box>
            </Box>
        </form >
    )
}