import React, { useState } from "react";

import { Button, InputAdornment, Stack, } from "@mui/material";
import Grid from "@mui/material/Grid";
import useInput from "../../../helpers/use-input";
import { postApiCallDynamic, } from "../../../services/api-service";
import { API } from "../../../services/api-config";
import CustomInput from "../../../helpers/custom-input";
import { useFormik } from "formik";
import { MAXLength, ValidationConstant } from "../../../constants/validation";
import LoadingOverlay from "@ronchalant/react-loading-overlay";

import { useRecoilState } from "recoil";
import { profileState } from "../../../recoil/profile-atom";
import { ROLE_LIST, SERVICE_TYPE, UPDATE } from "../../../constants/constatnt-variable";
import { CustomAutocomplete } from "../../../helpers/custome-autocomplete";
import { KSMailIcon, KSPhoneIcon } from "../../../icons/custome-icon";

export default function InvitationForm({ data, ...props }) {

  const [spinner, setspinner] = useState(false);
  let [profileData, setProfileData] = useRecoilState(profileState);
  let [firstName] = useInput({
    data: data.firstName,
    status: props.update,
    initial: "",
  });
  let [lastName] = useInput({
    data: data.lastName,
    status: props.update,
    initial: "",
  });
  let [phone] = useInput({
    data: data.phone,
    status: props.update,
    initial: "",
  });
  let [email] = useInput({
    data: data.email,
    status: props.update,
    initial: "",
  });
  let [message] = useInput({
    data: data.message,
    status: props.update,
    initial: "",
  });

  const handleDropDownChange =
    (val, name, multi = false) =>
      (event) => {
        if (multi) {
          if (event.length === 0) {
            val.saveValue("");

            return;
          }
          event = event[0];
        }
        val.saveValue(event.value);
      };

  const handleSubmit = () => {
    setspinner(true);
    let apiData = {
      id: data.id,
      firstName: firstName.value,
      lastName: lastName.value,
      phone: phone.value,
      email: email.value,
      message: message.value,
      role: ROLE_LIST.borrower,
      service: SERVICE_TYPE.invitation
    };
    postApiCallDynamic(
      {
        data: apiData,
        refresh: (res) => {
          return res;
        },

        path: API.teamInvitaion,
        header: props.update && UPDATE
      }
    ).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        props.handleSubmit();
      }
      setspinner(false);
    });
  };

  const handleValidation = (values) => {
    const re = ValidationConstant.emailRegx;

    let errors = {};

    if (firstName.value === "") {
      errors.firstName = "Enter a Name to proceed";
    }

    if (lastName.value === "") {
      errors.lastName = "Enter a Name to proceed";
    }
    if (re.test(String(email.value.trim()).toLowerCase()) === false) {
      errors.email = "Please enter valid Email Id";
    }
    if (message.value === "") {
      errors.message = "Enter Invitation Message to proceed";
    } if (message.value.length > 200) {
      errors.message = "Maximum length is 200"
    }

    if (ValidationConstant.usPhoneNumber.test(String(phone.value)) === false) {
      errors.phone = "Please enter valid Contact Number";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      lastName: lastName.value,
      firstName: firstName.value,
      email: email.value,
      phone: phone.value,
      message: message.value,
    },
    validate: (values) => handleValidation(values),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  return (
    <LoadingOverlay
      active={spinner}

      spinner={true}
      text="Please wait a moment"
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <CustomAutocomplete label={'Search for Name'} />
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <CustomInput
            label={"First Name"}
            required
            size="small"
            fullWidth
            placeholder="Enter the name of the person "
            change={firstName}
            maxLength={MAXLength.name}
            error={formik.errors.firstName}
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <CustomInput
            label={"Last Name"}
            required
            size="small"
            fullWidth
            placeholder="Enter the name of the person "
            change={lastName}
            maxLength={MAXLength.name}
            error={formik.errors.lastName}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <CustomInput
            label={"Phone Number"}
            required
            size="small"
            fullWidth
            placeholder="(655)349-4367"
            sicon={
              <InputAdornment position="start">
                <KSPhoneIcon />
              </InputAdornment>
            }
            type="number"
            change={phone}
            error={formik.errors.phone}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <CustomInput
            label={"Email Id"}
            required
            sicon={<KSMailIcon />}
            size="small"
            fullWidth
            placeholder="Enter recipent Email Id"
            change={email}
            error={formik.errors.email}
            maxLength={MAXLength.email}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <CustomInput
            label={"What can we help you with?"}
            required
            size="large"
            fullWidth
            placeholder="Hello Peter John, we are Inviting you  to apply
            for loan through cliqloan is easier"
            multiline
            rows={5}
            rowsMax={7}
            change={message}
            error={formik.errors.message}
            maxLength={MAXLength.invitationMessage}
          />
        </Grid>

        <Stack direction={'row'} justifyContent={'end'} gap={2}>
          <Button onClick={props.handleClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            {props.update ? "Update" : "Send"}
          </Button>
        </Stack>
      </form>
    </LoadingOverlay>
  );
}
