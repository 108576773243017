import {
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const customStyle = makeStyles({
  ContentHeader: {
    fontFamily: "Inter-Medium !important",
    fontSize: "24px !important",
    color: "#221F1F",
  },

  ContentHeader2: {
    fontFamily: "DMSans-Regular !important",
    fontSize: "24px !important",
    color: "#221F1F",
  },

  ContentBody: {
    fontFamily: "DMSans-Regular !important",
    fontSize: "24px !important",
    color: "#22222290",
    textAlign: "justify",
  },

  ContentUnderline: {
    fontFamily: "DMSans-Regular !important",
    fontSize: "24px !important",
    color: "#221F1F",
    textDecoration: "underline",
  },
});

const listText = [
  "Identifiers (For example, name and email address).",
  "Commercial information (For example, transaction data).",
  "Internet or other network or device activity (For example, browsing history and log data).",
  "Professional or employment-related information (For example, employment history when you apply for a job with us).",
  "Education information (For example, when you apply for a job with us).",
  "Location information (For example, general location information inferred from an IP address), and",
  "Other information that identifies or can be reasonably associated with you.",
];

const listText2 = [
  "To handle and react to your inquiries or to solicit feedback, including via our customer support services;",
  "To process and respond to your inquiries or to request your feedback, including through our customer support services;",
  "For marketing and advertising purposes, including contacting you with information or surveys we feel may be of interest to you about our Website, our goods and services, and third-party products and services;",
  "Internal research, analytics, and reporting, including but not limited to synthesizing and deriving insights from your usage of the Website;",
  "To enhance the content and features of the Website and our other goods or services; to develop or deliver new products or services;",
  "To customize the content you see on the Website;",
  "To manage and troubleshoot the website;",
  "To review and process your employment applications;",
  "To comply with laws, regulations, and other legal processes and procedures;",
  "To establish, exercise, or defend our legal rights; and",
  "To take steps that we reasonably believe are necessary to protect Kramasoft, its employees, service providers, and others safety, security, and rights.",
];

function PrivacyPolicy() {
  const classes = customStyle();
  return (
    <>
      <Box pt={4} id={"privacyPolicy"}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} md={12} px={{ xs: 2 }} mb={6}>
              <Typography variant="h1" color={"primary.main"} my={5}>
                Privacy Policy
              </Typography>

              <Box>
                <Typography className={classes.ContentBody}>
                  Effective/Updated date: December 1, 2022
                </Typography>
                <Typography className={classes.ContentBody}>
                  {`This Privacy Policy ("Policy") defines how Kramasoft LLC. ("Kramasoft," "we," "us," or "our") collects uses and discloses information about users of the Kramasoft website (https://Kramasoft.com/) ("Website"). We also explain your rights and options regarding our use of your information. "You" and "your" refer to the user of our Website for the purposes of these Terms. Your use of our Website is governed by this Policy and our Terms of Service.`}
                </Typography>
                <Typography className={classes.ContentBody}>
                  {`PLEASE CAREFULLY READ THIS PRIVACY POLICY TO UNDERSTAND HOW WE HANDLE YOUR INFORMATION. PLEASE DO NOT USE THE WEBSITE IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY. By using any section of the Website, you agree to the collection, use, and dissemination of the information you give in accordance with the terms of this Policy.`}
                </Typography>
                <Typography className={classes.ContentBody}>
                  {`This Policy does not apply to CliqLoan Platform users. The Kramasoft Platform Privacy Policy governs Kramasoft's usage of data given through the CliqLoan Platform.`}
                </Typography>
                <Typography className={classes.ContentBody} mb={2}>
                  {`If you are a California resident, please see our Notice at Collection for California Residents.`}
                </Typography>
              </Box>

              <Box>
                <Typography mt={6} className={classes.ContentUnderline}>
                  {`(1) What Information Does Kramasoft Collect?`}
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`We acquire information in a variety of ways, including when
                  you supply it directly to us and when we passively collect
                  data from you, such as through your browser or device used to
                  access our Website.`}
                </Typography>
                <Typography className={classes.ContentHeader2}>
                  {`(A) Categories of Information`}
                </Typography>
                <Typography mb={2} className={classes.ContentBody}>
                  {`We may collect the following types of information about you or
                  your use of the Website, as detailed further below and
                  depending on how you use the Website and interact with us:`}
                </Typography>
                <Box>
                  <List
                    sx={{
                      listStyleType: "disc",
                      pl: 3,
                    }}
                  >
                    {listText.map((item) => (
                      <ListItem
                        className={classes.ContentBody}
                        key={`item-${listText}-${item}`}
                        sx={{
                          padding: 0,
                          textAlign: "justify",
                          color: "#22222290",
                          marginBottom: 1,
                          display: "list-item",
                          "&.MuiListItem-root::marker": {
                            color: "#2F80ED",
                          },
                        }}
                      >
                        {item}
                      </ListItem>
                    ))}
                  </List>
                </Box>

                <Typography className={classes.ContentHeader2} my={3}>
                  (B) Categories of Information Sources
                </Typography>
                <Typography className={classes.ContentHeader2}>
                  (i) Information You Provide to Us
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  We may gather information about you when using or accessing
                  the Website, such as when you:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  · Request a demo of or ask about a Kramasoft product or
                  service; <br />
                  · Enter information on the Website (for example, to subscribe
                  to our blog, enter your email address); <br />· Contact us
                  (including via our customer support services or third-party
                  social media sites); or
                </Typography>
                <Typography className={classes.ContentHeader2}>
                  Apply for a job with Kramasoft.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We will gather whatever information you choose to provide us.
                  We normally gather your name, email address, phone number, and
                  information about your contacts with us.
                </Typography>
                <Typography className={classes.ContentHeader2}>
                  (ii) Information Collected Automatically
                </Typography>
                <Typography className={classes.ContentBody}>
                  Device/Usage Information: We may automatically collect certain
                  information about the computer or devices you use to access
                  the Website (including mobile devices or tablets). We may
                  collect and analyze information such as
                </Typography>
                <Typography className={classes.ContentBody}>
                  (a) IP addresses (including city and state information),
                  unique device identifiers, browser types, browser language,
                  operating system, and mobile device carrier information, as
                  further stated below. and
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  (b) information about how you interact with the Website, such
                  as referring and exiting web pages and URLs, platform type,
                  the number of clicks, domain names, pages, and content viewed
                  and the order in which those pages are viewed, statistical
                  information about how you use the Website, error logs, and
                  other similar information. We may utilize third-party
                  analytics providers and technologies, such as cookies and
                  similar techniques, to assist us in gathering this
                  information, as detailed further below.
                </Typography>
                <Typography className={classes.ContentBody}>
                  Cookies and Other Electronic Technologies: We may also collect
                  information about your use of the Website via internet server
                  logs, cookies, and/or tracking pixels. A web server log is a
                  file that records website activities. A cookie is a small text
                  file that is placed on your computer when you visit a website,
                  allowing us to:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  (i) recognize your computer. <br />
                  (ii) store your preferences and settings. <br />
                  (iii) understand the web pages of the Website you have
                  visited. <br />
                  (iv) improve your user experience by delivering content
                  tailored to your interests <br />
                  (v) perform analytics; and (vi) assist with security
                  administrative functions.
                </Typography>
                <Typography className={classes.ContentBody}>
                  Some cookies are stored in the cache of your browser. Tracking
                  pixels (also known as web beacons or clear GIFs) are tiny
                  electronic tags with a unique identifier that are embedded in
                  websites, online advertisements, and/or email and are designed
                  to provide usage information such as page clicks, measure the
                  popularity of the Website and associated content, and access
                  user cookies. We may amend this Policy from time to time when
                  new technologies are developed to collect additional
                  information through different means.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`Please keep in mind that you may alter your settings to be notified when a cookie is placed or modified, or to prevent cookies entirely. For further information, please see your browser's "Help" section (e.g., Internet Explorer; Google Chrome; Mozilla Firefox; or Apple Safari). Please keep in mind that if you disable some or all cookies, you may be unable to access certain Website features or products.`}
                </Typography>

                <Typography className={classes.ContentHeader2}>
                  (iii) Information from Third Parties
                </Typography>
                <Typography className={classes.ContentBody}>
                  Our Website may contain social networking elements such as
                  Facebook or Twitter buttons, as well as interactive
                  mini-programs that operate on our Website. These features may
                  gather your IP address and the page you are now viewing on our
                  Website, as well as establish a cookie to enable the feature
                  to work correctly. Social media tools and widgets are hosted
                  by either a third party or directly on our Website. The
                  privacy statement of the firm that provides these features
                  governs your interactions with them.
                </Typography>
                <Typography className={classes.ContentBody}>
                  We may also get additional information about you from other
                  parties, such as business partners, marketers, researchers,
                  analysts, and others, which we may combine with information
                  acquired directly from you or attribute to you based on your
                  classification into particular statistical groups.
                </Typography>
              </Box>

              <Box>
                <Typography mb={3} mt={6} className={classes.ContentHeader2}>
                  (2) How Will Kramasoft Use My Information?
                </Typography>
                <Typography className={classes.ContentBody}>
                  We and our service providers may use the information we
                  collect from and about you for the following business and
                  commercial reasons (as defined by relevant law):
                </Typography>
                <Box>
                  <List
                    sx={{
                      listStyleType: "disc",
                      pl: 3,
                    }}
                  >
                    {listText2.map((item) => (
                      <ListItem
                        className={classes.ContentBody}
                        key={`item-${listText2}-${item}`}
                        sx={{
                          padding: 0,
                          textAlign: "justify",
                          color: "#22222290",
                          marginBottom: 1,
                          display: "list-item",
                          "&.MuiListItem-root::marker": {
                            color: "#2F80ED",
                          },
                        }}
                      >
                        {item}
                      </ListItem>
                    ))}
                  </List>
                </Box>
                <Typography mb={3} className={classes.ContentBody}>
                  We may also aggregate, de-identify, and/or anonymize any
                  information gathered through the Website so that it cannot be
                  meaningfully linked to you or your device. We may use such
                  aggregated, de-identified, or anonymized data for any purpose,
                  including, but not limited to, research and marketing.
                </Typography>
              </Box>

              <Box>
                <Typography mb={3} mt={6} className={classes.ContentUnderline}>
                  (3) How Does Kramasoft Personalize Content to My Interests?
                </Typography>
                <Typography mb={3} className={classes.ContentUnderline}>
                  Online and Email Analytics
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  On the Website, we may utilize third-party web analytics
                  services such as Google Analytics. These service providers
                  collect information (including your IP address) to help us
                  analyze how users use the Website, including by noting the
                  third-party website from which you arrived, providing certain
                  features to you, improving and developing the Website,
                  monitoring and analyze use the Website, aid our technical
                  administration, assist in our troubleshooting and customizing.
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  Install the Google Analytics Opt-out Browser Add-on to prevent
                  Google Analytics from utilizing your information for
                  analytics. If you obtain a new computer, install a new
                  browser, delete or otherwise change your browser&apos;s cookie
                  file (including updating certain browsers), you may wipe the
                  opt-out cookies and must re-visit the applicable opt-out page.
                </Typography>
                <Typography mb={2} className={classes.ContentBody}>
                  If you receive an email from us, we may use certain tools,
                  such as clear GIFs, to collect data such as when you open our
                  message or click on any links or banners our email contains.
                  This information helps us make our communications with you
                  more interesting and improve our Website.
                </Typography>
              </Box>

              <Box>
                <Typography mb={2} fontWeight={"bold"}>
                  Legal Compliance
                </Typography>
                <Typography textAlign={"justify"} className={classes.ContentBody}>
                  You acknowledge, consent, and agree that Kramasoft may access,
                  preserve, and disclose content you provide us if required by
                  law or if we have a good faith belief that such access,
                  preservation, or disclosure is permitted by our Privacy Policy
                  or is reasonably necessary or appropriate for any of the
                  following reasons:
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  (1) to comply with legal process (e.g., a subpoena, court
                  order, or warrant). <br />
                  (2) to enforce these Terms.
                  <br />
                  (3) to respond to claims that any content violates the rights
                  of third parties.
                  <br />
                  {`(4) to protect Kramasoft, our agents and affiliates, our
                    users, and the public's rights, property, or personal safety.`}
                  <br />
                  (5) to respond to your requests.
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  {`Furthermore, please keep in mind that Kramasoft is not in the
                    business of offering professional services or legal, tax, or
                    other advice through or in connection with this Website.`}
                </Typography>
              </Box>

              <Box>
                <Typography mb={3} mt={6} className={classes.ContentUnderline}>
                  Notice Concerning Do Not Track Signals
                </Typography>
                <Typography className={classes.ContentBody}>
                  Do Not Track (&quot;DNT&quot;) is a privacy setting that users
                  can enable or disable in specific web browsers. We are
                  dedicated to giving you meaningful choices regarding the
                  information gathered through the Website for third-party
                  purposes, which is why we give Google Analytics options. We do
                  not, however, presently recognize or respond to
                  browser-initiated DNT signals. You can learn more about DNT at{" "}
                  <Link
                    href="https://allaboutdnt.com/"
                    underline="hover"
                    target="_blank"
                  >
                    All About Do Not Track
                  </Link>
                  .
                </Typography>
              </Box>

              <Box>
                <Typography
                  my={2}
                  fontWeight={"bold"}
                  textTransform={"uppercase"}
                  className={classes.ContentUnderline}
                >
                  LIMITATION OF LIABILITY
                </Typography>
                <Typography
                  mb={2}
                  textTransform={"uppercase"}
                  textAlign={"justify"}
                  className={classes.ContentBody}
                >
                  TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, KRAMASOFT
                  SHALL NOT BE LIABLE TO YOU OR ANY OTHER PERSON FOR (INCLUDING,
                  WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR
                  OTHERWISE) (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR
                  CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST
                  PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS,
                  OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE
                  AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR (II) THE
                  AMOUNTS PAID BY YOU TO KRAMASOFT IN CONNECTION WITH THE
                  WEBSITE IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS
                  APPLICABLE CLAIM.
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  {`If you have a disagreement with a third party, you accept that
                    Kramasoft is under no duty to intervene on your behalf. You
                    release Kramasoft and its officers, employees, agents, and
                    successors from any and all claims, demands, and damages,
                    known or unknown, suspected or unsuspected, disclosed or
                    undisclosed, arising out of or in any way related to such
                    disputes and/or the Website. If you live in California, you
                    agree to waive California Civil Code §1542, which states that
                    "a general release does not extend to claims which the
                    creditor does not know or suspect to exist in his favor at the
                    time of executing the release, which if known by him must have
                    materially affected his settlement with the debtor." Others'
                    criminal deeds are included in this publication.`}
                </Typography>

                <Typography mb={1} className={classes.ContentUnderline}>
                  Subsidiaries and affiliates
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We may disclose information collected from you to current and
                  future affiliates and subsidiaries of the Kramasoft group of
                  companies.
                </Typography>

                <Typography mb={1} className={classes.ContentUnderline}>
                  Service Providers and Other Third-Parties.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We may give access to or share your information with selected
                  third parties that perform services on our behalf, such as
                  marketing, market research, customer service, data storage,
                  analysis, processing, and legal services.
                </Typography>

                <Typography mb={1} className={classes.ContentUnderline}>
                  Protection of Kramasoft and Others
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  By using the Website, you acknowledge, consent, and agree that
                  we may access, preserve, and disclose your information as
                  required by law or in the good faith belief that such access,
                  preservation, or disclosure is reasonably necessary to
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  (a) comply with legal process. <br />
                  (b) enforce our Terms of Use, this Policy, or other contracts
                  with you, including investigation of potential violations
                  thereof. or <br />
                  (c) respond to claims that any content violates the rights of
                  third parties; <br />
                  (d) respond to your requests for customer service; and/or{" "}
                  <br />
                  (e) protect the rights, property, or personal safety of
                  Kramasoft, its agents and affiliates, and/or its users and/or
                  the public. This involves exchanging information with other
                  businesses and organizations for the sake of fraud prevention,
                  spam/malware prevention, and other similar goals. <br />
                </Typography>

                <Typography mb={1} className={classes.ContentUnderline}>
                  Business Transfers
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`We may purchase or combine with other firms as our business grows. Your information may be among the transferred assets in such transactions (including in the course of such transactions, e.g., due diligence). If a portion or all of Kramasoft's assets are sold or transferred to a third party as part of a corporate purchase, sale, or merger, your information may be one of the transferred company assets.`}
                </Typography>

                <Typography mb={1} className={classes.ContentUnderline}>
                  Consent
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We may also share your information with third parties with
                  your permission. Kramasoft may also share aggregated,
                  de-identified, and/or anonymized information with third
                  parties for any purpose, including, but not limited to,
                  advertising and promotional partners.
                </Typography>
              </Box>

              <Box>
                <Typography mb={1} mt={6} className={classes.ContentUnderline}>
                  (5) How Does Kramasoft Protect My Information?
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We use technological, administrative, and physical safeguards
                  to secure your data against accidental or illegal destruction,
                  loss, modification, unauthorized disclosure, or access. By
                  using our Website, you recognize and agree that we cannot
                  guarantee the security of any information you submit to,
                  through, or on our Website, and that any such communication is
                  done at your own risk. If you have any concerns concerning the
                  security of your data, please contact us at hr@Kramasoft.com.
                </Typography>
              </Box>

              <Box>
                <Typography mt={6} className={classes.ContentUnderline}>
                  (6) What Am I Entitled To?
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We and our service providers may share the above-mentioned
                  categories of information with the third-party categories
                  listed in this section.
                </Typography>

                <Typography mb={1} className={classes.ContentUnderline}>
                  (A) Getting to, Updating, and Deleting Your Data.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  In line with current legal requirements, you may be able to
                  view, update, or remove some information we store on you.
                  Please email us at hr@Kramasoft.com if you want to seek access
                  to or deletion of your information. We will react to your
                  inquiry as soon as possible.
                </Typography>

                <Typography mb={1} className={classes.ContentUnderline}>
                  (B) Marketing Communications and Sharing.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  By emailing us at hr@Kramasoft.com, you can instruct us not to
                  use your contact information to contact you with marketing
                  messages about products, services, promotions, and special
                  events that may be of interest to you. You may also opt out of
                  commercial email communications by following the instructions
                  at the bottom of such emails. Please keep in mind that,
                  regardless of your request, we may continue to use and
                  distribute some information in accordance with this Policy or
                  as required by applicable law. You cannot, for example, opt
                  out of some operational emails, such as those pertaining to
                  our relationship or transactions with you.
                </Typography>

                <Typography mb={1} className={classes.ContentUnderline}>
                  (C) Nevada Residents.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`Certain Nevada customers may opt out of the sale of "personally identifiable information" for a monetary benefit to a person in exchange for that person licensing or selling such information to further individuals under Nevada law. "Personally identifiable information" comprises a person's first and last name, address, email address, phone number, Social Security Number, or any other identifier that enables a specific individual to be reached physically or online. We do not engage in such activity; but, if you are a Nevada resident who has purchased or leased products or services from us, you may email hr@Kramasoft.com to seek to opt-out of any potential future sales under Nevada law. Please keep in mind that we will take reasonable efforts to verify your identity and the request's legitimacy. Once verified, we will maintain your request in the event our practices change.`}
                </Typography>

                <Typography mb={1} className={classes.ContentUnderline}>
                  (D) California Residents.
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`This part of the Policy only applies to residents of the state of California ("consumers" or "you"). This statement is provided in accordance with the California Consumer Privacy Act of 2018 ("CCPA") and other California privacy laws. Any terminology used in this Notice that is not specified in the Notice is defined under the CCPA and its accompanying rules.`}
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (i) Notice at Collection for California Residents
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  {`(a) What Information We Collect: We collect the information categories listed above in the Policy's "What Information Does Kramasoft Collect?" section. The specific information we gather about you is determined by the products/services you use as well as how you use our Website and interact with us. The information in this declaration is based on our data-collecting activities from the preceding 12 months to the present.`}
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  {`(b) How We Utilize Your Information: We use your information for the business or commercial objectives indicated above in the part of the Policy titled "How Will Kramasoft Use My Information." What products/services you use, as well as how you use our Website and interact with us, determine how we utilize your information. The information in this declaration is based on our data-collecting activities from the preceding 12 months to the present.`}
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  (c) Kramasoft does not “sell” your personal information as
                  defined by the California Consumer Privacy Act and its
                  implementing regulations
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`(d) Privacy Policy: California law requires us to disclose the following information in our Privacy Policy, among other things: (1) the information we collect about you, (2) the categories of sources from which we collect information about you, and (3) the business or commercial purposes for which we use the information we collect about you. To access this information, please check the sections above in the Policy under "What Information Does Kramasoft Collect," "Categories of Information Sources," and "How Will Kramasoft Use My Information."`}
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (ii) Right to Know:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`California law gives California residents the right to request that certain covered businesses provide them with I the categories of personal information we collect, use, disclose, or sell about you; (ii) the categories of sources of such information; (iii) the business or commercial purpose for collecting or selling your personal information (as applicable); and (iv) the categories of personal information we sell, have sold, or disclosed for a business purpose about the consumer and, (v) specific pieces of personal information that a business has collected about the consumer. Certain limitations apply to this "Right to Know." Furthermore, Kramasoft is not required to respond to requests for the Right to Know from users acting as an employee, owner, director, officer, or contractor of another entity or within the context of another entity in conducting due diligence regarding, or providing or receiving a product or service to or from such company.`}
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (iii) Right to Delete:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`California law gives California residents the right to request that some covered entities remove personal information obtained from them. Certain caveats apply to this "Right to Delete." Furthermore, Kramasoft is not required to respond to requests for the Right to Know made by users acting as an employee, owner, director, officer, or contractor of another entity, or in the context of another entity conducting due diligence on, or providing or receiving a product or service from, such company. Please keep in mind that we require certain sorts of information in order to offer you our services. If you request that your information be deleted and we are forced to do so, you may no longer be able to access it or use the services we provide.`}
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (iv) Kramasoft does not sell your data:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`While the CCPA gives California residents the right to opt-out of the "sale" of their personal information, Kramasoft has not "sold" consumer personal information in the preceding 12 months and does not "sell" your personal information as defined by the California Consumer Privacy Act and its implementing regulations. Furthermore, Kramasoft is unaware that it sells the personal information of kids under the age of 16.`}
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (v) Authorized Agent:
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  When a consumer employs an authorized agent to make a request
                  to know or a request to delete, a company may ask the consumer
                  to perform the following (this does not apply if the consumer
                  has granted the authorized agent power of attorney in
                  accordance with Probate Code sections 4000 to 4465):
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  i. Obtain signed authorization from the authorized agent.
                  <br />
                  ii. Directly verify their own identity with the firm.
                  <br />
                  iii. Confirm with the company that they gave the authorized
                  agent authorization to submit the request.
                  <br />
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  A firm may decline a request from an authorized agent who
                  fails to provide documentation that the customer has allowed
                  them to act on their behalf. To submit an authorized request
                  on behalf of another individual under California law, please
                  go to https://Kramasoft.com/policies/personal-data-requests/.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (vi) Additional Rights:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  California residents have the right, under California Civil
                  Code Sections 1798.83-1798.84, to request from certain covered
                  businesses a notice (a) identifying the categories of personal
                  information (as defined by California law) that are shared
                  with third parties for their own marketing purposes and (b)
                  providing contact information for such third parties.
                  Kramasoft, on the other hand, does not share such personal
                  information for these reasons, as stated in our Privacy
                  Policy.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (vii) Financial Incentives:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We do not provide monetary compensation for the collection of
                  your data. If this changes, we will contact you and ask your
                  permission to participate in this program before enrolling
                  you.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (vii) Financial Incentives:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We do not provide monetary compensation for the collection of
                  your data. If this changes, we will contact you and ask your
                  permission to participate in this program before enrolling
                  you.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (viii) Non-discrimination Rights:
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  You also have the right not to face discrimination if you
                  exercise some of your CCPA rights. We will not penalize you if
                  you utilize your CCPA:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  i. Refuse to provide you with products or services.
                  <br />
                  ii. Charge you various prices or rates for products or
                  services, including via the provision of discounts or other
                  advantages or the imposition of penalties.
                  <br />
                  iii. Offer you a higher or lower level of goods or services.
                  <br />
                  iv. Suggest that you may be charged a different price or rate
                  for products or services, or that the level or quality of
                  goods or services be different.
                  <br />
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (ix) How additional data privacy regulations may influence
                  your capacity to seek data access or deletion:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  Please keep in mind that your rights and choices about your
                  personal information are frequently governed by different
                  laws. Personal information gathered under other statutes, such
                  as the Fair Credit Reporting Act, the Grammy-Leach-Bliley Act,
                  and the California Financial Information Privacy Act, may be
                  excluded from access or deletion requests.
                </Typography>

                <Typography mb={1} className={classes.ContentHeader2}>
                  (x) Contact us:
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  To exercise any of these rights, please send an email to
                  privacy@Kramasoft.com or go to
                  https://Kramasoft.com/policies/personal-data-requests/. Before
                  we can complete your request, we will need to verify your
                  identity. You can also appoint an authorized representative to
                  make a request on your behalf. To do so, you must give us a
                  signed power of attorney or other consent for the agent to act
                  on your behalf. You will still need to verify your
                  identification with us directly. If you have any questions
                  about your legal rights in California, please contact us at
                  privacy@Kramasoft.com.
                </Typography>
              </Box>

              <Box>
                <Typography mb={1} mt={6} className={classes.ContentUnderline}>
                  {`(7) What Is Kramasoft's Obligation Regarding Third-Party Links on the Website?`}
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  {`The Website may include connections to or "from" third-party websites, programs, and other services that are accessible to support the operation and functioning of the Website. Please keep in mind that we are not responsible for the privacy policies of other websites or services. If you provide information to any of those websites, your information is subject to their privacy policies. We advise our users to be careful when they browse third-party websites and/or leave the Website and to read the privacy policies of each site they visit that collects information about them.We and our service providers may share the above-mentioned categories of information with the third-party categories listed in this section.`}
                </Typography>
              </Box>

              <Box>
                <Typography fontWeight={"bold"} className={classes.ContentHeader}>
                  MANDATORY ARBITRATION AND CLASS ACTION WAIVER
                </Typography>
                <Typography fontWeight={"bold"} className={classes.ContentHeader}>
                  (PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL
                  RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT)
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  Application: You and Kramasoft agree that these Terms impact
                  interstate commerce and that the interpretation and
                  enforcement of these arbitration clauses are governed by the
                  Federal Arbitration Act. This Section 11 is intended to be
                  broadly interpreted and governs any and all disputes between
                  us, including but not limited to claims arising out of or
                  relating to any aspect of our relationship, whether based on
                  contract, tort, statute, fraud, misrepresentation, or any
                  other legal theory; claims that arose prior to these Terms or
                  any prior agreement (including, but not limited to, claims
                  related to advertising); and claims that may arise after the
                  termination of these Terms or any prior agreement. The only
                  issues exempt from this wide restriction are those involving
                  intellectual property and modest court claims, as specified
                  below.
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  Initial Dispute Resolution: Most disagreements may be settled
                  without resorting to arbitration. If you have an issue with
                  us, you agree to contact us at hr@Kramasoft.com and provide a
                  brief written summary of the dispute as well as your contact
                  information before initiating any official action (including
                  your username, if your dispute relates to a Platform account).
                  Except for intellectual property and small claims court
                  claims, the parties agree to use their best efforts to resolve
                  any dispute, claim, question, or disagreement directly through
                  consultation with Kramasoft, and good faith negotiations shall
                  be a condition precedent to either party filing a lawsuit or
                  initiating the arbitration.
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  {`Binding Arbitration: If the parties do not reach an
                    agreed-upon solution within a period of thirty (30) days from
                    the time informal dispute resolution is initiated under the
                    Initial Dispute Resolution provision above, then either party
                    may initiate binding arbitration as the sole means to resolve
                    claims, (except as provided in the “Exception: Litigation of
                    Intellectual Property and Small Claims Court Claims”
                    subsection below) subject to the terms set forth below.
                    Specifically, all claims arising out of or relating to these
                    Terms (including the Terms’ formation, performance, and
                    breach), the parties' relationship with each other, and/or
                    your use of Kramasoft shall be finally settled by binding
                    arbitration administered by JAMS in accordance with the JAMS
                    Streamlined Arbitration Procedure Rules for claims that do not
                    exceed $250,000 and the JAMS Comprehensive Arbitration Rules
                    and Procedures for claims exceeding $250,000 in effect at the
                    time the arbitration is initiated, excluding any rules or
                    procedures governing or permitting class actions.`}
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  {`Arbitrator’s Powers: The arbitrator, rather than any federal,
                    state, or local court or agency, shall have sole authority to
                    resolve all disputes arising out of or relating to the
                    interpretation, applicability, enforceability, or formation of
                    these Terms, including, but not limited to, any claim that all
                    or any part of these Terms is void or voidable, whether a
                    claim is subject to arbitration, or the question of waiver by
                    litigation conduct. The arbitrator shall have the authority to
                    give any relief available in a court of law or equity. The
                    arbitrator's award must be in writing, binding on the parties,
                    and may be entered as a judgment in any court of competent
                    jurisdiction.`}
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  {`Filing a Demand: To start an arbitration, you must do the
                    following: (a) Write a Demand for Arbitration that includes a
                    description of the claim and the number of damages you seek to
                    recover (you may find a copy of a Demand for Arbitration at
                    www.jamsadr.com); (b) Send three copies of the Demand for
                    Arbitration, plus the appropriate filing fee, to JAMS, 2
                    Embarcadero Center, Suite 1500, San Francisco, California
                    94111; and (c) send one copy of the Demand for Arbitration to
                    us at: ATTN: Legal, Kramasoft LLC. If the filing fee for the
                    arbitration exceeds the cost of bringing a case, Kramasoft
                    will pay the difference. If the arbitrator rules that the
                    arbitration was not frivolous, Kramasoft will pay the JAMS
                    fees, including filing fees, arbitrator, and hearing expenses.
                    Unless the arbitration rules and/or relevant legislation
                    permit otherwise, you are liable for your own attorneys'
                    expenses.`}
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  {`Class Action Waiver: The parties further agree that the
                    arbitration will take place only in the parties' individual
                    capacities, rather than as a class or other representative
                    action, and the parties explicitly waive their right to file a
                    class action or seek relief on a class basis. YOU AND
                    KRAMASOFT AGREE THAT YOU AND KRAMASOFT MAY BRING CLAIMS
                    AGAINST EACH OTHER ONLY IN YOUR OR OUR INDIVIDUAL CAPACITY,
                    AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
                    OR REPRESENTATIVE PROCEEDING. If a court or arbitrator rules
                    that the class action waiver set forth in this paragraph is
                    void or unenforceable for any reason, or that arbitration can
                    proceed on a class basis, then the arbitration provisions set
                    forth above are null and void in their entirety, and the
                    parties are deemed not to have agreed to arbitrate disputes.`}
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  {`Exception: Litigation of Intellectual Property and Small
                    Claims Court Claims: Regardless of the parties' desire to
                    settle all issues through arbitration, either party may pursue
                    enforcement proceedings, validity decisions, or claims
                    originating from or pertaining to intellectual property theft,
                    piracy, or unauthorized use in state or federal court or in
                    the U.S. To defend its intellectual property rights, the
                    Patent and Trademark Office ("intellectual property rights"
                    include patents, copyrights, moral rights, trademarks, and
                    trade secrets, but not privacy or publicity rights). Either
                    party may also seek remedy in a small claims court for
                    disputes or claims that are within the jurisdiction of that
                    court.`}
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  {`30 - Day Right to Opt Out: You may opt-out of the arbitration
                    and class action waiver provisions set out above by giving
                    written notification of your choice to hr@Kramasoft.com with
                    the subject line, "ARBITRATION AND CLASS ACTION WAIVER
                    OPT-OUT." The notification must be delivered within thirty
                    (30) days of the later of (a) the Effective Date of these
                    Terms or (b) your first date of use of the Website that
                    contained any versions of the Terms that included this version
                    of the mandatory arbitration and class action waiver.
                    Otherwise, you must arbitrate conflicts in line with the
                    requirements of these paragraphs. Kramasoft will not be bound
                    by these arbitration terms if you choose not to use them.`}
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  {`Changes to This Section: Any modifications to this section
                    will be posted on the Website thirty (30) days in advance.
                    Amendments shall take effect thirty (30) days after being
                    posted on the Website. Otherwise, changes to this provision
                    will apply prospectively only to claims filed after the
                    thirty-first (30th) day. If a court or arbitrator rules that
                    this subsection on "Changes to This Section" is unenforceable
                    or invalid, it will be severed from the section entitled
                    "Mandatory Arbitration and Class Action Waiver," and the court
                    or arbitrator will apply the first Mandatory Arbitration and
                    Class Action Waiver section in existence after you started
                    using the Website.`}
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  Survival. This Mandatory Arbitration and Class Action Waiver
                  section shall survive any termination of your use of the
                  Website.
                </Typography>
              </Box>

              <Box>
                <Typography mb={1} mt={6} className={classes.ContentUnderline}>
                  (9) Retention of Your Information
                </Typography>
                <Typography mb={3} className={classes.ContentBody}>
                  We store your information for no longer than is necessary for
                  processing purposes and/or to comply with relevant laws. The
                  amount of time we keep information depends on the purposes for
                  which it was acquired and used.
                </Typography>
              </Box>

              <Box>
                <Typography mb={2} fontWeight={"bold"} className={classes.ContentHeader}>
                  General Terms
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  {`Force Majeure: In no event shall Kramasoft or our licensor or
                    supplier be liable for any delay or failure in performance
                    caused directly or indirectly by an event beyond our
                    reasonable control.`}
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  {`No Waiver; Severability: No waiver of any provision of these
                    Terms will be construed as a subsequent or continuing waiver
                    of such term or any other term, and Kramasoft's omission to
                    assert or enforce any right or remedy under these Terms does
                    not waive such right or remedy. If an arbitrator or a court of
                    competent jurisdiction rules that any provision of these Terms
                    is invalid, the parties agree that the court should endeavor
                    to give effect to the parties' intentions as reflected in the
                    provision, to the maximum extent permitted by law, and the
                    other provisions of these Terms will remain in full force and
                    effect.`}
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  {`Miscellaneous: These Terms (and all terms and conditions
                    incorporated herein) are the entire agreement between you and
                    Kramasoft and govern your use of the Website and the products
                    and services provided by Kramasoft through and in connection
                    with the Website, superseding any prior agreements between you
                    and Kramasoft on the subject matter. You may not assign or
                    delegate these Terms or any rights or licenses granted herein.
                    Kramasoft may freely assign or delegate these Terms and any
                    rights or licenses granted herein. These Terms bind and
                    benefit each party, as well as the party's successors and
                    allowed assignee. A spoken remark by a Kramasoft
                    representative does not modify these Terms These Terms do not
                    intend or create any agency, partnership, joint venture, or
                    employee-employer relationship. You agree that any agreements
                    formed by and between you and us in electronic form are
                    legally binding in the same way that physical paper agreements
                    are. The drafter will not be held liable under these Terms.
                    The section headings in these Terms are just for convenience
                    and have no legal or contractual significance. Except for New
                    Jersey residents, you agree that, regardless of any statute or
                    law to the contrary, any claim or cause of action arising out
                    of or related to the use of the Website and/or these Terms
                    must be filed within one (1) year of the occurrence of such
                    claim or cause of action or be forever barred.`}
                </Typography>
                <Typography mb={2} textAlign={"justify"} className={classes.ContentBody}>
                  {`Notices: We may provide you notice by posting a notice on the
                    Website or in any other way we deem appropriate, and such
                    notice will be effective upon delivery. If you provide us with
                    notice, it will be effective when received, and you must send
                    it to hr@Kramasoft.com.`}
                </Typography>
              </Box>

              <Box>
                <Typography mb={1} mt={6} className={classes.ContentUnderline}>
                  (11) Will Kramasoft Modify This Policy?
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  We retain the right to modify this Policy at any time to
                  reflect changes in the law, our data collecting and use
                  policies, Website features, or technological improvements.
                  Please visit this page for updates on a regular basis. Your
                  continued use of the Website after modifications to this
                  Policy are posted indicates that you accept such changes. If
                  our data privacy practices change materially, we will notify
                  you in compliance with any legal requirements.
                </Typography>
                <Typography
                  mb={1}
                  fontSize={"24px"}
                  fontFamily={"DMSans-Italic"}
                  color={"#22222295"}
                  fontWeight={"bold"}
                >
                  What Should I Do If I Have Concerns About This Privacy
                  Statement?
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  If you have any questions or issues about our Policy, please
                  contact us at privacy@Kramasoft.com and we will do our best to
                  address them.
                </Typography>
                <Typography mb={1} className={classes.ContentBody}>
                  Kramasoft LLC.
                  <br />
                  San Francisco, CA
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default PrivacyPolicy;
