import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { withRouter } from "./helpers/with-router";
import { Auth } from "./auth/auth";
import { configureAxios } from "./auth/config";
import IdelTimerContainer from "./helpers/idel-timer";

import { Path } from "./routes/route-config";
import Signup from "./pages/signup/signup";
import SetPassword from "./pages/set-password/set-password";
import ForgotPassword from "./pages/forget-password/forget-password";
import SignIn from "./pages/login/login";
import OTP from "./pages/otp/otp";
import BrokerIndex from "./pages/broker/index";
import InvitationPage from "./pages/broker/invitation/invitation";
import NotFound from "./pages/not-found/not-found";
import Landing from "./pages/landing";
import Settings from "./pages/settings/settings";
import InvitationReg from "./pages/invitation-reg/invitaion-reg";
import InvitationRegForm from "./pages/invitation-reg/invitation-register-form";
import RequireAuth from "./auth/requireAuth";
import Unauthorized from "./pages/unauthorized";
import LoansBorrower from "./pages/borrower/loans";
import DemoRequest from "./pages/admin/demo-request/demo-request";
import Support from "./pages/admin/support/support";
import Notes from "./pages/admin/note/notes";
import Leads from "./pages/broker/leads/leads";
import CredcoCredentials from "./pages/broker/credco/credco-credentials";
import BorrowerInvitations from "./pages/borrower/invitations";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import LoanList from "./pages/broker/loan/loan-list";
import GettingStarted from "./pages/borrower/loan-application/loan-pages/getting-started/geting-started";
import CoBorrowerStatus from "./pages/borrower/loan-application/loan-pages/getting-started/co-borrower";
import PartnerCoBorrowerSet from "./pages/borrower/loan-application/loan-pages/getting-started/partner-co-borrower-set";
import LoanDetails from "./pages/borrower/loan-application/loan-pages/loan-details/loan-details";
import LoanPersonalInfo from "./pages/borrower/loan-application/loan-pages/borrower-info/personal/personal-info";
import LoanContactInfo from "./pages/borrower/loan-application/loan-pages/borrower-info/loan-contact-info";
import LoanDemographics from "./pages/borrower/loan-application/loan-pages/borrower-info/demographics/demographics";
import Welcome from "./pages/admin/welcome";
import ApplyRedirect from "./pages/signup/apply-redirect";
import LoanDeclarations from "./pages/borrower/loan-application/loan-pages/borrower-info/declaration/declaration";
import LoanDependent from "./pages/borrower/loan-application/loan-pages/borrower-info/dependent/dependent";
import LoanEmployment from "./pages/borrower/loan-application/loan-pages/borrower-info/employment/loan-employment";
import LoanMilitaryService from "./pages/borrower/loan-application/loan-pages/borrower-info/military-service/military-service";
import LoanAssetsCredits from "./pages/borrower/loan-application/loan-pages/assets-credits/assets-credits";
import LoanRealEstate from "./pages/borrower/loan-application/loan-pages/real-estate/real-estate";
import LoanCreditReport from "./pages/borrower/loan-application/loan-pages/credit-report/credit-report";
import LoanCreditReportStatus from "./pages/borrower/loan-application/loan-pages/credit-report/credit-report-status";
import LoanLiabilities from "./pages/borrower/loan-application/loan-pages/liabilities/liabilities";
import LoanGuidedDocument from "./pages/borrower/loan-application/loan-pages/documents/guided-document";
import LoanAddditionalDocument from "./pages/borrower/loan-application/loan-pages/documents/additional-document/additional-document";
import LoanReview from "./pages/borrower/loan-application/loan-pages/review/loan-review";
import LoanSubmit from "./pages/borrower/loan-application/loan-pages/submit/loan-submit";
import LoanLayout from "./components/loan-application/loan-templates/loan-laout";
import Preapproval from "./pages/broker/leads/pre-approval/pre-approval";
import Quote from "./pages/broker/leads/quote/quote";
import AdditionalLoanContact from "./pages/broker/loan/additional-loan-contact/additional-loan-contact";
import LoanNotes from "./pages/broker/loan/note/loan-notes";
import LoanSummaryVisiblity from "./components/loan/loan-summary/loan-summary-visiblity";
import LoanDocument from "./pages/borrower/loan-application/loan-pages/loan-document/loan-document";
import DocumentLibrary from "./pages/admin/document-library/DocumentLibrary";
import TaskType from "./pages/admin/document-library/TaskType";

function Index(props) {
  if (props.isAuthenticated) {
    if (
      props.user.data.role === "Broker" ||
      props.user.data.role === "MLO Owner"
    ) {
      return <Navigate to={Path.broker} />;
    }
    if (props.user.data.role === "Admin") {
      return <Navigate to={Path.admin} />;
    }

    if (props.user.data.role === "Borrower") {
      return <Navigate to={Path.borrower} />;
    }

    if (props.user.data.role === "MLO") {
      return <Navigate to={Path.mlo} />;
    }

    if (props.user.data.role === "MLP") {
      return <Navigate to={Path.mlp} />;
    }
  }

  return <Navigate to={Path.landing} />;
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      user: "",
    };

    this.sessionClear = () => {
      localStorage.setItem(process.env.REACT_APP_USER_SESSION, "signout");
      configureAxios({ authToken: null, authCallback: this.authCallback });

      this.userHasAuthenticated(false);
      this.props.history.push(Path.landing);
    };

    this.logout = async () => {
      localStorage.setItem(process.env.REACT_APP_USER_SESSION, "signout");
      await Auth.signout();
      configureAxios({ authToken: null, authCallback: this.authCallback });

      this.userHasAuthenticated(false);
      this.props.history(Path.landing);
    };

    this.authCallback = (response) => {
      localStorage.removeItem(process.env.REACT_APP_SESSION);
      localStorage.setItem(process.env.REACT_APP_USER_SESSION, "signout");
    };

    Auth.setAuthCallback(this.authCallback);

    let result = Auth.currentSession();
    configureAxios({
      authToken: result == null ? null : result.access_token,
      authCallback: this.authCallback,
    });
  }

  UNSAFE_componentWillMount() {
    try {
      let result = Auth.currentSession();
      if (result) {
        this.userHasAuthenticated(true, result);
      }
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }
  }

  userHasAuthenticated = (authenticated, data = "") => {
    this.setState({ isAuthenticated: authenticated, user: data });
  };

  handleLogout = (event) => {
    this.logout();
  };

  render() {
    //production cancel out methods
    if (process.env.NODE_ENV === "production") {
      const noop = () => {
        // do nothing
      };
      [
        "assert",
        "clear",
        "count",
        "debug",
        "dir",
        "dirxml",
        "error",
        "exception",
        "group",
        "groupCollapsed",
        "groupEnd",
        "info",
        "log",
        "markTimeline",
        "profile",
        "profileEnd",
        "table",
        "time",
        "timeEnd",
        "timeline",
        "timelineEnd",
        "timeStamp",
        "trace",
        "warn",
      ].forEach((method) => {
        window.console[method] = noop;
      });
    }

    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      userHasAuthenticated: this.userHasAuthenticated,
      handleLogout: this.handleLogout,
      sessionClear: this.sessionClear,
    };

    const guestPaths = [
      Path.signin,
      Path.signup,
      Path.forget,
      Path.otp,
      Path.mloWebsiteApply,
      Path.setPassword,
      Path.landing,
      `/${Path.invitation}`,
      Path.invitationReg,
      `/test`,
    ];
    if (this.state.isAuthenticated) {
      if (guestPaths.includes(this.props.location.pathname)) {
        const { state } = this.props.location;
        if (state?.initialLocation) {
          return (
            <Navigate
              to={
                state.initialLocation.pathname +
                state.initialLocation.search +
                state.initialLocation.hash
              }
              state={state.initialLocation.state}
            />
          );
        }
        if (state?.website) {
          return (
            <Navigate
              to={`${Path.loanFormApply}${this.state.user.data.formId}`}
            />
          );
        } else {
          return <Navigate to={Path.index} />;
        }
      }
    } else {
      if (!guestPaths.includes(this.props.location.pathname)) {
        if (this.props.location.pathname === Path.index) {
          return (
            <Navigate
              to={Path.landing}
              state={{ initialLocation: this.props.location }}
              replace
            />
          );
        }
        return (
          <Navigate
            to={Path.signin}
            state={{ initialLocation: this.props.location }}
            replace
          />
        );
      }
    }

    return (
      <Suspense fallback={<></>}>
        <div className="App ">
          <LoadingOverlay
            active={this.props.globalSpinnerState}
            // spinner={<BounceLoader />}
            spinner={true}
            text="Please wait a moment"
          >
            <IdelTimerContainer props={childProps} />
            <Routes>
              <Route
                path={Path.index}
                element={<Index {...this.props} {...childProps} />}
              />

              <Route
                path={Path.landing}
                element={<Landing {...this.props} />}
              />
              <Route
                path={Path.signin}
                element={<SignIn {...this.props} {...childProps} />}
              />
              <Route path={Path.forget} element={<ForgotPassword />} />
              <Route
                path={Path.otp}
                element={<OTP {...this.props} {...childProps} />}
              />
              <Route
                path={Path.setPassword}
                element={<SetPassword {...this.props} {...childProps} />}
              />
              <Route path={Path.invitation} element={<InvitationReg />} />

              <Route
                path={Path.mloWebsiteApply}
                element={<ApplyRedirect {...this.props} {...childProps} />}
              />
              <Route path={Path.invitationReg}>
                <Route index element={<InvitationRegForm />} />
              </Route>
              <Route path={Path.signup} element={<Signup />} />

              {/* Admin Routing Starts */}
              <Route
                element={
                  <RequireAuth
                    allowedRoles={["Admin"]}
                    {...childProps}
                    {...this.props}
                  />
                }
              >
                <Route
                  path={Path.admin}
                  element={<BrokerIndex {...this.props} {...childProps} />}
                >
                  <Route index element={<Navigate to={Path.welcome} />} />

                  <Route
                    path={Path.welcome}
                    element={<Welcome {...this.props} {...childProps} />}
                  />

                  <Route
                    path={Path.demoRequest}
                    element={<DemoRequest {...this.props} {...childProps} />}
                  />

                  <Route
                    path={Path.demoRequest + "/:id/" + Path.notes}
                    element={<Notes {...this.props} {...childProps} />}
                  />
                  <Route
                    path={Path.support}
                    element={<Support {...this.props} {...childProps} />}
                  />
                  <Route
                    path={Path.documentLibrary}
                    element={<DocumentLibrary {...this.props} {...childProps} />}
                  />
                  <Route
                    path={Path.taskType}
                    element={<TaskType {...this.props} {...childProps} />}
                  />

                  <Route path={Path.setting}>
                    <Route index element={<Navigate to={"profile"} />} />
                    <Route
                      path=":id"
                      element={<Settings {...this.props} {...childProps} />}
                    ></Route>
                  </Route>
                </Route>
              </Route>
              {/* Admin Routing Ends */}

              {/* Broker Routing Starts */}
              <Route
                element={
                  <RequireAuth
                    allowedRoles={["Broker", "MLO Owner"]}
                    {...childProps}
                    {...this.props}
                  />
                }
              >
                <Route
                  path={Path.broker}
                  element={<BrokerIndex {...this.props} {...childProps} />}
                >
                  <Route index element={<Navigate to={Path.welcome} />} />

                  <Route
                    path={Path.welcome}
                    element={<Welcome {...this.props} {...childProps} />}
                  />
                  <Route
                    path={Path.invitationList}
                    element={<InvitationPage {...this.props} {...childProps} />}
                  />

                  <Route
                    path={Path.leads}
                    element={<Leads {...this.props} {...childProps} />}
                  />
                  <Route
                    path={Path.credcoCredentials}
                    element={
                      <CredcoCredentials {...this.props} {...childProps} />
                    }
                  />

                  <Route
                    path={Path.leads + "/" + Path.quoteForm + "/:id"}
                    element={<Quote {...this.props} {...childProps} />}
                  />

                  <Route
                    path={Path.leads + "/" + Path.preapprovalForm + "/:id"}
                    element={<Preapproval {...this.props} {...childProps} />}
                  />

                  <Route path={Path.loanApplication}>
                    <Route
                      index
                      element={<LoanList {...this.props} {...childProps} />}
                    />
                    <Route
                      path={":id/" + Path.additionalLoanContact}
                      element={<AdditionalLoanContact />}
                    />
                    <Route
                      path={":id/" + Path.loanNotes}
                      element={<LoanNotes {...this.props} {...childProps} />}
                    />

                    <Route
                      path={Path.loanSummary + "/:id"}
                      element={
                        <LoanSummaryVisiblity {...this.props} {...childProps} />
                      }
                    />
                    <Route
                      path=":id"
                      element={<LoanLayout {...this.props} {...childProps} />}
                    >
                      <Route
                        index
                        element={<Navigate to={Path.gettingStarted} />}
                      />
                      {/* <Route path={"geting-started"} element={<GettingStarted {...this.props} {...childProps} />} /> */}
                      <Route path={Path.gettingStarted}>
                        <Route
                          index
                          element={
                            <GettingStarted {...this.props} {...childProps} />
                          }
                        />
                        <Route
                          path={Path.gettingStartedCoBorrower}
                          element={
                            <CoBorrowerStatus {...this.props} {...childProps} />
                          }
                        />
                        <Route
                          path={Path.confirmPartnerCoBorrower}
                          element={
                            <PartnerCoBorrowerSet
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                      </Route>

                      <Route path={Path.loanDetails}>
                        <Route
                          index
                          element={
                            <LoanDetails {...this.props} {...childProps} />
                          }
                        />
                      </Route>
                      {[Path.loanBorrowerInfo, Path.loanPartnerInfo].map(
                        (path) => {
                          return (
                            <Route path={path} key={path}>
                              <Route
                                path={Path.loanPersonalInfo}
                                element={
                                  <LoanPersonalInfo
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanContactInfo}
                                element={
                                  <LoanContactInfo
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanDemographics}
                                element={
                                  <LoanDemographics
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanDeclarations}
                                element={
                                  <LoanDeclarations
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanDependent}
                                element={
                                  <LoanDependent
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanEmployment}
                                element={
                                  <LoanEmployment
                                    key={window.location.pathname}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanMilitaryService}
                                element={
                                  <LoanMilitaryService
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                            </Route>
                          );
                        }
                      )}
                      <Route
                        path={Path.loanAssetsCredits}
                        element={
                          <LoanAssetsCredits {...this.props} {...childProps} />
                        }
                      />

                      <Route
                        path={Path.loanRealEstate}
                        element={
                          <LoanRealEstate {...this.props} {...childProps} />
                        }
                      />

                      <Route path={Path.loanCreditReport}>
                        <Route
                          index
                          element={
                            <LoanCreditReport {...this.props} {...childProps} />
                          }
                        />
                        <Route
                          path={Path.loanCreditReportStatus}
                          element={
                            <LoanCreditReportStatus
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                      </Route>

                      <Route
                        path={Path.loanLiabilities}
                        element={
                          <LoanLiabilities {...this.props} {...childProps} />
                        }
                      />

                      <Route path={Path.loanDocuments}>
                        <Route
                          index
                          element={<Navigate to={Path.loanGuidedDocument} />}
                        />
                        <Route
                          path={Path.loanGuidedDocument}
                          element={
                            <LoanGuidedDocument
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                        <Route
                          path={Path.loanAdditionalDocument}
                          element={
                            <LoanAddditionalDocument
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                      </Route>

                      <Route
                        path={Path.loanReview}
                        element={<LoanReview {...this.props} {...childProps} />}
                      />

                      <Route
                        path={Path.loanSubmit}
                        element={<LoanSubmit {...this.props} {...childProps} />}
                      />
                    </Route>
                  </Route>

                  <Route path={Path.setting}>
                    <Route index element={<Navigate to={"profile"} />} />
                    <Route
                      path=":id"
                      element={<Settings {...this.props} {...childProps} />}
                    ></Route>
                  </Route>
                </Route>
              </Route>
              {/* Broker Routing Ends */}

              {/* Borrower Routing Starts */}
              <Route
                element={
                  <RequireAuth
                    allowedRoles={["Borrower"]}
                    {...childProps}
                    {...this.props}
                  />
                }
              >
                <Route
                  path={Path.borrower}
                  element={<BrokerIndex {...this.props} {...childProps} />}
                >
                  <Route index element={<Navigate to={Path.welcome} />} />

                  <Route
                    path={Path.welcome}
                    element={<Welcome {...this.props} {...childProps} />}
                  />
                  {/* <Route
                    path={Path.loan}
                    element={<LoansBorrower {...this.props} {...childProps} />}
                  /> */}

                  <Route path={Path.loanApplication}>
                    <Route index element={<LoansBorrower />} />
                    <Route
                      path={Path.loanSummary + "/:id"}
                      element={
                        <LoanSummaryVisiblity {...this.props} {...childProps} />
                      }
                    />

                    <Route
                      path=":id"
                      element={<LoanLayout {...this.props} {...childProps} />}
                    >
                      <Route
                        index
                        element={<Navigate to={Path.gettingStarted} />}
                      />
                      {/* <Route path={"geting-started"} element={<GettingStarted {...this.props} {...childProps} />} /> */}
                      <Route path={Path.gettingStarted}>
                        <Route
                          index
                          element={
                            <GettingStarted {...this.props} {...childProps} />
                          }
                        />
                        <Route
                          path={Path.gettingStartedCoBorrower}
                          element={
                            <CoBorrowerStatus {...this.props} {...childProps} />
                          }
                        />
                        <Route
                          path={Path.confirmPartnerCoBorrower}
                          element={
                            <PartnerCoBorrowerSet
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                      </Route>

                      <Route path={Path.loanDetails}>
                        <Route
                          index
                          element={
                            <LoanDetails {...this.props} {...childProps} />
                          }
                        />
                      </Route>
                      {[Path.loanBorrowerInfo, Path.loanPartnerInfo].map(
                        (path) => {
                          return (
                            <Route path={path} key={path}>
                              <Route
                                path={Path.loanPersonalInfo}
                                element={
                                  <LoanPersonalInfo
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanContactInfo}
                                element={
                                  <LoanContactInfo
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanDemographics}
                                element={
                                  <LoanDemographics
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanDeclarations}
                                element={
                                  <LoanDeclarations
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanDependent}
                                element={
                                  <LoanDependent
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanEmployment}
                                element={
                                  <LoanEmployment
                                    key={window.location.pathname}
                                  />
                                }
                              />

                              <Route
                                path={Path.loanMilitaryService}
                                element={
                                  <LoanMilitaryService
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                            </Route>
                          );
                        }
                      )}
                      <Route
                        path={Path.loanAssetsCredits}
                        element={
                          <LoanAssetsCredits {...this.props} {...childProps} />
                        }
                      />

                      <Route
                        path={Path.loanRealEstate}
                        element={
                          <LoanRealEstate {...this.props} {...childProps} />
                        }
                      />

                      <Route path={Path.loanCreditReport}>
                        <Route
                          index
                          element={
                            <LoanCreditReport {...this.props} {...childProps} />
                          }
                        />
                        <Route
                          path={Path.loanCreditReportStatus}
                          element={
                            <LoanCreditReportStatus
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                      </Route>

                      <Route
                        path={Path.loanLiabilities}
                        element={
                          <LoanLiabilities {...this.props} {...childProps} />
                        }
                      />

                      <Route path={Path.loanDocuments}>
                        <Route
                          index
                          element={<Navigate to={Path.loanGuidedDocument} />}
                        />
                        <Route
                          path={Path.loanGuidedDocument}
                          element={
                            <LoanGuidedDocument
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                        <Route
                          path={Path.loanAdditionalDocument}
                          element={
                            <LoanAddditionalDocument
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                      </Route>

                      <Route
                        path={Path.loanReview}
                        element={<LoanReview {...this.props} {...childProps} />}
                      />

                      <Route
                        path={Path.loanSubmit}
                        element={<LoanSubmit {...this.props} {...childProps} />}
                      />
                    </Route>
                  </Route>

                  <Route
                    path={Path.invitationList}
                    element={<BorrowerInvitations />}
                  />
                  <Route path={Path.setting}>
                    <Route index element={<Navigate to={"profile"} />} />
                    <Route
                      path=":id"
                      element={<Settings {...this.props} {...childProps} />}
                    ></Route>
                  </Route>
                </Route>
              </Route>
              {/* Borrower Routing Ends */}

              {/* MLO Routing Starts */}
              <Route
                element={
                  <RequireAuth
                    allowedRoles={["MLO"]}
                    {...childProps}
                    {...this.props}
                  />
                }
              >
                <Route
                  path={Path.mlo}
                  element={<BrokerIndex {...this.props} {...childProps} />}
                >
                  <Route index element={<Navigate to={Path.welcome} />} />

                  <Route
                    path={Path.welcome}
                    element={<Welcome {...this.props} {...childProps} />}
                  />
                  <Route
                    path={Path.invitationList}
                    element={<InvitationPage {...this.props} {...childProps} />}
                  />
                  <Route
                    path={Path.credcoCredentials}
                    element={
                      <CredcoCredentials {...this.props} {...childProps} />
                    }
                  />
                  <Route
                    path={Path.leads}
                    element={<Leads {...this.props} {...childProps} />}
                  />

                  <Route
                    path={Path.leads + "/" + Path.quoteForm + "/:id"}
                    element={<Quote {...this.props} {...childProps} />}
                  />

                  <Route
                    path={Path.leads + "/" + Path.preapprovalForm + "/:id"}
                    element={<Preapproval {...this.props} {...childProps} />}
                  />

                  <Route path={Path.loanApplication}>
                    <Route
                      index
                      element={<LoanList {...this.props} {...childProps} />}
                    />
                    <Route
                      path={":id/" + Path.additionalLoanContact}
                      element={<AdditionalLoanContact />}
                    />
                    <Route
                      path={":id/" + Path.loanNotes}
                      element={<LoanNotes {...this.props} {...childProps} />}
                    />

                    <Route
                      path={Path.loanSummary + "/:id"}
                      element={
                        <LoanSummaryVisiblity {...this.props} {...childProps} />
                      }
                    />
                    <Route
                      path=":id"
                      element={<LoanLayout {...this.props} {...childProps} />}
                    >
                      <Route
                        index
                        element={<Navigate to={Path.gettingStarted} />}
                      />
                      {/* <Route path={"geting-started"} element={<GettingStarted {...this.props} {...childProps} />} /> */}
                      <Route path={Path.gettingStarted}>
                        <Route
                          index
                          element={
                            <GettingStarted {...this.props} {...childProps} />
                          }
                        />
                        <Route
                          path={Path.gettingStartedCoBorrower}
                          element={
                            <CoBorrowerStatus {...this.props} {...childProps} />
                          }
                        />
                        <Route
                          path={Path.confirmPartnerCoBorrower}
                          element={
                            <PartnerCoBorrowerSet
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                      </Route>

                      <Route path={Path.loanDetails}>
                        <Route
                          index
                          element={
                            <LoanDetails {...this.props} {...childProps} />
                          }
                        />
                      </Route>
                      {[Path.loanBorrowerInfo, Path.loanPartnerInfo].map(
                        (path) => {
                          return (
                            <Route path={path} key={path}>
                              <Route
                                path={Path.loanPersonalInfo}
                                element={
                                  <LoanPersonalInfo
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanContactInfo}
                                element={
                                  <LoanContactInfo
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanDemographics}
                                element={
                                  <LoanDemographics
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanDeclarations}
                                element={
                                  <LoanDeclarations
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanDependent}
                                element={
                                  <LoanDependent
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanEmployment}
                                element={
                                  <LoanEmployment
                                    key={window.location.pathname}
                                  />
                                }
                              />

                              <Route
                                path={Path.loanMilitaryService}
                                element={
                                  <LoanMilitaryService
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                            </Route>
                          );
                        }
                      )}
                      <Route
                        path={Path.loanAssetsCredits}
                        element={
                          <LoanAssetsCredits {...this.props} {...childProps} />
                        }
                      />

                      <Route
                        path={Path.loanRealEstate}
                        element={
                          <LoanRealEstate {...this.props} {...childProps} />
                        }
                      />

                      <Route path={Path.loanCreditReport}>
                        <Route
                          index
                          element={
                            <LoanCreditReport {...this.props} {...childProps} />
                          }
                        />
                        <Route
                          path={Path.loanCreditReportStatus}
                          element={
                            <LoanCreditReportStatus
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                      </Route>

                      <Route
                        path={Path.loanLiabilities}
                        element={
                          <LoanLiabilities {...this.props} {...childProps} />
                        }
                      />

                      <Route path={Path.loanDocuments}>
                        <Route
                          index
                          element={<Navigate to={Path.loanGuidedDocument} />}
                        />
                        <Route
                          path={Path.loanGuidedDocument}
                          element={
                            <LoanGuidedDocument
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                        <Route
                          path={Path.loanAdditionalDocument}
                          element={
                            <LoanAddditionalDocument
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                      </Route>

                      <Route
                        path={Path.loanReview}
                        element={<LoanReview {...this.props} {...childProps} />}
                      />

                      <Route
                        path={Path.loanSubmit}
                        element={<LoanSubmit {...this.props} {...childProps} />}
                      />
                      <Route
                        path={Path.mloLoanDocument}
                        element={<LoanDocument />}
                      />
                    </Route>
                  </Route>

                  <Route path={Path.setting}>
                    <Route index element={<Navigate to={"profile"} />} />
                    <Route
                      path=":id"
                      element={<Settings {...this.props} {...childProps} />}
                    ></Route>
                  </Route>
                </Route>
              </Route>
              {/* MLO Routing Ends */}

              {/* MLP Routing Starts */}
              <Route
                element={
                  <RequireAuth
                    allowedRoles={["MLP"]}
                    {...childProps}
                    {...this.props}
                  />
                }
              >
                <Route
                  path={Path.mlp}
                  element={<BrokerIndex {...this.props} {...childProps} />}
                >
                  <Route index element={<Navigate to={Path.welcome} />} />

                  <Route
                    path={Path.welcome}
                    element={<Welcome {...this.props} {...childProps} />}
                  />
                  <Route
                    path={Path.invitationList}
                    element={<InvitationPage {...this.props} {...childProps} />}
                  />
                  <Route
                    path={Path.teamInvitationList}
                    element={<BorrowerInvitations />}
                  />
                  <Route
                    path={Path.leads}
                    element={<Leads {...this.props} {...childProps} />}
                  />

                  <Route
                    path={Path.leads + "/" + Path.quoteForm + "/:id"}
                    element={<Quote {...this.props} {...childProps} />}
                  />

                  <Route
                    path={Path.leads + "/" + Path.preapprovalForm + "/:id"}
                    element={<Preapproval {...this.props} {...childProps} />}
                  />

                  <Route path={Path.loanApplication}>
                    <Route
                      index
                      element={<LoanList {...this.props} {...childProps} />}
                    />
                    <Route
                      path={":id/" + Path.additionalLoanContact}
                      element={<AdditionalLoanContact />}
                    />
                    <Route
                      path={":id/" + Path.loanNotes}
                      element={<LoanNotes {...this.props} {...childProps} />}
                    />

                    <Route
                      path={Path.loanSummary + "/:id"}
                      element={
                        <LoanSummaryVisiblity {...this.props} {...childProps} />
                      }
                    />
                    <Route
                      path=":id"
                      element={<LoanLayout {...this.props} {...childProps} />}
                    >
                      <Route
                        index
                        element={<Navigate to={Path.gettingStarted} />}
                      />
                      {/* <Route path={"geting-started"} element={<GettingStarted {...this.props} {...childProps} />} /> */}
                      <Route path={Path.gettingStarted}>
                        <Route
                          index
                          element={
                            <GettingStarted {...this.props} {...childProps} />
                          }
                        />
                        <Route
                          path={Path.gettingStartedCoBorrower}
                          element={
                            <CoBorrowerStatus {...this.props} {...childProps} />
                          }
                        />
                        <Route
                          path={Path.confirmPartnerCoBorrower}
                          element={
                            <PartnerCoBorrowerSet
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                      </Route>

                      <Route path={Path.loanDetails}>
                        <Route
                          index
                          element={
                            <LoanDetails {...this.props} {...childProps} />
                          }
                        />
                      </Route>
                      {[Path.loanBorrowerInfo, Path.loanPartnerInfo].map(
                        (path) => {
                          return (
                            <Route path={path} key={path}>
                              <Route
                                path={Path.loanPersonalInfo}
                                element={
                                  <LoanPersonalInfo
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanContactInfo}
                                element={
                                  <LoanContactInfo
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanDemographics}
                                element={
                                  <LoanDemographics
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanDeclarations}
                                element={
                                  <LoanDeclarations
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanDependent}
                                element={
                                  <LoanDependent
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                              <Route
                                path={Path.loanEmployment}
                                element={
                                  <LoanEmployment
                                    key={window.location.pathname}
                                  />
                                }
                              />

                              <Route
                                path={Path.loanMilitaryService}
                                element={
                                  <LoanMilitaryService
                                    key={window.location.pathname}
                                    {...this.props}
                                    {...childProps}
                                  />
                                }
                              />
                            </Route>
                          );
                        }
                      )}
                      <Route
                        path={Path.loanAssetsCredits}
                        element={
                          <LoanAssetsCredits {...this.props} {...childProps} />
                        }
                      />

                      <Route
                        path={Path.loanRealEstate}
                        element={
                          <LoanRealEstate {...this.props} {...childProps} />
                        }
                      />

                      <Route path={Path.loanCreditReport}>
                        <Route
                          index
                          element={
                            <LoanCreditReport {...this.props} {...childProps} />
                          }
                        />
                        <Route
                          path={Path.loanCreditReportStatus}
                          element={
                            <LoanCreditReportStatus
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                      </Route>

                      <Route
                        path={Path.loanLiabilities}
                        element={
                          <LoanLiabilities {...this.props} {...childProps} />
                        }
                      />

                      <Route path={Path.loanDocuments}>
                        <Route
                          index
                          element={<Navigate to={Path.loanGuidedDocument} />}
                        />
                        <Route
                          path={Path.loanGuidedDocument}
                          element={
                            <LoanGuidedDocument
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                        <Route
                          path={Path.loanAdditionalDocument}
                          element={
                            <LoanAddditionalDocument
                              {...this.props}
                              {...childProps}
                            />
                          }
                        />
                      </Route>

                      <Route
                        path={Path.loanReview}
                        element={<LoanReview {...this.props} {...childProps} />}
                      />

                      <Route
                        path={Path.loanSubmit}
                        element={<LoanSubmit {...this.props} {...childProps} />}
                      />
                    </Route>
                  </Route>

                  <Route path={Path.setting}>
                    <Route index element={<Navigate to={"profile"} />} />
                    <Route
                      path=":id"
                      element={<Settings {...this.props} {...childProps} />}
                    ></Route>
                  </Route>
                </Route>
              </Route>
              {/* MLP Routing Ends */}

              {/* Finally, catch all unmatched routes */}

              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </LoadingOverlay>
        </div>
      </Suspense>
    );
  }
}

export default withRouter(App);
