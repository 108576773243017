import React, { useEffect, useState } from "react";
import CustomRadioChip from "../../../../helpers/radio-chip";
import {
    Box,
    Button,
    FormHelperText,
    FormLabel,
    InputLabel,
    Stack,
    Typography,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
    CONTACT_STATE,
    TASK_LENDERS_CONDITION,
    TASK_PRIORITY,
    TASK_TYPE_CHOICE,
    UPDATE,
} from "../../../../constants/constatnt-variable";
import CustomInput from "../../../../helpers/custom-input";
import RadioButtonGroup from "../../../../helpers/radio-button-group";
import {
    getApiCallDynamic,
    postApiCallDynamicWithOutReturn,
    uploadFile,
} from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import {
    DateToTimestapmFormater,
    TimeStampToMonthDateYearForInput,
} from "../../../../helpers/time-formater";
import { currentDate } from "../../../../helpers/common_fuctions/current-date";
import { KSCloudUploadIcon } from "../../../../icons/custome-icon";
import { toastNotify } from "../../../../helpers/alert-msg";
import { TaskTypeValidation, TaskValidation } from "../../../../constants/validation-rules";
import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";
import MultiSelectDropDown from "../../../../helpers/dropdown/multi-select-dropdown";

import SupportingDocumentForm from "./supporting-doc-form";
import SupportingDocument from "./supporting-doc";



export default function ThirdPartTaskForm({
    formId,
    edit = false,
    taskFormData,
    handleClose,
    taskReload,
}) {

    const [taskList, setTaskList] = useState([]);
    const [docList, setDocList] = useState([]);
    const [docEdit, setDocEdit] = useState();


    useEffect(() => {
        const taskListsData = () => {
            getApiCallDynamic({
                path: API.getMasterTask,
                // param: { formId: formId, status: status }, 
            }).then((res) => {
                if (res?.status === 200) {
                    setTaskList(res?.data);
                }
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
        };

        taskListsData();
    }, []);


    // let [taskList, setTaskList] = useState([
    //     { 'note': 'hello', 'documentType': 'doc1', 'taskType': 'task11', 'documentDecriptor': 'documentDecriptor' },
    //     { 'note': 'sdfasdasdasdasdasdasda', 'documentType': 'docsdadsadasdasdasdsadas2sdsada', 'taskType': 'sdasdasdasdasdasdasdtsdsadasdasd', 'documentDecriptor': 'documentDecriptor' },
    //     { 'note': 'world', 'documentType': 'doc2', 'taskType': 'task95', 'documentDecriptor': 'documentDecriptor' }])

    const taskTypeOptions = taskList.map((task) => ({
        label: task.taskTypeName, // Use taskType as the label
        value: task.id, // Use taskType as the value
    }));






    // useEffect(() => {
    //     const taskListsData = () => {
    //         getApiCallDynamic({
    //             path: API.getMasterTask,
    //             // param: { formId: formId, status: status }, 
    //         }).then((res) => {
    //             if (res?.status === 200) {
    //                 setTaskList(res?.data);
    //             }
    //         }).catch((error) => {
    //             console.error('Error fetching data:', error);
    //         });
    //     };

    //     taskListsData();
    // }, []);

    const [titles, setTitles] = useState([])

    useEffect(() => {
        const taskListsData = () => {
            getApiCallDynamic({
                path: API.getMasterTaskDoc,
                // param: { formId: formId, status: status },
            })
                .then((res) => {
                    if (res?.status === 200) {
                        const tasks = res?.data || [];
                        setTaskList(tasks);
                        console.log(" Tites " + JSON.stringify(res.data, null, 2))
                        // Generate titles from the fetched tasks
                        const titles = tasks
                            .filter(item => item.documentType !== undefined)
                            .map(item => item.documentType);

                        // Set the titles in the state
                        setTitles(titles);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        };

        taskListsData();
    }, []); // No dependencies in the useEffect array





    const [brokerFileID, setBrokerFileID] = useState(null);
    const [disableTaskCreate, setDisableTaskCreate] = useState(false)

    const fileUpload = (event) => {
        setDisableTaskCreate(true)
        let file = event.target.files[0];
        if (event.target.files) {
            uploadFile({
                formId: formId,
                docId: taskFormData?.brokerDocId ? taskFormData?.brokerDocId : null,
                file: file,
                serviceType: "task",
                path: API.proxyDocumentOthersUpload,
            }).then((res) => {
                if (res.status === 201) {
                    toastNotify("success", res.data.message);
                    setBrokerFileID(res?.data?.docId);
                    setDisableTaskCreate(false)
                }
            });
        } else {
            console.log("not found");
        }
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            formId: formId,
            taskType: taskFormData?.taskType,
            title: taskFormData?.title,
            description: taskFormData?.description,
            isLender: taskFormData?.isLender ? "Yes" : "No",
            dueDate: TimeStampToMonthDateYearForInput(taskFormData?.dueDate),
            priority: taskFormData?.priority,
            brokerDocId: taskFormData?.brokerDocId,
            borrowerDocId: taskFormData?.borrowerDocId,
        },
    });

    const formOnSubmit = async (data) => {

        console.log(" Third Party Task " + JSON.stringify(data, null, 2))
        data = Object.assign(data, {
            formId: formId,
            dueDate: DateToTimestapmFormater(data.dueDate),
            isLender: data?.isLender == "Yes" ? true : false,
            id: taskFormData?.id ? taskFormData.id : null,
            brokerDocId: taskFormData?.brokerDocId
                ? taskFormData?.brokerDocId
                : brokerFileID,
        });

        await postApiCallDynamicWithOutReturn({
            data: data,
            path: API.task,
            header: taskFormData ? UPDATE : "",
            refresh: taskReload,
        });

        handleClose();
    };

    const { taskType } = useWatch({ control })

    return (
        <>
            <form onSubmit={handleSubmit(formOnSubmit)}>

                <Typography variant="h3" paragraph>Create New Task</Typography>



                <Controller
                    name='taskType'
                    control={control}
                    rules={TaskTypeValidation.taskType}
                    render={({ field }) => (
                        <CustomDropDown
                            {...field}
                            field={field}
                            label={'Task Type'}
                            required
                            state={taskType}
                            option={taskTypeOptions}
                            error={errors?.taskType?.message}
                        />
                    )}
                />

                <Controller
                    name="description"
                    control={control}
                    rules={TaskTypeValidation.description}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            field={field}
                            required
                            type="text"
                            label={"Enter Task Description"}
                            error={errors?.description?.message}
                        />
                    )}
                />

                {/* <Controller
                    name="taskType"
                    control={control}
                    render={({ field }) => (
                        <MultiSelectDropDown
                            options={['Option 1', 'Option 2', 'Option 3', 'Option 4']}
                            value={field.value || []} // Default to empty array if field.value is undefined
                            setValue={(newValue) => setValue('taskType', newValue)}
                        />
                    )}
                /> */}
                {/* <Controller
                    name='taskType'
                    control={control}
                    render={({ field }) => (
                        <MultiSelectDropDown
                            field={field} // Pass field prop directly
                            options={['Option 1', 'Option 2', 'Option 3', 'Option 4']} // Pass options
                            errors={errors.taskType} // Pass relevant errors
                            label="Tag" // Pass label for the dropdown
                        />
                    )}
                /> */}

                <Box marginY={4}>

                    <Typography variant="h3" paragraph>Required Document</Typography>



                    <Controller
                        name='requiredDocs'
                        control={control}
                        render={({ field }) => (
                            <MultiSelectDropDown
                                field={field} // Pass field prop directly
                                // options={['Option 1', 'Option 2', 'Option 3', 'Option 4']} // Pass options
                                options={titles} // Pass options
                                errors={errors.requiredDocs} // Pass relevant errors
                                label="Tag" // Pass label for the dropdown
                            />
                        )}
                    />

                </Box>




                <Box marginY={4}>
                    <Typography variant="h3" paragraph>Task Receiver Contact</Typography>

                    <Controller
                        name='taskReceiver'
                        control={control}
                        //  rules={PropertyAddressValidation.stateofCountry}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={''}
                                required
                                state={taskType}
                                option={CONTACT_STATE}
                                error={errors?.taskReceiver?.message}
                            />
                        )}
                    />

                </Box>

                <Controller
                    name="receiverName"
                    control={control}
                    rules={TaskValidation.receiverName}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            field={field}
                            required
                            type="text"
                            label={"Name"}
                            error={errors?.receiverName?.message}
                        />
                    )}
                />
                <Controller
                    name="receiverPhoneNo"
                    control={control}
                    rules={TaskValidation.receiverPhoneNo}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            field={field}
                            required
                            type="text"
                            label={"Phone Number"}
                            error={errors?.receiverPhoneNo?.message}
                        />
                    )}
                />

                <Controller
                    name="receiverEmail"
                    control={control}
                    rules={TaskValidation.receiverEmail}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            field={field}
                            required
                            type="text"
                            label={"Email "}
                            error={errors?.receiverEmail?.message}
                        />
                    )}
                />




                <Controller
                    name="dueDate"
                    control={control}
                    rules={TaskValidation.dueDate}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            field={field}
                            required
                            type="text"
                            label={"Due date "}
                            error={errors?.dueDate?.message}
                        />
                    )}
                />





                <Controller
                    name="taskPriority"
                    control={control}
                    rules={TaskValidation.taskPriority}
                    render={({ field }) => (
                        <CustomRadioChip
                            {...field}
                            field={field}
                            required
                            label={"Task priority"}
                            data={TASK_PRIORITY}
                        //  error={errors?.taskPriority?.message}
                        //state={taskPriority}
                        />
                    )}
                />

                {/* <Controller
                    name="title"
                    control={control}
                    rules={TaskValidation.title}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            field={field}
                            required
                            type="text"
                            label={"Task Title"}
                            error={errors?.title?.message}
                        />
                    )}
                /> */}



                <Controller
                    name="isLender"
                    control={control}
                    rules={TaskValidation.isLender}
                    render={({ field }) => (
                        <RadioButtonGroup
                            {...field}
                            field={field}
                            required
                            direction="row"
                            labelComponent={<FormLabel>{`Is Lender's Condition?`}</FormLabel>}
                            data={TASK_LENDERS_CONDITION}
                            error={errors?.isLender?.message}
                        />
                    )}
                />

                {/* <Controller
                    name="dueDate"
                    control={control}
                    rules={TaskValidation.dueDate}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            field={field}
                            minDate={currentDate()}
                            required
                            type="date"
                            label={"Due Date"}
                            error={errors?.dueDate?.message}
                        />
                    )}
                /> */}

                {/* {
                    taskType == 'Download & upload' ?
                        <Controller
                            name="brokerDocId"
                            control={control}
                            rules={TaskValidation.brokerDocId}
                            render={({ field }) => (
                                <>
                                    <Stack
                                        direction={"row"}
                                        gap={1}
                                        alignItems={"center"}
                                        my={2}
                                        justifyContent={"space-between"}
                                    >
                                        <InputLabel>{`Document to Sign`}
                                            <Typography component={'span'} color={'error'}>{`*`}</Typography>
                                        </InputLabel>


                                        <Button
                                            component="label"
                                            size="small"
                                            {...field}
                                            value={brokerFileID}
                                            startIcon={<KSCloudUploadIcon color={"primary"} />}
                                        >
                                            <input
                                                onChange={fileUpload}
                                                hidden
                                                accept="image/*"
                                                type="file"
                                            />
                                            {`Upload`}
                                        </Button>
                                    </Stack>
                                    <FormHelperText error>
                                        {errors?.brokerDocId?.message}
                                    </FormHelperText>
                                </>
                            )}
                        /> : null
                }
 */}





                <Box marginY={4}>
                    <Typography variant="h3" paragraph>Supporting Documents</Typography>

                    <SupportingDocument />

                </Box>

                <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2}>
                    <Button type="submit" variant="contained" disabled={disableTaskCreate}>
                        {edit ? "Update" : "Create"}
                    </Button>
                    <Button variant="text" onClick={handleClose}>
                        Cancel
                    </Button>
                </Stack>
            </form>
        </>
    );
}
