import { Box, Button, Stack } from "@mui/material";
import React from "react";
import RadioButtonGroup from "../../../../helpers/radio-button-group";
import {
  LIST_DATA,
  UPDATE,
} from "../../../../constants/constatnt-variable";
import { Controller, useForm } from "react-hook-form";
import { postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import { loanStateValidation } from "../../../../constants/validation-rules";

export default function LoanSummaryLoanStatusForm({
  loanCode,
  loanState,
  handleClose,
  reloader,
}) {


  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      loanCode: loanCode,
      loanState: loanState ? loanState : null,
    },
  });

  const formOnSubmit = async (data) => {
    await postApiCallDynamicWithOutReturn({
      data: data,
      path: API.loanSummaryLoanStateAPI,
      header: UPDATE,
      refresh: reloader,
    });

    handleClose();
  };
  return (
    <form onSubmit={handleSubmit(formOnSubmit)}>
      <Box width={"100%"} p={1}>
        <Controller
          name="loanState"
          control={control}
          rules={loanStateValidation.loanState}
          render={({ field }) => (
            <RadioButtonGroup
              {...field}
              field={field}
              borderBottom
              direction="column"
              data={LIST_DATA}
              error={errors?.loanState?.message}
            />
          )}
        />
      </Box>
      <Stack direction={"row-reverse"} gap={2} p={2}>
        <Button variant="contained" type="submit">{`Submit`}</Button>
        <Button onClick={handleClose}>{`Cancel`}</Button>
      </Stack>
    </form>
  );
}
