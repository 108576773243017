import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import UploadDocumentCard from './upload-document-card'
import { getApiCallDynamic, postApiCallDynamicWithOutReturn, uploadFile } from '../../../../../services/api-service'
import { API } from '../../../../../services/api-config'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../../recoil/profile-atom'
import { toastNotify } from '../../../../../helpers/alert-msg'
import { UPDATE } from '../../../../../constants/constatnt-variable'


export default function LoanGuidedDocuments() {
    // let [formValue] = useRecoilState(formState)
    let [formValue, setFormValue] = useRecoilState(formState)

    const route = useNavigate()
    const location = useLocation();

    let [documents, setDocuments] = useState(() => null)
    let [updateState, setUpdateState] = useState(() => false)

    useEffect(() => {
        getApiCallDynamic({ param: { formId: formValue?.id }, path: API.guidedDocument }).then((res) => {
            if (res?.status === 200) {
                setDocuments(res.data)
            }
        });

    }, [updateState])

    // const [checkData, setCheckData] = useState()

    let doc = []
    documents ? documents.map(i => i.documents.map(j => { return doc.push(j.isSkipped) })) : ""
    console.log("documents", doc, "---", doc.includes(null));

    const { control, handleSubmit, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            checkbox: true
        }

    })

    // const goToNextPage = () => {
    //     return route(location.pathname.replace(/[^/]*$/, "additional-document"))
    // }


    const onSubmit = async (data, event) => {

        console.log("data", data);
        if (event.target.id == "main-document" && !doc.includes(null)) {

            let apiCallData = {
                page: documents,
                formId: formValue.id,
                isComplete: true
            }
            const updateState = () => {
                setFormValue({
                    ...formValue,
                    documents: {
                        ...formValue.documents,
                        page: {
                            ...formValue.documents.page,
                            guidedDocument: {
                                ...formValue.documents.page.guidedDocument,
                                subPage: apiCallData.page,
                                isComplete: true
                            }
                        }
                    }
                })
                route(location.pathname.replace(/[^/]*$/, 'additional-document'));
            }
            await postApiCallDynamicWithOutReturn(
                {
                    data: apiCallData,
                    path: API.guidedDocument,
                    refresh: updateState,
                    header: UPDATE,
                }
            )
        }
    }

    const fileUpload = (event) => {
        let file = event.target.files[0]
        if (event.target.files) {
            uploadFile({ docId: event.target.id, file: file, path: API.proxyDocumentUpload }).then((res) => {
                if (res.status === 201) {
                    toastNotify("success", res.data.message);
                    setUpdateState((prev) => !prev)
                }
            })
        } else {
            console.log("not found");
        }

    }

    // TODO THis a temporary work around need to fix it
    if (documents == null) {
        return <></>
    }
    return (
        <>
            <form id='main-document' key={"main-document"} onSubmit={handleSubmit(onSubmit)}>
                <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                    <Box>
                        <Typography variant='h2' mb={2} >{`Upload these documents`}</Typography>
                        <Typography mb={2}>{`The purpose of collecting this information is to make sure that every individual will be treated fairly`}</Typography>

                        {
                            documents.map((item, index) => (
                                <UploadDocumentCard
                                    key={item.title}
                                    title={item.title}
                                    control={control}
                                    errors={errors}
                                    data={item.documents}
                                    description={item.description}
                                    // checkValues={checkValues}
                                    fileUpload={fileUpload}
                                    setUpdateState={setUpdateState}
                                />
                            ))
                        }

                    </Box>

                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Link to={'../../liabilities'}>
                                <Button startIcon={<ArrowBackIos />}>
                                    Back
                                </Button>
                            </Link>
                            {/* <Link to={'../additional-documents'}> */}
                            <Button form='main-document' type='submit' endIcon={<ArrowForwardIos />}>
                                Next
                            </Button>
                            {/* </Link> */}
                        </Stack>
                    </Box>
                </Stack>
            </form>
        </>
    )
}

