import { Box, Button, FormHelperText, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import CustomDropDown from '../../../../../../helpers/dropdown/custom-dropdown'
import CustomInput from '../../../../../../helpers/custom-input'
import { CloudUpload } from '@mui/icons-material'
import { DOCUMENT_TYPE } from '../../../../../../constants/constatnt-variable'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { AdditionalDocumentsValidation } from '../../../../../../constants/validation-rules'

export default function AdditionalDocumentForm({ fileUpload, handleClose, onSubmit, editItem, disableSave }) {

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            documentName: editItem?.documentName,
            documentType: editItem?.documentType,
            note: editItem?.note,
            docId: editItem?.docId
        },
    })

    const { documentType } = useWatch({ control })


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box width={'100%'} my={2} >

                <Controller
                    name='documentType'
                    control={control}
                    rules={AdditionalDocumentsValidation.documentType}
                    render={({ field }) => (
                        <CustomDropDown
                            field={field}
                            label={'Select Additional Document Type'}
                            required
                            option={DOCUMENT_TYPE}
                            error={errors?.documentType?.message}
                        />
                    )}
                />
                {documentType == "Others" ?
                    <Controller
                        name='documentName'
                        control={control}
                        rules={AdditionalDocumentsValidation.documentName}
                        render={({ field }) => (
                            <CustomInput
                                {...field}
                                required
                                type={'text'}
                                label={'Enter Additional Document Type Name'}
                                error={errors?.documentName?.message}
                            />
                        )}
                    /> : null}
                <Controller
                    name='note'
                    control={control}
                    rules={AdditionalDocumentsValidation.note}
                    render={({ field }) => (
                        <CustomInput
                            {...field}
                            required
                            type={'text'}
                            label={'Add a note'}
                            multiline
                            minRows={4}
                            error={errors?.note?.message}
                        />
                    )}
                />
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Controller
                    name='docId'
                    control={control}
                    rules={AdditionalDocumentsValidation.docId}
                    render={({ field }) => (
                        <Stack direction={'column'} justifyContent={'flex-end'} >
                            <IconButton {...field} color="primary" aria-label="upload picture" component="label" sx={{ borderRadius: '4%', padding: '0px' }}>
                                <input onChange={fileUpload} hidden accept="image/*" type="file" />
                                <CloudUpload />
                                <Typography variant='body1' ml={.5} my={.5}>Upload</Typography>
                            </IconButton>
                            <FormHelperText error>{errors?.docId?.message}</FormHelperText>
                        </Stack>
                    )}
                />
                <Stack direction={'row'} gap={2} justifyContent={'end'} >
                    <Button onClick={handleClose}>{`Cancel`}</Button>
                    <Button type='submit' variant='contained' disabled={disableSave}>{`Save`}</Button>
                </Stack>
            </Box>
        </form>
    )
}
