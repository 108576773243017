import { Box, Button, Grid, Typography, } from "@mui/material";
import React, { useMemo, useState } from "react";
import ConfirmationModel from "../../helpers/modal/confirm-modal";
import CustomInput from "../../helpers/custom-input";
import CustomReactMaterialTable from "../../helpers/table/react-material-table";
import DialogSuccesMsg from "../../helpers/modal/dialog-succes-msg";
import { DialogCommonHooks } from "../../helpers/modal/modal";

function Merge(props) {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const handleSuccessOpen = () => {
    setSuccessModalOpen(true);
  };
  const handleClose = () => {
    setSuccessModalOpen(false);
    handleModalClose();
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "accountNumber",
        header: "Account Number",
        enableColumnActions: false,
        enableSorting: false,
      },
      //column definitions...
      {
        accessorKey: "mergeStatus",
        header: "Merge Status",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "dateRequested",
        header: "Date Requested",
        enableColumnActions: false,
        enableSorting: false,
      },

      //end
    ],
    []
  );

  let data = [
    {
      accountNumber: "12345",
      mergeStatus: "Pending",
      dateRequested: "Oct 28, 2022",
    },
    {
      accountNumber: "56475",
      mergeStatus: "Merge",
      dateRequested: "Sep 09, 2022",
    },
    {
      accountNumber: "73373",
      mergeStatus: "Decline",
      dateRequested: "Oct 28, 2022",
    },
  ];

  return (
    <>
      <Grid container direction={"row"}>
        <Grid item sm={12} md={5} my={1}>
          <Typography variant="h3">Marge</Typography>
          <Typography variant="p2">
            Account number to invite for the merge?
          </Typography>
        </Grid>
        <Grid item sm={12} md={7} my={1} overflow={'auto'}>
          <CustomReactMaterialTable
            endPoint="/api/users"
            columns={columns}
            staticLoad={true}
            staticData={data}
            enableToolbarInternalActions={false}
            renderTopToolbarCustomActions={(table) => (
              <Box my={1} display="flex" direction="row" justifyContent="end">
                <Button variant="outlined" onClick={handleOpenModal}>
                  Add More
                </Button>
              </Box>
            )}
          />
        </Grid>
      </Grid>

      {/* Marge Req modal */}
      <DialogCommonHooks
        title="Account number to invite"
        show={openModal}
        handleClose={handleModalClose}
        body={
          <Grid container direction={"column"} my={2} spacing={2}>
            <Grid item>
              <CustomInput
                label="Account Number"
                required
                fullWidth
                placeholder="12345"
              />
            </Grid>
            <Grid item textAlign={{ xs: 'center', md: 'right' }}>
              <ConfirmationModel
                body={<Button variant="contained">Send Merge Request</Button>}
                handleSubmit={handleSuccessOpen}
                message={"Are you sure you want to send the merge request?"}
                title={"Confirmation"}
              />
            </Grid>
          </Grid>
        }
      />
      {/* Marge Req success modal */}
      <DialogCommonHooks
        show={successModalOpen}
        handleClose={handleClose}
        body={
          <DialogSuccesMsg
            description="Thank You! Request has been sent."
            handleSubmit={handleSuccessOpen}
            handleClose={handleClose}
          />
        }
      />
    </>
  );
}

export default Merge;
