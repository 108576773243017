import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const customStyle = makeStyles({
  ContentBody: {
    fontFamily: "DMSans-Regular !important",
    fontSize: "24px !important",
    color: "#22222290",
    textAlign: "justify",
  },

});

function Security() {
  const classes = customStyle();
  return (
    <>
      <Box pt={4} id={"security"}>
        <Container maxWidth="lg">
          <Grid container minHeight="50vh">
            <Grid item xs={12} md={12} px={{ xs: 2 }} mb={6}>
              <Typography variant="h1" color={"primary.main"} my={5}>
                Security
              </Typography>
              <Box>
                <Typography mb={2} className={classes.ContentBody}>
                  {`Our obligations to customers do not stop at just providing
                    financial solutions. We care about our customer's trust .We
                    take the data & payment security with utmost seriousness. The
                    below speak for themselves`}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Security;
