import { EditOutlined } from "@mui/icons-material";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Facebook from "../../../assets/Facebook.png";
import Twitter from "../../../assets/Twitter.png";
import Linkedin from "../../../assets/Linkedin.png";
import Instagram from "../../../assets/Instagram.png";
import Divider from "@mui/material/Divider";
import { useRecoilState } from "recoil";
import { webInfoState } from "../../../recoil/profile-atom";
import CustomInput from "../../../helpers/custom-input";
import useInput from "../../../helpers/use-input";
import { postApiCallDynamic } from "../../../services/api-service";
import { API } from "../../../services/api-config";

function SocialLink() {
  const [webInfo, setWebInfo] = useRecoilState(webInfoState);

  const [edit, setEdit] = useState(false);

  let [facebook] = useInput({
    data: "",
    status: true,
    initial: "",
  });
  let [twitter] = useInput({
    data: "",
    status: true,
    initial: "",
  });
  let [instagram] = useInput({
    data: "",
    status: true,
    initial: "",
  });
  let [linkedIn] = useInput({
    data: "",
    status: true,
    initial: "",
  });
  let [youtube] = useInput({
    data: "",
    status: true,
    initial: "",
  });
  let [wordpress] = useInput({
    data: "",
    status: true,
    initial: "",
  });
  let [id] = useInput({
    data: "",
    status: true,
    initial: "",
  });

  const stateReload = () => {
    facebook.saveValue(webInfo?.socialMediaLink?.facebook);
    twitter.saveValue(webInfo?.socialMediaLink?.twitter);
    instagram.saveValue(webInfo?.socialMediaLink?.instagram);
    linkedIn.saveValue(webInfo?.socialMediaLink?.linkedIn);
    youtube.saveValue(webInfo?.socialMediaLink?.youtube);
    wordpress.saveValue(webInfo?.socialMediaLink?.wordpress);
    id.saveValue(webInfo?.id);
  };

  useEffect(() => {
    stateReload();
  }, [webInfo]);

  const linkButtonComponent = (linkUrl, state) => {
    return (
      <Box>
        {edit ? (
          <>
            <CustomInput placeholder={`Enter Url`} change={state} />
          </>
        ) : (
          <Button
            onClick={() => window.open(linkUrl, "_blank")}
            variant="contained"
          >
            Link
          </Button>
        )}
      </Box>
    );
  };

  const updateSocialLink = () => {
    let data = {
      websiteId: id.value,
      socialMediaLink: {
        facebook: facebook.value,
        twitter: twitter.value,
        instagram: instagram.value,
        linkedIn: linkedIn.value,
        youtube: youtube.value,
        wordpress: wordpress.value,
      },
    };
    postApiCallDynamic(
      {
        data: data,
        refresh: (res) => {
          return res;
        },
        path: API.socialMediaLinkUpdate,
        header: "update"
      }
    ).then((res) => {
      if (res?.status == 200) {
        setWebInfo((prev) => {
          return {
            ...prev,
            socialMediaLink: res.data.socialMediaLink,
          };
        });
      }
    });
    setEdit((prev) => !prev);
  };

  const socialMediaButtonComponent = () => {
    return (
      <>
        {edit ? (
          <>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Button
                size="large"
                color="primary"
                type="button"
                variant="outlined"
                onClick={() => {
                  setEdit((prev) => !prev);
                  stateReload();
                }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                size="large"
                color="primary"
                sx={{ borderRadius: "24px", fontWeight: "bold" }}
                type="button"
                onClick={updateSocialLink}
              >
                Submit
              </Button>
            </Box>
          </>
        ) : (
          <Button
            startIcon={<EditOutlined />}
            variant="outlined"
            onClick={() => {
              setEdit((prev) => !prev);
            }}
          >
            Edit
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={5} my={1}>
          <Typography variant="h3">Social Media Links</Typography>
        </Grid>
        <Grid item container sm={12} md={7} my={1} overflow={'auto'}>
          {/* Without grid button size will be vary based on content */}

          <Grid
            item
            xs={12}
            display={"flex"}
            flexWrap={'wrap'}
            justifyContent={"space-between"}
            mb={2}
            alignItems={'center'}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              alignItems={"center"}
              spacing={2}
            >
              <img src={Facebook} alt="Facebook" />
              <Typography>Facebook</Typography>
            </Stack>
            {linkButtonComponent(webInfo?.socialMediaLink?.facebook, facebook)}
          </Grid>

          <Grid
            item
            xs={12}
            display={"flex"}
            flexWrap={'wrap'}
            justifyContent={"space-between"}
            mb={2}
            alignItems={'center'}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              alignItems={"center"}
              spacing={2}
            >
              <img src={Twitter} alt="Twitter" />
              <Typography>Twitter</Typography>
            </Stack>
            {linkButtonComponent(webInfo?.socialMediaLink?.twitter, twitter)}
          </Grid>

          <Grid
            item
            xs={12}
            display={"flex"}
            flexWrap={'wrap'}
            justifyContent={"space-between"}
            mb={2}
            alignItems={'center'}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              alignItems={"center"}
              spacing={2}
            >
              <img src={Instagram} alt="Instagram" />
              <Typography>Instagram</Typography>
            </Stack>
            {linkButtonComponent(
              webInfo?.socialMediaLink?.instagram,
              instagram
            )}
          </Grid>

          <Grid
            item
            xs={12}
            display={"flex"}
            flexWrap={'wrap'}
            justifyContent={"space-between"}
            mb={2}
            alignItems={'center'}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              alignItems={"center"}
              spacing={2}
            >
              <img src={Linkedin} alt="LinkedIn" />
              <Typography>LinkedIn</Typography>
            </Stack>
            {linkButtonComponent(webInfo?.socialMediaLink?.linkedIn, linkedIn)}
          </Grid>

          <Grid
            item
            xs={12}
            display={"flex"}
            flexWrap={'wrap'}
            justifyContent={"space-between"}
            mb={2}
            alignItems={'center'}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              alignItems={"center"}
              spacing={2}
            >
              <img src={Instagram} alt="Youtube" />
              <Typography>Youtube</Typography>
            </Stack>
            {linkButtonComponent(webInfo?.socialMediaLink?.youtube, youtube)}
          </Grid>

          <Grid
            item
            xs={12}
            display={"flex"}
            flexWrap={'wrap'}
            justifyContent={"space-between"}
            mb={2}
            alignItems={'center'}
          >
            <Stack
              display={"flex"}
              direction={"row"}
              alignItems={"center"}
              spacing={2}
            >
              <img src={Instagram} alt="Wordpress" />
              <Typography>Wordpress</Typography>
            </Stack>
            {linkButtonComponent(
              webInfo?.socialMediaLink?.wordpress,
              wordpress
            )}
          </Grid>

          <Divider sx={{ height: "1px", width: "100%" }} />

          <Grid item xs={12} my={2} textAlign="center">
            {socialMediaButtonComponent()}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default SocialLink;
