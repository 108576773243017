import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { formState } from '../../../../../../recoil/profile-atom';
import { postApiCallDynamicWithOutReturn } from '../../../../../../services/api-service';
import { API } from '../../../../../../services/api-config';
import { UPDATE } from '../../../../../../constants/constatnt-variable';
import { DeclarationForm } from './declaration-info-form';


export default function LoanDeclarations() {
    const route = useNavigate()
    const location = useLocation()
    const partnerInfoPath = location.pathname.split("/")[location.pathname.split("/").length - 2]


    let [formValue, setFormValue] = useRecoilState(formState)

    let [declaration, setDeclaration] = useState(() => null)

    let [formValueId, setFormValueId] = useState(() => null);

    useEffect(() => {
        if (partnerInfoPath == "partner-info") {
            setDeclaration(formValue.partnerInfo?.page?.declarations?.subPage)
            setFormValueId(formValue.id)

        } else {
            setDeclaration(formValue.borrowerInfo?.page?.declarations?.subPage)
            setFormValueId(formValue.id)

        }
    }, [formValue.partnerInfo?.page?.declarations?.subPage, formValue.borrowerInfo?.page?.declarations?.subPage, formValue.id])





    const hanldeCheck = async data => {
        console.log("data", data);
        let path, updateState
        if (partnerInfoPath == "partner-info") {
            path = API.partnerInfoDeclarations
            updateState = {
                ...formValue,
                partnerInfo: {
                    ...formValue.partnerInfo,
                    page: {
                        ...formValue.partnerInfo.page,
                        declarations: {
                            ...formValue.partnerInfo.page.declarations,
                            subPage: data,
                            isComplete: true
                        }
                    }
                }
            }
        } else {
            path = API.borrowerInfoDeclarations
            updateState = {
                ...formValue,
                borrowerInfo: {
                    ...formValue.borrowerInfo,
                    page: {
                        ...formValue.borrowerInfo.page,
                        declarations: {
                            ...formValue.borrowerInfo.page.declarations,
                            subPage: data,
                            isComplete: true
                        }
                    }
                }
            }
        }

        const apiCallData = {
            page: {
                subPage: data,
                isComplete: true
            },
            isComplete: true,
            formId: formValue.id
        }

        const setDeclarationsInrecoil = () => {

            setFormValue(updateState)
            route(location.pathname.replace(/[^/]*$/, 'dependent'))
        }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: path,
                header: UPDATE,
                refresh: setDeclarationsInrecoil,
            }
        )
        console.log("apiCallData:", JSON.stringify(apiCallData, null, 2));
    }



    return (
        <>
            {
                formValueId == null ?
                    <></>
                    :
                    <DeclarationForm data={declaration} onCheckSubmit={hanldeCheck} />
            }
        </>
    )
}
