import React from 'react'
import { AttachMoney } from '@mui/icons-material'
import { Button, Checkbox, FormControlLabel, Stack, Typography, Box } from '@mui/material'
import CustomInput from '../../../../../../../helpers/custom-input'

import { Controller, useForm, useWatch } from 'react-hook-form'
import { PropertyAddress } from '../../../../../../../components/loan-application/property-address'
import { usPhoneNumberFormatDefaultValue } from '../../../../../../../constants/validation'
import { TimeStampToMonthDateYearForInput } from '../../../../../../../helpers/time-formater'
import { SecondaryEmployedValidation } from '../../../../../../../constants/validation-rules'
import CustomLargeLabel from '../../../../../../../helpers/large-label'
import CustomRadioChip from '../../../../../../../helpers/radio-chip'
import { ExtraIncomeValidation } from '../../../../../../../constants/validation-rules'

export default function SecondaryEmploymentForm({ onSubmit, editItem }) {




    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            employmentStatus: editItem?.employmentStatus,
            businessName: editItem?.businessName,
            employNumber: usPhoneNumberFormatDefaultValue(editItem?.employNumber),
            positionOfTitle: editItem?.positionOfTitle,
            startDate: TimeStampToMonthDateYearForInput(editItem?.startDate),
            endDate: TimeStampToMonthDateYearForInput(editItem?.endDate),
            profession: editItem?.profession,
            monthlyIncome: editItem?.monthlyIncome,
            addressLine1: editItem?.addressLine1,
            addressLine2: editItem?.addressLine2,
            unit: editItem?.unit,
            city: editItem?.city,
            stateOfCountry: editItem?.stateOfCountry,
            zip: editItem?.zip,
            isStillWorking: editItem?.isStillWorking == "Yes" ? true : false,
        }
    })

    const { stateofCountry, isStillWorking, isSideIncomeAvailable, employedByOther } = useWatch({ control })

    return (
        <form id={'secondaryEmployed'} onSubmit={handleSubmit(onSubmit)}>
            {/* <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}> */}

            <Controller
                name='businessName'
                control={control}
                rules={SecondaryEmployedValidation.businessName}
                render={({ field }) => (
                    <CustomInput {...field} {...field} type={'text'} label={`Employer or Business Name`} required error={errors?.businessName?.message} />
                )}
            />
            <Controller
                name='employNumber'
                control={control}
                rules={SecondaryEmployedValidation.employNumber}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={`Employer Phone Number`} required error={errors?.employNumber?.message} />
                )}
            />

            <Controller
                name='positionOfTitle'
                control={control}
                rules={SecondaryEmployedValidation.positionOfTitle}
                render={({ field }) => (
                    <CustomInput {...field} type={'text'} label={`Position or Title`} required error={errors?.positionOfTitle?.message} />
                )}
            />

            <Controller
                name='startDate'
                control={control}
                rules={SecondaryEmployedValidation.startDate}
                render={({ field }) => (
                    <CustomInput
                        {...field}
                        type={'date'}
                        label={`Start Date`}
                        labelRightComponent={
                            <FormControlLabel
                                control={
                                    <Controller
                                        name='isStillWorking'
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox checked={isStillWorking}  {...field} />
                                        )}
                                    />
                                }
                                label=
                                {`Are you still working there?`}
                            />
                        }
                        required
                        error={errors?.startDate?.message}
                    />
                )}
            />
            {isStillWorking ? null : <Controller
                name='endDate'
                control={control}
                rules={SecondaryEmployedValidation.endDate}
                render={({ field }) => (
                    <CustomInput {...field} type={'date'} label={`End Date`} required error={errors?.endDate?.message} />
                )}
            />}

            <Controller
                name='profession'
                control={control}
                rules={SecondaryEmployedValidation.profession}
                render={({ field }) => (
                    <CustomInput {...field} type={'number'} label={`Years in this line of profession `} required error={errors?.profession?.message} />
                )}
            />
            <Controller
                name='monthlyIncome'
                control={control}
                rules={SecondaryEmployedValidation.monthlyIncome}
                render={({ field }) => (
                    <CustomInput {...field} type={'number'} label={`Base Monthly Income `} sicon={<AttachMoney />} required error={errors?.monthlyIncome?.message} />
                )}
            />

            <Typography variant='h6'>{`Employer or Business Address`}</Typography>
            <PropertyAddress control={control} Controller={Controller} errors={errors} stateOfCountry={stateofCountry} />
            <Stack direction={'column'} justifyContent={'space-between'} py={2} height={'inherit'} overflow={'auto'}>
                <Box>


                    <Box maxWidth={{ xs: '100%', md: '100%' }} mt={2}>

                        <Controller
                            name='isSideIncomeAvailable'
                            control={control}
                            rules={ExtraIncomeValidation.isSideIncomeAvailable}
                            render={({ field }) => (
                                <CustomRadioChip
                                    labelComponent={<CustomLargeLabel value={`Do you receive bonus, military entitlement or commission income?`} required />}
                                    {...field}
                                    field={field}
                                    state={isSideIncomeAvailable}
                                    data={[
                                        { id: 'yes', value: "Yes", label: "Yes" },
                                        { id: 'no', value: "No", label: "No" },
                                    ]}
                                    error={errors?.isSideIncomeAvailable?.message}
                                />
                            )}
                        />
                        {isSideIncomeAvailable == 'Yes' ?
                            <>
                                <Controller
                                    name='avgMonthlyIncome'
                                    control={control}
                                    rules={ExtraIncomeValidation.avgMonthlyIncome}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'number'} label={`Average monthly bonus`} sicon={<AttachMoney />} error={errors?.avgMonthlyIncome?.message} />
                                    )}
                                />

                                <Controller
                                    name='avgMonthlyOvertime'
                                    control={control}
                                    rules={ExtraIncomeValidation.avgMonthlyOvertime}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'number'} label={`Average monthly overtime`} sicon={<AttachMoney />} error={errors?.avgMonthlyOvertime?.message} />
                                    )}
                                />

                                <Controller
                                    name='militaryEntitlement'
                                    control={control}
                                    rules={ExtraIncomeValidation.militaryEntitlement}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'number'} label={`Military entitlement `} sicon={<AttachMoney />} error={errors?.militaryEntitlement?.message} />
                                    )}
                                />

                                <Controller
                                    name='avgMonthlyComission'
                                    control={control}
                                    rules={ExtraIncomeValidation.avgMonthlyComission}
                                    render={({ field }) => (
                                        <CustomInput {...field} type={'number'} label={`Average monthly commission`} sicon={<AttachMoney />} required error={errors?.avgMonthlyComission?.message} />
                                    )}
                                />
                            </>
                            : null
                        }

                    </Box>

                </Box>
            </Stack>

            <FormControlLabel
                control={
                    <Controller
                        name='employedByOther'
                        control={control}
                        render={({ field }) => (
                            <Checkbox checked={employedByOther}  {...field} />
                        )}
                    />
                }
                label=
                {`I am employed by a family member, property seller, real estate agent, or other party to the transaction. `}
            />







            <Stack direction={'row-reverse'} spacing={2}>
                <Button form={'secondaryEmployed'} variant='contained' type='submit'>
                    {editItem.length != 0 ? 'Update' : 'Add'}
                </Button>
            </Stack>
            {/* </Stack> */}
        </form >
    )
}
