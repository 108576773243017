import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import ChatAvater from "../../assets/Avatar-mlo.png";
import React from "react";
import { convertUTCToLocal } from "../../helpers/time-formater";
import { KSCheveronLeftIcon } from "../../icons/custome-icon";

export default function ChatHeader({
  setChatScreen,
  setChatData,
  chatData,
  channel,
}) {

  return (
    <Box
      bgcolor={"primary.main"}
      color={"white"}
      pl={{ xs: 0, md: 1 }}
      pr={3}
      width={{ xs: "100vw", md: "480px" }}
    >
      <Toolbar disableGutters>
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Box>
            <IconButton
              color="inherit"
              onClick={() => {
                setChatScreen(false);
                setChatData({ ...chatData, firstScreen: true });
              }}
            >
              <KSCheveronLeftIcon fontSize="50" />
            </IconButton>
          </Box>
          <Avatar src={ChatAvater} />
          <Box ml={2}>
            <Typography variant="h6">App No: {chatData.appNo}</Typography>
            <Typography variant="body2">
              {`Last seen today at ${convertUTCToLocal(
                channel?.data?.created_by?.last_active
              )}`}
            </Typography>
          </Box>
        </Stack>
      </Toolbar>
    </Box>
  );
}
