import {
  Button,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MainLoyOut from "../../../../components/main-layout";
import CustomInput from "../../../../helpers/custom-input";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller, useForm } from "react-hook-form";
import { DateToTimestapmFormater, TimeStampToMonthDateYearForInput } from "../../../../helpers/time-formater";
import RadioButtonGroup from "../../../../helpers/radio-button-group";
import { PURPOSE_LIST, LOAN_TYPE_LIST, UPDATE } from "../../../../constants/constatnt-variable";
import { API } from "../../../../services/api-config";
import { postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadReportFromServer, getFileUrl } from "../../../../helpers/file-upload-download";
import { QuoteFormValidation } from "../../../../constants/validation-rules";
import { DialogCommonHooks, PreviewPDFModal } from "../../../../helpers/modal/modal";
import FilePreview from "../file-preview";
import { useRecoilState } from "recoil";
import { globalSpinner } from "../../../../recoil/profile-atom";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function QuoteForm({ leadData }) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const router = useNavigate();
  const location = useLocation();
  const leadId = location.pathname.split("/")[location.pathname.split("/").length - 1];
  const [modalOpen, setModalOpen] = useState(false);
  let [, setSpinner] = useRecoilState(globalSpinner);


  const handleClose = () => {
    router("/broker/leads")
  }



  // pre approval form submit logic here
  const onSubmit = async data => {
    setSpinner(true)
    let apiCallData = {
      id: leadId,
      leadInfo: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        dateOfBirth: DateToTimestapmFormater(data?.dateOfBirth),
        phone: data?.phone,
        ssn: data?.ssn,
        monthlyIncome: data?.monthlyIncome,
        totalLiabilities: data?.totalLiabilities,
      },
      leadForm: {
        interestRate: data?.interestRate,
        points: data?.points,
        pmiAmountPerMonth: data?.pmiAmountPerMonth,
        mmpAmountPerMonth: data?.mmpAmountPerMonth,
        closingCostWorksheet: {
          sheetFirstName: data?.sheetFirstName,
          sheetLastName: data?.sheetLastName,
          sheetEmail: data?.sheetEmail,
          sheetNMLS: data?.sheetNMLS,
          sheetPhone: data?.sheetPhone,
          sheetPhoneAlt: data?.sheetPhoneAlt,
          sheetLoanType: data?.sheetLoanType,
          sheetLoanPurpose: data?.sheetLoanPurpose,
          sheetZip: data?.sheetZip,
          sheetPropertyAddress: data?.sheetPropertyAddress,
          sheetSalePrice: data?.sheetSalePrice,
          sheetIssueDate: data?.sheetIssueDate,
          sheetLoanAmount: data?.sheetLoanAmount,
          sheetLoanTerm: data?.sheetLoanTerm,
          sheetInterestRate: data?.sheetInterestRate,
          sheetAnnualPercentageRate: data?.sheetAnnualPercentageRate,
          sheetLoanToValue: data?.sheetLoanToValue,
          sheetPrincipalAndInterest: data?.sheetPrincipalAndInterest,
          sheetOtherFinancing: data?.sheetOtherFinancing,
          sheetOthers: data?.sheetOthers,
          sheetHazardInsurance: data?.sheetHazardInsurance,
          sheetPropertyTaxes: data?.sheetPropertyTaxes,
          sheetMortgageInsurance: data?.sheetMortgageInsurance,
          sheetHOADues: data?.sheetHOADues,

          sheetFundDownPayment: data?.sheetFundDownPayment,
          sheetFundClosingCosts: data?.sheetFundClosingCosts,
          sheetFundReserves: data?.sheetFundReserves,
          sheetFundFundingFee: data?.sheetFundFundingFee,

          sheetCreditsEarnestMoney: data?.sheetCreditsEarnestMoney,
          sheetCreditsSellerCredit: data?.sheetCreditsSellerCredit,
          sheetCreditsLenderCredit: data?.sheetCreditsLenderCredit,
          sheetCreditsSubordinateFinancing: data?.sheetCreditsSubordinateFinancing,

          sheetOriginationAdministrationFee: data?.sheetOriginationAdministrationFee,
          sheetOriginationDiscountPoints: data?.sheetOriginationDiscountPoints,

          sheetLenderAppraisalFee: data?.sheetLenderAppraisalFee,
          sheetLenderCreditReportFee: data?.sheetLenderCreditReportFee,
          sheetLenderFloodCertificationFee: data?.sheetLenderFloodCertificationFee,
          sheetLenderMERSFee: data?.sheetLenderMERSFee,
          sheetLenderNewDebtMonitoring: data?.sheetLenderNewDebtMonitoring,
          sheetLenderTaxServiceFee: data?.sheetLenderTaxServiceFee,

          sheetTitleCountyTransferTax: data?.sheetTitleCountyTransferTax,
          sheetTitleRecordingFee: data?.sheetTitleRecordingFee,
          sheetTitleInsurance: data?.sheetTitleInsurance,
          sheetTitleCourierFee: data?.sheetTitleCourierFee,
          sheetTitleMobileNotaryFee: data?.sheetTitleMobileNotaryFee,
          sheetTitleSettlement: data?.sheetTitleSettlement,

          sheetPrePaidItemsPerDiemInterest: data?.sheetPrePaidItemsPerDiemInterest,
          sheetPrePaidItemsEstimatedReserves: data?.sheetPrePaidItemsEstimatedReserves,

          sheetTransactionSalePrice: data?.sheetTransactionSalePrice,
          sheetTransactionReserves: data?.sheetTransactionReserves,
          sheetTransactionIssueDate: data?.sheetTransactionIssueDate,
          sheetTransactionLoanAmount: data?.sheetTransactionLoanAmount,
          sheetTransactionLoanTerm: data?.sheetTransactionLoanTerm,
          sheetTransactionInterestRate: data?.sheetTransactionInterestRate,
          sheetTransactionAnnualPercentageRate: data?.sheetTransactionAnnualPercentageRate,
          sheetTransactionLoanToValue: data?.sheetTransactionLoanToValue,
          sheetTransactionPrincipalAndInterest: data?.sheetTransactionPrincipalAndInterest,
          sheetTransactionOtherFinancing: data?.sheetTransactionOtherFinancing,
          sheetTransactionOthers: data?.sheetTransactionOthers,
          sheetTransactionHazardInsurance: data?.sheetTransactionHazardInsurance,
          sheetTransactionPropertyTaxes: data?.sheetTransactionPropertyTaxes,
          sheetTransactionMortgageInsurance: data?.sheetTransactionMortgageInsurance,
          sheetTransactionHOADues: data?.sheetTransactionHOADues,
        }
      }
    }
    await postApiCallDynamicWithOutReturn({
      data: apiCallData, refresh: () => { setSpinner(false); setModalOpen(true); }, path: API.lead, header: UPDATE
    })

  }


  const downloadPdf = () => {
    downloadReportFromServer(API.leadDownload, { id: leadId, serviceType: "quote" })

  }

  const handlePdfClose = () => {
    setModalOpen(false)
  }

  const { control, handleSubmit, formState: { errors }, watch } = useForm({
    defaultValues: {
      firstName: leadData?.leadInfo?.firstName,
      lastName: leadData?.leadInfo?.lastName,
      email: leadData?.leadInfo?.email,
      dateOfBirth: TimeStampToMonthDateYearForInput(leadData?.leadInfo?.dateOfBirth),
      phone: leadData?.leadInfo?.phone,
      ssn: leadData?.leadInfo?.ssn,
      monthlyIncome: leadData?.leadInfo?.monthlyIncome,
      totalLiabilities: leadData?.leadInfo?.totalLiabilities,
      // loanType: "",
      // loanPurpose: "",
      // propertyZip: "",
      // propertyAddress: "",
      // salePrice: "",
      // issueDate: "",
      // loanTerm: "",
      interestRate: leadData?.leadForm?.interestRate,
      points: leadData?.leadForm?.points,
      pmiAmountPerMonth: leadData?.leadForm?.pmiAmountPerMonth,
      mmpAmountPerMonth: leadData?.leadForm?.mmpAmountPerMonth,

      sheetFirstName: leadData?.leadForm?.closingCostWorksheet?.sheetFirstName,
      sheetLastName: leadData?.leadForm?.closingCostWorksheet?.sheetLastName,
      sheetEmail: leadData?.leadForm?.closingCostWorksheet?.sheetEmail,
      sheetNMLS: leadData?.leadForm?.closingCostWorksheet?.sheetNMLS,
      sheetPhone: leadData?.leadForm?.closingCostWorksheet?.sheetPhone,
      sheetPhoneAlt: leadData?.leadForm?.closingCostWorksheet?.sheetPhoneAlt,

      sheetLoanType: leadData?.leadForm?.closingCostWorksheet?.sheetLoanType,
      sheetLoanPurpose: leadData?.leadForm?.closingCostWorksheet?.sheetLoanPurpose,
      sheetZip: leadData?.leadForm?.closingCostWorksheet?.sheetZip,
      sheetPropertyAddress: leadData?.leadForm?.closingCostWorksheet?.sheetPropertyAddress,
      sheetSalePrice: leadData?.leadForm?.closingCostWorksheet?.sheetSalePrice,
      sheetIssueDate: leadData?.leadForm?.closingCostWorksheet?.sheetIssueDate,
      sheetLoanAmount: leadData?.leadForm?.closingCostWorksheet?.sheetLoanAmount,
      sheetLoanTerm: leadData?.leadForm?.closingCostWorksheet?.sheetLoanTerm,
      sheetInterestRate: leadData?.leadForm?.closingCostWorksheet?.sheetInterestRate,
      sheetAnnualPercentageRate: leadData?.leadForm?.closingCostWorksheet?.sheetAnnualPercentageRate,
      sheetLoanToValue: leadData?.leadForm?.closingCostWorksheet?.sheetLoanToValue,
      sheetPrincipalAndInterest: leadData?.leadForm?.closingCostWorksheet?.sheetPrincipalAndInterest,
      sheetOtherFinancing: leadData?.leadForm?.closingCostWorksheet?.sheetOtherFinancing,
      sheetOthers: leadData?.leadForm?.closingCostWorksheet?.sheetOthers,
      sheetHazardInsurance: leadData?.leadForm?.closingCostWorksheet?.sheetHazardInsurance,
      sheetPropertyTaxes: leadData?.leadForm?.closingCostWorksheet?.sheetPropertyTaxes,
      sheetMortgageInsurance: leadData?.leadForm?.closingCostWorksheet?.sheetMortgageInsurance,
      sheetHOADues: leadData?.leadForm?.closingCostWorksheet?.sheetHOADues,

      sheetFundDownPayment: leadData?.leadForm?.closingCostWorksheet?.sheetFundDownPayment,
      sheetFundClosingCosts: leadData?.leadForm?.closingCostWorksheet?.sheetFundClosingCosts,
      sheetFundReserves: leadData?.leadForm?.closingCostWorksheet?.sheetFundReserves,
      sheetFundFundingFee: leadData?.leadForm?.closingCostWorksheet?.sheetFundFundingFee,

      sheetCreditsEarnestMoney: leadData?.leadForm?.closingCostWorksheet?.sheetCreditsEarnestMoney,
      sheetCreditsSellerCredit: leadData?.leadForm?.closingCostWorksheet?.sheetCreditsSellerCredit,
      sheetCreditsLenderCredit: leadData?.leadForm?.closingCostWorksheet?.sheetCreditsLenderCredit,
      sheetCreditsSubordinateFinancing: leadData?.leadForm?.closingCostWorksheet?.sheetCreditsSubordinateFinancing,

      sheetOriginationAdministrationFee: leadData?.leadForm?.closingCostWorksheet?.sheetOriginationAdministrationFee,
      sheetOriginationDiscountPoints: leadData?.leadForm?.closingCostWorksheet?.sheetOriginationDiscountPoints,

      sheetLenderAppraisalFee: leadData?.leadForm?.closingCostWorksheet?.sheetLenderAppraisalFee,
      sheetLenderCreditReportFee: leadData?.leadForm?.closingCostWorksheet?.sheetLenderCreditReportFee,
      sheetLenderFloodCertificationFee: leadData?.leadForm?.closingCostWorksheet?.sheetLenderFloodCertificationFee,
      sheetLenderMERSFee: leadData?.leadForm?.closingCostWorksheet?.sheetLenderMERSFee,
      sheetLenderNewDebtMonitoring: leadData?.leadForm?.closingCostWorksheet?.sheetLenderNewDebtMonitoring,
      sheetLenderTaxServiceFee: leadData?.leadForm?.closingCostWorksheet?.sheetLenderTaxServiceFee,

      sheetTitleCountyTransferTax: leadData?.leadForm?.closingCostWorksheet?.sheetTitleCountyTransferTax,
      sheetTitleRecordingFee: leadData?.leadForm?.closingCostWorksheet?.sheetTitleRecordingFee,
      sheetTitleInsurance: leadData?.leadForm?.closingCostWorksheet?.sheetTitleInsurance,
      sheetTitleCourierFee: leadData?.leadForm?.closingCostWorksheet?.sheetTitleCourierFee,
      sheetTitleMobileNotaryFee: leadData?.leadForm?.closingCostWorksheet?.sheetTitleMobileNotaryFee,
      sheetTitleSettlement: leadData?.leadForm?.closingCostWorksheet?.sheetTitleSettlement,

      sheetPrePaidItemsPerDiemInterest: leadData?.leadForm?.closingCostWorksheet?.sheetPrePaidItemsPerDiemInterest,
      sheetPrePaidItemsEstimatedReserves: leadData?.leadForm?.closingCostWorksheet?.sheetPrePaidItemsEstimatedReserves,

      sheetTransactionSalePrice: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionSalePrice,
      sheetTransactionReserves: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionReserves,
      sheetTransactionIssueDate: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionIssueDate,
      sheetTransactionLoanAmount: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionLoanAmount,
      sheetTransactionLoanTerm: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionLoanTerm,
      sheetTransactionInterestRate: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionInterestRate,
      sheetTransactionAnnualPercentageRate: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionAnnualPercentageRate,
      sheetTransactionLoanToValue: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionLoanToValue,
      sheetTransactionPrincipalAndInterest: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionPrincipalAndInterest,
      sheetTransactionOtherFinancing: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionOtherFinancing,
      sheetTransactionOthers: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionOthers,
      sheetTransactionHazardInsurance: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionHazardInsurance,
      sheetTransactionPropertyTaxes: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionPropertyTaxes,
      sheetTransactionMortgageInsurance: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionMortgageInsurance,
      sheetTransactionHOADues: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionHOADues,

    }
  })

  return (
    <MainLoyOut>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container p={5} direction={"column"} spacing={5}>
          <Grid item container direction={"column"}>
            <Grid item>
              <Typography variant="h3">Lead info</Typography>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>

                <Controller
                  name="firstName"
                  control={control}
                  rules={QuoteFormValidation.firstName}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'text'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"First Name"}
                      placeholder={"Enter your first name"}
                      error={errors?.firstName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>

                <Controller
                  name="lastName"
                  control={control}
                  rules={QuoteFormValidation.lastName}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'text'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Last Name"}
                      placeholder={"Enter your last name"}
                      error={errors?.lastName?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="email"
                  control={control}
                  rules={QuoteFormValidation.email}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'email'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Email Id"}
                      placeholder={"Enter your email id"}
                      error={errors?.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="dateOfBirth"
                  control={control}
                  rules={QuoteFormValidation.dateOfBirth}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'date'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Date of Birth"}
                      placeholder={"Mar-15-1994"}
                      error={errors?.dateOfBirth?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="phone"
                  control={control}
                  rules={QuoteFormValidation.phone}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      sicon={<CallOutlinedIcon />}
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Mobile Number"}
                      placeholder={"(887) 509 - 5904"}
                      error={errors?.phone?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="ssn"
                  control={control}
                  rules={QuoteFormValidation.ssn}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Enter your SSN"}
                      placeholder={"455-45-4578"}
                      error={errors?.ssn?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="monthlyIncome"
                  control={control}
                  rules={QuoteFormValidation.monthlyIncome}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      sicon={<CallOutlinedIcon />}
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Total Monthly Income"}
                      placeholder={"$10000"}
                      error={errors?.monthlyIncome?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="totalLiabilities"
                  control={control}
                  rules={QuoteFormValidation.totalLiabilities}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Total Liabilities"}
                      placeholder={"$5000"}
                      error={errors?.totalLiabilities?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>

          <Grid item container direction={"column"}>
            <Grid item my={5}>
              <Typography variant="h3">Credit Score</Typography>
              <Typography variant="body2">
                Press the button to see your Credit Score
              </Typography>
            </Grid>
            <Grid item container direction={"row"}>
              <Grid item sm={12} md={4}>
                <Button size="small" variant="contained">
                  Pull Credit Report
                </Button>
              </Grid>
              <Grid item container sm={12} md={4}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="softPull"
                    name="radio-buttons-group"
                  >
                    <Grid item container>
                      <Grid item>
                        <FormControlLabel
                          value="softPull"
                          control={<Radio />}
                          label="Soft pull"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value="hardPull"
                          control={<Radio />}
                          label="Hard pull"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>

          {/* <Grid item container direction={"column"}>
            <Grid item my={5}>
              <Typography variant="h3">Loan details</Typography>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item container md={5} direction={"column"}>
                <Grid item>
                  <InputLabel>
                    Loan Type
                    <Typography variant="small" color="error">
                      *
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Controller
                    name='loanType'
                    control={control}
                    rules={QuoteFormValidation.loanType}
                    render={({ field }) => (
                      <RadioButtonGroup
                        field={field}
                        data={LOAN_TYPE_LIST}
                        direction="row"
                        labelColor={true}
                        error={errors?.loanType?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item container md={5} direction={"column"}>
                <Grid item>
                  <InputLabel>
                    Loan Purpose
                    <Typography variant="small" color="error">
                      *
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Controller
                    name='loanPurpose'
                    control={control}
                    rules={QuoteFormValidation.loanPurpose}
                    render={({ field }) => (
                      <RadioButtonGroup
                        field={field}
                        data={PURPOSE_LIST}
                        direction="row"
                        labelColor={true}
                        error={errors?.loanPurpose?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>

                <Controller
                  name='propertyZip'
                  control={control}
                  rules={QuoteFormValidation.propertyZip}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      required
                      type="number"
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Property ZIP Code"}
                      placeholder={"Enter your property ZIP code"}
                      error={errors?.propertyZip?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name='propertyAddress'
                  control={control}
                  rules={QuoteFormValidation.propertyAddress}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      required
                      type="text"
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Property Address"}
                      placeholder={"To be determined"}
                      error={errors?.propertyAddress?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name='salePrice'
                  control={control}
                  rules={QuoteFormValidation.salePrice}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      required
                      type="number"
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Sale Price"}
                      placeholder={"Enter the sale price"}
                      error={errors?.salePrice?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="issueDate"
                  control={control}
                  rules={QuoteFormValidation.issueDate}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type="date"
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Date Issued"}
                      placeholder={"25/56/2023"}
                      error={errors?.issueDate?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="email"
                  control={control}
                  rules={QuoteFormValidation.email}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'email'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Email Id"}
                      placeholder={"Enter your email id"}
                      error={errors?.email?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="loanTerm"
                  control={control}
                  rules={QuoteFormValidation.loanTerm}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Loan Term"}
                      placeholder={"Years"}
                      error={errors?.loanTerm?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid> */}

          <Grid item container direction={"column"}>
            <Grid item>
              <Typography variant="h3">Fill by MLO/Broker/MLP</Typography>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="interestRate"
                  control={control}
                  rules={QuoteFormValidation.interestRate}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Interst Rate"}
                      placeholder={"6.000%"}
                      error={errors?.interestRate?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="points"
                  control={control}
                  rules={QuoteFormValidation.points}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Credits or Points"}
                      placeholder={"$ 10,000"}
                      error={errors?.points?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item md={5}>
                <Controller
                  name="pmiAmountPerMonth"
                  control={control}
                  rules={QuoteFormValidation.pmiAmountPerMonth}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Primary Mortgage Insurance Amount per month"}
                      placeholder={"$ 200"}
                      error={errors?.pmiAmountPerMonth?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5}>
                <Controller
                  name="mmpAmountPerMonth"
                  control={control}
                  rules={QuoteFormValidation.mmpAmountPerMonth}
                  render={({ field }) => (
                    <CustomInput
                      {...field}
                      type={'number'}
                      required
                      boxProps={{ my: 2 }}
                      size="small"
                      fullWidth
                      label={"Monthly Mortgage payment Amount per month"}
                      placeholder={"$ 4,000"}
                      error={errors?.mmpAmountPerMonth?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>

          <Grid item>
            <CardActions onClick={handleExpandClick} disableSpacing>
              <Typography variant="h3">Closing Cost Worksheet</Typography>
              <ExpandMore
                expand={expanded}

                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Grid container direction={"column"} spacing={5}>
                  <Grid item container direction={"column"}>
                    <Grid item>
                      <Typography variant="h3">MLO Personal</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetFirstName"
                          control={control}
                          rules={QuoteFormValidation.sheetFirstName}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"First Name"}
                              placeholder={"Enter your first name"}
                              error={errors?.sheetFirstName?.message}
                            />
                          )}
                        />

                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetLastName"
                          control={control}
                          rules={QuoteFormValidation.sheetLastName}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Last Name"}
                              placeholder={"Enter your last name"}
                              error={errors?.sheetLastName?.message}
                            />
                          )}
                        />

                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetEmail"
                          control={control}
                          rules={QuoteFormValidation.sheetEmail}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'email'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Email Id"}
                              placeholder={"Enter your email id"}
                              error={errors?.sheetEmail?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetNMLS"
                          control={control}
                          rules={QuoteFormValidation.sheetNMLS}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"NMLS ID"}
                              placeholder={"Enter your NMLS id"}
                              error={errors?.sheetNMLS?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetPhone"
                          control={control}
                          rules={QuoteFormValidation.sheetPhone}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              sicon={<CallOutlinedIcon />}
                              label={"Mobile Number"}
                              placeholder={"(887) 509 - 5904"}
                              error={errors?.sheetPhone?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetPhoneAlt"
                          control={control}
                          rules={QuoteFormValidation.sheetPhoneAlt}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              sicon={<CallOutlinedIcon />}
                              label={"Mobile Number"}
                              placeholder={"(887) 509 - 5904"}
                              error={errors?.sheetPhoneAlt?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>

                  <Grid item container direction={"column"}>
                    <Grid item my={5}>
                      <Typography variant="h3">Loan details</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item container md={5} direction={"column"}>
                        <Grid item>
                          <InputLabel>
                            Loan Type
                            <Typography variant="small" color="error">
                              *
                            </Typography>
                          </InputLabel>
                        </Grid>
                        <Grid item>
                          <Controller
                            name='sheetLoanType'
                            control={control}
                            rules={QuoteFormValidation.sheetLoanType}
                            render={({ field }) => (
                              <RadioButtonGroup
                                field={field}
                                data={LOAN_TYPE_LIST}
                                direction="row"
                                labelColor={true}
                                error={errors?.sheetLoanType?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container md={5} direction={"column"}>
                        <Grid item>
                          <InputLabel>
                            Loan Purpose
                            <Typography variant="small" color="error">
                              *
                            </Typography>
                          </InputLabel>
                        </Grid>
                        <Grid item>
                          <Controller
                            name='sheetLoanPurpose'
                            control={control}
                            rules={QuoteFormValidation.sheetLoanPurpose}
                            render={({ field }) => (
                              <RadioButtonGroup
                                field={field}
                                data={PURPOSE_LIST}
                                direction="row"
                                labelColor={true}
                                error={errors?.sheetLoanPurpose?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetZip"
                          control={control}
                          rules={QuoteFormValidation.sheetZip}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Property ZIP Code"}
                              placeholder={"Enter your property ZIP code"}
                              error={errors?.sheetZip?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetPropertyAddress"
                          control={control}
                          rules={QuoteFormValidation.sheetPropertyAddress}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Property Address"}
                              placeholder={"166 Barnhill Dr"}
                              error={errors?.sheetPropertyAddress?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetSalePrice"
                          control={control}
                          rules={QuoteFormValidation.sheetSalePrice}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Sale Price"}
                              placeholder={"Enter the sale price"}
                              error={errors?.sheetSalePrice?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetIssueDate"
                          control={control}
                          rules={QuoteFormValidation.sheetIssueDate}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'date'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Date Issued"}
                              placeholder={"25/56/2023"}
                              error={errors?.sheetIssueDate?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetLoanAmount"
                          control={control}
                          rules={QuoteFormValidation.sheetLoanAmount}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Loan Amount"}
                              placeholder={"Loan Amount"}
                              error={errors?.sheetLoanAmount?.message}
                            />
                          )}
                        />

                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetLoanTerm"
                          control={control}
                          rules={QuoteFormValidation.sheetLoanTerm}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Loan Term"}
                              placeholder={"convensional 30 Years"}
                              error={errors?.sheetLoanTerm?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetInterestRate"
                          control={control}
                          rules={QuoteFormValidation.sheetInterestRate}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Interest Rate (%)"}
                              placeholder={"5.23%"}
                              error={errors?.sheetInterestRate?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetAnnualPercentageRate"
                          control={control}
                          rules={QuoteFormValidation.sheetAnnualPercentageRate}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Annual Percentage Rate (%)"}
                              placeholder={"5.88%"}
                              error={errors?.sheetAnnualPercentageRate?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetLoanToValue"
                          control={control}
                          rules={QuoteFormValidation.sheetLoanToValue}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Loan to Value (LTV)"}
                              placeholder={"80.00%"}
                              error={errors?.sheetLoanToValue?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetPrincipalAndInterest"
                          control={control}
                          rules={QuoteFormValidation.sheetPrincipalAndInterest}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Principal and Interest (P&I)"}
                              placeholder={"$3323"}
                              error={errors?.sheetPrincipalAndInterest?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetOtherFinancing"
                          control={control}
                          rules={QuoteFormValidation.sheetOtherFinancing}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Other Financing (P&I)"}
                              placeholder={"$1000"}
                              error={errors?.sheetOtherFinancing?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetOthers"
                          control={control}
                          rules={QuoteFormValidation.sheetOthers}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Others"}
                              placeholder={"$600"}
                              error={errors?.sheetOthers?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetHazardInsurance"
                          control={control}
                          rules={QuoteFormValidation.sheetHazardInsurance}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Hazard Insurance/Flood"}
                              placeholder={"$2000"}
                              error={errors?.sheetHazardInsurance?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetPropertyTaxes"
                          control={control}
                          rules={QuoteFormValidation.sheetPropertyTaxes}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Property Taxes"}
                              placeholder={"$1079"}
                              error={errors?.sheetPropertyTaxes?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetMortgageInsurance"
                          control={control}
                          rules={QuoteFormValidation.sheetMortgageInsurance}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Mortgage Insurance"}
                              placeholder={"$1000"}
                              error={errors?.sheetMortgageInsurance?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetHOADues"
                          control={control}
                          rules={QuoteFormValidation.sheetHOADues}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"HOA Dues"}
                              placeholder={"$500"}
                              error={errors?.sheetHOADues?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>

                  <Grid item container direction={"column"}>
                    <Grid item>
                      <Typography variant="h3">
                        Estimated Funds to Close
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetFundDownPayment"
                          control={control}
                          rules={QuoteFormValidation.sheetFundDownPayment}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Down Payment %"}
                              placeholder={"$1000"}
                              error={errors?.sheetFundDownPayment?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetFundClosingCosts"
                          control={control}
                          rules={QuoteFormValidation.sheetFundClosingCosts}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Closing Costs (excludes PMI/MIP/FF)"}
                              placeholder={"$18,315"}
                              error={errors?.sheetFundClosingCosts?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetFundReserves"
                          control={control}
                          rules={QuoteFormValidation.sheetFundReserves}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Pre-paid Items/Reserves"}
                              placeholder={"$175"}
                              error={errors?.sheetFundReserves?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetFundFundingFee"
                          control={control}
                          rules={QuoteFormValidation.sheetFundFundingFee}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"PMI, MIP, Funding Fee"}
                              placeholder={"$1000"}
                              error={errors?.sheetFundFundingFee?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>

                  <Grid item container direction={"column"}>
                    <Grid item>
                      <Typography variant="h3">Credits</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetCreditsEarnestMoney"
                          control={control}
                          rules={QuoteFormValidation.sheetCreditsEarnestMoney}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Earnest Money"}
                              placeholder={"$10,000"}
                              error={errors?.sheetCreditsEarnestMoney?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetCreditsSellerCredit"
                          control={control}
                          rules={QuoteFormValidation.sheetCreditsSellerCredit}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Seller Credit"}
                              placeholder={"$10,000"}
                              error={errors?.sheetCreditsSellerCredit?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetCreditsLenderCredit"
                          control={control}
                          rules={QuoteFormValidation.sheetCreditsLenderCredit}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Lender Credit"}
                              placeholder={"$20000"}
                              error={errors?.sheetCreditsLenderCredit?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetCreditsSubordinateFinancing"
                          control={control}
                          rules={QuoteFormValidation.sheetCreditsSubordinateFinancing}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Subordinate Financing"}
                              placeholder={"$5000"}
                              error={errors?.sheetCreditsSubordinateFinancing?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>

                  <Grid item container direction={"column"}>
                    <Grid item>
                      <Typography variant="h3">
                        Estimated Closing Costs
                      </Typography>
                    </Grid>

                    <Grid item container py={2}>
                      <Grid item>
                        <Typography variant="body2" fontWeight={"bold"}>
                          Origination Charges
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item md={5}>
                          <Controller
                            name="sheetOriginationAdministrationFee"
                            control={control}
                            rules={QuoteFormValidation.sheetOriginationAdministrationFee}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Administration Fee"}
                                placeholder={"$1595"}
                                error={errors?.sheetOriginationAdministrationFee?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={5}>
                          <Controller
                            name="sheetOriginationDiscountPoints"
                            control={control}
                            rules={QuoteFormValidation.sheetOriginationDiscountPoints}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Discount Points"}
                                placeholder={"$11145"}
                                error={errors?.sheetOriginationDiscountPoints?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item container py={2}>
                      <Grid item>
                        <Typography variant="body2" fontWeight={"bold"}>
                          3rd Party Services (Required by the Lender)
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item md={5}>
                          <Controller
                            name="sheetLenderAppraisalFee"
                            control={control}
                            rules={QuoteFormValidation.sheetLenderAppraisalFee}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Appraisal Fee"}
                                placeholder={"$775"}
                                error={errors?.sheetLenderAppraisalFee?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={5}>
                          <Controller
                            name="sheetLenderCreditReportFee"
                            control={control}
                            rules={QuoteFormValidation.sheetLenderCreditReportFee}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Credit Report Fee"}
                                placeholder={"$42"}
                                error={errors?.sheetLenderCreditReportFee?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item md={5}>
                          <Controller
                            name="sheetLenderFloodCertificationFee"
                            control={control}
                            rules={QuoteFormValidation.sheetLenderFloodCertificationFee}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Flood Certification Fee"}
                                placeholder={"$4"}
                                error={errors?.sheetLenderFloodCertificationFee?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={5}>
                          <Controller
                            name="sheetLenderMERSFee"
                            control={control}
                            rules={QuoteFormValidation.sheetLenderMERSFee}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"MERS Fee"}
                                placeholder={"$25"}
                                error={errors?.sheetLenderMERSFee?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item md={5}>
                          <Controller
                            name="sheetLenderNewDebtMonitoring"
                            control={control}
                            rules={QuoteFormValidation.sheetLenderNewDebtMonitoring}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"New Debt Monitoring"}
                                placeholder={"$12"}
                                error={errors?.sheetLenderNewDebtMonitoring?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={5}>
                          <Controller
                            name="sheetLenderTaxServiceFee"
                            control={control}
                            rules={QuoteFormValidation.sheetLenderTaxServiceFee}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Tax Service Fee"}
                                placeholder={"$69"}
                                error={errors?.sheetLenderTaxServiceFee?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item container py={2}>
                      <Grid item>
                        <Typography variant="body2" fontWeight={"bold"}>
                          3rd Party Services (Title Company Charges)
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item md={5}>
                          <Controller
                            name="sheetTitleCountyTransferTax"
                            control={control}
                            rules={QuoteFormValidation.sheetTitleCountyTransferTax}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"County Transfer Tax"}
                                placeholder={"$770"}
                                error={errors?.sheetTitleCountyTransferTax?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={5}>
                          <Controller
                            name="sheetTitleRecordingFee"
                            control={control}
                            rules={QuoteFormValidation.sheetTitleRecordingFee}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Recording Fee"}
                                placeholder={"$159"}
                                error={errors?.sheetTitleRecordingFee?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item md={5}>
                          <Controller
                            name="sheetTitleInsurance"
                            control={control}
                            rules={QuoteFormValidation.sheetTitleInsurance}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Title-Insurance/Lenders"}
                                placeholder={"$1555"}
                                error={errors?.sheetTitleInsurance?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={5}>
                          <Controller
                            name="sheetTitleCourierFee"
                            control={control}
                            rules={QuoteFormValidation.sheetTitleCourierFee}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Title - Messenger/Courier Fee"}
                                placeholder={"$150"}
                                error={errors?.sheetTitleCourierFee?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item md={5}>
                          <Controller
                            name="sheetTitleMobileNotaryFee"
                            control={control}
                            rules={QuoteFormValidation.sheetTitleMobileNotaryFee}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Title-Mobile Notary Fee"}
                                placeholder={"$150"}
                                error={errors?.sheetTitleMobileNotaryFee?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={5}>
                          <Controller
                            name="sheetTitleSettlement"
                            control={control}
                            rules={QuoteFormValidation.sheetTitleSettlement}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Title-Settlement/Escrow/Clos"}
                                placeholder={"$1863"}
                                error={errors?.sheetTitleSettlement?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>

                  <Grid item container direction={"column"}>
                    <Grid item>
                      <Typography variant="h3">
                        Pre-Paid Items/Reserves
                      </Typography>
                    </Grid>

                    <Grid item container py={2}>
                      <Grid item>
                        <Typography variant="body2" fontWeight={"bold"}>
                          Estimated Pre-Paid Items
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item md={5}>
                          <Controller
                            name="sheetPrePaidItemsPerDiemInterest"
                            control={control}
                            rules={QuoteFormValidation.sheetPrePaidItemsPerDiemInterest}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Per Diem Interest(%)"}
                                placeholder={"$175"}
                                error={errors?.sheetPrePaidItemsPerDiemInterest?.message}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={5}>
                          <Controller
                            name="sheetPrePaidItemsEstimatedReserves"
                            control={control}
                            rules={QuoteFormValidation.sheetPrePaidItemsEstimatedReserves}
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                type={'number'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Estimated Reserves"}
                                placeholder={"$1000"}
                                error={errors?.sheetPrePaidItemsEstimatedReserves?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>

                  <Grid item container direction={"column"}>
                    <Grid item>
                      <Typography variant="h3">Details of Transaction</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionSalePrice"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionSalePrice}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Sale Price"}
                              placeholder={"$699922"}
                              error={errors?.sheetTransactionSalePrice?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionReserves"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionReserves}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Pre-paid Items/Reserves"}
                              placeholder={"$175"}
                              error={errors?.sheetTransactionReserves?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionSalePrice"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionSalePrice}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Sale Price"}
                              placeholder={"Enter the sale price"}
                              error={errors?.sheetTransactionSalePrice?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionIssueDate"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionIssueDate}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'date'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Date Issued"}
                              placeholder={"25/56/2023"}
                              error={errors?.sheetTransactionIssueDate?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionLoanAmount"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionLoanAmount}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Loan Amount"}
                              placeholder={"Loan Amount"}
                              error={errors?.sheetTransactionLoanAmount?.message}
                            />
                          )}
                        />

                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionLoanTerm"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionLoanTerm}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Loan Term"}
                              placeholder={"convensional 30 Years"}
                              error={errors?.sheetTransactionLoanTerm?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionInterestRate"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionInterestRate}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Interest Rate (%)"}
                              placeholder={"5.23%"}
                              error={errors?.sheetTransactionInterestRate?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionAnnualPercentageRate"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionAnnualPercentageRate}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Annual Percentage Rate (%)"}
                              placeholder={"5.88%"}
                              error={errors?.sheetTransactionAnnualPercentageRate?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionLoanToValue"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionLoanToValue}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Loan to Value (LTV)"}
                              placeholder={"80.00%"}
                              error={errors?.sheetTransactionLoanToValue?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionPrincipalAndInterest"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionPrincipalAndInterest}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Principal and Interest (P&I)"}
                              placeholder={"$3323"}
                              error={errors?.sheetTransactionPrincipalAndInterest?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionOtherFinancing"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionOtherFinancing}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Other Financing (P&I)"}
                              placeholder={"$1000"}
                              error={errors?.sheetTransactionOtherFinancing?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionOthers"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionOthers}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Others"}
                              placeholder={"$600"}
                              error={errors?.sheetTransactionOthers?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionHazardInsurance"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionHazardInsurance}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Hazard Insurance/Flood"}
                              placeholder={"$2000"}
                              error={errors?.sheetTransactionHazardInsurance?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionPropertyTaxes"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionPropertyTaxes}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Property Taxes"}
                              placeholder={"$1079"}
                              error={errors?.sheetTransactionPropertyTaxes?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionMortgageInsurance"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionMortgageInsurance}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Mortgage Insurance"}
                              placeholder={"$1000"}
                              error={errors?.sheetTransactionMortgageInsurance?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Controller
                          name="sheetTransactionHOADues"
                          control={control}
                          rules={QuoteFormValidation.sheetTransactionHOADues}
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"HOA Dues"}
                              placeholder={"$500"}
                              error={errors?.sheetTransactionHOADues?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Collapse>
          </Grid>
          <Grid item container md={12} justifyContent={"end"} spacing={2}>
            <Grid item>
              <Button onClick={handleClose} variant="outlined"> Cancel</Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained"> Preview & Save</Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <PreviewPDFModal
        size={'lg'}
        show={modalOpen}
        handleClose={handlePdfClose}
        title={`Generate Quote`}
        body={
          <FilePreview
            fileUrl={getFileUrl(leadId, "quote")}
            downloadPdf={downloadPdf}
          />
        }
      />
    </MainLoyOut>
  );
}
