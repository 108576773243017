import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { DialogCommonHooks } from "../../../../helpers/modal/modal";
import LoanSummaryLoanStatisticsForm from "../loan-summary-forms/loan-summary-loan-statistics-form";
import { KSPencilIcon } from "../../../../icons/custome-icon";
import { LoanSummaryParentData } from "../loan-summary-visiblity";

export default function LoanSummaryLoanStatistics({
  loanSummaryLoanStatisticsData,
  statisticsReloader,
}) {
  const { visiblity } = useContext(LoanSummaryParentData);
  const [loanSummaryModal, setLoanSummaryModal] = useState(false);

  const loanSummaryModalOpen = () => {
    setLoanSummaryModal(true);
  };

  const loanSummaryModalClose = () => {
    setLoanSummaryModal(false);
  };

  return (
    <>
      <Box bgcolor={"white"} borderRadius={3} p={2}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
        >
          <Typography variant="h3">{`Loan Summary`}</Typography>
          <Typography color={"warning.main"} variant="body2">
            {loanSummaryLoanStatisticsData.loanStatus}
          </Typography>
          {visiblity ? (
            <Button
              variant="outlined"
              size="small"
              startIcon={<KSPencilIcon />}
              onClick={loanSummaryModalOpen}
            >{`Edit`}</Button>
          ) : null}
        </Stack>

        <Divider
          style={{ marginTop: 8, marginBottom: 8 }}
          variant="fullWidth"
        />

        {/* Loan Summary Data */}
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <Typography variant="body2">{`Loan Number`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.loanCode
                ? loanSummaryLoanStatisticsData.loanCode
                : "Not available"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2">{`Amount`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.loanAmount
                ? `$ ${loanSummaryLoanStatisticsData.loanAmount.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2 }
                  )}`
                : "Not available"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2">{`Term`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.loanTerm
                ? loanSummaryLoanStatisticsData.loanTerm
                : "Not available"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2">{`Interest Rate`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.interestRate
                ? `${loanSummaryLoanStatisticsData.interestRate} %`
                : "Not available"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2">{`Estimated Monthly Payment`}</Typography>
            <Typography
              variant="body1"
              fontWeight={"bold"}
              color={"primary.main"}
            >
              {loanSummaryLoanStatisticsData.empAmount
                ? `$ ${loanSummaryLoanStatisticsData.empAmount.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2 }
                  )}`
                : "Not available"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2">{`Estimated Monthly Escrow`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.emeAmount
                ? `$ ${loanSummaryLoanStatisticsData.emeAmount.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2 }
                  )}`
                : "Not available"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2">{`Loan Type`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.loanType
                ? loanSummaryLoanStatisticsData.loanType
                : "Not available"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="body2">{`Appraisal Status`}</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {loanSummaryLoanStatisticsData.appraisalStatus
                ? loanSummaryLoanStatisticsData.appraisalStatus
                : "Not available"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Edit Modal of Loan Summaray */}
      {visiblity ? (
        <DialogCommonHooks
          title={`Edit Loan Summary`}
          show={loanSummaryModal}
          handleClose={loanSummaryModalClose}
          body={
            <LoanSummaryLoanStatisticsForm
              statisticsData={loanSummaryLoanStatisticsData}
              statisticsReloader={statisticsReloader}
              handleClose={loanSummaryModalClose}
            />
          }
        />
      ) : null}
    </>
  );
}
