// import { Box, Button, Grid, IconButton, Typography, } from "@mui/material";
// import React, { useMemo, useState } from "react";
// import { useLocation } from "react-router-dom";
// import MainLoyOut from "../../../../components/main-layout";
// import CustomReactMaterialTable from "../../../../helpers/table/react-material-table";
// import { DialogCommonHooks } from "../../../../helpers/modal/modal";
// import NoteForm from "../note/note-form";



// const DocumentLibrary = () => {
//     return (
//         <MainLoyOut>
//             <Box mx={{ md: 15 }}>
//                 <Grid container>
//                     <Grid item xs={12} sm={12} md={12}>
//                         <CustomReactMaterialTable
//                             endPoint={API.note}
//                             params={{ demoRequestId: demoRequestId }}
//                             refetch={tableReload}
//                             columns={columns}
//                             enablePagination={false}
//                             enableToolbarInternalActions={false}
//                             renderTopToolbarCustomActions={(table) => (
//                                 <Box display="flex" justifyContent="flex-end">
//                                     <Button
//                                         variant="outlined"
//                                         onClick={handleOpen}
//                                     >
//                                         Add More
//                                     </Button>
//                                 </Box>
//                             )}
//                         />
//                     </Grid>
//                     HIIIII
//                     <DialogCommonHooks
//                         title="Add New Note"
//                         show={openModal}
//                         handleClose={handleClose}
//                         body={
//                             <NoteForm
//                                 data={data}
//                                 action={action}
//                                 onSubmit={handleSubmit}
//                             />
//                         }
//                     />
//                 </Grid>
//             </Box>
//         </MainLoyOut>
//     )
// }

// export default DocumentLibrary

import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, FormHelperText, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks, DocumentDialogCommonHooks } from '../../../../helpers/modal/modal'
import CustomReactMaterialTable from '../../../../helpers/table/react-material-table'
import { API } from '../../../../services/api-config'
import ConfirmationModel from '../../../../helpers/modal/confirm-modal'
import { KSPencilAltIcon, KSTrashIcon } from '../../../../icons/custome-icon'

import { postApiCallDynamicWithOutReturn } from '../../../../services/api-service'
import { UPDATE } from '../../../../constants/constatnt-variable'
import { kstheme } from '../../../../theme/theme'
import SupportingDocumentForm from './supporting-doc-form'





export default function SupportingDocument() {

    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

    const route = useNavigate()
    const location = useLocation();

    let [formValue, setFormValue] = useState([])

    // let [docList, setDocList] = useState(() =>
    //     formValue?.assetsCredits?.page == null ? [] : _.cloneDeep(formValue?.assetsCredits?.page)
    // )

    let [docList, setDocList] = useState([
        { 'note': 'hello', 'documentType': 'doc1', 'taskType': 'task11', 'documentDecriptor': 'documentDecriptor' },
        { 'note': 'sdfasdasdasdasdasdasda', 'documentType': 'docsdadsadasdasdasdsadas2sdsada', 'taskType': 'sdasdasdasdasdasdasdtsdsadasdasd', 'documentDecriptor': 'documentDecriptor' },
        { 'note': 'world', 'documentType': 'doc2', 'taskType': 'task95', 'documentDecriptor': 'documentDecriptor' }])



    let [customError, setCustomerror] = useState("")

    const [editState, setEditState] = useState(() => {
        return null
    })


    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(docList)
            let editItem = list.find((item, i) => i == editState)
            setDocEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])


    const [deleteState, setDeleteState] = useState(() => {
        return null
    })


    // delete table
    useEffect(() => {
        if (deleteState != null) {
            docList.splice(deleteState, 1)
            setDocList([...docList])
            setDeleteState(null)
        }

    }, [deleteState])



    const tableColumn = useMemo(
        () => [
            {
                accessorKey: "taskType",
                header: "Task Type",
                enableColumnActions: false,
            },
            {
                accessorKey: "documentType",
                header: "Document Type",
                enableColumnActions: false,
            },
            {
                accessorKey: "documentDecriptor",
                header: "Document Descriptor",
                enableColumnActions: false,
            },

            {
                accessorKey: "note",
                header: "Note",
                enableColumnActions: false,
            },


        ],
        []
    );

    const [modalOpen, setModalOpen] = useState(false);
    const [docEdit, setDocEdit] = useState();
    const handleClickOpen = () => {

        setDocEdit([])
        setModalOpen(true);
    };



    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)

    };



    const handleCloseDelete = (event, row) => {

        if (event.target.value == 'yes') {
            setDeleteState(row.index)

        }
    }

    const docsSubmit = (data) => {
        setCustomerror("")
        if (editState != null) {
            docList[editState] = {
                ...docList[editState],
                ...data
            }

            setEditState(null)
        } else {
            docList.push(data);
        }

        setDocList([...docList]);
        handleClose()
    }
    useEffect(() => {
        setDocList([...docList]);
    }, [docList.length])


    const handleAssestCreditFormSubmit = async () => {
        // console.log("docList", docList);
        // if (docList.length == 0) {
        //     setCustomerror("Please enter atleast one assets")
        // } else {
        //     setCustomerror("")
        //     const apiCallData = {
        //         page: docList,
        //         formId: formValue.id,
        //         isComplete: true
        //     }
        //     const updateState = () => {
        //         setFormValue({
        //             ...formValue,
        //             assetsCredits: {
        //                 ...formValue.assetsCredits,
        //                 page: docList,
        //                 isComplete: true
        //             }
        //         })
        //         route(location.pathname.replace(/[^/]*$/, 'real-estate'));
        //     }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: API.assetCredit,
                header: UPDATE,
                refresh: updateState
            }
        )
        //}
    }






    return (
        <>

            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={0.5} height={'inherit'} overflow={'auto'}>
                <Box >
                    {/* <Typography variant='h3' mb={2} >{`Mention all the financial asssts you hold`}</Typography>
                    <Typography variant='h4' mb={2} >{`Bank Accounts, Retirement, and Other Accounts`}</Typography> */}

                    <CustomReactMaterialTable
                        staticLoad={true}
                        staticData={docList}
                        columns={tableColumn}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableSorting={false}
                        enableToolbarInternalActions={false}
                        renderTopToolbarCustomActions={() => (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mr: isMobile ? '0rem' : '1rem', mt: isMobile ? '0.5rem' : '1rem' }}>
                                <Button variant="outlined" onClick={handleClickOpen}>
                                    Add More
                                </Button>
                            </Box>
                        )}
                        enableRowActions={true}
                        renderRowActions={
                            ({ row }) => {

                                return (
                                    <Stack direction={'row'}>
                                        <IconButton onClick={() => { setEditState(row.index) }}>
                                            <KSPencilAltIcon />
                                        </IconButton>


                                        <ConfirmationModel
                                            body={<IconButton
                                                id={row.index} onClick={() => {
                                                    // setDeleteState(row.index)
                                                }}>
                                                <KSTrashIcon />
                                            </IconButton>}
                                            message={`Are you sure want remove  ${row?.original?.documentType} - Savings?`}
                                            title={'Delete Document'}
                                            handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                        />

                                    </Stack>
                                )
                            }
                        }
                    />
                    {customError && <FormHelperText error>{customError}</FormHelperText>}
                </Box>



                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    {/* <Stack direction={'row'} justifyContent={'space-between'}>
                        <Link to={'../borrower-info/personal-information'}>
                            <Button startIcon={<ArrowBackIos />}>
                                Previous
                            </Button>
                        </Link>
                        <Button onClick={handleAssestCreditFormSubmit} endIcon={<ArrowForwardIos />} >
                            Next
                        </Button>
                    </Stack> */}
                </Box>
            </Stack>

            <DocumentDialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={docEdit?.length !== 0 ? `Update Task Documents` : `Add Document`}
                body={
                    <SupportingDocumentForm
                        onSubmit={docsSubmit}
                        editItem={docEdit}
                        hasPartner={formValue?.hasPartner}
                        handleClose={handleClose}
                    />
                }
            />


        </>
    )
}
