import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Path } from "../../routes/route-config";
import { useLocation, useNavigate } from "react-router-dom";
import { getApiCallDynamic } from "../../services/api-service";
import { API } from "../../services/api-config";
import loginImage from "../../assets/bg_sign.png";

export default function InvitationReg(props) {
  const { state, ...location } = useLocation();
  let router = useNavigate();
  const params = new URLSearchParams(location.search);


  const email = params.get('email');
  const service = params.get('service');
  const team = params.get('team');
  const formId = params.get('formId')

  const [verifyCode, setVerifyCode] = useState(() => {
    try {
      return {
        email: email,
        service: service,
        team: team,
        formId: formId
      }
    } catch {
      return;
    }
  });

  let [data, setData] = useState(null);
  const [isVerified, setisVerified] = useState(undefined);
  useEffect(() => {
    if (verifyCode) {
      getApiCallDynamic({ param: verifyCode, path: API.verifyInvitation }).then(
        (res) => {
          if (res && res.status === 200) {
            setisVerified(true);
            setData(res?.data);
          } else {
            if (res?.status === 404 || res?.status === 401) {
              setisVerified(false);
            }
            setisVerified(false);
          }
        }
      );
    } else {
      setisVerified(false);
    }
  }, [verifyCode]);

  useEffect(() => {
    if (isVerified == false) {
      router(Path.landing);
    }
  }, [isVerified]);

  const handleSubmit = () => {
    // // router();
    // let data = {
    //   emailOtpCount: 120,
    //   phoneOtpCount: 200,
    //   twoWayAuthentication: true,
    //   msg: "",
    // };
    // router(Path.otp, {
    //   state: { ...data, email: verifyCode, from: "invitation" },
    // });

    getApiCallDynamic({ param: verifyCode, path: API.generateOtp }).then((res) => {
      if (res?.status === 200) {
        router(Path.otp, {
          state: {
            ...res.data,
            email: verifyCode?.email,
            team: verifyCode?.team,
            role: data.recipientRole,
            from: verifyCode.service,
          },
        });
      }
    });
  };
  return (
    <>
      {isVerified ? (
        <Grid container>
          <Grid
            item
            xs={false}
            sm={6}
            fontWeight={"bold"}
            p={{ sx: 0, md: 5 }}
            height="100vh"
            justifyContent={{ sm: "space-between", md: "initial" }}
            flexDirection={{ xs: "column" }}
            className="bgTablet"

          >
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={false}
              display={{ xs: "none", sm: "block" }}
            >
              <Grid item height={"30vh"}>
                <Box p={5}>
                  <Typography
                    mt={4}
                    sx={{
                      fontFamily: "inter",
                      fontSize: "32px",
                      fontWeight: 200,
                      color: "#888888",
                      lineHeight: "48px",
                    }}
                  >
                    The Best Homes are
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: "32px",
                      fontWeight: 200,
                      color: "#888888",
                      lineHeight: "48px",
                    }}
                  >
                    Financed through
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: "60px",
                      fontWeight: 700,
                      color: "#2F80ED",
                    }}
                  >
                    Kramasoft
                  </Typography>
                </Box>
              </Grid>
              <Grid item height={"60vh"}>
                <Box width={{ xs: "100%", sm: "80%", md: "75%" }}>
                  <img src={loginImage} width={"100%"} style={{ marginTop: "20%" }} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ height: "100vh", overflow: "auto", margin: "auto" }}
          >
            <Box
              px={{ xs: 2, md: 20 }}
              pb={{ xs: 2, md: 3 }}
              textAlign="center"
              minHeight="85vh"
              justifyContent="center"
              flexDirection="column"
              display="flex"
            >
              <Typography variant="h4" fontWeight="bold" mb={3}>
                Welcome Mr. {data?.recipientName}
              </Typography>
              <Box textAlign={"center"}>
                <img
                  src="/images/borrower.png"
                  width="100"
                  height="100"
                  alt={"borrower"}
                />
              </Box>
              <Typography>
                {"Your loan broker"}{" "}
                <b
                  style={{ color: "#2F80ED !important" }}
                >{`Mr. ${data?.sender}`}</b>{" "}
                {"has invited you for to"}
                <br />
                {`collaborate as ${data?.recipientRole} with him on Kramasoft.`}
              </Typography>
              <Typography sx={{ mt: 3 }}>{"Let’s get started!"}</Typography>

              <Box my={2}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{ borderRadius: "24px", fontWeight: "bold" }}
                  onClick={handleSubmit}
                >
                  Generate OTP
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
