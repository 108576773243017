
export const CUSTOM_ERROR_MESSAGE = {
    required: "Required",
    name: "Enter your name",
    firstName: "Enter your first name",
    lastName: "Enter your last name",
    companyName: "Enter company name",
    minFirstName: "Minimum 3 alphabet",
    maxName: "Maximum 10 alphabet",
    ssn: "Requred",
    ssnMinMax: "Enter valid ssn",
    dateOfBirth: "Enter your date Of Birth",
    minDateOfBirth: "At least 18 years old",
    phone: "Enter your phone no",
    validPhone: "Enter valid phone no",
    email: "Enter your email id",
    propertyAddress: "Enter your property address",
    salePrice: "Enter your sale price",
    issueDate: "Enter your issue date",
    loanTerm: "Enter your loan term",
    interestRate: "Enter your interest rate",
    points: "Enter your credits or points",
    pmiAmountPerMonth: "Enter your Primary Mortgage Insurance Amount per month",
    mmpAmountPerMonth: "Enter your Monthly Mortgage payment Amount per month",
    sheetNMLS: "Enter your NMLS Id",
    sheetNMLSValid: "Enter your valid NMLS Id",
    sheetLoanAmount: "Enter your loan amount",
    sheetLoanTerm: "Enter your loan term",
    sheetInterestRate: "Enter your interest rate",
    sheetAnnualPercentageRate: "Enter your Annual Percentage Rate",
    sheetLoanToValue: "Enter your Loan To Value",
    sheetPrincipalAndInterest: "Enter your Principal And Interest",
    sheetOtherFinancing: "Enter your Other Financing",
    sheetOthers: "Enter your Other",
    sheetHazardInsurance: "Enter your Hazard Insurance",
    sheetPropertyTaxes: "Enter your Property Taxes",
    sheetMortgageInsurance: "Enter your Mortgage Insurance",
    sheetHOADues: "Enter your HOA Dues",

    sheetFundDownPayment: "Enter your Down Payment",
    sheetFundClosingCosts: "Enter your Closing Costs",
    sheetFundReserves: "Enter your Reserves",
    sheetFundFundingFee: "Enter your Funding Fee",

    sheetCreditsEarnestMoney: "Enter your Earnest Money",
    sheetCreditsSellerCredit: "Enter your Seller Credit",
    sheetCreditsLenderCredit: "Enter your Lender Credit",
    sheetCreditsSubordinateFinancing: "Enter your Subordinate Financing",

    sheetOriginationAdministrationFee: "Enter your Administration Fee",
    sheetOriginationDiscountPoints: "Enter your Discount Points",

    sheetLenderAppraisalFee: "Enter your Appraisal Fee",
    sheetLenderCreditReportFee: "Enter your Credit Report Fee",
    sheetLenderFloodCertificationFee: "Enter your Flood Certification Fee",
    sheetLenderMERSFee: "Enter your MERS Fee",
    sheetLenderNewDebtMonitoring: "Enter your New Debt Monitoring",
    sheetLenderTaxServiceFee: "Enter your Tax Service Fee",

    sheetTitleCountyTransferTax: "Enter your County Transfer Tax",
    sheetTitleRecordingFee: "Enter your Recording Fee",
    sheetTitleInsurance: "Enter your Insurance",
    sheetTitleCourierFee: "Enter your Courier Fee",
    sheetTitleMobileNotaryFee: "Enter your Mobile Notary Fee",
    sheetTitleSettlement: "Enter your Settlement",

    sheetPrePaidItemsPerDiemInterest: "Enter your Per Diem Interest",
    sheetPrePaidItemsEstimatedReserves: "Enter your Estimated Reserves",

    sheetTransactionSalePrice: "Enter your Sale Price",
    sheetTransactionReserves: "Enter your Reserves",
    // sheetTransactionSalePrice: "Enter your Transaction Sale Price",
    sheetTransactionIssueDate: "Enter your Issue Date",
    sheetTransactionLoanAmount: "Enter your Loan Amount",
    sheetTransactionLoanTerm: "Enter your Loan Term",
    sheetTransactionInterestRate: "Enter your Interest Rate",
    sheetTransactionAnnualPercentageRate: "Enter your Annual Percentage Rate",
    sheetTransactionLoanToValue: "Enter your Loan To Value",
    sheetTransactionPrincipalAndInterest: "Enter your Principal And Interest",
    sheetTransactionOtherFinancing: "Enter your Other Financing",
    sheetTransactionOthers: "Enter your Others",
    sheetTransactionHazardInsurance: "Enter your Hazard Insurance",
    sheetTransactionPropertyTaxes: "Enter your Property Taxes",
    sheetTransactionMortgageInsurance: "Enter your Mortgage Insurance",
    sheetTransactionHOADues: "Enter your HOA Dues",

    loanToValue: "Enter your loan To Value",
    loanAmount: "Enter your loan Amount",
    maxPurchasePriceEligibility: "Enter your max Purchase Price Eligibility",

    validPEmail: "Enter valid email id",
    addressLine1: "Enter address line 1",
    addressLine2: "Enter address line 2",
    addressMaxMsg: "Maximum address lenth is 50",
    addressMinMsg: "Minimum address lenth is 15",
    city: "Enter your city",
    county: "Enter your county",
    country: "Enter your country",
    zip: "Enter your zip code",
    validZip: "Enter valid zip Code",
    year: "Enter your period stay year",
    minPeriodStay: "Minimum 0 digit",
    maxPeriodStay: "maximum 2 digits ",
    month: "Enter your period stay month",
    minPeriodStayMonth: "Minimum 0 digit",
    maxPeriodStayMonth: "maximum 2 digits ",
    minPeriodMonth: "Invalid Month",
    rentAmount: "Enter your rent amount",
    minRentAmount: "Minimum 0 digit",
    maxRentAmount: "Maximum 7 digits",
    dependentAge: "Enter dependent number",
    depentNumber: "Enter dependent age",
    minDependent: "Minimum 1 years",
    maxDependent: "Maximum 100 years",
    minDependentNumber: "Minimum 1 dependent",
    maxDependentNumber: "Maximum 4 dependent",
    businessName: "Enter your employee or business name",
    minbusinessName: "Minimum 5 character",
    maxbusinessName: "Maximum 20 character",
    positionOfTitle: "Enter Your position title",
    minpositionOfTitle: "Minimum 2 character",
    maxpositionOfTitle: "Maximum 20 character",
    startDate: "Enter your start date",
    endDate: "Enter your End date",
    profession: "Enter your profession year",
    payFrequency: "Select Pay Frequency",
    monthlyIncome: "Enter your monthly income",
    monthlyBonus: "Enter your monthly bonus",
    monthlyOvertime: "Enter your monthly overtime",
    militaryEntitlement: "Enter your military entitlement",
    mothlyCommission: "Enter your monthly comission",
    prevGrossMonthlyIncome: "Enter your monthly comission",
    retirementDate: "Enter your retirement date",
    retirementIncome: "Enter your retirement month income",
    serviceStatus: "Enter your service status",
    expireDate: "Enter your expire date",
    selectOne: "Select One",
    institutionName: "Enter your institution name",
    minInsitutionName: "Minimum 5 Characters",
    maxInstitutionName: "Maximum 20 characters",
    accountNo: "Enter your account no",
    minaccountNo: "Minimum 6 digits",
    maxaccountNo: "Maximum 12 digits",
    marketValue: "Enter your market value",
    minmarketValue: "minimum 2 digit",
    maxmarketValue: "Maximum 12 digits",
    propertyInsurance: "Enter your property insurance",
    minpropertyInsurance: "Minimum 2 digit",
    maxpropertyInsurance: "Maximum 8 digit",
    propertyTaxes: "Enter your property taxes",
    minpropertyTaxes: "Minimum 2 digit",
    maxpropertyTaxes: "Maximum 8 digit",
    solarLeasePayment: "Enter your solar lease payment",
    hoaPayment: "Enter your HOA payment",
    mortgageInsurance: "Enter mortage Insurance",
    minMortgageInsurance: "Minimum 2 digit",
    maxMortgageInsurance: "Maximum 8 digit",

    //ERROR_MESSAGE
    taskType: "Required Task Type",
    documentType: "Required Document Type",
    documentDescriptor: "Required Document Descriptor",
    note: "Required Note",
    title: "Required Task Title",
    description: "Required Task Description",
    isLender: "Required Lender Condition",
    dueDate: "Required Due Date",
    brokerDocId: "Required Document",
    borrowerDocId: "Required Document",
    priority: "Required Task Priority",
    ownerNote: "Required Owner Note",
    borrowerNote: "Required Borrower Note",

    //LOAN_SUMMARY_STATISTICS_ERROR_MESSAGE
    loanCode: "Required Loan Code",
    empAmount: "Required EMP Amount",
    emeAmount: "Required EME Amount",
    lockStatus: "Required Lock Status",
    lockExpirationDate: "Required Lock Expiration Date",
    loanType: "Required Loan Type",
    appraisalStatus: "Required Appraisal Status",

    //ERROR_MESSAGE
    changeMLOMLP: "Required value",

    //ERROR_MESSAGE
    loanState: "Required State Selection",

    //DECLARATION - ERROR_MESSAGE
    primaryResident: 'required',
    ownershipProperty: 'required',
    declarationTypeOfProperty: 'required',
    declarationTitleOfProperty: 'required',
    affiliation: 'required',
    moneyFromAnotherParty: 'required',
    amountFromAnotherParty: 'required',
    loanOnAnotherProperty: 'required',
    creditOnBeforeLoan: 'required',
    lien: 'required',
    coSignerOrGuarantor: 'required',
    outstandingJudgement: 'required',
    federalDept: 'required',
    partyLawsuit: 'required',
    convertTitle: 'required',
    preForeclosure: 'required',
    propertyForeclosed: 'required',
    declaredBankruptcy: 'required',
    typesOfBankruptcy: 'required',



    //ERROR_MESSAGE
    buyingOrRefinancing: 'Select for next step',
    loanCategory: 'Select for next step',
    statusOfPurchase: 'Select for next step',
    statusOfRealtor: 'Select for next step',
    solarPurchasedStatus: 'Required',
    isRecentlyRefinanced: 'Required',
    mixedUsePropertyStatus: 'Required',
    manufacturedHomeStatus: 'Required',

    isMortgageStatementAvailable: 'Required',
    typeOfRefinance: 'Required',
    reasonOfRefinance: 'Required',
    cashoutAmount: 'Enter cashout amount',
    loanTermType: 'Required',
    loanOptions: 'Required',
    homeHOAManagement: 'Required',
    propertyType: 'Required',
    attachmentType: 'Required',
    occupancyType: 'Required',
    unit: 'Enter unit',
    state: 'Enter your state',
    closingDate: 'Enter closing date',
    datePropertyAcquired: 'Enter date Property Acquired date',
    lastFinancedDate: 'Enter last Financed Date date',
    propertyBuiltDate: 'Enter property built date',
    purchasePrice: 'Enter purchase price',
    estimatedPropertyValue: 'Enter estimated property value',
    downPayment: 'Enter valid percentage',
    rateMortgagePayment: 'Enter interest rate',
    HOIAmount: 'Enter HOI Amount',
    HOADues: 'Enter HOA Dues',
    investmentMonthIncome: 'Enter Monthly rental income',
    typeOfTitle: 'Enter type of title',
    solarleaseapyment: 'Enter solar lease payment',
    countryPropertyTax: 'Enter country Property Tax',
    minmortageInsurance: "Minimum 2 digit",

    suffix: "Enter your suffix",
    middleName: "Enter your middle name",
    documentName: "Enter the document name",
    note: "Enter notes",
    unpaidBalance: "Enter unpaid balance",
    monthlyPay: "Enter monthly payment",
    monthsLeft: "Enter months left to pay",

    coBorrower: "Select Co-Borrower",
    primaryBorrower: "Select Primary Borrower"
};