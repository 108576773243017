import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    Typography,
    FormLabel,
    Divider,
} from "@mui/material";
import React from "react";

export default function RadioButtonGroupDoc({
    label,
    required,
    data = [],
    defaultValue,
    state,
    direction = "column",
    labelColor = false,
    field,
    error = null,
    labelComponent,
    borderBottom = false,
    row = true,
    ...props
}) {

    const handleChange = (event) => {
        state.saveValue(event.target.value);
    };

    return (
        <FormControl>
            <FormLabel>
                <Typography
                    style={{
                        color: 'black !important'// Force the label color to black
                    }}
                >
                    {label}
                </Typography>
                {labelComponent ? labelComponent : null}
                {required ? (
                    <Typography variant="small" color="error">
                        *
                    </Typography>
                ) : null}
            </FormLabel>

            <RadioGroup
                row={row}
                defaultValue={defaultValue}
                {...(field ? field : { onChange: handleChange })}
            >
                {
                    data?.map((item) => (
                        <FormControlLabel
                            key={item.value}
                            value={item.value}
                            control={<Radio />}
                            label={item.label}
                            sx={{ color: item.value == field?.value ? 'primary.main' : 'grey' }}
                        />))
                }
            </RadioGroup>
            {borderBottom ? <Divider /> : null}
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    );
}
