import { AttachMoney, } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import React from 'react'
import {
    ACCOUNT_TYPE,
    ASSET_OWNED_BY,
    ASSET_OWNED_BY_WITHOUT_PARTNER,
    STATUS_OF_REALTOR
} from '../../../../../constants/constatnt-variable'
import CustomInput from '../../../../../helpers/custom-input'
import CustomInputSideCheckbox from '../../../../../helpers/custom-input-side-checkbox'
import CustomDropDown from '../../../../../helpers/dropdown/custom-dropdown'
import { Controller, useForm } from 'react-hook-form'
import { AssetsCreditValidation } from '../../../../../constants/validation-rules'
import CustomRadioChip from '../../../../../helpers/radio-chip'


export function AssetCreditForm({ handleClose, onSubmit, editItem, hasPartner }) {



    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            accountType: editItem?.accountType,
            institutionName: editItem?.institutionName,
            accountNo: editItem?.accountNo,
            marketValue: editItem?.marketValue,
            ownedBy: editItem?.ownedBy,
            isDownPayment: editItem?.isDownPayment
        }
    })

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box width={'100%'} my={2} >
                <Box>
                    <Controller
                        name='accountType'
                        control={control}
                        rules={AssetsCreditValidation.accountType}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'Account Type'}
                                required
                                option={ACCOUNT_TYPE}
                                error={errors?.accountType?.message}
                            />
                        )}
                    />

                    <Controller
                        name="institutionName"
                        control={control}
                        rules={AssetsCreditValidation.institutionName}
                        render={({ field }) => (
                            <CustomInput {...field} type={'text'} label={'Institution Name'} required error={errors?.institutionName?.message} />
                        )}
                    />

                    <Controller
                        name="accountNo"
                        control={control}
                        rules={AssetsCreditValidation.accountNo}
                        render={({ field }) => (
                            <CustomInputSideCheckbox {...field} type={'number'} label={'Account No.'} required error={errors?.accountNo?.message} />
                        )}
                    />

                    <Controller
                        name="marketValue"
                        control={control}
                        rules={AssetsCreditValidation.marketValue}
                        render={({ field }) => (
                            <CustomInput {...field} type={'number'} label={'Cash or Market value'} required sicon={<AttachMoney />} error={errors?.marketValue?.message} />
                        )}
                    />

                    <Controller
                        name="ownedBy"
                        control={control}
                        rules={AssetsCreditValidation.ownedBy}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'Asset Owned by'}
                                required
                                option={hasPartner ? ASSET_OWNED_BY : ASSET_OWNED_BY_WITHOUT_PARTNER}
                                error={errors?.ownedBy?.message}
                            />
                        )}
                    />
                    <Controller
                        name='isDownPayment'
                        control={control}
                        rules={AssetsCreditValidation.isDownPayment}
                        render={({ field }) => (
                            <CustomRadioChip
                                {...field}
                                field={field}
                                required
                                label={'Is this asset going to use for down payment?'}
                                data={STATUS_OF_REALTOR}
                                error={errors?.isDownPayment?.message}
                            />
                        )}
                    />
                </Box>

                <Box textAlign={'right'}>
                    <Button type='submit' variant='contained'>
                        {editItem.length !== 0 ? 'Update' : 'Add'}
                    </Button>
                </Box>
            </Box>
        </form>

    )
}