import { AttachMoney } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../../../../../../helpers/custom-input";
import { TimeStampToMonthDateYearForInput } from "../../../../../../../helpers/time-formater";
import { currentDate } from "../../../../../../../helpers/common_fuctions/current-date";
import { RetiredValidation } from "../../../../../../../constants/validation-rules";

export default function RetiredForm({ onSubmit, editItem }) {

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      retirementDate: TimeStampToMonthDateYearForInput(
        editItem?.retirementDate
      ),
      retirementIncome: editItem?.retirementIncome,
    },
  });
  return (
    <form key={"retired"} id={"retired"} onSubmit={handleSubmit(onSubmit)}>

      <Controller
        name="retirementDate"
        control={control}
        rules={RetiredValidation.retirementDate}
        render={({ field }) => (
          <CustomInput
            {...field}
            type={"date"}
            maxDate={currentDate()}
            label={`Retirement Date`}
            required
            error={errors?.retirementDate?.message}
          />
        )}
      />

      <Controller
        name="retirementIncome"
        control={control}
        rules={RetiredValidation.retirementIncome}
        render={({ field }) => (
          <CustomInput
            {...field}
            type={"number"}
            label={`Retirement Income per month`}
            sicon={<AttachMoney />}
            required
            error={errors?.retirementIncome?.message}
          />
        )}
      />
      <Stack direction={"row-reverse"} spacing={2}>
        <Button form={"retired"} variant="contained" type="submit">
          {editItem.length != 0 ? "Update" : "Add"}
        </Button>
      </Stack>
      {/* </Stack> */}
    </form>
  );
}
