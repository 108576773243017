import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import loginImage from "../assets/bg_sign.png";

function AuthSideUi() {
  return (
    <Grid item
      md={6}
      sm={6}
      xs={false}
      display={{ xs: "none", sm: "flex" }}
    >
      <Stack direction={'column'} justifyContent={{ xs: 'center', md: 'space-between' }} width={'100%'}>
        <Box p={{ xs: 1, md: 5 }} mb={{ xs: 5, md: 0 }}>
          <Typography
            sx={{
              fontFamily: "inter",
              fontSize: "32px !important",
              fontWeight: 200,
              color: "#888888",
              lineHeight: "48px",
            }}
          >
            The Best Homes are
          </Typography>
          <Typography
            sx={{
              fontFamily: "inter",
              fontSize: "32px !important",
              fontWeight: 200,
              color: "#888888",
              lineHeight: "48px",
            }}
          >
            Financed through
          </Typography>
          <Typography
            sx={{
              fontFamily: "inter",
              fontSize: "60px !important",
              fontWeight: 700,
              color: "#2F80ED",
            }}
          >
            Kramasoft
          </Typography>
        </Box>

        <Box width={{ xs: "100%", md: "85%" }}>
          <img src={loginImage} width={"100%"} />
        </Box>
      </Stack>
    </Grid>
  );
}

export default AuthSideUi;
