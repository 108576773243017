import { Button, Stack } from '@mui/material';
import React from 'react';

const FilePreview = ({ fileUrl, downloadPdf }) => {
    return (
        <>
            <iframe
                src={`${fileUrl}#toolbar=0`}
                title="File Preview"
                width="100%"
                height="auto"
                style={{ border: 'none', minHeight: '70vh' }}
            />
            <Stack direction={"row-reverse"} gap={2} p={1}>
                {/* <Button
                    variant="contained"
                    size='small'
                // onClick={downloadPdf}
                >
                    {`Send`}
                </Button> */}
                
                <Button
                    variant="contained"
                    onClick={downloadPdf}
                >
                    {`Download`}
                </Button>
            </Stack>
        </>
    );
};

export default FilePreview;