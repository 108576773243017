import { CUSTOM_ERROR_MESSAGE as msg } from "./error-message"

export const ERROR_MESSAGE = msg

export const PAGENAME = {
  signin: "signin",
  signup: "signup",
  forget: "forget",
  invitation: "invitation",
};
export const MSG = {
  emailChanged: "Email Changed Successfully",
  changePassword: "Password Changed Successfully",
  userCreated: "User Created Successfully",
};

export const title_MAP = {
  settings: "Settings",
  invitation: "Invitations",
  loan: "Loan",
  profile: "Profile",
  account: "Account",
  website: "Website",
  preferences: "Preferences",
  team: "Team",
  merge: "Merge",
  "additional-loan-contact": "Additional Loan Contact",
  "credco-credentials": "Credco Credentioals",
  "loan-application": "Loans",
  "invitation-list": "Invitations",
  leads: "Leads",
  support: 'Support',
  'demo-request': 'Demo Request'
};

export const CREATE = "create";
export const UPDATE = "update";
export const DELETE = "delete";
export const PUT = "put";
export const POST = "post";

export const MODAL_ACTION = {
  [UPDATE]: "Update",
  [PUT]: "put",
  [CREATE]: "Add",
  [DELETE]: "Delete",
};

export const TEAM_TABS = {
  Manage: "#manage",
  ReassignMLO: "#reassign-mlo",
  ReassignMLP: "#reassign-mlp",
};

export const WEBSITE_TABS = {
  Bio: "#bio",
  SocialMediaLink: "#social-media-link",
  Reviews: "#reviews",
  Rates: "#rates",
  Publish: "#publish",
};
export const SERVICE_TYPE = {
  signup: "signup",
  signin: "signin",
  forget: "forget-password",
  invitation: "invitation",
  teamInvitation: "team-invitation",
  coBorrowerInvitation: "co-borrower-invitation",
  emailChange: "change-email",
  changePassword: "change-password",
  changeCompanyNmlsId: "change-company-nmls-id",
  changeNmlsId: "change-nmls-id",
  changePhone: "change-phone",
  adminChangeEmail: "admin-change-email",
  adminChangePhone: "admin-change-phone",
};

export const ROLE_LIST = {
  admin: "Admin",
  mlo: "MLO",
  mlp: "MLP",
  mloOwner: "MLO Owner",
  broker: "Broker",
  borrower: "Borrower",
};

export const TEAM_INVITATION_ROLE = [
  { label: "MLO", value: "MLO" },
  { label: "MLP", value: "MLP" },
];

export const TEAM_INVITATION_ROLE_MLP = [
  { label: "MLP", value: "MLP" },
];

export const DEMO_STATUS = [
  { label: "Pending", value: "Pending" },
  { label: "Completed", value: "Completed" },
  { label: "Declined", value: "Declined" },
];

export const NMLS_STATUS = [
  { value: "not validated", label: "Not validated" },
  { value: "valid", label: "Valid NMLS ID" },
  { value: "invalid", label: "Invalid NMLS ID" },
];

export const NMLS_STATUS_INVALITED = "invalid";
export const NMLS_STATUS_VALID = "valid";

export const LOAN_STATUS = {
  active: "Active",
  inProgress: "In Progress",
  rejected: "Rejected",
  submitted: "Submitted",
};

export const SETTING_MENU = {
  Profile: "profile",
  Account: "account",
  Preferences: "preferences",
  Team: "team",
  Merge: "merge",
  Website: "website",
};

export const CREDCO_STATUS = [
  { value: "Not validated", label: "Not validated" },
  { value: "Valid Credentials", label: "Valid Credentials" },
  { value: "Invalid Credentials", label: "Invalid Credentials" },
];

export const CREDCO_INFO = [
  { value: "use broker info", label: "Use broker info" },
  { value: "my info", label: "My info" },
];

export const roleOwner = (role) => {
  return role == ROLE_LIST.broker || role == ROLE_LIST.mloOwner;
};

// Alert Deafult constant value
export const DEFAULT_ALERET_SEVERITY = {
  state: false,
  data: {
    severity: "",
    msg: "",
  },
};

// Quote form Constant
export const LOAN_TYPE_LIST = [
  { label: "Conventional", value: "Conventional" },
  { label: "FHA", value: "FHA" },
  { label: "VA", value: "VA" },
];

export const PURPOSE_LIST = [
  { label: "Purchase", value: "Purchasing" },
  { label: "Refinance", value: "Refinancing" },
];

export const DESIGNATOR_LIST = [
  { label: "Realtor Buyer", value: "Realtor Buyer" },
  { label: "Realtor Seller", value: "Realtor Seller" },
  { label: "Escrow Officer", value: "Escrow Officer" },
  { label: "Escrow Assistant", value: "Escrow Assistant" },
  { label: "Lender Officer", value: "Lender Officer" },
  { label: "Lender Assistant", value: "Lender Assistant" },
];

export const LIST_DATA = [
  { value: "Application in Review", label: "Application in Review" },
  { value: "Ready to Submit to Lender", label: "Ready to Submit to Lender" },
  { value: "Submitted to Lender", label: "Submitted to Lender" },
  { value: "In Underwriting", label: "In Underwriting" },
  { value: "Conditionally Approved", label: "Conditionally Approved" },
  { value: "Clear to Close", label: "Clear to Close" },
  { value: "Closing Scheduled", label: "Closing Scheduled" },
  { value: "Funded", label: "Funded" },
  { value: "Rejected", label: "Rejected" },
];


//from loan application constant

export const LOAN_PARTNER_STATUS = [
  { id: 'yes', value: "Yes", label: "Yes" },
  { id: 'no', value: "No", label: "No" },
]

export const CONSENT_STATUS = [
  { id: 'yes', value: "yes", label: "Yes" },
  { id: 'no', value: "no", label: "No" },
]

export const LOAN_CO_BORROWER_STATUS = [
  { id: 'yes', value: "yes", label: "Yes" },
  { id: 'no', value: "no", label: "No" },
]


export const BUYING_AND_REFINACEING_LIST = [
  { label: "Purchase", value: "Purchasing" },
  { label: "Refinance", value: "Refinancing" }

]

export const TYPES_OF_LOAN = [
  { label: "Conventional", value: "Conventional" },
  { label: "FHA", value: "FHA" },
  { label: "VA", value: "VA" },
  { label: "USDA", value: "USDA" },
  { label: "Other", value: "Other" },

]

{/*
export const LOAN_TERM_TYPE = [
  { value: "30 Years", label: "30 Years Fixed" },
  { value: "20 Years", label: "20 Years Fixed" },
  { value: "15 Years", label: "15 Years Fixed" },
  { value: "10 Years", label: "10 Years Fixed" },
  { value: "5 Years", label: "5 Years Fixed" },
]*/}

export const LOAN_TERM_TYPE = [
  { value: "30", label: "30 Years Fixed" },
  { value: "20", label: "20 Years Fixed" },
  { value: "15", label: "15 Years Fixed" },
  { value: "10", label: "10 Years Fixed" },
  { value: "5", label: "5 Years Fixed" },
]


export const LOAN_TERM_TYPE_FIXED = 'Fixed'

export const LOAN_TERM_TYPE_ARM = 'Adjustable Rate Mortgage(ARM)'

export const LOAN_TERM_TYPE_FIXED_OPTION = [
  { value: "30 Years Fixed", label: "30 Years Fixed" },
  { value: "15 Years Fixed", label: "15 Years Fixed" },
]

export const LOAN_TERM_TYPE_ARM_OPTION = [
  { value: "Fixed", label: "Fixed" },
  { value: "10/1", label: "ARM 10/1" },
  { value: "7/1", label: "ARM 7/1" },
  { value: "5/1", label: "ARM 5/1" },
  { value: "3/1", label: "ARM 3/1" },
  { value: "1/1", label: "ARM 1/1" },
]

export const LOAN_DECLARATIONS = [
  { id: 'yes', value: "yes", label: "Yes" },
  { id: 'no', value: "no", label: "no" },
]


//------------------------------------------
// loan details
export const STATUS_OF_PURCHASE = [
  { label: "TBD", value: 'TBD' },
  { label: "Offer Accepted", value: 'Offer Accepted' },
  { label: "Under Construction", value: 'Under Construction' },
  {/*{ label: "Searching", value: 'Searching' },
  { label: "Found Property", value: 'Found Property' },
  { label: "Offer Accepted", value: 'Offer Accepted' },
  { label: "Sending Offers", value: 'Sending Offers' },
{ label: "TBD", value: 'TBD' },*/},

]

export const STATUS_OF_REALTOR = [
  { id: 'yes', value: 'Yes', label: "Yes" },
  { id: 'no', value: 'No', label: "No" },
]

export const SOLAR_PURCHASED_STATUS = [
  { id: 'no solar', value: "No Solar", label: "No Solar" },
  { id: 'purchased', value: "Purchased", label: "Purchased" },
  { id: 'lease', value: "Lease", label: "Lease" },
]


export const STATUS_OF_REFINANCE = [
  { id: 'yes', value: "Yes", label: "Yes" },
  { id: 'no', value: "No", label: "No" },
]


export const STATUS_MANUFACTURED_HOME = [
  { id: 'yes', value: "Yes", label: "Yes" },
  { id: 'no', value: "No", label: "No" },
]


export const STATUS_MIXED_USE_PROPERTY = [
  { id: 'yes', value: "Yes", label: "Yes" },
  { id: 'no', value: "No", label: "No" },
]



export const STATUS_OF_NEW_LENDER = [
  { id: 'yes', value: "Yes", label: "Yes" },
  { id: 'no', value: "No", label: "No" },
]

export const TYPE_OF_REFINANCE = [
  { id: 'Rate/Term', value: "Rate/Term", label: "Rate/Term" },
  { id: 'Cash Out Refinance', value: "Cash Out Refinance", label: "Cash Out Refinance" },
]

export const REASON_OF_REFINANCE = [
  { label: "Debt Consolidation", value: 'Debt Consolidation' },
  { label: "Student Loan", value: 'Student Loan' },
  { label: "Home Improvement", value: 'Home Improvement' },
  { label: "Rate and Term Change", value: 'Rate and Term Change' },
]

export const HOME_HOA_MANAGEMENT = [
  { id: 'yes', value: "Yes", label: "Yes" },
  { id: 'no', value: "No", label: "No" },
]

export const PROPERTY_TYPE = [
  { label: "Single family", value: 'Single family' },
  { label: "Townhouse", value: 'Townhouse' },
  { label: "Co-op", value: 'Co-op' },
  { label: "Multi-unit (2-4)", value: 'Multi-unit(2-4)' },
  { label: "Condominium", value: 'Condo' },
  { label: "Manufactured home", value: 'Manufactured home' },
  { label: "Planned Unit Development (PUD)", value: 'Planned Unit Development (PUD)' },
  { label: "Property is located in project", value: 'Property is located in project' }
]

export const ATTACHMENT_TYPE = [
  { id: 'Attached', value: "Attached", label: "Attached" },
  { id: 'Detached', value: "Detached", label: "Detached" },
]


export const OCCUPANCY_TYPE = [
  { id: 'Primary', value: "Primary", label: "Primary Residence" },
  { id: 'Secondary', value: "Secondary", label: "Secondary Home" },
  { id: 'Investment', value: "Investment", label: "Investment Property" },
  // { id: 'FHS', value: "FHS", label: "FHA Secondary Residence" },
]

export const STATE = [
  { label: "California-CA", value: 'California-CA' },
  { label: "California-CB", value: 'California-CB' },
  { label: "New yourk", value: 'New yourk' },
]

export const TYPE_OF_CONTROL = [
  // { id: 'Joint Tenants', value: "Joint Tenants", label: "Joint Tenants" },
  //{ id: 'Community Property', value: "Community Property", label: "Community Property" },
  //{ id: 'Single Man', value: "Single Man", label: "Single Man" },
  // { id: 'Single Women', value: "Single Women", label: "Single Women" },
  // { id: 'Tenants in common', value: "Tenants in common", label: "Tenants in common" },
  //{ id: 'To be determined in Escrow', value: "To be determined in Escrow", label: "To be determined in Escrow" },
  { id: 'Sole Ownership', value: "Sole Ownership", label: "Sole Ownership" },
  { id: 'Joint Tenancy with Right of Survivorship', value: "Joint Tenancy with Right of Survivorship", label: "Joint Tenancy with Right of Survivorship" },
  { id: 'Life Estate', value: "Life Estate", label: "Life Estate" },
  { id: 'Tenancy by the Entirety', value: "Tenancy by the Entirety", label: "Tenancy by the Entirety" },
  { id: 'Tenancy in Common', value: "Tenants in common", label: "Tenants in common" },
  { id: 'Others', value: "Others", label: "Others" },
]



//------------------------------
// loan personal info
export const SUFFIX = [
  { label: "Jr", value: 'Jr' },
  { label: "Hz", value: 'Hz' }
]

export const STATUS_OF_CITIZENSHIP = [
  { id: 'US Citizen', value: "US Citizen", label: "US Citizen" },
  //{ id: 'Non US Citizen', value: "Non US Citizen", label: "Non-Permanent Resident Alien" },
  { id: 'Permanent Resident Alien', value: "Permanent Resident Alien", label: "Permanent Resident Alien" },
  { id: 'Non-Permanent Resident Alien', value: "Non-Permanent Resident Alien", label: "Non-Permanent Resident Alien" },
]

export const VISA_TYPE = [
  {/*{ label: "I-7974 / A", value: 'I-7974/A' },
  { label: "I-94", value: 'I-94' },
  { label: "Passport", value: 'Passport' },
  { label: "Visa Copy", value: 'Visa Copy' },
  { label: "USA Passport", value: 'USA Passport' },
  { label: "EAD", value: 'EAD' },
  { label: "Driving License", value: 'Driving License' },
  { label: "Green Card", value: 'Green Card' },
  { label: "Passport Card", value: 'Passport Card' },
{ label: "L1", value: 'L1' },*/},
  { label: "H-1B", value: "H-1B" },
  { label: "H-1C", value: "H-1C" },
  { label: "USCIS Form I-797", value: "USCIS Form I-797" },
  { label: "USCIS Form I-797C", value: "USCIS Form I-797C" },
  { label: "Other", value: 'Other' },

]

export const MARITAL_STATUS = [
  { id: 'married', value: "Married", label: "Married" },
  { id: 'unmarried', value: "Unmarried", label: "Unmarried" },
  { id: 'Seperated', value: "Seperated", label: "Seperated" },
]

// loan contact info 
export const CONTACT_STATE = [
  { label: "California - CA", value: 'California-CA' },
  { label: "NewYourk - NY", value: 'NewYourk-NY' },
  { label: "DC", value: 'DC' },
]

export const CONTACT_COUNTRY = [
  { label: "USA", value: 'USA' },
  { label: "Other", value: 'Other' },
  //{ label: "DC", value: 'DC' },
]


export const MAILING_ADDRESS_STATUS = [
  { id: 'yes', value: "Yes", label: "Yes" },
  { id: 'no', value: "No", label: "No" },
]

export const OWNERSHIP_STATUS = [
  { id: 'Rent', value: "Rent", label: "Rent" },
  { id: 'Own', value: "Own", label: "Own" },
  // { id: 'Rent Free', value: "Rent Free", label: "Rent Free" },
  { id: 'No Primary Housing Expenses', value: "No Primary Housing Expenses", label: "No Primary Housing Expenses" }
]

// DEPENDENt

export const LOAN_DEPENDENT_STATUS = [
  { id: 'yes', value: "yes", label: "Yes" },
  { id: 'no', value: "no", label: "No" },
]

// EMPLOYMENT
export const EMPLOYMENT_STATUS = [
  { id: 'employed', value: "employed", label: "Employed" },
  { id: 'self-employed', value: "self-employed", label: "Self-Employed" },
  { id: 'retired', value: "retired", label: "Retired" },
]
export const PAY_FREQUENCY = [
  { label: "Weekly", value: "Weekly" },
  { label: "Bi-weekly/Semi-Monthly", value: "Bi-weekly/Semi-Monthly" },
  { label: "Monthly", value: "Monthly" },
]


export const OWNERSHIP = [
  { id: 'more-than-25', value: "More than 25%", label: "More than 25%" },
  { id: 'less-than-25', value: "Less than 25%", label: "Less than 25%" },
]

export const STATE_OF_COUNTRY = [
  { label: "California - CA", value: 'California-CA' },
  { label: "NewYourk - NY", value: 'NewYourk-NY' },
]

// Demographic

export const ETHINI_CITY_STATUS = [
  { id: 'yes', value: "yes", label: "Yes" },
  { id: 'Not-Hispanic-or-Latino', value: "Not-Hispanic-or-Latino", label: "Not Hispanic or Latino" },
  { id: 'Not-interested-in-disclosing', value: "Not-interested-in-disclosing", label: "Not interested in disclosing" },
]

// Military Service 
export const MILITARY_SERVICE = [
  { label: "Currently serving as active duty", value: 'Currently serving as active duty' },
  { label: "Currently retired or discharged", value: "Currently retired or discharged" },
  { label: "Separated from the service", value: "Separated from the service" },
  { label: "Period of service is a non activated member of Reserve of National Guard", value: "Period of service is a non activated member of Reserve of National Guard" },
  { label: "Surviving spouse", value: "Surviving spouse" },
]

export const CHOOSE_TYPE = [
  { id: 'Mexican', value: "Mexican", label: "Mexican" },
  { id: 'Cuban', value: "Cuban", label: "Cuban" },
  { id: 'Puerto Rican', value: "Puerto Rican", label: "Puerto Rican" },
  { id: 'others', value: "others", label: "Others" },
]

export const RACE_TYPE = [
  { id: 'American-Indian-or-Alaska-Native', value: "American-Indian-or-Alaska-Native", label: "American Indian or Alaska Native" },
  { id: 'asian', value: "asian", label: "Asian" },
  { id: 'Native Hawaiian or Other pacific islander', value: "Native Hawaiian or Other pacific islander", label: "Native Hawaiian or Other pacific islander" },
  { id: 'Black or African American', value: "Black or African American", label: "Black or African American" },
  { id: 'White', value: "White", label: "White" },
  { id: 'Not interested in disclosing', value: "Not interested in disclosing", label: "Not interested in disclosing" },

]

export const RACE_SUB_TYPE = [
  { id: 'Asian Indian', value: "Asian Indian", label: "Asian Indian" },
  { id: 'chinese', value: "chinese", label: "Chinese" },
  { id: 'Fillpino', value: "Fillpino", label: "Fillpino" },
  { id: 'Japanese', value: "Japanese", label: "Japanese" },
  { id: 'korean', value: "korean", label: "Korean" },
  { id: 'Vietnamies', value: "Vietnamies", label: "Vietnamies" },
  { id: 'Others', value: "Others", label: "Other Asian" },
]

export const RACE_SUB_TYPE_NATIVE = [
  { id: 'Native Hawaiian', value: "Native Hawaiian", label: "Native Hawaiian" },
  { id: 'Guamanain or Chamorro', value: "Guamanain or Chamorro", label: "Guamanain or Chamorro" },
  { id: 'Samoan', value: "Samoan", label: "Samoan" },
  { id: 'Other Pacific Islanders', value: "Other Pacific Islanders", label: "Other Pacific Islanders" },
]
export const SEX = [
  { id: 'male', value: "male", label: "Male" },
  { id: 'female', value: "female", label: "Female" },
  { id: 'Not-interested-in-disclosing', value: "Not-interested-in-disclosing", label: "Not interested in disclosing" },
]


// Real State

export const REAL_STATE_STATUS = [
  { id: 'yes', value: "Yes", label: "Yes" },
  { id: 'no', value: "No", label: "No" },
]

export const SOLAR_STATUS = [
  { id: 'Lease', value: "Lease", label: "Lease" },
  { id: 'Purchased', value: "Purchased", label: "Purchased" },
  { id: 'No solar', value: "No solar", label: "No solar" },
]

export const MORTAGE_NEW_LENDER = [
  { id: 'yes', value: "Yes", label: "Yes" },
  { id: 'no', value: "No", label: "No" },
]

export const REAL_STATE_OWNED_BY = [
  { label: "Borrower", value: 'Borrower' },
  { label: "Partner", value: 'Partner' },
]
export const REAL_STATE_OWNED_BY_WITHOUT_PARTNER = [
  { label: "Borrower", value: 'Borrower' },
]

export const PROPERTY_STATUS = [
  { id: 'Sold', value: "Sold", label: "Sold" },
  { id: 'Pending sale', value: "Pending sale", label: "Pending sale" },
  { id: 'Retained ', value: "Retained", label: "Retained " },
]

export const MORTAGE_PROPERTY = [
  { id: 'yes', value: "Yes", label: "Yes" },
  { id: 'no', value: "No", label: "No" },
]

export const INTENDED_OCCUPANCY = [
  { id: 'Investment', value: "Investment", label: "Investment" },
  { id: 'Primary Residence', value: "Primary Residence", label: "Primary Residence" },
  { id: 'Second Home', value: "Second Home", label: "Second Home" },
  { id: 'Others', value: "Others", label: "Others" },
]


// DECLARATION


export const DECLARATION_TYPE_OF_PROPERTY = [
  { label: "Primary Residence (PR)", value: 'A' },
  { label: "FHA Secondary Residence (SR)", value: 'B' },
  { label: "Second Home (SH)", value: 'C' },
  { label: "Investment Property (IP)", value: 'D' },
]

export const DECLARATION_TITLE_OF_PROPERTY = [
  { label: "By yourself (S), ", value: 'B' },
  { label: "Jointly with your spouse (SP)", value: 'C' },
  { label: "Jointly with another person (O)", value: 'D' },
]

export const DECLARATION_TYPE_OF_BANKRUPTCY = [
  { label: "Chapter 7", value: 'A' },
  { label: "Chapter 11", value: 'B' },
  { label: "Chapter 12", value: 'C' },
  { label: "Chapter 13", value: 'D' },
]
// ASSET CREDIT

export const ACCOUNT_TYPE = [
  { label: "Checking", value: 'Checking' },
  { label: "Certificate of deposits", value: 'Certificate of deposits' },
  { label: "Savings", value: 'Savings' },
  { label: "Bonds", value: 'Bonds' },
  { label: "Stock options", value: 'Stock options' },
  { label: "Bridge loan proceeds", value: 'Bridge loan proceeds' },
  { label: "Trust accounts", value: 'Trust accounts' },
  { label: "Mutual funds", value: 'Mutual funds' },
  { label: "Money market", value: 'Money market' },
  { label: "Stocks, retirement", value: 'Stocks, retirement' },
  { label: "Individual development account", value: 'Individual development account' },
  { label: "Cash value of life insurance", value: 'Cash value of life insuranceds' },
  { label: "Proceeds from real estate property to be sold on or before closing", value: 'Proceeds from real estate property to be sold on or before closing' },
  { label: "Proceeds from Sale of non-real estate assets", value: 'Proceeds from Sale of non-real estate assets' },
  { label: "Secured borrowed funds", value: 'Secured borrowed funds' },
  { label: "Unsecured borrowed funds", value: 'Unsecured borrowed funds' },
  { label: "Earnest Money", value: 'Earnest Money' },
  { label: "Relocation Fund", value: 'Relocation Fund' },
  { label: "Sweat Equity", value: 'Sweat Equity' },
  { label: "Employer assistance", value: 'Employer assistance' },
  { label: "Rent credit", value: 'Rent credit' },
  { label: "Trade Equity", value: 'Trade Equity' },
  { label: "Lot equity", value: 'Lot equity' },
  { label: "Credits", value: 'Credits' },
  { label: "Other", value: 'Other' },
]

export const ASSET_OWNED_BY = [
  { label: "Borrower", value: 'Borrower' },
  { label: "Partner", value: 'Partner' },
  { label: "Joint", value: 'Joint' },
]
export const ASSET_OWNED_BY_WITHOUT_PARTNER = [
  { label: "Borrower", value: 'Borrower' },
]

// Document

export const DOCUMENT_TYPE = [
  { label: "W2", value: 'W2' },
  { label: "Tax return", value: 'Tax Return' },
  { label: "RPA(Purchase agreement)", value: 'Purchase Agreement' },
  { label: "Mortagage statement", value: 'Mortgage Statement' },
  { label: "Internet connection receipt", value: 'Internet Statement' },
  { label: "CD(Previous loan closing disclosure)", value: 'Closing Disclosure' },
  { label: "Mortagage Note(if purchased with in last 12 months)", value: 'Mortgage Note' },
  { label: "Others", value: 'Others' },
]
// Liabilities

export const LOAN_CREDIT_TYPE = [
  { id: 'Mortgage', value: "Mortgage", label: "Mortgage Liability" },
  { id: 'Non-Mortgage', value: "Non-Mortgage", label: "Non-Mortgage Liability" },
  { id: 'Expense', value: "Expense", label: "Expense" },
]

export const LOAN_CREDIT_TYPE_MORTGAGE = 'Mortgage'

export const LOAN_CREDIT_TYPE_NON_MORTGAGE = 'Non-Mortgage'

export const LOAN_CREDIT_TYPE_EXPENSE = 'Expense'

export const LOAN_CREDIT_TYPE_MORTGAGE_OPTION = [
  { value: "First Lien", label: "First Lien" },
  { value: "Second Lien", label: "Second Lien" },
  { value: "HELOC", label: "HELOC" },
]

export const LOAN_CREDIT_TYPE_NON_MORTGAGE_OPTION = [
  { value: "Collections Judgements and Liens", label: "Collections Judgements and Liens" },
  { value: "Installment", label: "Installment (e.g., car, student, personal loans)" },
  { value: "Lease", label: "Lease (not real estate)" },
  { value: "Open 30-Day", label: "Open 30-Day (balance paid monthly)" },
  { value: "Revolving", label: "Revolving (e.g., credit cards)" },
  { value: "Tax Lien", label: "Tax Lien" },
  { value: "Taxes", label: "Taxes" },
  { value: "Others", label: "Others" },
]

export const LOAN_CREDIT_TYPE_EXPENSE_OPTION = [
  { value: "Alimony", label: "Alimony" },
  { value: "Child Support", label: "Child Support" },
  { value: "Job Related Expenses", label: "Job Related Expenses" },
  { value: "Separate Maintenance", label: "Separate Maintenance" },
  { value: "Others", label: "Others" },
]


export const LOAN_OWNED_BY = [
  { label: "Borrower", value: 'Borrower' },
  { label: "Partner", value: 'Partner' },
]


// Constant Value Start
export const LOAN_TERM_VALUE = [
  { value: "Fixed", label: "Fixed" },
  {
    value: "Adjustable Rate Mortgage(ARM)",
    label: "Adjustable Rate Mortgage(ARM)",
  },
];

export const APPRAISAL_STATUS_VALUE = [
  { value: "Not Ordered", label: "Not Ordered" },
  { value: "Ordered", label: "Ordered" },
  { value: "Received", label: "Received" },
];

export const APPRAISAL_TYPE_VALUE = [
  { value: "Conventional", label: "Conventional" },
  { value: "FHA", label: "FHA" },
  { value: "VA", label: "VA" },
];

export const LOCK_STATUS_VALUE = [
  { value: "Not Locked", label: "Not Locked" },
  { value: "Locked", label: "Locked" },
];

// Loan Application Task
export const TASK_TYPE_CHOICE = [
  {
    id: "Download & upload",
    value: "Download & upload",
    label: "Download & upload",
  },
  {
    id: "Upload",
    value: "Upload",
    label: "Upload",
  },
  {
    id: "External",
    value: "External",
    label: "External",
  },
];

export const TASK_LENDERS_CONDITION = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];




export const TASK_VENDOR = [
  { value: "borrower", label: "borrower" },
  { value: "thirdparty", label: "Third Party" },
  { value: "other", label: "Others" },
];

export const BLANK_DOCUMENT_STATUS = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const TASK_PRIORITY = [
  { id: "High", value: "High", label: "High" },
  { id: "Low", value: "Low", label: "Low" },
];


// object key name change for review 
export const NEW_KEYS = {
  firstName: "First Name",
  lastName: "Last Name",
  phone: "Phone Number",
  email: "Email ID",
  companyName: "Company Name",
  creditType: "Credit Type",
  propertyType: "Property Type",
  occupancyType: "Occupancy Type",
  attachmentType: "Attachment Type",
  propertyBuiltDate: "Property Built Date",
  purchasePrice: "Purchase Price",
  downPayment: "Down Payment",
  downPaymentAmount: "Down Payment Amount",
  loanAmount: "Loan Amount",
  firstMortgagePayment: "First Mortgage Payment",
  secondMortgagePayment: "Second Mortgage Payment",
  thirdMortgagePayment: "Third Mortgage Payment",
  homeOwnerInsurance: "Home Owner Insurance",
  cashoutAmount: "Cash out Amount",
  isMortgageStatementAvailable: "Is Mortgage Statement Available",
  isRecentlyRefinanced: "Is Recently Refinanced",
  mortgageInsurance: "Mortgage Insurance",
  buyingOrRefinancing: "Are you buying a new home or refinancing the existing one?",
  solarPurchasedStatus: "Is solar purchased?",
  statusOfPurchase: "Status of Purchase",
  typeOfTitle: "Type of Title",
  statusOfRealtor: "Are you working with Realtor?",
  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  closingDate: "Closing Date",
  homeHOAManagement: "Do the home under HOA management?",
  datePropertyAcquired: "Date Property Acquired",
  lastFinancedDate: "Last Financed Date",
  HOIAmount: "HOI Amount",
  HOADues: "HOA Dues",
  solarleaseapyment: "Solar Lease payment (Per month)",
  countryPropertyTax: "Country Property Tax",
  loanTermType: "loan Term Type",
  loanOptions: "Loan Options",
  typeOfRefinance: "Refinance Type",
  reasonOfRefinance: "Refinance Reason",
  middleName: "Middle Name",
  suffix: "Suffix",
  ssn: "SSN",
  dateOfBirth: "Date of Birth",
  maritalStatus: "Marital Status",
  statusOfCitizenship: "Citizenship",
  visatype: "Visa type",
  unit: "Unit",
  city: "City",
  stateOfCountry: "State",
  zip: "ZIP",
  type: "Type",
  year: "Year",
  month: "Month",
  ethinicityStatus: "Are you a Hispanic or Latino?",
  chooseType: "Choose the Type",
  raceType: "Race",
  raceSubType: "Mention your Tribe",
  sex: "Sex",
  alimony: "Are you a obligated to pay alimony?",
  moneyForLoan: "About this property and your money for this loan",
  primaryResident: "Will you occupy this property as your primary resident?",
  ownershipProperty: "Have you had ownership property in last three years?",
  affiliation: "If this is a purchase transaction, do you have a family relationship or business affiliation with the seller of the property?",
  moneyFromAnotherParty: "Are you borrowing any money for this real estate transaction ( e.g. money for your closing cost or down payment) for obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application.",
  loanOnAnotherProperty: "Have you or will you be applying for a Mortgage Loan on another property ( Not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application.",
  creditOnBeforeLoan: "Have you or will you be applying for any new credit ( e.g. installment loan , credit card, etc.) on or before closing this loan that is not disclosed in this application.",
  lien: "Will this property be subject to a lien that could take priority or the first mortgage lien, such as a Clean Energy lien paid through your property taxes ( e g. the property assessed Clean Energy program)?",
  coSignerOrGuarantor: "Are you a co-signer or guarantor on any Dept or loan that is not disclosed on this application?",
  outstandingJudgement: "Are there any outstanding judgement against you?",
  federalDept: "are you currently delinquent or under fault on a federal Dept",
  partyLawsuit: "Are you party lawsuit in which you potentially have any personal financial liability?",
  convertTitle: "Have you convert title to any property in lieu of foreclosure in the past seven years?",
  preForeclosure: "Within the past seven years, Have you completed a pre- foreclosure sale or a short sale, whereby the property was sold to a third party and the lender agreed to accept less than the outstanding mortgage balance due.",
  propertyForeclosed: "Have you had property foreclosed upon in the last seven years?",
  loanDependentStatus: "Do you have dependents?",
  dependent: "Number Dependents",
  ageDependent1: "Age of Dependent 1",
  ageDependent2: "Age of Dependent 2",
  ageDependent3: "Age of Dependent 3",
  ageDependent4: "Age of Dependent 4",
  statusOfMiliteryService: "Have you or your spouse ever been part of United State Armed Force?",
  militaryService: "Service status",
  expireDate: "Expiration Date",
  employmentStatus: "Employment Status",
  startDate: "Start Date",
  endDate: "End Date",
  ownerShip: "Ownership",
  retirementDate: "Retirement Date",
  retirementIncome: "Retirement Income",
  profession: "Years in this line of profession",
  positionOfTitle: "Position or Title",
  businessName: "Employer or Business Name",
  employNumber: "Employer Number",
  monthlyIncome: "Monthly Income",
  accountType: "Account Type",
  institutionName: "Institution Name",
  accountNo: "Account No.",
  marketValue: "Cash or Market value",
  ownedBy: "Asset Owned by",
  isDownPayment: "Is this asset going to use for down payment?",
  realestateStatus: "Do you own any real estate?",
  solarStatus: "Is solar purchased?",
  isHoaManaged: "Do the home under HOA management?",
  newLeaderStatus: "Did the mortgage transfer to a new lender recently?",
  newLeaderStatement: "Do you have a mortgage statement with the new lender?",
  realEstateOwnedBy: "Real estate Owned by",
  propertyStatus: "Property Status",
  propertyMortgage: "Do you have mortgage on this property?",
  intendedOccupancy: "Intended Occupancy",
  propertyInsurance: "Property Insurance",
  propertyTaxes: "Property Taxes",
  solarLeasePayment: "Solar Lease Payment",
  hoaPayment: "HOA Payment",
  liabilityType: "Liability Type",
  unpaidBalance: "Unpaid Balance",
  monthlyPay: "Monthly Payment",
  monthsLeft: "Months Left to pay",
  isStillWorking: "Are you still working there?",
  state: "State",
  documentType: "Document Type",
  documentName: "Document Name",
  fileName: "File Name",
  note: "Note",
};

export const REMOVE_KEYS = [
  'formId',
  'id',
  'docId',
  'matchStatus',
  'validStatus',
  'currentState',
  'uploadDate',
  'isOverridden',
  'isSkipped',
  'data',
  'info',
  'overrideReason',
  'chidSectionName',
  'realtor',
  'sideIncome',
  'loanCode',
  'text',
  'tribe',
  'checkbox',
  'isDeclared',
  // 'note',
  'balance',
  'propertyAddress',
  'country',
  'isCurrent',
]

// Chat Tab
export const CHAT_TABS = [
  { id: 1, title: "Internal Chat" },
  { id: 2, title: "External Chat" },
];

export const DEMO_IMAGE = "https://picsum.photos/200/300"

export const GOOGLE_MAP_API = 'AIzaSyDD30wbrfkzGlaa9K87NpXULYQ2Ga8AWOc'

// Constant Value End

